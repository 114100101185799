import { CustomAvatar, LazyBreadcrumb } from '@europrocurement/flexy-components';
import {
    SousClient,
    SousClientsSelector,
    useTiersService,
    TiersAppDispatch,
    SousClientApi,
} from '@europrocurement/l2d-domain';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { Typography, useTheme, Tooltip, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useCurrentPrescriber } from '@europrocurement/l2d-modules/hooks/useCurrentPrescriber';
import {
    ColumnDatatable,
    FiltersDatatableList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { Box } from '@mui/system';
import { customizerSelector } from '@europrocurement/flexy-components/redux/storeConfig/selectors';
import { AnyAction } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { AddPrescriberClientModal } from '@europrocurement/l2d-modules/modules/PrescriberClient/components/AddPrescriberClient';
import ActionMenuSocietes from '../../components/ActionMenuSocietes';
import { TiersObjectGroup, useSocietesObjectGroup } from '../../hooks';

const ListeSocietes: React.FunctionComponent = function () {
    const { xIdSociete } = useSelector(customizerSelector);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const { searchTiersObjectGroup } = useSocietesObjectGroup();

    const dispatch = useDispatch<TiersAppDispatch>();
    const prescripteur = useCurrentPrescriber();
    const datasource: DataSource<SousClient> = useSelector(SousClientsSelector).main;
    const { getSousClients } = useTiersService();

    useEffect(() => {
        dispatch({
            type: 'sousClient/setmainFilter',
            payload: {
                key: 'prescripteurId',
                value: prescripteur.id,
            },
        });
        dispatch(getSousClients({}));
    }, [dispatch, getSousClients, prescripteur.id]);

    const navigate = useNavigate();

    const theme = useTheme();
    const { palette } = theme;
    const breadcrumbItems = [
        {
            link: '/accueil',
            icon: {
                icon: ['fasl', 'house'] as IconProp,
                sx: { display: 'flex', alignItems: 'center' },
            },
        },
        {
            link: '/societes',
            path: 'Sociétés',
        },
        'Sociétés',
    ];
    const handleRedirect = () => {
        navigate('/dossiers', { replace: true });
    };

    function normalizeString(sData: string | undefined, type: string = 'phone') {
        if (sData === undefined) {
            return '';
        }
        // Supprimer tous les espaces existants
        const cleaned = sData.replace(/\s+/g, '');

        if (type === 'siren') {
            return cleaned.match(/.{1,3}/g)?.join(' ') || '';
        }
        // Vérifier si le numéro commence par un indicatif (+33, etc.)
        const match = cleaned.match(/^(\+\d+)(.*)$/);
        if (match) {
            const internationalCode = match[1];
            const restOfNumber = match[2];
            const formattedRest = restOfNumber.match(/.{1,2}/g)?.join(' ') || '';
            return `${internationalCode} ${formattedRest}`.trim();
        }

        // Si pas d'indicatif, formater en blocs de 2 chiffres
        return cleaned.match(/.{1,2}/g)?.join(' ') || '';
    }
    const hoverBackground = theme.palette.primary.light;
    const hoverColor = theme.palette.getContrastText(hoverBackground);
    const sousClientColumns: ColumnDatatable[] = [
        {
            isDisplayed: true,
            sortable: true,
            label: 'Denomination',
            field: 'raisonSociale',
            render: 'raisonSociale',
            sx: {
                fontWeight: 500,
                paddingLeft: '8px',
            },
        },
        {
            isDisplayed: true,
            sortable: false,
            label: 'Adresse',
            field: 'adresses',
            render: (sousClient: SousClientApi) => {
                const adressePrincipal = sousClient.adresses.find((adresse) => adresse.defaut);
                if (adressePrincipal) {
                    const fields = [
                        adressePrincipal.adresse1,
                        adressePrincipal.adresse2,
                        adressePrincipal.adresse3,
                        adressePrincipal.adresse4,
                        adressePrincipal.codePostal,
                        adressePrincipal.ville,
                    ];
                    const adressConcaten = fields
                        .filter((field) => field != null && field.trim() !== '')
                        .join(' ');

                    return (
                        <Typography
                            variant="l2dbodyxs"
                            sx={{
                                color: palette.grey[700],
                            }}
                        >
                            {adressConcaten}
                        </Typography>
                    );
                }
                return null;
            },
        },
        {
            isDisplayed: true,
            label: 'SIREN',
            sortable: true,
            field: 'siren',
            render: (sousClient: SousClientApi) => (
                <Typography
                    variant="l2dbodyxs"
                    sx={{
                        color: palette.grey[700],
                    }}
                >
                    {normalizeString(sousClient.siren, 'siren')}
                </Typography>
            ),
        },
        {
            isDisplayed: true,
            label: 'Téléphone',
            field: 'contacts',
            sortable: false,
            render: (sousClient: SousClientApi) => {
                const contactPrincipal = sousClient.contacts.find((contact) => contact.principal);
                if (contactPrincipal) {
                    const telephonesContactPrincipal = contactPrincipal.telephonesContact.find(
                        (tel) => tel.defaut,
                    );
                    return (
                        <Box
                            sx={{
                                color: palette.grey[700],

                                display: 'flex',
                                flexDirection: 'row',
                                gap: '5px',
                            }}
                        >
                            <FaOptionIcon icon={['fasl', 'phone']} />
                            <Typography
                                variant="l2dbodyxs"
                                sx={{
                                    color: palette.grey[700],
                                }}
                            >
                                {normalizeString(telephonesContactPrincipal?.telephone)}
                            </Typography>
                        </Box>
                    );
                }
                return null;
            },
        },
        {
            isDisplayed: true,
            sortable: false,
            label: 'Email',
            field: 'contacts',
            render: (sousClient: SousClientApi) => {
                const contactPrincipal = sousClient.contacts.find((contact) => contact.principal);
                if (contactPrincipal) {
                    const emailContactPrincipal = contactPrincipal.emailsContact.find(
                        (email) => email.defaut,
                    );
                    return (
                        <Box
                            sx={{
                                color: palette.grey[700],

                                display: 'flex',
                                flexDirection: 'row',
                                gap: '5px',
                            }}
                        >
                            <FaOptionIcon icon={['fasl', 'envelope']} />
                            <Typography
                                variant="l2dbodyxs"
                                sx={{
                                    color: palette.grey[700],
                                }}
                            >
                                <a
                                    href={`mailto:${emailContactPrincipal?.email}`}
                                    style={{ color: 'inherit', textDecoration: 'inherit' }}
                                >
                                    {emailContactPrincipal?.email}
                                </a>
                            </Typography>
                        </Box>
                    );
                }
                return null;
            },
        },
        {
            isDisplayed: true,
            label: 'Dos...',
            headerTooltip: 'Dossiers Finalisés',
            field: 'fCollectionDossier',
            sortable: true,
            render: (sousClient: SousClientApi) => {
                const aDoss = sousClient?.fCollectionDossier;
                let nbDossFinal = 0;
                let sNumDoss = '';
                const idStatus = 1;

                if (aDoss) {
                    sNumDoss = aDoss
                        .flatMap(
                            (dossier) =>
                                dossier.fProDossier
                                    ?.filter((proDossier) => proDossier.fstat?.fstatId === idStatus)
                                    ?.map((proDossier) => `Numéro: ${proDossier.fdosNumero}`)
                                    .filter(Boolean) || [],
                        )
                        .join('<br> ');
                    nbDossFinal = aDoss.reduce((total, dossier) => {
                        // Vérifie si fProDossier est défini
                        if (!dossier.fProDossier) {
                            return total;
                        }

                        // Filtre les fProDossier ayant un fstat défini avec fstatId = 4
                        const count = dossier.fProDossier.filter(
                            (proDossier) =>
                                proDossier.fstat && proDossier.fstat.fstatId === idStatus,
                        ).length;

                        return total + count;
                    }, 0);
                }
                return (
                    <Tooltip
                        title={<span dangerouslySetInnerHTML={{ __html: sNumDoss }} />}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '16px',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                color: 'text.gris70',

                                display: 'flex',
                                flexDirection: 'row',
                                gap: '5px',
                            }}
                        >
                            <FaOptionIcon icon={['fasl', 'folder']} />
                            <Typography
                                variant="l2dtableCell"
                                component="span"
                            >
                                {nbDossFinal}
                            </Typography>
                        </Box>
                    </Tooltip>
                );
            },
            onClickCell: handleRedirect,
        },
        {
            isDisplayed: true,
            label: 'For...',
            headerTooltip: 'Formaliste',
            field: 'contacts',
            sortable: true,
            render: (sousClient: SousClientApi) => {
                const contactPrincipal = sousClient.contacts.find((contact) => contact.principal);
                if (contactPrincipal) {
                    return (
                        <Tooltip
                            title={`${contactPrincipal.prenomContact} ${contactPrincipal.nomContact}`}
                        >
                            <Box
                                sx={{
                                    borderRadius: '16px',
                                    width: '32px',
                                    height: '32px',
                                    margin: '0px 16px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <CustomAvatar
                                    sx={{
                                        width: '32px',
                                        height: '32px',
                                    }}
                                    firstName={contactPrincipal.prenomContact}
                                    lastName={contactPrincipal.nomContact}
                                />
                            </Box>
                        </Tooltip>
                    );
                }
                return null;
            },
        },
        {
            label: 'Actions',
            displayLabel: false,
            render: (sousClient: SousClientApi) => <ActionMenuSocietes idTiers={sousClient.id} />,
            isDisplayed: true,
            sortable: false,
            sx: {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
            },
        },
    ];

    const sousClientFilters: FiltersDatatableList = [
        {
            label: 'Sociétés',
            field: 'raisonSociale',
            type: 'text',
            icon: 'building-user',
        },
        {
            label: 'Formalités',
            field: 'formaliteFdoss',
            type: 'text',
            icon: 'folder',
        },
        {
            label: 'Références dossiers',
            field: 'formaliteFdossNum',
            type: 'text',
            icon: 'note',
        },
        {
            label: 'Références clients',
            field: 'formaliteFdossSteRef',
            type: 'text',
            icon: 'user',
        },
        {
            label: 'Mes groupes',
            field: 'objectgroups',
            type: 'selectApi',
            icon: 'folder',
            searchFunction: searchTiersObjectGroup,
            renderItem: (item: TiersObjectGroup) => item.label as unknown as string,
            valueMap: (item: TiersObjectGroup) => item.id as unknown as string,
        },
    ];
    const handleSuccess = () => {
        setModalOpen(false);
        // attente 1 seconde pour récupération du sous-client
        setTimeout(() => {
            dispatch({
                type: 'sousClient/setmainPagination',
                payload: {
                    page: 1,
                },
            });
            dispatch({
                type: 'sousClient/setmainFilter',
                payload: {
                    key: 'prescripteurId',
                    value: prescripteur.id,
                },
            });
            dispatch(getSousClients({}) as unknown as AnyAction);
        }, 1000);
    };

    return (
        <>
            <LazyBreadcrumb
                path={breadcrumbItems}
                separator=" / "
                sx={{ marginBottom: '1.5em' }}
            />
            <Box
                sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-end' }}
            >
                <Button
                    sx={{
                        display: 'flex',
                        alignItems: 'baseline',
                        marginRight: '10px',
                        padding: '14px 22px',
                        borderRadius: '12px',
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.getContrastText(theme.palette.primary.dark),
                        ':hover': {
                            backgroundColor: hoverBackground,
                            color: hoverColor,
                        },
                    }}
                    onClick={() => setModalOpen(true)}
                >
                    <Typography
                        variant="l2dbodys"
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                        }}
                    >
                        <FaOptionIcon icon={faPlus} />
                        Ajouter une société
                    </Typography>
                </Button>
                <AddPrescriberClientModal
                    open={isModalOpen}
                    onSuccess={handleSuccess}
                    onCancel={() => {
                        setModalOpen(false);
                    }}
                />
            </Box>

            <StoreDatatable
                dataSource={datasource}
                columns={sousClientColumns}
                fetchData={getSousClients}
                filters={sousClientFilters}
                localStorageKey="FlexyStoreDatatableSociete"
                observers={[xIdSociete]}
                sx={{ marginBottom: '2.25em' }}
            />
        </>
    );
};

export default ListeSocietes;
