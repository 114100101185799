import React from 'react';
import { Container } from '@mui/system';
import DetailDossierHeader from './DetailDossierHeader';
import SelectedDossierProvider from '../../providers/SelectedDossierProvider';
import DetailDossierBody from './DetailDossierBody';

const DetailDossier: React.FunctionComponent = function () {
    return (
        <Container maxWidth="lg">
            <SelectedDossierProvider>
                <DetailDossierHeader />
                <DetailDossierBody />
            </SelectedDossierProvider>
        </Container>
    );
};

export default DetailDossier;
