import { useContext } from 'react';
import {
    FormaliteApi,
    DossierApi,
    DossierOpenSearchApi,
    CollectionDeDossierApi,
    FichiersDossierApi,
    DossierDossierRead,
    FormalisteApi,
    StatutApi,
    PrestationApi,
    TapesDeTraitementDesDossiersApi,
    MessageApi,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { DossierFormalite, FormalityModel } from '@europrocurement/l2d-domain/reducers';
import { FormalityReducerType } from '@europrocurement/l2d-domain/reducers/formalites/types';
import { DataSourcesThunksType, getDataThunkType } from '@europrocurement/l2d-redux-utils';
import L2DApiContext from '../L2DApiContext';

export const useFormaliteService = () => {
    const context = useContext(L2DApiContext);
    if (!context) {
        throw new Error('useL2DApi must be used within a L2DApiProvider');
    }

    if (!context.formalite) {
        throw new Error('Formality service is not registred in L2DApiProvider');
    }
    const { apis, slices, thunks, fetchItems, getItems, models, reducer } = context.formalite;

    return {
        formaliteApi: apis.formaliteApi as FormaliteApi,
        dossierApi: apis.dossierApi as DossierApi,
        dossierOpenSearchApi: apis.dossierOpenSearchApi as DossierOpenSearchApi,
        collectionApi: apis.collectionApi as CollectionDeDossierApi,
        fichiersDossierApi: apis.fichiersDossierApi as FichiersDossierApi,
        formalisteApi: apis.formalisteApi as FormalisteApi,
        dossierStepApi: apis.dossierStepApi as TapesDeTraitementDesDossiersApi,
        statusApi: apis.statusApi as StatutApi,
        prestationFormaliteApi: apis.prestationFormaliteApi as PrestationApi,
        messageApi: apis.messageApi as MessageApi,

        dossierFormaliteSlice: slices.dossierFormaliteSlice,
        dossierOpenSearchSlice: slices.dossierOpenSearchSlice,
        formaliteSlice: slices.formaliteSlice,
        legalStatusSlice: slices.legalStatusSlice,
        categoryFormaliteSlice: slices.categoryFormaliteSlice,
        formaliteDocumentSlice: slices.formaliteDocumentSlice,
        formalisteSlice: slices.formalisteSlice,
        dossierStepSlice: slices.dossierStepSlice,
        statusSlice: slices.statusSlice,

        dossierFormaliteDataSourcesThunks:
            thunks.dossierFormaliteDataSourcesThunks as DataSourcesThunksType<DossierDossierRead>,
        dossierOpenSearchDataSourcesThunk: thunks.dossierFormaliteDataSourcesThunks,
        formaliteDataSourcesThunks: thunks.formaliteDataSourcesThunks,
        categoryFormaliteDataSourcesThunks: thunks.categoryFormaliteDataSourcesThunks,
        legalStatusDataSourcesThunks: thunks.legalStatusDataSourcesThunks,
        formaliteDocumentDataSourcesThunks: thunks.formaliteDocumentDataSourcesThunks,
        formalisteDataSourcesThunks: thunks.formalisteDataSourcesThunks,
        dossierStepDataSourcesThunks: thunks.dossierStepDataSourcesThunks,
        statusDataSourcesThunks: thunks.statusDataSourcesThunks,

        getFormalites: fetchItems.getFormalites,
        getDossierFormalite: fetchItems.getDossierFormalite as getDataThunkType<DossierFormalite>,
        getOpenSearchDossiers: fetchItems.getOpenSearchDossiers,
        getAutocompleteDossierFormalite: fetchItems.getAutocompleteDossierFormalite,
        getFormalistes: fetchItems.getFormalistes,
        getDossierSteps: fetchItems.getDossierSteps,
        getStatuses: fetchItems.getStatuses,

        selectDossierFormalite: getItems.selectDossierFormalite,
        selectOpenSearchDossier: getItems.selectOpenSearchDossier,
        selectFormality: getItems.selectFormality,
        selectAutocompleteDossierFormalite: getItems.selectAutocompleteDossierFormalite,
        selectFormaliste: getItems.selectFormaliste,
        selectDossierStep: getItems.selectDossierStep,
        selectStatus: getItems.selectStatus,

        formalityModel: models.formality as FormalityModel,
        reducer: reducer as FormalityReducerType,
    };
};
