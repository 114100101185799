import React, { useState } from 'react';

import { Checkbox, SxProps, TableCell, TableRow, Typography, TypographyProps } from '@mui/material';

import useSelectItem from '../FlexyDatatableTable/useSelectItem';
import { FlexyDatatableSelectionActionProps } from '../FlexyDatatableSelectionAction/FlexyDatatableSelectionAction';
import { ColumnDatatable } from '../ColumnDatatable';

const tableCellSx: SxProps = {
    padding: '7px 8px',
    borderBottom: 'none',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderTopColor: 'text.gris10',
};

export type FlexyDatatableBodySuccededRowProps<T extends Record<string, unknown>> = {
    textCellVariant?: TypographyProps['variant'];
    onClickRow?: (e: React.MouseEvent<HTMLElement>, item?: T) => void;
    onWheelClickRow?: (e: React.MouseEvent<HTMLElement>, item?: T) => void;
    item: T;
    computeSxRow?: (item: T) => SxProps;
    columns: Array<ColumnDatatable<T>>;
    rowsActions?: Array<FlexyDatatableSelectionActionProps>;
};

const removeDatatableIdentifier = (item: Record<string, unknown>) => {
    const newItem = { ...item };
    if (newItem.datatableIdentifier) {
        delete newItem.datatableIdentifier;
    }
    return newItem;
};

const FlexyDatatableBodySuccededRow = function <T extends Record<string, unknown>>({
    textCellVariant,
    onClickRow,
    onWheelClickRow,
    item,
    rowsActions = [],
    columns,
    computeSxRow = () => null,
}: FlexyDatatableBodySuccededRowProps<T>) {
    const { isItemSelected, handleSelectItem } = useSelectItem();

    const [rowHover, setRowHover] = useState(false);

    let bgColor = 'inherit';

    if (isItemSelected(item)) {
        bgColor = 'primary.light';
    }

    return (
        <TableRow
            sx={{
                '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: isItemSelected(item) ? bgColor : 'transparent',
                    boxShadow: '1px 1px 15px 0px rgba(0, 0, 0, 0.1)',
                },
                height: '70px',
                backgroundColor: `${bgColor}`,
                ...computeSxRow(item),
            }}
            onMouseEnter={() => setRowHover(true)}
            onMouseLeave={() => setRowHover(false)}
        >
            {rowsActions.length > 0 && (
                <TableCell
                    key={`${item.datatableIdentifier}-select-item`}
                    sx={{
                        ...tableCellSx,
                        width: '60px',
                        display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'table-cell',
                            lg: 'table-cell',
                            xl: 'table-cell',
                        },
                    }}
                >
                    <Checkbox
                        checked={isItemSelected(item)}
                        onChange={() => {
                            handleSelectItem(item);
                        }}
                        sx={{
                            width: 36,
                            height: 36,
                            opacity: isItemSelected(item) || rowHover ? '1' : '0',
                        }}
                    />
                </TableCell>
            )}
            {columns.map((column, idx) => (
                <TableCell
                    key={`${item.datatableIdentifier}-${column.label}`}
                    onClick={(e) => {
                        if (column.onClickCell && typeof column.onClickCell === 'function') {
                            column.onClickCell(e, removeDatatableIdentifier(item) as T);
                        } else if (onClickRow && typeof onClickRow === 'function') {
                            onClickRow(e, removeDatatableIdentifier(item) as T);
                        }
                    }}
                    onAuxClick={(e) => {
                        const wheelClick = e.button === 1;
                        if (wheelClick) {
                            if (onWheelClickRow) {
                                onWheelClickRow(e, removeDatatableIdentifier(item) as T);
                            }
                        }
                    }}
                    sx={
                        {
                            ...tableCellSx,
                            paddingLeft: idx === 0 ? '15px' : undefined,
                            ...column.sx,
                        } as SxProps
                    }
                >
                    <Typography
                        variant={textCellVariant || 'l2dcaption'}
                        sx={{
                            display: 'flex',
                            flexDirection: column.rowReverse ? 'row-reverse' : 'row',
                        }}
                    >
                        {typeof column.render === 'string'
                            ? column.render in item && (item[column.render] as string)
                            : column.render(item)}
                    </Typography>
                </TableCell>
            ))}
        </TableRow>
    );
};

export default FlexyDatatableBodySuccededRow;
