import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ToolbarActionButton } from '../../atoms';

const ToolbarPreviousAction: React.FunctionComponent = function () {
    const navigate = useNavigate();
    return (
        <ToolbarActionButton
            onClick={() => navigate(-1)}
            icon={['fasl', 'arrow-left']}
        />
    );
};

export default ToolbarPreviousAction;
