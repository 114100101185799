import React from 'react';
import { Outlet } from 'react-router-dom';
import { DossierStepProvider } from './providers';

const DossiersRoot: React.FunctionComponent = function () {
    return (
        <DossierStepProvider>
            <Outlet />
        </DossierStepProvider>
    );
};

export default DossiersRoot;
