import { FormalityStateType } from './types';

export const formalitiesSelector = (s: FormalityStateType) => s.formalites.formalities.main;

export const categoriesFormalitySelector = (s: FormalityStateType) =>
    s.formalites.categories_formalites.main;

export const DossierFormaliteSelector = (s: FormalityStateType) => s.formalites.dosform.main;
export const DossierOpenSearchSelector = (s: FormalityStateType) =>
    s.formalites.dossiersOpenSearch.main;

export const legalStatusSelector = (s: FormalityStateType) => s.formalites.legalStatuses.main;
export const formalitiesDocumentSelector = (s: FormalityStateType) =>
    s.formalites.formaliteDocuments.main;
export const formalistesSelector = (s: FormalityStateType) => s.formalites.formalistes.main;
export const dossierStepsSelector = (s: FormalityStateType) => s.formalites.dossierSteps.main;
export const statusesSelector = (s: FormalityStateType) => s.formalites.statuses.main;
