import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { ProgressBar } from '@europrocurement/flexy-components';

export type DossierStepCompletionProps = {
    step: string;
    currentCompletion: number;
};

const DossierStepCompletion: React.FunctionComponent<DossierStepCompletionProps> = function (
    props,
) {
    const { step, currentCompletion } = props;
    const { palette } = useTheme();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <ProgressBar
                sx={{ height: '5px', width: '100px' }}
                completion={currentCompletion}
                color={palette.primary}
                minCompletion={5}
            />
            <Typography
                variant="l2dtableCell"
                color="text.gris70"
            >
                {step}
            </Typography>
        </Box>
    );
};

export default DossierStepCompletion;
