import React from 'react';

import { Stepper } from '@europrocurement/flexy-components';
import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';
import { Box, Container } from '@mui/material';

export function useChangeHeaderParcours() {
    const { setCustomHeader } = useFullLayout();

    return (step: number, progress: number = 15) => {
        const updatedSteps = [
            {
                name: 'step_company',
                text: 'Société',
                progress: 0,
            },
            {
                name: 'step_formality',
                text: 'Formalité',
                progress: 0,
            },
            {
                name: 'step_documents',
                text: 'Documents',
                progress: 0,
            },
            {
                name: 'step_summary',
                text: 'Récapitulatif',
                progress: 0,
            },
        ].map((item, i) => {
            if (i < step - 1) return { ...item, progress: 100 };
            if (i === step - 1) return { ...item, progress };
            return { ...item, progress: 0 };
        });
        setCustomHeader(
            <Box sx={{ width: '100%', padding: 'auto' }}>
                <Container maxWidth="md">
                    <Stepper steps={updatedSteps} />
                </Container>
            </Box>,
        );
    };
}
