import React from 'react';
import { RouteObject } from 'react-router-dom';
import { societesBasePath, listeSocietesPath } from './constants';
import { SocietesRoot } from './societesRoot';
import ListeSocietes from './page/liste/ListeSousClient';

export const SocietesRouter: RouteObject = {
    path: societesBasePath,
    element: <SocietesRoot />,
    children: [
        {
            path: listeSocietesPath,
            element: <ListeSocietes />,
        },
    ],
};
