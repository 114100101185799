import React, { ReactNode, useCallback, useState, useMemo, useEffect } from 'react';
import {
    CatalogApiObject,
    FormulaireFormaliteApiObject,
    Prescripteur,
    selectedSousClientSelector,
    SousClientApi,
    SousClientMainSelector,
    TiersAppDispatch,
    useFormaliteService,
    useFormulaireService,
    useOffreService,
    useTiersService,
    useTiersServiceSelector,
} from '@europrocurement/l2d-domain';
import { useDispatch, useSelector } from 'react-redux';
import { customizerSelector } from '@europrocurement/flexy-components/redux/storeConfig/selectors';
import { castIriToId } from '@europrocurement/l2d-utils';
import { useCurrentContact } from '@europrocurement/l2d-modules/hooks/useCurrentContact';
import { useSnackbar } from 'notistack';
import { FormulaireFormaliteJsonld } from '@europrocurement/l2d-domain/openApi/ApiFormulaire';
import {
    CollectionDeDossierJsonldCollectiondossierCreated,
    FproPrestationJsonldCollectiondossierCreated,
    FproPrestationJsonldDossierRead,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import _ from 'lodash';
import {
    ParcoursFormaliteContext,
    type ParcoursFormaliteContextType,
} from './ParcoursFormaliteContext';
import { createCollectionFormalite } from './functions';

export type ModalProviderType = {
    children: ReactNode;
    prescripteur: Prescripteur;
    defaultCollectionId?: number;
    defaultDossierId?: number;
    defaultPrestaId?: number;
    defaultCurrentCatalogId?: number;
};

export const ParcoursFormaliteProvider: React.FunctionComponent<ModalProviderType> = function ({
    children,
    prescripteur,
    defaultCollectionId = -1,
    defaultDossierId = -1,
    defaultPrestaId = -1,
    defaultCurrentCatalogId = -1,
}) {
    const { formaliteApi, dossierApi, collectionApi } = useFormaliteService();
    const { catalogsApi } = useOffreService();

    const contact = useCurrentContact();

    const { xIdSociete } = useSelector(customizerSelector);

    const [collections, setCollections] = useState<ParcoursFormaliteContextType['collections']>({});
    const [dossiers, setDossiers] = useState<ParcoursFormaliteContextType['dossiers']>({});
    const [prestas, setPrestas] = useState<ParcoursFormaliteContextType['prestas']>([]);

    const [currentCollectionId, setCurrentCollectionId] = useState<number>(defaultCollectionId);
    const [currentDossierId, setCurrentDossierId] = useState<number>(defaultDossierId);
    const [currentPrestaId, setCurrentPrestaId] = useState<number>(defaultPrestaId);
    const [idFormalites, setIdFormalites] = useState<Array<number>>([]);
    const [idSousClients, setIdSousClients] = useState<Array<number>>([]);
    const [currentCatalog, setCurrentCatalog] = useState<CatalogApiObject | undefined>();
    const [currentCatalogs, setCurrentCatalogs] = useState<CatalogApiObject[]>();
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const sousClientDs = useTiersServiceSelector(SousClientMainSelector);
    const currentSousClient = useTiersServiceSelector(selectedSousClientSelector);

    const { selectSousClients } = useTiersService();

    const dispatch = useDispatch<TiersAppDispatch>();

    const [form, setForm] = useState<FormulaireFormaliteApiObject | null>(null);

    const [currentCatalogId, setCurrentCatalogId] = useState<number>(defaultCurrentCatalogId);

    const { formulairesApi } = useFormulaireService();

    const isFormaliteSimple = () => false; // TODO : To be implemented
    const isFormaliteCombinee = () => false; // TODO : To be implemented
    const isFormaliteMultiple = () => false; // TODO : To be implemented
    const isFormaliteDupliquee = () => false; // TODO : To be implemented
    const { enqueueSnackbar } = useSnackbar();

    if (!prescripteur.id) {
        enqueueSnackbar('Missing id in prescriber', { variant: 'error' });
        throw new Error('missing id in prescriber');
    }

    useEffect(() => {
        if (sousClientDs.selectedStatus !== 'loading' && idSousClients && idSousClients[0]) {
            if (
                currentSousClient &&
                currentSousClient.id &&
                !_.isEqual(idSousClients[0], currentSousClient.id)
            ) {
                dispatch(selectSousClients({ id: idSousClients[0] }));
            } else if (!currentSousClient) {
                dispatch(selectSousClients({ id: idSousClients[0] }));
            }
        }
    }, [
        dispatch,
        idSousClients,
        selectSousClients,
        currentSousClient,
        sousClientDs.selectedStatus,
    ]);

    const refreshCollection = useCallback(
        async (collectionId: number) => {
            const resCollection = await collectionApi.apiCollectionsDossiersIdGet({
                id: `${collectionId}`,
            });

            setCollections((prev) => {
                const newCollection = { ...prev };
                if (!resCollection.data.client || typeof resCollection.data.client !== 'string') {
                    enqueueSnackbar('Missing client in collection', { variant: 'error' });
                    throw new Error('missing client in collection');
                }
                newCollection[castIriToId(resCollection.data.client)] = resCollection.data;

                return { ...newCollection };
            });
        },
        [collectionApi, enqueueSnackbar],
    );

    const refreshDossier = useCallback(
        async (dossierId: number) => {
            const resDossier = await dossierApi.apiDossiersIdGet({
                id: `${dossierId}`,
            });

            if (!resDossier.data.id) {
                enqueueSnackbar('Missing id in collection', { variant: 'error' });
                throw new Error('missing id in collection');
            }
            if (!resDossier.data.client) {
                enqueueSnackbar('Missing client in collection', { variant: 'error' });
                throw new Error('missing client in collection');
            }

            setDossiers((prev) => {
                const newDossier = { ...prev };
                newDossier[castIriToId(resDossier.data.client as unknown as string)] =
                    resDossier.data;

                return { ...newDossier };
            });
        },
        [dossierApi, enqueueSnackbar],
    );

    const refreshCatalog = useCallback(
        async (catalogId: number) => {
            const resCatalog = await catalogsApi.apiCataloguesIdGet({
                id: `${catalogId}`,
            });

            setCurrentCatalog(resCatalog.data);
        },
        [catalogsApi],
    );

    const getCurrentDossier = useCallback(() => {
        if (Object.keys(dossiers).length === 1) {
            const idSousClient = Object.keys(dossiers)[0];
            return dossiers[Number(idSousClient)];
        }
        if (Object.keys(dossiers).length > 1) {
            enqueueSnackbar('formalité multiples a implementer', { variant: 'error' });
        }

        return null;
    }, [dossiers, enqueueSnackbar]);

    const getCurrentPresta = useCallback(() => {
        const curr = prestas.find(({ '@id': id }) => castIriToId(id || '') === currentPrestaId);

        return curr || null;
    }, [currentPrestaId, prestas]);

    const switchToNextPresta = useCallback(() => {
        const curr = prestas.find(({ '@id': id }) => castIriToId(id || '') === currentPrestaId);
        if (!curr) {
            enqueueSnackbar('next presta error', { variant: 'error' });
            throw new Error('next presta error');
        }
        const idx = prestas.indexOf(curr);
        if (idx < prestas.length - 1) {
            const nextPresta = prestas[idx + 1];
            if (!nextPresta || !nextPresta.id) {
                enqueueSnackbar('next presta error', { variant: 'error' });
                throw new Error('next presta error');
            }
            setCurrentPrestaId(nextPresta.id);
            if (nextPresta.catalogue) {
                let nextCatalogId: number = -1;
                if (typeof nextPresta.catalogue === 'string') {
                    nextCatalogId = castIriToId(nextPresta.catalogue);
                } else if (nextPresta.catalogue.id) {
                    nextCatalogId = nextPresta.catalogue.id;
                }
                refreshCatalog(nextCatalogId);
            }
            return nextPresta;
        }

        return false;
    }, [currentPrestaId, enqueueSnackbar, prestas, refreshCatalog]);

    const fetchForm = useCallback(
        async (
            innerPresta?:
                | FproPrestationJsonldDossierRead
                | FproPrestationJsonldCollectiondossierCreated
                | null,
        ) => {
            setForm(null);
            let currentPresta:
                | FproPrestationJsonldDossierRead
                | FproPrestationJsonldCollectiondossierCreated
                | undefined
                | null = innerPresta;

            if (!currentPresta) {
                currentPresta = getCurrentPresta();
            }

            if (!formLoading && currentPresta) {
                setFormLoading(true);
                const resFormality = await formaliteApi.apiFormalitesIdGet({
                    id: `${castIriToId(currentPresta.formalite as unknown as string)}`,
                });
                if (resFormality.data.formulaire) {
                    const resFormulaire =
                        await formulairesApi.apiFormulaireFormalitesIdformaliteIdFormaliteGet({
                            id: `${castIriToId(resFormality.data.formulaire)}`,
                            idFormalite: `${castIriToId(currentPresta.formalite as unknown as string)}`,
                        });

                    if (resFormulaire.data) {
                        setForm(resFormulaire.data as FormulaireFormaliteJsonld);
                        setFormLoading(false);
                    }
                } else {
                    enqueueSnackbar("La formalité n'a pas de formulaire", { variant: 'error' });
                    setFormLoading(false);
                    throw new Error('no formulaire on formality');
                }
            }
        },
        [formLoading, getCurrentPresta, formaliteApi, formulairesApi, enqueueSnackbar],
    );

    const create = useCallback(
        async (
            newIdSousClients: Array<number>,
            newIdFormalite: Array<number>,
            sousClient: SousClientApi,
        ) => {
            let collection: CollectionDeDossierJsonldCollectiondossierCreated;
            try {
                collection = await createCollectionFormalite({
                    prescripteur,
                    contact,
                    catalogs: currentCatalogs,
                    collectionApi,
                    xIdSociete,
                    sousClient,
                });

                if (!collection.dossiers) {
                    throw new Error('missing dossiers in collection');
                }

                if (!collection.id) {
                    throw new Error('missing id collection');
                }

                if (!collection.dossiers[0].id) {
                    throw new Error('missing id dossiers');
                }

                if (!collection.dossiers[0].prestations) {
                    throw new Error('missing prestassion in dossiers');
                }

                if (!collection.dossiers[0].prestations[0]) {
                    throw new Error('missing prestassion in dossiers');
                }

                if (!collection.dossiers[0].prestations[0]['@id']) {
                    throw new Error('missing prestassion id in dossiers');
                }

                if (collection.dossiers.length > 1) {
                    throw new Error('more than 1 dossier, impossible at this step');
                }
            } catch (e) {
                enqueueSnackbar((e as Error).message, { variant: 'error' });
                throw new Error((e as Error).message);
            }

            const ids = collection.dossiers[0].prestations
                ?.filter((innetPresta) => innetPresta.formalite)
                .map((innetPresta) => castIriToId(innetPresta.formalite as string));
            if (ids) {
                setIdFormalites(ids);
            }

            if (currentSousClient?.id) {
                setIdSousClients([currentSousClient?.id]);
            }

            setCurrentCollectionId(collection.id);
            setCurrentDossierId(collection.dossiers[0].id);
            setPrestas(collection.dossiers[0].prestations);
            setCurrentPrestaId(castIriToId(collection.dossiers[0].prestations[0]['@id']));
            refreshCollection(collection.id);
            await refreshDossier(collection.dossiers[0].id);
            fetchForm(collection.dossiers[0].prestations[0]);
        },
        [
            currentSousClient,
            refreshCollection,
            refreshDossier,
            fetchForm,
            prescripteur,
            contact,
            currentCatalogs,
            collectionApi,
            xIdSociete,
            enqueueSnackbar,
        ],
    );

    useEffect(() => {
        if (Object.keys(collections).length === 0 && currentCollectionId !== -1) {
            refreshCollection(currentCollectionId);
        }
    }, [collections, currentCollectionId, refreshCollection]);

    useEffect(() => {
        if (Object.keys(dossiers).length === 0 && currentDossierId !== -1) {
            refreshDossier(currentDossierId);
        }
    }, [currentDossierId, dossiers, refreshDossier]);

    useEffect(() => {
        if (!currentCatalog && currentCatalogId !== -1) {
            refreshCatalog(currentCatalogId);
        }
    }, [currentCatalog, currentCatalogId, refreshCatalog]);

    const parcoursFormaliteContextValue = useMemo<ParcoursFormaliteContextType>(() => {
        if (!prescripteur.id) {
            enqueueSnackbar('Missing id in prescriber', { variant: 'error' });
            throw new Error('missing id in prescriber');
        }
        return {
            collections,
            currentCollectionId,
            currentCatalog,
            currentCatalogs,
            currentDossierId,
            prescripteur,
            idFormalites,
            idSousClients,
            prescripteurId: prescripteur.id,
            dossiers,
            form,
            currentPrestaId,
            prestas,
            currentSousClient,
            currentCatalogId,
            switchToNextPresta,
            getCurrentPresta,
            fetchForm,
            setForm,
            refreshDossier,
            setIdSousClients,
            setIdFormalites,
            getPrescripteur: () => prescripteur,
            getCollections: () => collections,
            getCurrentCollection: () => {
                if (Object.keys(collections).length === 1) {
                    const idSousClient = Object.keys(collections)[0];
                    return collections[Number(idSousClient)];
                }
                if (Object.keys(collections).length > 1) {
                    enqueueSnackbar('formalité multiples a implementer', { variant: 'error' });
                }
                return null;
            },
            loading,
            setLoading,
            getCurrentDossier,
            save: () => null,
            isFormaliteSimple,
            isFormaliteCombinee,
            isFormaliteMultiple,
            isFormaliteDupliquee,
            setCollections,
            create,
            setCurrentCatalogId,
            setCurrentCatalog,
            setCurrentCatalogs,
            containerWidth: 'sm',
        };
    }, [
        prescripteur,
        collections,
        currentCollectionId,
        currentCatalog,
        currentCatalogs,
        currentDossierId,
        idFormalites,
        idSousClients,
        dossiers,
        form,
        currentPrestaId,
        prestas,
        currentSousClient,
        currentCatalogId,
        loading,
        setLoading,
        switchToNextPresta,
        getCurrentPresta,
        fetchForm,
        refreshDossier,
        getCurrentDossier,
        create,
        enqueueSnackbar,
    ]);

    return (
        <ParcoursFormaliteContext.Provider value={parcoursFormaliteContextValue}>
            {children}
        </ParcoursFormaliteContext.Provider>
    );
};
