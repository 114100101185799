import React, { useState } from 'react';
import { CustomOptionResolvers } from '@europrocurement/flexy-form';
import { useFormaliteService } from '@europrocurement/l2d-domain';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@mui/system';
import { Previous } from '@europrocurement/flexy-components';
import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';
import { type jsonForm } from '@europrocurement/flexy-form/utils/types/formTypes';
import { useTheme } from '@mui/material';
import { castIriToId } from '@europrocurement/l2d-utils';
import { JsonForm } from '../../JsonForms';
import { useParcoursFormalite } from '../provider';
import { useListeSelectionDataResolver } from '../../JsonForms/hooks/useListeSelectionDataResolver';
import { updateJsonPrestaionFormalite } from '../provider/functions';
import { parcoursFormalitePathResolver } from '../parcoursFormalitePathResolver';
import CurrentFormalityAndClient from '../components/currentFormalityAndClient';
import { useChangeHeaderParcours } from '../hooks/useChangeHeaderParcours';

export type StepFormulairesProps = {
    onFinish?: (data: Record<string, unknown> | undefined) => void;
    startPage?: number;
};

const StepFormulaires: React.FunctionComponent<StepFormulairesProps> = function ({
    onFinish,
    startPage = 0,
}) {
    const {
        getCurrentDossier,
        getCurrentCollection,
        getCurrentPresta,
        containerWidth,
        currentCatalog,
        refreshDossier,
        fetchForm,
        form,
        switchToNextPresta,
        setLoading,
    } = useParcoursFormalite();
    const dossier = getCurrentDossier();
    const collection = getCurrentCollection();
    const presta = getCurrentPresta();

    const { prestationFormaliteApi } = useFormaliteService();
    const navigate = useNavigate();

    React.useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    const createListeSelectionDataResolver = useListeSelectionDataResolver();

    const [currentPage, setCurrentPage] = useState(startPage);
    const [pagelenth, setPagelenth] = useState<number | undefined>(startPage);

    const defaultValues = {};

    const { setCustomHeader } = useFullLayout();
    const changeHeaderParcours = useChangeHeaderParcours();

    React.useEffect(() => {
        changeHeaderParcours(2, pagelenth ? 15 + (70 / pagelenth) * currentPage : 15);
        return () => {
            setCustomHeader(null);
        };
    }, [setCustomHeader, changeHeaderParcours, pagelenth, currentPage]);

    const theme = useTheme();

    React.useEffect(() => {
        if (!form && dossier) {
            fetchForm();
        }
    }, [dossier, fetchForm, form]);

    if (!form || !dossier || !collection || !collection.id) {
        console.log(form, dossier, collection);
        if (!form) {
            console.warn('Missing form');
        }
        if (!dossier) {
            console.warn('Missing dossier');
        }
        if (!collection) {
            console.warn('Missing collection');
        }
        if (collection && !collection.id) {
            console.warn('Missing collection id');
        }

        return null;
    }

    let formjson;
    if (form) {
        const formjsonstring = form.formulaire;
        // if (formDirigeant) {
        //     const formjsonstring = formDirigeant.formulaire;
        if (formjsonstring) {
            formjson = JSON.parse(formjsonstring);
        }
    }

    const onPrevious = () => {
        navigate(parcoursFormalitePathResolver('stepClient'));
    };

    const customOptionResolvers: CustomOptionResolvers = {
        listeSelectionDataResolver: createListeSelectionDataResolver(form),
    };
    return (
        <Container maxWidth={containerWidth}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '32px',
                }}
            >
                <CurrentFormalityAndClient />
            </Box>
            {currentPage === 0 ? <Previous onClick={onPrevious} /> : null}
            {formjson ? (
                <JsonForm
                    onChange={async (curr, newPagelenth, data) => {
                        if (curr === newPagelenth) {
                            if (onFinish) {
                                onFinish(data);
                            } else if (collection.id) {
                                setLoading(true);
                                await updateJsonPrestaionFormalite(
                                    prestationFormaliteApi,
                                    presta && presta['@id'] ? castIriToId(presta['@id']) : null,
                                    JSON.stringify(data),
                                );
                                const haveNext = switchToNextPresta();
                                if (haveNext) {
                                    navigate(parcoursFormalitePathResolver('stepNextForm'));
                                } else {
                                    if (dossier.id) {
                                        await refreshDossier(dossier.id);
                                    }
                                    navigate(parcoursFormalitePathResolver('stepDocs'));
                                }
                            }
                        } else {
                            setCurrentPage(curr);
                            setPagelenth(newPagelenth);
                        }
                    }}
                    visibility={currentCatalog?.offre?.visibilite || 'FULL'}
                    debugMode={false}
                    json={formjson as jsonForm}
                    startPage={startPage}
                    defaultValues={defaultValues}
                    customOptionResolvers={customOptionResolvers}
                    submitStyle={{
                        backgroundColor: theme.palette.primary.dark,
                        width: '100%',
                        marginTop: '30px',
                        padding: '15px',
                        fontSize: '1rem',
                        borderRadius: '15px',
                    }}
                />
            ) : (
                <h1>Aucun formulaire</h1>
            )}
        </Container>
    );
};

export default StepFormulaires;
