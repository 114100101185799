import { useTiersService } from '@europrocurement/l2d-domain';
import { ObjectGroupJsonldObjectgroupRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';

export type TiersObjectGroup = ObjectGroupJsonldObjectgroupRead & Record<string, unknown>;

const useSocietesObjectGroup = () => {
    const { objectGroupApi } = useTiersService();
    let searchObjectGroupTimeout: number | undefined;

    const searchTiersObjectGroup = (type: string): Promise<Array<TiersObjectGroup>> =>
        new Promise((resolve, reject) => {
            clearTimeout(searchObjectGroupTimeout);
            const requestParameters = { type: 'sousClient' };
            searchObjectGroupTimeout = setTimeout(() => {
                objectGroupApi
                    .searchObjectGroupByTypeObjectGroupCollection(requestParameters)
                    .then((res) =>
                        resolve(
                            res.data['hydra:member'].filter(
                                (item) =>
                                    item.label !== undefined &&
                                    (item.label
                                        .toLowerCase()
                                        .includes(
                                            type.toLowerCase(),
                                        ) as unknown as Array<TiersObjectGroup>),
                            ),
                        ),
                    )
                    .catch((err) => {
                        reject(err);
                    });
            }, 300);
        });

    return { searchTiersObjectGroup };

    // const addTiersObjectGroup = (idTiers: number, idObjectGroup: number): Promise<> =>
    //     // new Promise((resolve, reject) => {});
};

export default useSocietesObjectGroup;
