import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, useMediaQuery, useTheme } from '@mui/material';
import { TiersSousclientJsonldSousclientCreated } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import AddPrescriberClientForm from '../AddPrescriberClientForm/AddPrescriberClientForm';

export type AddPrescriberClientModalProps = {
    open: boolean;
    onSuccess: (sousClient: TiersSousclientJsonldSousclientCreated) => void;
    onCancel: () => void;
};

const AddPrescriberClientModal: React.FunctionComponent<AddPrescriberClientModalProps> = function (
    props,
) {
    const { open, onSuccess, onCancel } = props;
    const [isOpen, setOpen] = useState(open);
    const theme = useTheme();
    const { palette, breakpoints } = theme;
    const upSmBreakpoint = useMediaQuery(breakpoints.up('sm'));

    useEffect(() => {
        if (open !== isOpen) {
            setOpen(open);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleCancel = () => {
        setOpen(false);
        onCancel();
    };

    const handleSuccess = (sousClient: TiersSousclientJsonldSousclientCreated) => {
        setOpen(false);
        onSuccess(sousClient);
    };

    return (
        <Dialog
            onClose={onCancel}
            open={isOpen}
            maxWidth="lg"
        >
            <Button
                onClick={onCancel}
                sx={{
                    borderColor: palette.grey[400],
                    backgroundColor: palette.background.paper,
                    color: palette.text.primary,
                    position: 'absolute',
                    top: '24px',
                    right: '40px',
                    borderRadius: '60px',
                    padding: '8px 16px',
                    display: upSmBreakpoint ? 'flex' : 'none',
                    gap: '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '25.2px',
                    textAlign: 'left',
                    zIndex: '10000',
                }}
            >
                <FaOptionIcon icon={faTimes} />
                Fermer
            </Button>
            <DialogContent>
                <AddPrescriberClientForm
                    onCancel={handleCancel}
                    onSuccess={handleSuccess}
                />
            </DialogContent>
        </Dialog>
    );
};

export default AddPrescriberClientModal;
