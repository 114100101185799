import React from 'react';
import { FaOptionIcon, plusIcon } from '@europrocurement/l2d-icons';
import { Box, Button, Typography } from '@mui/material';
import { FlexyTextField, ObjectGroupProps, ObjectGroupType } from '../../atoms';
import { ObjectGroupList } from '../../molecules';
import './ObjectGroupsModal.css';

export type ObjectGroupsModalProps = {
    title: string;
    createButtonLabel: string;
    submitButtonLabel: string;
    placeholderLabel?: string;
    objectGroups: Array<ObjectGroupType>;
    onCreate?: () => void;
    onSubmit: (ObjectGroup: ObjectGroupType) => void;
    onClick?: (objectGroup: ObjectGroupType) => void;
    search: (string: string) => void;
};
const ObjectGroupsModal: React.FunctionComponent<ObjectGroupsModalProps> = function ({
    title,
    createButtonLabel,
    submitButtonLabel,
    placeholderLabel,
    objectGroups,
    onCreate,
    onSubmit,
    onClick,
    search,
}) {
    const [searchValue, setSearchValue] = React.useState<string>('');
    const searchWrapper: React.ChangeEventHandler<HTMLInputElement> = (searchString) => {
        search(searchString.target.value);
        setSearchValue(searchString.target.value);
    };

    const onCreateWrapper = () => {
        if (onCreate) {
            onCreate();
        }
    };

    const [selected, setSelected] = React.useState<ObjectGroupType>();

    const onClickWrapper: ObjectGroupProps['onClick'] = (objectGroup) => {
        // condition pour déselectionner
        if (selected !== undefined && objectGroup.id === selected.id) {
            setSelected(undefined);
        } else {
            setSelected(objectGroup);
        }
        // appliquer le bon style a l'enfant séléctionné
        if (onClick) {
            onClick(objectGroup);
        }
    };

    const onSubmitWrapper = () => {
        if (selected !== undefined) {
            onSubmit(selected);
        }
    };

    return (
        <Box className="groupment-modal">
            <Box className="body">
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Box className="title">{title}</Box>
                    <Box sx={{ width: '100%', borderRadius: '10px' }}>
                        <FlexyTextField
                            className="search"
                            variant="outlined"
                            value={searchValue}
                            onChange={searchWrapper}
                            placeholder={placeholderLabel || 'Rechercher'}
                            sx={{ width: '100%' }}
                            inputMode="search"
                            InputProps={{ sx: { borderRadius: '10px' } }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <ObjectGroupList
                        objectGroups={objectGroups}
                        onClick={onClickWrapper}
                    />
                </Box>
            </Box>
            <Box className="actions">
                <Button
                    className="plus-btn"
                    variant="text"
                    onClick={onCreateWrapper}
                    sx={{
                        color: '#011B58',
                    }}
                >
                    <FaOptionIcon {...plusIcon.props} />
                    <Typography className="plus-label">{createButtonLabel}</Typography>
                </Button>
                <Button
                    type="submit"
                    className="submit-btn"
                    variant="contained"
                    onClick={onSubmitWrapper}
                    color="primary"
                    sx={{ backgroundColor: '#011B58' }}
                >
                    {submitButtonLabel || 'Déplacer'}
                </Button>
            </Box>
        </Box>
    );
};

export default ObjectGroupsModal;
