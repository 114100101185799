import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';

import { isEmpty } from 'lodash';
import {
    UtilisateursApi,
    UtilisateursApiApiUtilisateursGetCollectionRequest,
    Configuration as UtilisateursConfiguration,
} from '../../../openApi/ApiUtilisateur';
import { UTILISATEUR_REDUCER_NAME, UTILISATEUR_SLICE_NAME } from '../constants';
import {
    type Mutable,
    type UtilisateurApiObject,
    type UtilisateursConfigurationParameters,
} from '../types';

export type Utilisateur = UtilisateurApiObject;

const mapperUtilisateur = function (user: UtilisateurApiObject) {
    return {
        ...user,
    } as Utilisateur;
};

export function createUtilisateurSlice(configuration: UtilisateursConfigurationParameters) {
    const conf = new UtilisateursConfiguration(configuration);
    const api = new UtilisateursApi(conf);

    const fetchUtilisateurCollection: FetchCollectionData<UtilisateurApiObject> = ({
        pagination: { page, itemsPerPage },
        xIdSociete,
        filters,
        search,
        orders,
    }) => {
        const input: Mutable<UtilisateursApiApiUtilisateursGetCollectionRequest> = {
            xIdSociete,
            page: page + 1,
            itemsPerPage,
            isDeleted: false,
        };

        /**
         * Manage search filters.
         */
        if (search && search !== '') {
            input.multiFieldSearch = search;
        }

        if (filters.mail && filters.mail !== '') {
            input.mail = filters.mail as string;
        }

        if (filters.user && filters.user !== '') {
            input.user = filters.user as string;
        }

        /**
         * Manage boolean filters.
         */
        if (filters.isDeleted) {
            input.isDeleted = filters.isDeleted as boolean;
        }

        if (filters.isFormalist) {
            input.isFormalist = filters.isFormalist as boolean;
        }

        if (filters.hasG3Access) {
            input.hasG3Access = filters.hasG3Access as boolean;
        }

        if (filters.hasComptaSoftware) {
            input.hasComptaSoftware = filters.hasComptaSoftware as boolean;
        }

        if (filters.hasAccessL2DF) {
            input.hasAccessL2DF = filters.hasAccessL2DF as boolean;
        }

        if (filters.isAdminApps) {
            input.isAdminApps = filters.isAdminApps as boolean;
        }

        /**
         * Manage order filters.
         */
        if (orders && orders !== null) {
            orders.forEach((order) => {
                if (!isEmpty(order.field) && !isEmpty(order.value)) {
                    switch (order.field) {
                        case 'id':
                            input.orderId = order.value;
                            break;
                        case 'nom':
                            input.orderNom = order.value;
                            break;
                        case 'user':
                            input.orderUser = order.value;
                            break;
                        case 'mail':
                            input.orderMail = order.value;
                            break;
                        // Pole
                        case 'libelle':
                            input.orderPoleId = order.value;
                            break;
                        default:
                    }
                }
            });
        }

        return api
            .apiUtilisateursGetCollection(input)
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<UtilisateurApiObject>>;
    };

    const fetchUtilisateurItem: FetchItemDataType<UtilisateurApiObject> = ({
        idItem,
        // pagination,
        xIdSociete,
    }) =>
        api
            .apiUtilisateursIdGet({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<UtilisateurApiObject>;

    const { slice: utilisateurSlice, dataSourcesThunks: utilisateurDataSourcesThunks } =
        SliceFactory.createSlice<UtilisateurApiObject, Utilisateur>(
            UTILISATEUR_SLICE_NAME,
            UTILISATEUR_REDUCER_NAME,
            [],
            fetchUtilisateurCollection,
            fetchUtilisateurItem,
            mapperUtilisateur,
            {},
        );

    return { utilisateurSlice, utilisateurDataSourcesThunks };
}
