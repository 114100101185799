import { Box } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ToolbarActionButton } from '../../atoms';
import LazyBreadcrumb, { LazyBreadcrumbsProps } from '../LazyBreadcrumb';
import ToolbarPreviousAction from '../../molecules/ToolbarPreviousAction';
import VerticalDivider from '../../atoms/VerticalDivider';

export type ToolbarAction = {
    icon: IconProp;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export type ToolbarProps = {
    path?: LazyBreadcrumbsProps['path']; // Breadcrumb path
    actions?: Array<ToolbarAction>;
};

const Toolbar: React.FunctionComponent<PropsWithChildren<ToolbarProps>> = function ({
    path,
    actions,
    children,
}) {
    const toolbarStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        paddingY: '20px',
    };
    const toolbarMainContent = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        margin: '0px 15px',
    };
    const actionKeyGenerate = (index: number) => `toolbar_action_${index}`;
    return (
        <Box
            data-testid="toolbar-test-id"
            sx={toolbarStyle}
        >
            <ToolbarPreviousAction />
            <Box sx={toolbarMainContent}>
                {path && (
                    <LazyBreadcrumb
                        path={path}
                        separator=" / "
                    />
                )}
                {children}
            </Box>
            <VerticalDivider sx={{ margin: '0px 10px' }} />
            <Box
                display="flex"
                justifyContent="space-between"
                sx={{ width: '20%' }}
            >
                {actions && actions.length > 0 && (
                    <>
                        {actions.map((action, index) => (
                            <ToolbarActionButton
                                {...action}
                                key={actionKeyGenerate(index)}
                            />
                        ))}
                    </>
                )}
            </Box>
        </Box>
    );
};

export default Toolbar;
