import React, { ReactNode } from 'react';
import {
    DayMonthPicker,
    DayMonthPickerProps,
    FlexyFormLabel,
} from '@europrocurement/flexy-components';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { FormHelperText, SxProps, Tooltip, Typography } from '@mui/material';

export type ControlledDayMonthPickerProps = {
    name: string;
    rules?: RegisterOptions;
    label?: string;
    inputlabel?: string | ReactNode;
    required?: boolean;
    sx?: SxProps;
} & Omit<DayMonthPickerProps, 'onChange' | 'items'>;

const ControlledDayMonthPicker: React.FunctionComponent<ControlledDayMonthPickerProps> = function ({
    name,
    rules,
    inputlabel,
    required,
    sx: sxProps,
    ...inputProps
}) {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                    {inputlabel && inputlabel !== '' ? (
                        <FlexyFormLabel
                            sx={sxProps}
                            data-testid="test-id-FlexyInput-label"
                        >
                            {inputlabel}
                            {required && (
                                <Tooltip title="Le champ est requis">
                                    <Typography
                                        component="span"
                                        color="danger.main"
                                    >
                                        &nbsp;&nbsp;*
                                    </Typography>
                                </Tooltip>
                            )}
                        </FlexyFormLabel>
                    ) : null}

                    <DayMonthPicker
                        {...inputProps}
                        value={value}
                        onChange={onChange}
                    />

                    {error ? (
                        <FormHelperText
                            sx={{
                                margin: '4px 14px 0px 14px',
                            }}
                            error
                        >
                            {error?.message}
                        </FormHelperText>
                    ) : null}
                </>
            )}
        />
    );
};

export default ControlledDayMonthPicker;
