import {
    selectedPrescripteursSelector,
    useTiersServiceSelector,
} from '@europrocurement/l2d-domain';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const SocietesRoot: React.FunctionComponent = function () {
    const prescripteur = useTiersServiceSelector(selectedPrescripteursSelector);

    if (!prescripteur) {
        return null;
    }

    return <Outlet />;
};

export default SocietesRoot;
