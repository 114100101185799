import {
    DossierJsonldDossierRead,
    FichiersDossierApi,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';

export const loadDossier = (
    dossier: DossierJsonldDossierRead,
    fichiersDossierApi: FichiersDossierApi,
) =>
    fichiersDossierApi.apiDossiersDossierFichiersGetCollection({
        dossier: `${dossier.id}`,
    });

export const uploadDossierFiles = async (
    dossier: DossierJsonldDossierRead,
    fichiersDossierApi: FichiersDossierApi,
    files: File[],
    typageAuto: boolean = true,
) => {
    await Promise.all(
        files.map(async (file) => {
            await fichiersDossierApi.apiDossiersDossierFichiersPost({
                dossier: `${dossier.id}`,
                typageAuto,
                file,
            });
        }),
    );
};
export const uploadDossierFile = async (
    dossier: DossierJsonldDossierRead,
    fichiersDossierApi: FichiersDossierApi,
    file: File,
    typeDoc: string,
    typageAuto: boolean = false,
) =>
    await fichiersDossierApi.apiDossiersDossierFichiersPost({
        dossier: `${dossier.id}`,
        typeDoc,
        file,
        typageAuto,
    });
