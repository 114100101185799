import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import { FlexyTextField } from '@europrocurement/flexy-components';
import { PreFilterDatatable, SearchOpts } from '../DatatableHeader';
import FlexyFiltersList from '../FlexyFiltersList';
import FlexyPreFilters from '../FlexyPreFilters';
import FlexyFiltersToogleButton from '../FlexyFiltersToogleButton/FlexyFiltersToogleButton';

export type FlexyFiltersProps = {
    searchOpts?: SearchOpts;
};

const FlexyFilters: React.FunctionComponent<FlexyFiltersProps> = function ({
    searchOpts,
}: FlexyFiltersProps) {
    const activeFiltersCount = Object.keys(searchOpts?.filtersOpts?.activeFilters || {}).length;
    const filtersCount = Object.keys(searchOpts?.filtersOpts?.filters || {}).length;
    const [showFilter, setShowFilters] = useState<boolean>(activeFiltersCount > 0);
    const [currentPreFilter, setCurrentPreFilter] = useState<PreFilterDatatable>();
    const preFiltersProps = searchOpts?.filtersOpts?.preFilters || { items: [] };
    const preFilters = preFiltersProps;

    useEffect(() => {
        if (preFilters?.items.length > 0) {
            setCurrentPreFilter(preFilters.items[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!filtersCount) {
        return <h1>coucoiu</h1>;
    }

    const applyPreFilter = (preFilter: PreFilterDatatable) => {
        setCurrentPreFilter(preFilter);
        searchOpts?.filtersOpts?.onUpdateFilters(preFilter);
    };

    return (
        <Grid
            container
            direction="column"
            alignItems="end"
        >
            <Grid
                item
                sx={{ width: '100%' }}
            >
                <Box
                    display="flex"
                    flexDirection="row-reverse"
                    justifyContent="space-between"
                    alignItems="end"
                    gap={2}
                >
                    <Box>
                        <FlexyFiltersToogleButton
                            searchOpts={searchOpts}
                            setShowFilters={setShowFilters}
                            showFilter={showFilter}
                        />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        {preFilters.items.length > 0 && (
                            <FlexyPreFilters
                                filters={preFilters}
                                onClick={applyPreFilter}
                                current={currentPreFilter}
                            />
                        )}
                        {searchOpts?.showSearch ? (
                            <FlexyTextField
                                sx={{
                                    marginTop: '15px',
                                }}
                                inputProps={{
                                    style: {
                                        padding: '14px 14px',
                                    },
                                }}
                                defaultValue={searchOpts?.search || ''}
                                onChange={(e) => {
                                    if (searchOpts?.onSearchChange) {
                                        searchOpts?.onSearchChange(e.target.value);
                                    }
                                }}
                                variant="outlined"
                                fullWidth
                                placeholder="Recherche textuelle"
                            />
                        ) : null}
                    </Box>
                </Box>
            </Grid>
            <Grid
                item
                alignSelf="start"
                sx={{ marginTop: '15px' }}
            >
                {showFilter && <FlexyFiltersList filtersOpts={searchOpts?.filtersOpts} />}
            </Grid>
        </Grid>
    );
};

export default FlexyFilters;
