import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { Button, IconButton, Menu, MenuItem, Modal } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { ObjectGroupsCreate, ObjectGroupsModal } from '@europrocurement/flexy-components';
// import {
//     TiersAppDispatch,
//     useTiersService,
//     // SousClient,
//     // SousClientsSelector,
//     ObjectGroupApi,
//     ObjectGroupSelector,
// } from '@europrocurement/l2d-domain';
// import { useCurrentPrescriber } from '@europrocurement/l2d-modules/hooks/useCurrentPrescriber';
// import { useSelector, useDispatch } from 'react-redux';
// import { DataSource } from '@europrocurement/l2d-redux-utils';
import { parcoursFormalitePathResolver } from '../../ParcoursFormalite';
// import { TiersObjectGroup, useSocietesObjectGroup } from '../hooks';

type ComponentProps = {
    idTiers: number | undefined;
};
const ActionMenuSocietes: React.FunctionComponent<ComponentProps> = function (props) {
    const { idTiers } = props;
    // const { searchTiersObjectGroup } = useSocietesObjectGroup();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const open = Boolean(anchorEl);
    // const prescripteur = useCurrentPrescriber();
    // const datasource: DataSource<ObjectGroupApi> = useSelector(ObjectGroupSelector).main;
    // const { getObjectGroup } = useTiersService();

    // const listobjectGroup: (searchTerm: string) => Promise<Array<TiersObjectGroup>> =
    //     searchTiersObjectGroup;
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenMoveModal = () => {
        // const result = listobjectGroup('');
        setIsMoveModalOpen(true);
        handleClose(); // Fermer le menu
    };

    const handleCloseMoveModal = () => {
        setIsMoveModalOpen(false);
    };

    const handleCloseCreateModal = () => {
        setIsCreateModalOpen(false);
    };

    // const handleOpenCreateModal = () => {
    //     console.log(idTiers);
    //     setIsMoveModalOpen(false);
    //     setIsCreateModalOpen(true);
    //     handleClose(); // Fermer le menu
    // };
    const navigate = useNavigate();

    const handleAddDoss = () => {
        navigate(parcoursFormalitePathResolver('stepFormalite'));
    };

    const handleDeleteSousClient = () => {
        handleClose();
    };

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
        >
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <FaOptionIcon
                    icon={['fasl', 'ellipsis-vertical']}
                    sx={{ fontSize: '1rem' }}
                />
            </IconButton>
            <Menu
                id={`${idTiers}-long-menu`}
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem
                    key={`${idTiers}-add-group`}
                    onClick={handleOpenMoveModal}
                >
                    Déplacer dans un groupe
                </MenuItem>
                <MenuItem
                    key={`${idTiers}-modify`}
                    onClick={handleClose}
                >
                    Modifier
                </MenuItem>
                <MenuItem
                    key={`${idTiers}-open`}
                    onClick={handleClose}
                >
                    Ouvrir dans un nouvel onglet
                </MenuItem>
                <MenuItem
                    onClick={handleAddDoss}
                    key={`${idTiers}-new-dossier`}
                >
                    Créer un dossier
                </MenuItem>
                <MenuItem
                    key={`${idTiers}-delete`}
                    onClick={handleDeleteSousClient}
                >
                    Supprimer
                </MenuItem>
            </Menu>
            <Modal
                open={isMoveModalOpen}
                onClose={handleCloseMoveModal}
                aria-labelledby="move-modal-title"
                aria-describedby="move-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <ObjectGroupsModal
                        title="Déplacer la société dans un groupe"
                        createButtonLabel="Créer un groupe"
                        submitButtonLabel="Ajouter au groupe"
                        onSubmit={(item) => {
                            console.log(item);
                        }}
                        search={(searchString) => console.log(searchString)}
                        objectGroups={[]}
                        // onCreate={handleOpenCreateModal()}
                    />
                    {/* Ajoutez ici le contenu ou formulaire pour déplacer */}
                    <Button
                        onClick={handleCloseMoveModal}
                        sx={{ mt: 2 }}
                        variant="contained"
                    >
                        Fermer
                    </Button>
                </Box>
            </Modal>
            <Modal
                open={isCreateModalOpen}
                onClose={handleCloseCreateModal}
                aria-labelledby="create-modal-title"
                aria-describedby="create-modal-description"
            >
                <Box>
                    <ObjectGroupsCreate
                        title="Créer un groupe de société"
                        fieldLabel="nom du groupe"
                        submitButtonLabel="Créer le groupe"
                        onSubmit={() => console.log('tada!!')}
                    />
                </Box>
            </Modal>
        </div>
    );
};

export default ActionMenuSocietes;
