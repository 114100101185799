import React from 'react';
import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    SxProps,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    TypographyProps,
    useTheme,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import { Checkbox } from '@europrocurement/flexy-components';
import ColumnHeaderItemSortabled, { SortDirection } from '../ColumnHeaderItemSortabled';
import { ColumnDatatable } from '../ColumnDatatable';
import { SearchOpts } from '../DatatableHeader';
import useSelectItem from '../FlexyDatatableTable/useSelectItem';
import FlexyDatatableSelectionAction, {
    FlexyDatatableSelectionActionProps,
} from '../FlexyDatatableSelectionAction/FlexyDatatableSelectionAction';

export type FlexyDatatableHeaderProps<T = Record<string, unknown>> = {
    columns: Array<ColumnDatatable<T>>;
    searchOpts?: SearchOpts;
    columnLabelVariant?: TypographyProps['variant'];
    columnLabelColor?: TypographyProps['color'];
    itemsPerPage?: number;
    hideColumnOptions?: boolean;
    setColumns?: React.Dispatch<React.SetStateAction<ColumnDatatable<T>[]>>;
    rowsActions?: Array<FlexyDatatableSelectionActionProps>;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

const FlexyDatatableHeader = function <T extends Record<string, unknown>>(
    props: FlexyDatatableHeaderProps<T>,
) {
    const {
        columns,
        searchOpts,
        columnLabelVariant,
        itemsPerPage,
        hideColumnOptions = false,
        setColumns = null,
        rowsActions = [],
        columnLabelColor = 'text.gris50',
        onClick = () => {},
    } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const openDisplayColumn = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const countDisplayed = columns.filter((column) => column.isDisplayed).length;

    const { countSelectedItems, hasSelectedItems, handleSelectAllItems, countUnselectedItems } =
        useSelectItem();
    const selectAllCkb = (
        <Checkbox
            checked={countUnselectedItems() === 0}
            indeterminate={
                itemsPerPage !== undefined &&
                countUnselectedItems() > 0 &&
                countUnselectedItems() < itemsPerPage
            }
            onChange={handleSelectAllItems}
            size="small"
        />
    );

    const celStyle: SxProps = {
        padding: '7px 8px',
        textAlign: 'left',
        verticalAlign: 'middle',
        justifyContent: 'flex-start',
        backgroundColor: 'background.default',
        borderTopColor: theme.palette.grey[300],
        borderBottomColor: 'transparent',
        maxHeight: '55px',
    };

    const innerColumns = columns.filter((column) => column.isDisplayed !== false);

    if (hasSelectedItems() && rowsActions.length > 0) {
        const highlightStyle = {
            ...celStyle,
            backgroundColor: 'primary.main',
            color: 'primary.dark.contrastText',
            fontSize: '14px',
            lineHeight: '25.2px',
            width: 'auto',
        };

        return (
            <TableHead>
                <TableRow>
                    <TableCell
                        colSpan={countDisplayed + 1}
                        sx={{
                            ...highlightStyle,
                            borderTopLeftRadius: '12px',
                            borderTopRightRadius: '12px',
                        }}
                    >
                        <Box sx={{ display: 'flex' }}>
                            {selectAllCkb}
                            <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                                <Box>{countSelectedItems()} sélection(s)</Box>
                                {rowsActions.map((rowAction) => (
                                    <FlexyDatatableSelectionAction
                                        key={rowAction.label}
                                        {...rowAction}
                                    />
                                ))}
                            </Box>
                        </Box>
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    return (
        <TableHead data-testid="FlexyDatatableHead">
            <TableRow>
                {rowsActions.length > 0 && (
                    <TableCell
                        key="select-item-cell"
                        sx={{
                            ...celStyle,
                            borderTopLeftRadius: '12px',
                            width: '60px',
                            display: {
                                xs: 'none',
                                sm: 'none',
                                md: 'table-cell',
                                lg: 'table-cell',
                                xl: 'table-cell',
                            },
                        }}
                    >
                        {selectAllCkb}
                    </TableCell>
                )}
                {innerColumns?.map((column, i) => {
                    let orderable = false;
                    let defaultOrder: SortDirection;
                    if (column.sortable && searchOpts?.ordersOpts?.orders) {
                        orderable = true;
                        if (column.field && searchOpts.ordersOpts?.orders) {
                            const currentOrder = searchOpts.ordersOpts.orders.find(
                                (item) => item.field === column.field,
                            );
                            if (currentOrder) {
                                defaultOrder = currentOrder.value as SortDirection;
                            }
                        }
                    }

                    return (
                        <TableCell
                            key={column.label}
                            sx={{
                                ...celStyle,
                                cursor: 'pointer',
                                borderTopLeftRadius:
                                    i === 0 && rowsActions.length <= 0 ? '12px' : '0px',
                                paddingLeft: i === 0 ? '15px' : undefined,
                                ...column.sx,
                            }}
                            onClick={onClick}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    // verticalAlign: 'center',
                                }}
                            >
                                <Tooltip
                                    placement="top"
                                    arrow
                                    title={column.headerTooltip || ''}
                                >
                                    <Typography
                                        variant={columnLabelVariant}
                                        color={columnLabelColor}
                                        display="flex"
                                        flexDirection="row"
                                    >
                                        {!(column.displayLabel === false) && column.label}
                                        {orderable && searchOpts?.ordersOpts ? (
                                            <ColumnHeaderItemSortabled
                                                onOrdersChange={
                                                    searchOpts.ordersOpts?.onOrdersChange
                                                }
                                                defaultValue={defaultOrder}
                                                field={column.field}
                                            />
                                        ) : null}
                                    </Typography>
                                </Tooltip>
                                {i === innerColumns.length - 1 && !hideColumnOptions ? (
                                    <>
                                        <Tooltip
                                            title="affichage colonnes"
                                            data-testid="DisplayFiltersButton"
                                        >
                                            <IconButton onClick={openDisplayColumn}>
                                                <MoreVertIcon
                                                    sx={{
                                                        color: 'inherit',
                                                    }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            PaperProps={{
                                                style: {
                                                    maxHeight: 48 * 4.5,
                                                    width: '20ch',
                                                },
                                            }}
                                        >
                                            {columns?.slice(0, -1).map((currentColumn, index) => (
                                                <MenuItem
                                                    key={currentColumn.label}
                                                    selected={currentColumn.isDisplayed}
                                                    onClick={() => {
                                                        const newCols = [...columns];
                                                        newCols[index].isDisplayed =
                                                            !newCols[index].isDisplayed;
                                                        if (setColumns) setColumns(newCols);
                                                    }}
                                                >
                                                    {currentColumn.isDisplayed && (
                                                        <CheckIcon fontSize="small" />
                                                    )}
                                                    {currentColumn.label}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </>
                                ) : null}
                            </Box>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

export default FlexyDatatableHeader;
