import { combineReducers } from '@reduxjs/toolkit';
import {
    DossierApi,
    FormaliteApi,
    Configuration as FormalitesConfiguration,
    ConfigurationParameters as FormaliteConfigurationParameters,
    CollectionDeDossierApi,
    FichiersDossierApi,
    FormalisteApi,
    StatutApi,
    DossierOpenSearchApi,
    PrestationApi,
    MessageApi,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { createDossierFormaliteSlice } from './slices/dossierFormaliteSlice';
import { createDossierOpenSearchSlice } from './slices/dossierOpenSearchSlice';
import { createFormaliteSlice } from './slices/formalite';
import { createCategoriesFormaliteSlice } from './slices/categoriesFormalite';
import { createLegalStatusSlice } from './slices/legalStatusSlice';
import { createFormaliteDocumentSlice } from './slices/documentSlice';
import { createFormalisteSlice } from './slices/formalisteSlice';
import { createDossierStepSlice } from './slices/dossierStepSlice';
import {
    CATEGORY_SLICE_NAME,
    DOSSIER_STEP_SLICE_NAME,
    DOSSIERS_FORMALITES_SLICE_NAME,
    DOSSIERS_OPEN_SEARCH_SLICE_NAME,
    FORMALISTE_SLICE_NAME,
    FORMALITE_DOCUMENT_SLICE_NAME,
    FORMALITES_REDUCER_NAME,
    FORMALITES_SLICE_NAME,
    LEGAL_STATUS_SLICE_NAME,
    STATUS_SLICE_NAME,
} from './constants';
import { type FormalityStateType } from './types';
import { buildFormalityModel } from './Models';
import { createStatusSlice } from './slices/statusSlice';

export { type FormaliteConfigurationParameters };

export function createDossierFormaliteReducer(configuration: FormaliteConfigurationParameters) {
    const { dossierFormaliteSlice, dossierFormaliteDataSourcesThunks } =
        createDossierFormaliteSlice(configuration);
    return { dossierFormaliteSlice, dossierFormaliteDataSourcesThunks };
}

export function createDossierOpenSearchReducer(configuration: FormaliteConfigurationParameters) {
    const { dossierOpenSearchSlice, dossierOpenSearchDataSourcesThunks } =
        createDossierOpenSearchSlice(configuration);
    return { dossierOpenSearchSlice, dossierOpenSearchDataSourcesThunks };
}

export function createFormaliteReducer(configuration: FormaliteConfigurationParameters) {
    const { formaliteSlice, formaliteDataSourcesThunks } = createFormaliteSlice(configuration);
    return { formaliteSlice, formaliteDataSourcesThunks };
}

export function createCategoriesFormaliteReducer(configuration: FormaliteConfigurationParameters) {
    const { categoryFormaliteSlice, categoryFormaliteDataSourcesThunks } =
        createCategoriesFormaliteSlice(configuration);
    return { categoryFormaliteSlice, categoryFormaliteDataSourcesThunks };
}

export function createLegalStatusReducer(configuration: FormaliteConfigurationParameters) {
    const { legalStatusSlice, legalStatusDataSourcesThunks } =
        createLegalStatusSlice(configuration);
    return { legalStatusSlice, legalStatusDataSourcesThunks };
}

export function createFormaliteDocumentReducer(configuration: FormaliteConfigurationParameters) {
    const { formaliteDocumentSlice, formaliteDocumentDataSourcesThunks } =
        createFormaliteDocumentSlice(configuration);
    return { formaliteDocumentSlice, formaliteDocumentDataSourcesThunks };
}

export function createFormalisteReducer(configuration: FormaliteConfigurationParameters) {
    const { formalisteSlice, formalisteDataSourcesThunks } = createFormalisteSlice(configuration);
    return { formalisteSlice, formalisteDataSourcesThunks };
}

export function createDossierStepReducer(configuration: FormaliteConfigurationParameters) {
    const { dossierStepSlice, dossierStepDataSourcesThunks } =
        createDossierStepSlice(configuration);
    return { dossierStepSlice, dossierStepDataSourcesThunks };
}

export function createStatusReducer(configuration: FormaliteConfigurationParameters) {
    const { statusSlice, statusDataSourcesThunks } = createStatusSlice(configuration);
    return { statusSlice, statusDataSourcesThunks };
}

export const baseFormalitySelector = (state: FormalityStateType) =>
    state[FORMALITES_REDUCER_NAME][FORMALITES_SLICE_NAME];

export function buildFormaliteRedux(configuration: FormaliteConfigurationParameters) {
    const formalitesConfiguration = new FormalitesConfiguration(configuration);

    const formaliteApi = new FormaliteApi(formalitesConfiguration);
    const dossierApi = new DossierApi(formalitesConfiguration);
    const dossierOpenSearchApi = new DossierOpenSearchApi(formalitesConfiguration);
    const collectionApi = new CollectionDeDossierApi(formalitesConfiguration);
    const fichiersDossierApi = new FichiersDossierApi(formalitesConfiguration);
    const formalisteApi = new FormalisteApi(formalitesConfiguration);
    const statusApi = new StatutApi(formalitesConfiguration);
    const prestationFormaliteApi = new PrestationApi(formalitesConfiguration);
    const messageApi = new MessageApi(formalitesConfiguration);

    const { dossierFormaliteSlice, dossierFormaliteDataSourcesThunks } =
        createDossierFormaliteReducer(configuration);

    const { dossierOpenSearchSlice, dossierOpenSearchDataSourcesThunks } =
        createDossierOpenSearchReducer(configuration);

    const { formaliteSlice, formaliteDataSourcesThunks } = createFormaliteReducer(configuration);

    const { categoryFormaliteSlice, categoryFormaliteDataSourcesThunks } =
        createCategoriesFormaliteReducer(configuration);

    const { legalStatusSlice, legalStatusDataSourcesThunks } =
        createLegalStatusReducer(configuration);

    const { formaliteDocumentSlice, formaliteDocumentDataSourcesThunks } =
        createFormaliteDocumentReducer(configuration);

    const { formalisteSlice, formalisteDataSourcesThunks } = createFormalisteReducer(configuration);
    const { dossierStepSlice, dossierStepDataSourcesThunks } =
        createDossierStepReducer(configuration);

    const { statusSlice, statusDataSourcesThunks } = createStatusReducer(configuration);

    const combinedFormaliteReducer = combineReducers({
        [DOSSIERS_FORMALITES_SLICE_NAME]: dossierFormaliteSlice.reducer,
        [DOSSIERS_OPEN_SEARCH_SLICE_NAME]: dossierOpenSearchSlice.reducer,
        [FORMALITES_SLICE_NAME]: formaliteSlice.reducer,
        [CATEGORY_SLICE_NAME]: categoryFormaliteSlice.reducer,
        [LEGAL_STATUS_SLICE_NAME]: legalStatusSlice.reducer,
        [FORMALITE_DOCUMENT_SLICE_NAME]: formaliteDocumentSlice.reducer,
        [FORMALISTE_SLICE_NAME]: formalisteSlice.reducer,
        [STATUS_SLICE_NAME]: statusSlice.reducer,
        [DOSSIER_STEP_SLICE_NAME]: dossierStepSlice.reducer,
    });

    return {
        serviceConfigurationParameters: configuration,
        serviceConfiguration: formalitesConfiguration,
        reducer: combinedFormaliteReducer,

        apis: {
            formaliteApi,
            dossierApi,
            dossierOpenSearchApi,
            collectionApi,
            fichiersDossierApi,
            formalisteApi,
            statusApi,
            prestationFormaliteApi,
            messageApi,
        },

        slices: {
            dossierFormaliteSlice,
            dossierOpenSearchSlice,
            formaliteSlice,
            legalStatusSlice,
            categoryFormaliteSlice,
            formaliteDocumentSlice,
            formalisteSlice,
            dossierStepSlice,
            statusSlice,
        },
        thunks: {
            dossierFormaliteDataSourcesThunks,
            dossierOpenSearchDataSourcesThunks,
            formaliteDataSourcesThunks,
            categoryFormaliteDataSourcesThunks,
            legalStatusDataSourcesThunks,
            formaliteDocumentDataSourcesThunks,
            formalisteDataSourcesThunks,
            dossierStepDataSourcesThunks,
            statusDataSourcesThunks,
        },

        fetchItems: {
            getFormalites: formaliteDataSourcesThunks.main.getData,
            getDossierFormalite: dossierFormaliteDataSourcesThunks.main.getData,
            getOpenSearchDossiers: dossierOpenSearchDataSourcesThunks.main.getData,
            getAutocompleteDossierFormalite:
                dossierFormaliteDataSourcesThunks.autocompleteDossiersFormalites.getData,
            getFormalistes: formalisteDataSourcesThunks.main.getData,
            getDossierSteps: dossierStepDataSourcesThunks.main.getData,
            getStatuses: statusDataSourcesThunks.main.getData,
        },
        getItems: {
            selectDossierFormalite: dossierFormaliteDataSourcesThunks.main.getSelected,
            selectOpenSearchDossier: dossierOpenSearchDataSourcesThunks.main.getSelected,
            selectFormality: formaliteDataSourcesThunks.main.getSelected,

            selectAutocompleteDossierFormalite:
                dossierFormaliteDataSourcesThunks.autocompleteDossiersFormalites.getSelected,
            selectFormaliste: formalisteDataSourcesThunks.getSelected,
            selectDossierStep: dossierStepDataSourcesThunks.getSelected,
            selectStatus: statusDataSourcesThunks.getSelected,
        },

        models: {
            formality: buildFormalityModel(formaliteApi, formaliteDataSourcesThunks),
        },
    };
}
