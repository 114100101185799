import React, { PropsWithChildren, ReactNode } from 'react';
import { Box, SxProps, useTheme } from '@mui/material';

export type CardSectionProps = {
    header: ReactNode;
    sx?: SxProps;
    sxHeader?: SxProps;
    sxContent?: SxProps;
};

const CardSection: React.FunctionComponent<PropsWithChildren<CardSectionProps>> = function ({
    header,
    sx = {},
    sxHeader = {},
    sxContent = {},
    children,
}) {
    const theme = useTheme();
    const { palette } = theme;
    const color = palette.grey[300];
    const cardStyle = {
        backgroundColor: palette.background.paper,
        borderRadius: '10px',
        boxShadow: `2px 2px 6px 0px ${color}`,
        marginY: '20px',
        ...sx,
    };

    const headerStyle = {
        ...sxHeader,
    };

    const contentStyle = {
        padding: '30px',
        ...sxContent,
    };

    return (
        <Box
            sx={cardStyle}
            data-testid="CardSection-test-id"
        >
            <Box sx={headerStyle}>{header}</Box>
            <Box sx={contentStyle}>{children}</Box>
        </Box>
    );
};

export default CardSection;
