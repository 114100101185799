import React from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faBarsFilter, faChevronDown, faChevronUp } from '@fortawesome/pro-duotone-svg-icons';
import CountBadge from '@europrocurement/flexy-components/components/atoms/CountBadge';
import { SearchOpts } from '../DatatableHeader';

export type FlexyFiltersToogleButtonProps = {
    searchOpts?: SearchOpts;
    showFilter: boolean;
    setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
};

const FlexyFiltersToogleButton: React.FunctionComponent<FlexyFiltersToogleButtonProps> = function ({
    searchOpts,
    showFilter,
    setShowFilters,
}: FlexyFiltersToogleButtonProps) {
    const activeFiltersCount = Object.keys(searchOpts?.filtersOpts?.activeFilters || {}).length;
    const filtersCount = Object.keys(searchOpts?.filtersOpts?.filters || {}).length;
    const theme = useTheme();

    if (!filtersCount) {
        return null;
    }

    const counter = activeFiltersCount > 0 ? <CountBadge count={activeFiltersCount} /> : null;
    const chevronStyle = {
        fontSize: '8px',
        lineHeight: '8px',
    };

    return (
        <Button
            sx={{
                padding: '12px 16px 12px 16px',
                borderRadius: '12px',
                borderStyle: 'solid',
                borderWidth: '1px',
                display: 'flex',
                gap: '2px',
                alignItems: 'center',
                fontFamily: 'Inter',
                fontSize: '15px',
                fontWeight: '600',
                lineHeight: '24px',
                textAlign: 'left',
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.primary.dark,
                borderColor: theme.palette.grey[300],
            }}
            onClick={() => {
                setShowFilters((v) => !v);
            }}
        >
            <FaOptionIcon icon={faBarsFilter} />
            Filtrer
            {counter}
            {showFilter ? (
                <Box sx={chevronStyle}>
                    <FaOptionIcon icon={faChevronDown} />
                </Box>
            ) : (
                <Box sx={chevronStyle}>
                    <FaOptionIcon icon={faChevronUp} />
                </Box>
            )}
        </Button>
    );
};

export default FlexyFiltersToogleButton;
