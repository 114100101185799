/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement Api Offre
 * API de gestion des offres
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ActionDetailActionDetailRead
 */
export interface ActionDetailActionDetailRead {
    /**
     * 
     * @type {number}
     * @memberof ActionDetailActionDetailRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionDetailActionDetailRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionDetailJsonldActionDetailRead
 */
export interface ActionDetailJsonldActionDetailRead {
    /**
     * 
     * @type {string}
     * @memberof ActionDetailJsonldActionDetailRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionDetailJsonldActionDetailRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionDetailJsonldActionDetailRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof ActionDetailJsonldActionDetailRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionDetailJsonldActionDetailRead
     */
    'libelle'?: string | null;
}
/**
 * @type ActionDetailJsonldActionDetailReadContext
 * @export
 */
export type ActionDetailJsonldActionDetailReadContext = ActionDetailJsonldActionDetailReadContextOneOf | string;

/**
 * 
 * @export
 * @interface ActionDetailJsonldActionDetailReadContextOneOf
 */
export interface ActionDetailJsonldActionDetailReadContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ActionDetailJsonldActionDetailReadContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof ActionDetailJsonldActionDetailReadContextOneOf
     */
    'hydra': ActionDetailJsonldActionDetailReadContextOneOfHydraEnum;
}

export const ActionDetailJsonldActionDetailReadContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type ActionDetailJsonldActionDetailReadContextOneOfHydraEnum = typeof ActionDetailJsonldActionDetailReadContextOneOfHydraEnum[keyof typeof ActionDetailJsonldActionDetailReadContextOneOfHydraEnum];

/**
 * 
 * @export
 * @interface ActionsActionDetailRead
 */
export interface ActionsActionDetailRead {
    /**
     * 
     * @type {string}
     * @memberof ActionsActionDetailRead
     */
    'package'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsActionDetailRead
     */
    'option'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsActionDetailRead
     */
    'article'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsActionDetailRead
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {TblActionDetailActionDetailRead}
     * @memberof ActionsActionDetailRead
     */
    'action'?: TblActionDetailActionDetailRead;
}
/**
 * 
 * @export
 * @interface ActionsJsonldActionDetailRead
 */
export interface ActionsJsonldActionDetailRead {
    /**
     * 
     * @type {string}
     * @memberof ActionsJsonldActionDetailRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsJsonldActionDetailRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsJsonldActionDetailRead
     */
    'package'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsJsonldActionDetailRead
     */
    'option'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsJsonldActionDetailRead
     */
    'article'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsJsonldActionDetailRead
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {TblActionDetailJsonldActionDetailRead}
     * @memberof ActionsJsonldActionDetailRead
     */
    'action'?: TblActionDetailJsonldActionDetailRead;
}
/**
 * 
 * @export
 * @interface ApiActionDetailsGetCollection200Response
 */
export interface ApiActionDetailsGetCollection200Response {
    /**
     * 
     * @type {Array<ActionDetailJsonldActionDetailRead>}
     * @memberof ApiActionDetailsGetCollection200Response
     */
    'hydra:member': Array<ActionDetailJsonldActionDetailRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiActionDetailsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiActionDetailsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiActionDetailsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiActionDetailsGetCollection200ResponseHydraSearch
 */
export interface ApiActionDetailsGetCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner
 */
export interface ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiActionDetailsGetCollection200ResponseHydraView
 */
export interface ApiActionDetailsGetCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 * 
 * @export
 * @interface ApiActionDetailsGetCollection401Response
 */
export interface ApiActionDetailsGetCollection401Response {
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiActionDetailsGetCollection404Response
 */
export interface ApiActionDetailsGetCollection404Response {
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection404Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiActionDetailsGetCollection404ResponseException}
     * @memberof ApiActionDetailsGetCollection404Response
     */
    'exception'?: ApiActionDetailsGetCollection404ResponseException;
}
/**
 * 
 * @export
 * @interface ApiActionDetailsGetCollection404ResponseException
 */
export interface ApiActionDetailsGetCollection404ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection404ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiActionDetailsGetCollection404ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiArticlesGetCollection200Response
 */
export interface ApiArticlesGetCollection200Response {
    /**
     * 
     * @type {Array<ArticlesJsonldArticleRead>}
     * @memberof ApiArticlesGetCollection200Response
     */
    'hydra:member': Array<ArticlesJsonldArticleRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiArticlesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiArticlesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiArticlesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiArticlesIdPut400Response
 */
export interface ApiArticlesIdPut400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiArticlesIdPut400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiArticlesIdPut400ResponseException}
     * @memberof ApiArticlesIdPut400Response
     */
    'exception': ApiArticlesIdPut400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiArticlesIdPut400ResponseException
 */
export interface ApiArticlesIdPut400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiArticlesIdPut400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiArticlesIdPut400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiArticlesIdPut400ResponseExceptionViolationsInner>}
     * @memberof ApiArticlesIdPut400ResponseException
     */
    'violations'?: Array<ApiArticlesIdPut400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiArticlesIdPut400ResponseExceptionViolationsInner
 */
export interface ApiArticlesIdPut400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiArticlesIdPut400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiArticlesIdPut400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiArticlesPost400Response
 */
export interface ApiArticlesPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiArticlesPost400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiArticlesPost400ResponseException}
     * @memberof ApiArticlesPost400Response
     */
    'exception': ApiArticlesPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiArticlesPost400ResponseException
 */
export interface ApiArticlesPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiArticlesPost400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiArticlesPost400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiArticlesPost400ResponseExceptionViolationsInner>}
     * @memberof ApiArticlesPost400ResponseException
     */
    'violations'?: Array<ApiArticlesPost400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiArticlesPost400ResponseExceptionViolationsInner
 */
export interface ApiArticlesPost400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiArticlesPost400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiArticlesPost400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiArticlesPost403Response
 */
export interface ApiArticlesPost403Response {
    /**
     * 
     * @type {string}
     * @memberof ApiArticlesPost403Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiArticlesPost403ResponseException}
     * @memberof ApiArticlesPost403Response
     */
    'exception'?: ApiArticlesPost403ResponseException;
}
/**
 * 
 * @export
 * @interface ApiArticlesPost403ResponseException
 */
export interface ApiArticlesPost403ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiArticlesPost403ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiArticlesPost403ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiCataloguesGetCollection200Response
 */
export interface ApiCataloguesGetCollection200Response {
    /**
     * 
     * @type {Array<CatalogueJsonldCatalogueRead>}
     * @memberof ApiCataloguesGetCollection200Response
     */
    'hydra:member': Array<CatalogueJsonldCatalogueRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiCataloguesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiCataloguesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiCataloguesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiCataloguesIdActionsAEffectuerGetCollection200Response
 */
export interface ApiCataloguesIdActionsAEffectuerGetCollection200Response {
    /**
     * 
     * @type {Array<ActionsJsonldActionDetailRead>}
     * @memberof ApiCataloguesIdActionsAEffectuerGetCollection200Response
     */
    'hydra:member': Array<ActionsJsonldActionDetailRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiCataloguesIdActionsAEffectuerGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiCataloguesIdActionsAEffectuerGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiCataloguesIdActionsAEffectuerGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiCataloguesIdDocumentsAFournirGetCollection200Response
 */
export interface ApiCataloguesIdDocumentsAFournirGetCollection200Response {
    /**
     * 
     * @type {Array<TypeDeDocumentJsonldTypedocumentRead>}
     * @memberof ApiCataloguesIdDocumentsAFournirGetCollection200Response
     */
    'hydra:member': Array<TypeDeDocumentJsonldTypedocumentRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiCataloguesIdDocumentsAFournirGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiCataloguesIdDocumentsAFournirGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiCataloguesIdDocumentsAFournirGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiCataloguesPost400Response
 */
export interface ApiCataloguesPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiCataloguesPost400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiCataloguesPost400ResponseException}
     * @memberof ApiCataloguesPost400Response
     */
    'exception': ApiCataloguesPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiCataloguesPost400ResponseException
 */
export interface ApiCataloguesPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiCataloguesPost400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiCataloguesPost400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiCataloguesPost400ResponseExceptionViolationsInner>}
     * @memberof ApiCataloguesPost400ResponseException
     */
    'violations'?: Array<ApiCataloguesPost400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiCataloguesPost400ResponseExceptionViolationsInner
 */
export interface ApiCataloguesPost400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiCataloguesPost400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiCataloguesPost400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiChampsComplementairesGetCollection200Response
 */
export interface ApiChampsComplementairesGetCollection200Response {
    /**
     * 
     * @type {Array<ChampComplMentaireJsonldChampcomplementaireRead>}
     * @memberof ApiChampsComplementairesGetCollection200Response
     */
    'hydra:member': Array<ChampComplMentaireJsonldChampcomplementaireRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiChampsComplementairesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiChampsComplementairesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiChampsComplementairesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiChampsComplementairesPost400Response
 */
export interface ApiChampsComplementairesPost400Response {
    /**
     * 
     * @type {ApiChampsComplementairesPost400ResponseError}
     * @memberof ApiChampsComplementairesPost400Response
     */
    'error'?: ApiChampsComplementairesPost400ResponseError;
}
/**
 * 
 * @export
 * @interface ApiChampsComplementairesPost400ResponseError
 */
export interface ApiChampsComplementairesPost400ResponseError {
    /**
     * 
     * @type {string}
     * @memberof ApiChampsComplementairesPost400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiChampsComplementairesPost400ResponseError
     */
    'code'?: number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiChampsComplementairesPost400ResponseErrorViolationsInner>}
     * @memberof ApiChampsComplementairesPost400ResponseError
     */
    'violations'?: Array<ApiChampsComplementairesPost400ResponseErrorViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiChampsComplementairesPost400ResponseErrorViolationsInner
 */
export interface ApiChampsComplementairesPost400ResponseErrorViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiChampsComplementairesPost400ResponseErrorViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiChampsComplementairesPost400ResponseErrorViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiDomainesGetCollection200Response
 */
export interface ApiDomainesGetCollection200Response {
    /**
     * 
     * @type {Array<DomainesJsonldDomaineRead>}
     * @memberof ApiDomainesGetCollection200Response
     */
    'hydra:member': Array<DomainesJsonldDomaineRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDomainesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDomainesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDomainesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiFormalitesGetCollection200Response
 */
export interface ApiFormalitesGetCollection200Response {
    /**
     * 
     * @type {Array<FormaliteJsonldFormaliteRead>}
     * @memberof ApiFormalitesGetCollection200Response
     */
    'hydra:member': Array<FormaliteJsonldFormaliteRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiFormalitesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiFormalitesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiFormalitesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiGroupementsGetCollection200Response
 */
export interface ApiGroupementsGetCollection200Response {
    /**
     * 
     * @type {Array<GroupementJsonldGroupementRead>}
     * @memberof ApiGroupementsGetCollection200Response
     */
    'hydra:member': Array<GroupementJsonldGroupementRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiGroupementsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiGroupementsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiGroupementsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiOffresGetCollection200Response
 */
export interface ApiOffresGetCollection200Response {
    /**
     * 
     * @type {Array<OffresJsonldOffreRead>}
     * @memberof ApiOffresGetCollection200Response
     */
    'hydra:member': Array<OffresJsonldOffreRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiOffresGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiOffresGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiOffresGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiOptionValuesGetCollection200Response
 */
export interface ApiOptionValuesGetCollection200Response {
    /**
     * 
     * @type {Array<OptionValueJsonldOptionValueRead>}
     * @memberof ApiOptionValuesGetCollection200Response
     */
    'hydra:member': Array<OptionValueJsonldOptionValueRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiOptionValuesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiOptionValuesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiOptionValuesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiOptionValuesIdPut400Response
 */
export interface ApiOptionValuesIdPut400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiOptionValuesIdPut400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiOptionValuesIdPut400ResponseException}
     * @memberof ApiOptionValuesIdPut400Response
     */
    'exception': ApiOptionValuesIdPut400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiOptionValuesIdPut400ResponseException
 */
export interface ApiOptionValuesIdPut400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiOptionValuesIdPut400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiOptionValuesIdPut400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiOptionValuesIdPut400ResponseExceptionViolationsInner>}
     * @memberof ApiOptionValuesIdPut400ResponseException
     */
    'violations'?: Array<ApiOptionValuesIdPut400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiOptionValuesIdPut400ResponseExceptionViolationsInner
 */
export interface ApiOptionValuesIdPut400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiOptionValuesIdPut400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiOptionValuesIdPut400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiOptionValuesPost400Response
 */
export interface ApiOptionValuesPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiOptionValuesPost400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiOptionValuesPost400ResponseException}
     * @memberof ApiOptionValuesPost400Response
     */
    'exception': ApiOptionValuesPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiOptionValuesPost400ResponseException
 */
export interface ApiOptionValuesPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiOptionValuesPost400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiOptionValuesPost400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiOptionValuesPost400ResponseExceptionViolationsInner>}
     * @memberof ApiOptionValuesPost400ResponseException
     */
    'violations'?: Array<ApiOptionValuesPost400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiOptionValuesPost400ResponseExceptionViolationsInner
 */
export interface ApiOptionValuesPost400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiOptionValuesPost400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiOptionValuesPost400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiOptionsGetCollection200Response
 */
export interface ApiOptionsGetCollection200Response {
    /**
     * 
     * @type {Array<OptionsJsonldOptionRead>}
     * @memberof ApiOptionsGetCollection200Response
     */
    'hydra:member': Array<OptionsJsonldOptionRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiOptionsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiOptionsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiOptionsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiOptionsIdPut400Response
 */
export interface ApiOptionsIdPut400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiOptionsIdPut400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiOptionsIdPut400ResponseException}
     * @memberof ApiOptionsIdPut400Response
     */
    'exception': ApiOptionsIdPut400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiOptionsIdPut400ResponseException
 */
export interface ApiOptionsIdPut400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiOptionsIdPut400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiOptionsIdPut400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiOptionsIdPut400ResponseExceptionViolationsInner>}
     * @memberof ApiOptionsIdPut400ResponseException
     */
    'violations'?: Array<ApiOptionsIdPut400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiOptionsIdPut400ResponseExceptionViolationsInner
 */
export interface ApiOptionsIdPut400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiOptionsIdPut400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiOptionsIdPut400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiOptionsPost400Response
 */
export interface ApiOptionsPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiOptionsPost400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiOptionsPost400ResponseException}
     * @memberof ApiOptionsPost400Response
     */
    'exception': ApiOptionsPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiOptionsPost400ResponseException
 */
export interface ApiOptionsPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiOptionsPost400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiOptionsPost400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiChampsComplementairesPost400ResponseErrorViolationsInner>}
     * @memberof ApiOptionsPost400ResponseException
     */
    'violations'?: Array<ApiChampsComplementairesPost400ResponseErrorViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiOrdresGroupementsDomainesGetCollection200Response
 */
export interface ApiOrdresGroupementsDomainesGetCollection200Response {
    /**
     * 
     * @type {Array<GroupementJsonldOrdreGroupementOrigineRead>}
     * @memberof ApiOrdresGroupementsDomainesGetCollection200Response
     */
    'hydra:member': Array<GroupementJsonldOrdreGroupementOrigineRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiOrdresGroupementsDomainesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiOrdresGroupementsDomainesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiOrdresGroupementsDomainesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiOrdresGroupementsDomainesPost400Response
 */
export interface ApiOrdresGroupementsDomainesPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiOrdresGroupementsDomainesPost400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiOrdresGroupementsDomainesPost400ResponseException}
     * @memberof ApiOrdresGroupementsDomainesPost400Response
     */
    'exception': ApiOrdresGroupementsDomainesPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiOrdresGroupementsDomainesPost400ResponseException
 */
export interface ApiOrdresGroupementsDomainesPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiOrdresGroupementsDomainesPost400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiOrdresGroupementsDomainesPost400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiOrdresGroupementsDomainesPost400ResponseExceptionViolationsInner>}
     * @memberof ApiOrdresGroupementsDomainesPost400ResponseException
     */
    'violations'?: Array<ApiOrdresGroupementsDomainesPost400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiOrdresGroupementsDomainesPost400ResponseExceptionViolationsInner
 */
export interface ApiOrdresGroupementsDomainesPost400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiOrdresGroupementsDomainesPost400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiOrdresGroupementsDomainesPost400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiOriginesGetCollection200Response
 */
export interface ApiOriginesGetCollection200Response {
    /**
     * 
     * @type {Array<OriginesJsonldOrigineRead>}
     * @memberof ApiOriginesGetCollection200Response
     */
    'hydra:member': Array<OriginesJsonldOrigineRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiOriginesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiOriginesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiOriginesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiOriginesOffresGetCollection200Response
 */
export interface ApiOriginesOffresGetCollection200Response {
    /**
     * 
     * @type {Array<OriginesOffresJsonldOrigineOffreRead>}
     * @memberof ApiOriginesOffresGetCollection200Response
     */
    'hydra:member': Array<OriginesOffresJsonldOrigineOffreRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiOriginesOffresGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiOriginesOffresGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiOriginesOffresGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiOriginesOffresTiersGetCollection200Response
 */
export interface ApiOriginesOffresTiersGetCollection200Response {
    /**
     * 
     * @type {Array<OriginesOffresTiersJsonldOrigineOffreTiersRead>}
     * @memberof ApiOriginesOffresTiersGetCollection200Response
     */
    'hydra:member': Array<OriginesOffresTiersJsonldOrigineOffreTiersRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiOriginesOffresTiersGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiOriginesOffresTiersGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiOriginesOffresTiersGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiPackagesGetCollection200Response
 */
export interface ApiPackagesGetCollection200Response {
    /**
     * 
     * @type {Array<PackageJsonldPackageRead>}
     * @memberof ApiPackagesGetCollection200Response
     */
    'hydra:member': Array<PackageJsonldPackageRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiPackagesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiPackagesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiPackagesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiPackagesIdPut400Response
 */
export interface ApiPackagesIdPut400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiPackagesIdPut400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiPackagesIdPut400ResponseException}
     * @memberof ApiPackagesIdPut400Response
     */
    'exception': ApiPackagesIdPut400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiPackagesIdPut400ResponseException
 */
export interface ApiPackagesIdPut400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiPackagesIdPut400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiPackagesIdPut400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiPackagesIdPut400ResponseExceptionViolationsInner>}
     * @memberof ApiPackagesIdPut400ResponseException
     */
    'violations'?: Array<ApiPackagesIdPut400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiPackagesIdPut400ResponseExceptionViolationsInner
 */
export interface ApiPackagesIdPut400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiPackagesIdPut400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiPackagesIdPut400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiPackagesPost400Response
 */
export interface ApiPackagesPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiPackagesPost400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiPackagesPost400ResponseException}
     * @memberof ApiPackagesPost400Response
     */
    'exception': ApiPackagesPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiPackagesPost400ResponseException
 */
export interface ApiPackagesPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiPackagesPost400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiPackagesPost400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiPackagesPost400ResponseExceptionViolationsInner>}
     * @memberof ApiPackagesPost400ResponseException
     */
    'violations'?: Array<ApiPackagesPost400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiPackagesPost400ResponseExceptionViolationsInner
 */
export interface ApiPackagesPost400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiPackagesPost400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiPackagesPost400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiRubriqueFacturationsGetCollection200Response
 */
export interface ApiRubriqueFacturationsGetCollection200Response {
    /**
     * 
     * @type {Array<RubriqueFacturationJsonldRubriqueFacturationRead>}
     * @memberof ApiRubriqueFacturationsGetCollection200Response
     */
    'hydra:member': Array<RubriqueFacturationJsonldRubriqueFacturationRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiRubriqueFacturationsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiRubriqueFacturationsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiRubriqueFacturationsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiTarifsPrescripteursGetCollection200Response
 */
export interface ApiTarifsPrescripteursGetCollection200Response {
    /**
     * 
     * @type {Array<TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead>}
     * @memberof ApiTarifsPrescripteursGetCollection200Response
     */
    'hydra:member': Array<TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiTarifsPrescripteursGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiTarifsPrescripteursGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiTarifsPrescripteursGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiTarifsPrescripteursPost400Response
 */
export interface ApiTarifsPrescripteursPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiTarifsPrescripteursPost400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiTarifsPrescripteursPost400ResponseException}
     * @memberof ApiTarifsPrescripteursPost400Response
     */
    'exception': ApiTarifsPrescripteursPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiTarifsPrescripteursPost400ResponseException
 */
export interface ApiTarifsPrescripteursPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiTarifsPrescripteursPost400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiTarifsPrescripteursPost400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiTarifsPrescripteursPost400ResponseExceptionViolationsInner>}
     * @memberof ApiTarifsPrescripteursPost400ResponseException
     */
    'violations'?: Array<ApiTarifsPrescripteursPost400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiTarifsPrescripteursPost400ResponseExceptionViolationsInner
 */
export interface ApiTarifsPrescripteursPost400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiTarifsPrescripteursPost400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiTarifsPrescripteursPost400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiTvaGetCollection200Response
 */
export interface ApiTvaGetCollection200Response {
    /**
     * 
     * @type {Array<TypeTvaJsonldTvaRead>}
     * @memberof ApiTvaGetCollection200Response
     */
    'hydra:member': Array<TypeTvaJsonldTvaRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiTvaGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiTvaGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiTvaGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiTypesDocumentsPost400Response
 */
export interface ApiTypesDocumentsPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiTypesDocumentsPost400Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiTypesDocumentsPost400ResponseException}
     * @memberof ApiTypesDocumentsPost400Response
     */
    'exception'?: ApiTypesDocumentsPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiTypesDocumentsPost400ResponseException
 */
export interface ApiTypesDocumentsPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiTypesDocumentsPost400ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTypesDocumentsPost400ResponseException
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface ArticlesArticleCreate
 */
export interface ArticlesArticleCreate {
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleCreate
     */
    'codeArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleCreate
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleCreate
     */
    'unite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleCreate
     */
    'societe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleCreate
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleCreate
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleCreate
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleCreate
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleCreate
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleCreate
     */
    'cegidTl3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleCreate
     */
    'rubArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleCreate
     */
    'domaine': string;
    /**
     * 
     * @type {Array<TblRelTypeDocumentArticleCreate>}
     * @memberof ArticlesArticleCreate
     */
    'documents'?: Array<TblRelTypeDocumentArticleCreate>;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleCreate
     */
    'natureTva'?: string;
}
/**
 * 
 * @export
 * @interface ArticlesArticleLibRead
 */
export interface ArticlesArticleLibRead {
    /**
     * 
     * @type {number}
     * @memberof ArticlesArticleLibRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleLibRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface ArticlesArticleRead
 */
export interface ArticlesArticleRead {
    /**
     * 
     * @type {number}
     * @memberof ArticlesArticleRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleRead
     */
    'codeArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleRead
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleRead
     */
    'unite'?: string | null;
    /**
     * 
     * @type {TblSocieteArticleRead}
     * @memberof ArticlesArticleRead
     */
    'societe'?: TblSocieteArticleRead | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleRead
     */
    'cegidTl3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleRead
     */
    'rubArticle'?: string | null;
    /**
     * 
     * @type {TblDefDomaineArticleRead}
     * @memberof ArticlesArticleRead
     */
    'domaine'?: TblDefDomaineArticleRead;
    /**
     * 
     * @type {Array<TblRelTypeDocumentArticleRead>}
     * @memberof ArticlesArticleRead
     */
    'documents'?: Array<TblRelTypeDocumentArticleRead>;
    /**
     * 
     * @type {Array<ArticlesArticleReadChampsComplementairesInner>}
     * @memberof ArticlesArticleRead
     */
    'champsComplementaires'?: Array<ArticlesArticleReadChampsComplementairesInner>;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlesArticleRead
     */
    'isArticleValid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleRead
     */
    'natureTva'?: string;
}
/**
 * 
 * @export
 * @interface ArticlesArticleReadChampsComplementairesInner
 */
export interface ArticlesArticleReadChampsComplementairesInner {
    /**
     * 
     * @type {number}
     * @memberof ArticlesArticleReadChampsComplementairesInner
     */
    'champComplementaire'?: number;
}
/**
 * 
 * @export
 * @interface ArticlesArticleUpdate
 */
export interface ArticlesArticleUpdate {
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleUpdate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleUpdate
     */
    'codeArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleUpdate
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleUpdate
     */
    'unite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleUpdate
     */
    'societe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleUpdate
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleUpdate
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleUpdate
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleUpdate
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleUpdate
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleUpdate
     */
    'cegidTl3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleUpdate
     */
    'rubArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleUpdate
     */
    'domaine': string;
    /**
     * 
     * @type {Array<TblRelTypeDocumentArticleUpdate>}
     * @memberof ArticlesArticleUpdate
     */
    'documents'?: Array<TblRelTypeDocumentArticleUpdate>;
    /**
     * 
     * @type {string}
     * @memberof ArticlesArticleUpdate
     */
    'natureTva'?: string;
}
/**
 * 
 * @export
 * @interface ArticlesJsonldArticleCreate
 */
export interface ArticlesJsonldArticleCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ArticlesJsonldArticleCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleCreate
     */
    'codeArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleCreate
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleCreate
     */
    'unite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleCreate
     */
    'societe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleCreate
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleCreate
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleCreate
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleCreate
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleCreate
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleCreate
     */
    'cegidTl3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleCreate
     */
    'rubArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleCreate
     */
    'domaine': string;
    /**
     * 
     * @type {Array<TblRelTypeDocumentJsonldArticleCreate>}
     * @memberof ArticlesJsonldArticleCreate
     */
    'documents'?: Array<TblRelTypeDocumentJsonldArticleCreate>;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleCreate
     */
    'natureTva'?: string;
}
/**
 * 
 * @export
 * @interface ArticlesJsonldArticleLibRead
 */
export interface ArticlesJsonldArticleLibRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ArticlesJsonldArticleLibRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleLibRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleLibRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ArticlesJsonldArticleLibRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleLibRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface ArticlesJsonldArticleRead
 */
export interface ArticlesJsonldArticleRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ArticlesJsonldArticleRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ArticlesJsonldArticleRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleRead
     */
    'codeArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleRead
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleRead
     */
    'unite'?: string | null;
    /**
     * 
     * @type {TblSocieteJsonldArticleRead}
     * @memberof ArticlesJsonldArticleRead
     */
    'societe'?: TblSocieteJsonldArticleRead | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleRead
     */
    'cegidTl3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleRead
     */
    'rubArticle'?: string | null;
    /**
     * 
     * @type {TblDefDomaineJsonldArticleRead}
     * @memberof ArticlesJsonldArticleRead
     */
    'domaine'?: TblDefDomaineJsonldArticleRead;
    /**
     * 
     * @type {Array<TblRelTypeDocumentJsonldArticleRead>}
     * @memberof ArticlesJsonldArticleRead
     */
    'documents'?: Array<TblRelTypeDocumentJsonldArticleRead>;
    /**
     * 
     * @type {Array<ArticlesArticleReadChampsComplementairesInner>}
     * @memberof ArticlesJsonldArticleRead
     */
    'champsComplementaires'?: Array<ArticlesArticleReadChampsComplementairesInner>;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlesJsonldArticleRead
     */
    'isArticleValid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleRead
     */
    'natureTva'?: string;
}
/**
 * 
 * @export
 * @interface ArticlesJsonldArticleUpdate
 */
export interface ArticlesJsonldArticleUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ArticlesJsonldArticleUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleUpdate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleUpdate
     */
    'codeArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleUpdate
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleUpdate
     */
    'unite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleUpdate
     */
    'societe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleUpdate
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleUpdate
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleUpdate
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleUpdate
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleUpdate
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleUpdate
     */
    'cegidTl3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleUpdate
     */
    'rubArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleUpdate
     */
    'domaine': string;
    /**
     * 
     * @type {Array<TblRelTypeDocumentJsonldArticleUpdate>}
     * @memberof ArticlesJsonldArticleUpdate
     */
    'documents'?: Array<TblRelTypeDocumentJsonldArticleUpdate>;
    /**
     * 
     * @type {string}
     * @memberof ArticlesJsonldArticleUpdate
     */
    'natureTva'?: string;
}
/**
 * 
 * @export
 * @interface ArticlesResourceDependencyDependencyRead
 */
export interface ArticlesResourceDependencyDependencyRead {
    /**
     * 
     * @type {Array<string>}
     * @memberof ArticlesResourceDependencyDependencyRead
     */
    'dependencies'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ArticlesResourceDependencyJsonldDependencyRead
 */
export interface ArticlesResourceDependencyJsonldDependencyRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ArticlesResourceDependencyJsonldDependencyRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ArticlesResourceDependencyJsonldDependencyRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlesResourceDependencyJsonldDependencyRead
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArticlesResourceDependencyJsonldDependencyRead
     */
    'dependencies'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CatalogueCatalogueCreate
 */
export interface CatalogueCatalogueCreate {
    /**
     * 
     * @type {string}
     * @memberof CatalogueCatalogueCreate
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueCatalogueCreate
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogueCatalogueCreate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogueCatalogueCreate
     */
    'nomCommercial'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueCatalogueCreate
     */
    'offre'?: string;
    /**
     * 
     * @type {number}
     * @memberof CatalogueCatalogueCreate
     */
    'origine'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CatalogueCatalogueCreate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CatalogueCatalogueCreate
     */
    'package'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CatalogueCatalogueCreate
     */
    'groupement'?: number | null;
    /**
     * 
     * @type {Array<TblSurchargeArticlePackageCatalogueCreate>}
     * @memberof CatalogueCatalogueCreate
     */
    'surchargeArticles'?: Array<TblSurchargeArticlePackageCatalogueCreate>;
    /**
     * 
     * @type {Array<TblRelOptionCatalogueCreate>}
     * @memberof CatalogueCatalogueCreate
     */
    'options'?: Array<TblRelOptionCatalogueCreate>;
    /**
     * 
     * @type {Array<TblRelActionCatalogueCreate>}
     * @memberof CatalogueCatalogueCreate
     */
    'actions'?: Array<TblRelActionCatalogueCreate>;
    /**
     * 
     * @type {Array<ArticlesArticleReadChampsComplementairesInner>}
     * @memberof CatalogueCatalogueCreate
     */
    'champsComplementaires'?: Array<ArticlesArticleReadChampsComplementairesInner>;
    /**
     * 
     * @type {Array<CatalogueCatalogueCreateDocumentsInner>}
     * @memberof CatalogueCatalogueCreate
     */
    'documents'?: Array<CatalogueCatalogueCreateDocumentsInner>;
    /**
     * 
     * @type {string}
     * @memberof CatalogueCatalogueCreate
     */
    'domaine'?: string;
}
/**
 * 
 * @export
 * @interface CatalogueCatalogueCreateDocumentsInner
 */
export interface CatalogueCatalogueCreateDocumentsInner {
    /**
     * 
     * @type {number}
     * @memberof CatalogueCatalogueCreateDocumentsInner
     */
    'typeDocument'?: number;
}
/**
 * 
 * @export
 * @interface CatalogueCatalogueRead
 */
export interface CatalogueCatalogueRead {
    /**
     * 
     * @type {number}
     * @memberof CatalogueCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CatalogueCatalogueRead
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueCatalogueRead
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogueCatalogueRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogueCatalogueRead
     */
    'nomCommercial'?: string;
    /**
     * 
     * @type {TblOffreCatalogueRead}
     * @memberof CatalogueCatalogueRead
     */
    'offre'?: TblOffreCatalogueRead;
    /**
     * 
     * @type {TblOrigineCatalogueRead}
     * @memberof CatalogueCatalogueRead
     */
    'origine'?: TblOrigineCatalogueRead | null;
    /**
     * 
     * @type {number}
     * @memberof CatalogueCatalogueRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {TblPackageCatalogueRead}
     * @memberof CatalogueCatalogueRead
     */
    'package'?: TblPackageCatalogueRead | null;
    /**
     * 
     * @type {TblGroupementCatalogueRead}
     * @memberof CatalogueCatalogueRead
     */
    'groupement'?: TblGroupementCatalogueRead | null;
    /**
     * 
     * @type {Array<TblSurchargeArticlePackageCatalogueRead>}
     * @memberof CatalogueCatalogueRead
     */
    'surchargeArticles'?: Array<TblSurchargeArticlePackageCatalogueRead>;
    /**
     * 
     * @type {Array<TblRelOptionCatalogueRead>}
     * @memberof CatalogueCatalogueRead
     */
    'options'?: Array<TblRelOptionCatalogueRead>;
    /**
     * 
     * @type {Array<TblRelActionCatalogueRead>}
     * @memberof CatalogueCatalogueRead
     */
    'actions'?: Array<TblRelActionCatalogueRead>;
    /**
     * 
     * @type {Array<ArticlesArticleReadChampsComplementairesInner>}
     * @memberof CatalogueCatalogueRead
     */
    'champsComplementaires'?: Array<ArticlesArticleReadChampsComplementairesInner>;
    /**
     * 
     * @type {Array<CatalogueCatalogueCreateDocumentsInner>}
     * @memberof CatalogueCatalogueRead
     */
    'documents'?: Array<CatalogueCatalogueCreateDocumentsInner>;
    /**
     * 
     * @type {TblDefDomaineCatalogueRead}
     * @memberof CatalogueCatalogueRead
     */
    'domaine'?: TblDefDomaineCatalogueRead;
}
/**
 * 
 * @export
 * @interface CatalogueCatalogueUpdate
 */
export interface CatalogueCatalogueUpdate {
    /**
     * 
     * @type {string}
     * @memberof CatalogueCatalogueUpdate
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogueCatalogueUpdate
     */
    'nomCommercial': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueCatalogueUpdate
     */
    'offre': string;
    /**
     * 
     * @type {number}
     * @memberof CatalogueCatalogueUpdate
     */
    'origine'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CatalogueCatalogueUpdate
     */
    'groupement': number | null;
    /**
     * 
     * @type {Array<TblSurchargeArticlePackageCatalogueUpdate>}
     * @memberof CatalogueCatalogueUpdate
     */
    'surchargeArticles'?: Array<TblSurchargeArticlePackageCatalogueUpdate>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CatalogueCatalogueUpdate
     */
    'options'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CatalogueCatalogueUpdate
     */
    'actions'?: Array<string>;
    /**
     * 
     * @type {Array<ArticlesArticleReadChampsComplementairesInner>}
     * @memberof CatalogueCatalogueUpdate
     */
    'champsComplementaires'?: Array<ArticlesArticleReadChampsComplementairesInner>;
    /**
     * 
     * @type {Array<CatalogueCatalogueCreateDocumentsInner>}
     * @memberof CatalogueCatalogueUpdate
     */
    'documents'?: Array<CatalogueCatalogueCreateDocumentsInner>;
    /**
     * 
     * @type {string}
     * @memberof CatalogueCatalogueUpdate
     */
    'domaine'?: string;
}
/**
 * 
 * @export
 * @interface CatalogueJsonldCatalogueCreate
 */
export interface CatalogueJsonldCatalogueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    'nomCommercial'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    'offre'?: string;
    /**
     * 
     * @type {number}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    'origine'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    'package'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    'groupement'?: number | null;
    /**
     * 
     * @type {Array<TblSurchargeArticlePackageJsonldCatalogueCreate>}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    'surchargeArticles'?: Array<TblSurchargeArticlePackageJsonldCatalogueCreate>;
    /**
     * 
     * @type {Array<TblRelOptionJsonldCatalogueCreate>}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    'options'?: Array<TblRelOptionJsonldCatalogueCreate>;
    /**
     * 
     * @type {Array<TblRelActionJsonldCatalogueCreate>}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    'actions'?: Array<TblRelActionJsonldCatalogueCreate>;
    /**
     * 
     * @type {Array<ArticlesArticleReadChampsComplementairesInner>}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    'champsComplementaires'?: Array<ArticlesArticleReadChampsComplementairesInner>;
    /**
     * 
     * @type {Array<CatalogueCatalogueCreateDocumentsInner>}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    'documents'?: Array<CatalogueCatalogueCreateDocumentsInner>;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueCreate
     */
    'domaine'?: string;
}
/**
 * 
 * @export
 * @interface CatalogueJsonldCatalogueRead
 */
export interface CatalogueJsonldCatalogueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CatalogueJsonldCatalogueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CatalogueJsonldCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueRead
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueRead
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueRead
     */
    'nomCommercial'?: string;
    /**
     * 
     * @type {TblOffreJsonldCatalogueRead}
     * @memberof CatalogueJsonldCatalogueRead
     */
    'offre'?: TblOffreJsonldCatalogueRead;
    /**
     * 
     * @type {TblOrigineJsonldCatalogueRead}
     * @memberof CatalogueJsonldCatalogueRead
     */
    'origine'?: TblOrigineJsonldCatalogueRead | null;
    /**
     * 
     * @type {number}
     * @memberof CatalogueJsonldCatalogueRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {TblPackageJsonldCatalogueRead}
     * @memberof CatalogueJsonldCatalogueRead
     */
    'package'?: TblPackageJsonldCatalogueRead | null;
    /**
     * 
     * @type {TblGroupementJsonldCatalogueRead}
     * @memberof CatalogueJsonldCatalogueRead
     */
    'groupement'?: TblGroupementJsonldCatalogueRead | null;
    /**
     * 
     * @type {Array<TblSurchargeArticlePackageJsonldCatalogueRead>}
     * @memberof CatalogueJsonldCatalogueRead
     */
    'surchargeArticles'?: Array<TblSurchargeArticlePackageJsonldCatalogueRead>;
    /**
     * 
     * @type {Array<TblRelOptionJsonldCatalogueRead>}
     * @memberof CatalogueJsonldCatalogueRead
     */
    'options'?: Array<TblRelOptionJsonldCatalogueRead>;
    /**
     * 
     * @type {Array<TblRelActionJsonldCatalogueRead>}
     * @memberof CatalogueJsonldCatalogueRead
     */
    'actions'?: Array<TblRelActionJsonldCatalogueRead>;
    /**
     * 
     * @type {Array<ArticlesArticleReadChampsComplementairesInner>}
     * @memberof CatalogueJsonldCatalogueRead
     */
    'champsComplementaires'?: Array<ArticlesArticleReadChampsComplementairesInner>;
    /**
     * 
     * @type {Array<CatalogueCatalogueCreateDocumentsInner>}
     * @memberof CatalogueJsonldCatalogueRead
     */
    'documents'?: Array<CatalogueCatalogueCreateDocumentsInner>;
    /**
     * 
     * @type {TblDefDomaineJsonldCatalogueRead}
     * @memberof CatalogueJsonldCatalogueRead
     */
    'domaine'?: TblDefDomaineJsonldCatalogueRead;
}
/**
 * 
 * @export
 * @interface CatalogueJsonldCatalogueUpdate
 */
export interface CatalogueJsonldCatalogueUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CatalogueJsonldCatalogueUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueUpdate
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueUpdate
     */
    'nomCommercial': string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueUpdate
     */
    'offre': string;
    /**
     * 
     * @type {number}
     * @memberof CatalogueJsonldCatalogueUpdate
     */
    'origine'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CatalogueJsonldCatalogueUpdate
     */
    'groupement': number | null;
    /**
     * 
     * @type {Array<TblSurchargeArticlePackageJsonldCatalogueUpdate>}
     * @memberof CatalogueJsonldCatalogueUpdate
     */
    'surchargeArticles'?: Array<TblSurchargeArticlePackageJsonldCatalogueUpdate>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CatalogueJsonldCatalogueUpdate
     */
    'options'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CatalogueJsonldCatalogueUpdate
     */
    'actions'?: Array<string>;
    /**
     * 
     * @type {Array<ArticlesArticleReadChampsComplementairesInner>}
     * @memberof CatalogueJsonldCatalogueUpdate
     */
    'champsComplementaires'?: Array<ArticlesArticleReadChampsComplementairesInner>;
    /**
     * 
     * @type {Array<CatalogueCatalogueCreateDocumentsInner>}
     * @memberof CatalogueJsonldCatalogueUpdate
     */
    'documents'?: Array<CatalogueCatalogueCreateDocumentsInner>;
    /**
     * 
     * @type {string}
     * @memberof CatalogueJsonldCatalogueUpdate
     */
    'domaine'?: string;
}
/**
 * 
 * @export
 * @interface CataloguePrescripteurJsonldPackagePrescripteurRead
 */
export interface CataloguePrescripteurJsonldPackagePrescripteurRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CataloguePrescripteurJsonldPackagePrescripteurRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CataloguePrescripteurJsonldPackagePrescripteurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CataloguePrescripteurJsonldPackagePrescripteurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CataloguePrescripteurJsonldPackagePrescripteurRead
     */
    'prescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof CataloguePrescripteurJsonldPackagePrescripteurRead
     */
    'catalogue'?: number;
    /**
     * 
     * @type {TblOffreJsonldPackagePrescripteurRead}
     * @memberof CataloguePrescripteurJsonldPackagePrescripteurRead
     */
    'offre'?: TblOffreJsonldPackagePrescripteurRead;
    /**
     * 
     * @type {TblPackageJsonldPackagePrescripteurRead}
     * @memberof CataloguePrescripteurJsonldPackagePrescripteurRead
     */
    'package'?: TblPackageJsonldPackagePrescripteurRead;
    /**
     * 
     * @type {Array<string>}
     * @memberof CataloguePrescripteurJsonldPackagePrescripteurRead
     */
    'options'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CataloguePrescripteurJsonldPackagePrescripteurRead
     */
    'surcharges'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CataloguePrescripteurJsonldTarifPrescripteurRead
 */
export interface CataloguePrescripteurJsonldTarifPrescripteurRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CataloguePrescripteurJsonldTarifPrescripteurRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CataloguePrescripteurJsonldTarifPrescripteurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CataloguePrescripteurJsonldTarifPrescripteurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CataloguePrescripteurJsonldTarifPrescripteurRead
     */
    'prescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof CataloguePrescripteurJsonldTarifPrescripteurRead
     */
    'catalogue'?: number;
    /**
     * 
     * @type {string}
     * @memberof CataloguePrescripteurJsonldTarifPrescripteurRead
     */
    'tarif'?: string;
    /**
     * 
     * @type {string}
     * @memberof CataloguePrescripteurJsonldTarifPrescripteurRead
     */
    'package'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CataloguePrescripteurJsonldTarifPrescripteurRead
     */
    'articles'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CataloguePrescripteurJsonldTarifPrescripteurRead
     */
    'options'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CataloguePrescripteurJsonldTarifPrescripteurRead
     */
    'champsComplementaires'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CataloguePrescripteurPackagePrescripteurRead
 */
export interface CataloguePrescripteurPackagePrescripteurRead {
    /**
     * 
     * @type {number}
     * @memberof CataloguePrescripteurPackagePrescripteurRead
     */
    'prescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof CataloguePrescripteurPackagePrescripteurRead
     */
    'catalogue'?: number;
    /**
     * 
     * @type {TblOffrePackagePrescripteurRead}
     * @memberof CataloguePrescripteurPackagePrescripteurRead
     */
    'offre'?: TblOffrePackagePrescripteurRead;
    /**
     * 
     * @type {TblPackagePackagePrescripteurRead}
     * @memberof CataloguePrescripteurPackagePrescripteurRead
     */
    'package'?: TblPackagePackagePrescripteurRead;
    /**
     * 
     * @type {Array<string>}
     * @memberof CataloguePrescripteurPackagePrescripteurRead
     */
    'options'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CataloguePrescripteurPackagePrescripteurRead
     */
    'surcharges'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CataloguePrescripteurTarifPrescripteurRead
 */
export interface CataloguePrescripteurTarifPrescripteurRead {
    /**
     * 
     * @type {number}
     * @memberof CataloguePrescripteurTarifPrescripteurRead
     */
    'prescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof CataloguePrescripteurTarifPrescripteurRead
     */
    'catalogue'?: number;
    /**
     * 
     * @type {string}
     * @memberof CataloguePrescripteurTarifPrescripteurRead
     */
    'tarif'?: string;
    /**
     * 
     * @type {string}
     * @memberof CataloguePrescripteurTarifPrescripteurRead
     */
    'package'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CataloguePrescripteurTarifPrescripteurRead
     */
    'articles'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CataloguePrescripteurTarifPrescripteurRead
     */
    'options'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CataloguePrescripteurTarifPrescripteurRead
     */
    'champsComplementaires'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CatalogueResourceDependencyDependencyRead
 */
export interface CatalogueResourceDependencyDependencyRead {
    /**
     * 
     * @type {Array<string>}
     * @memberof CatalogueResourceDependencyDependencyRead
     */
    'dependencies'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CatalogueResourceDependencyJsonldDependencyRead
 */
export interface CatalogueResourceDependencyJsonldDependencyRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CatalogueResourceDependencyJsonldDependencyRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CatalogueResourceDependencyJsonldDependencyRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueResourceDependencyJsonldDependencyRead
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CatalogueResourceDependencyJsonldDependencyRead
     */
    'dependencies'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ChampComplMentaireChampcomplementaireCreate
 */
export interface ChampComplMentaireChampcomplementaireCreate {
    /**
     * 
     * @type {number}
     * @memberof ChampComplMentaireChampcomplementaireCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireCreate
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireCreate
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireCreate
     */
    'htmlFieldType': ChampComplMentaireChampcomplementaireCreateHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof ChampComplMentaireChampcomplementaireCreate
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireCreate
     */
    'mappingRcs'?: string | null;
}

export const ChampComplMentaireChampcomplementaireCreateHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type ChampComplMentaireChampcomplementaireCreateHtmlFieldTypeEnum = typeof ChampComplMentaireChampcomplementaireCreateHtmlFieldTypeEnum[keyof typeof ChampComplMentaireChampcomplementaireCreateHtmlFieldTypeEnum];

/**
 * json permettant de renvoyer la configuration du champ au front
 * @export
 * @interface ChampComplMentaireChampcomplementaireCreateConfiguration
 */
export interface ChampComplMentaireChampcomplementaireCreateConfiguration {
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfiguration
     */
    'placeholder'?: string;
    /**
     * 
     * @type {Mixed}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfiguration
     */
    'default'?: Mixed;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfigurationRequired}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfiguration
     */
    'required'?: ChampComplMentaireChampcomplementaireCreateConfigurationRequired;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfigurationMin}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfiguration
     */
    'min'?: ChampComplMentaireChampcomplementaireCreateConfigurationMin;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfigurationMax}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfiguration
     */
    'max'?: ChampComplMentaireChampcomplementaireCreateConfigurationMax;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfigurationPattern}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfiguration
     */
    'pattern'?: ChampComplMentaireChampcomplementaireCreateConfigurationPattern;
    /**
     * Options selectionnables pour un type select
     * @type {Array<ChampComplMentaireChampcomplementaireCreateConfigurationOptionsInner>}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfiguration
     */
    'options'?: Array<ChampComplMentaireChampcomplementaireCreateConfigurationOptionsInner>;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfigurationGridOptions}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfiguration
     */
    'gridOptions'?: ChampComplMentaireChampcomplementaireCreateConfigurationGridOptions;
}
/**
 * nombre de colonnes que le champ doit occuper en fonction de la taille de l\'écran
 * @export
 * @interface ChampComplMentaireChampcomplementaireCreateConfigurationGridOptions
 */
export interface ChampComplMentaireChampcomplementaireCreateConfigurationGridOptions {
    /**
     * 
     * @type {number}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfigurationGridOptions
     */
    'lg'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfigurationGridOptions
     */
    'md'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfigurationGridOptions
     */
    'sm'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfigurationGridOptions
     */
    'xs'?: number;
}
/**
 * Valeur maximale du champ dans le cas d\'un type number, longueur maximale de la chaine pour un type text
 * @export
 * @interface ChampComplMentaireChampcomplementaireCreateConfigurationMax
 */
export interface ChampComplMentaireChampcomplementaireCreateConfigurationMax {
    /**
     * 
     * @type {number}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfigurationMax
     */
    'value': number;
    /**
     * Message à afficher en cas d\'erreur de saisie
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfigurationMax
     */
    'message': string;
}
/**
 * Valeur minimale du champ dans le cas d\'un type number, longueur minimale de la chaine pour un type text
 * @export
 * @interface ChampComplMentaireChampcomplementaireCreateConfigurationMin
 */
export interface ChampComplMentaireChampcomplementaireCreateConfigurationMin {
    /**
     * 
     * @type {number}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfigurationMin
     */
    'value': number;
    /**
     * Message à afficher en cas d\'erreur de saisie
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfigurationMin
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface ChampComplMentaireChampcomplementaireCreateConfigurationOptionsInner
 */
export interface ChampComplMentaireChampcomplementaireCreateConfigurationOptionsInner {
    /**
     * Valeur de l\'option à renvoyer à l\'api
     * @type {any}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfigurationOptionsInner
     */
    'value': any;
    /**
     * Texte à afficher
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfigurationOptionsInner
     */
    'label': string;
}
/**
 * Regex à appliquer sur le champ pour la validation
 * @export
 * @interface ChampComplMentaireChampcomplementaireCreateConfigurationPattern
 */
export interface ChampComplMentaireChampcomplementaireCreateConfigurationPattern {
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfigurationPattern
     */
    'value': string;
    /**
     * Message à afficher en cas d\'erreur de saisie
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfigurationPattern
     */
    'message': string;
}
/**
 * Le champ est-il obligatoire ?
 * @export
 * @interface ChampComplMentaireChampcomplementaireCreateConfigurationRequired
 */
export interface ChampComplMentaireChampcomplementaireCreateConfigurationRequired {
    /**
     * 
     * @type {boolean}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfigurationRequired
     */
    'value': boolean;
    /**
     * Message à afficher en cas d\'erreur de saisie
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireCreateConfigurationRequired
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface ChampComplMentaireChampcomplementaireRead
 */
export interface ChampComplMentaireChampcomplementaireRead {
    /**
     * 
     * @type {number}
     * @memberof ChampComplMentaireChampcomplementaireRead
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ChampComplMentaireChampcomplementaireRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireRead
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireRead
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireRead
     */
    'htmlFieldType': ChampComplMentaireChampcomplementaireReadHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof ChampComplMentaireChampcomplementaireRead
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireRead
     */
    'mappingRcs'?: string | null;
}

export const ChampComplMentaireChampcomplementaireReadHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type ChampComplMentaireChampcomplementaireReadHtmlFieldTypeEnum = typeof ChampComplMentaireChampcomplementaireReadHtmlFieldTypeEnum[keyof typeof ChampComplMentaireChampcomplementaireReadHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface ChampComplMentaireChampcomplementaireUpdate
 */
export interface ChampComplMentaireChampcomplementaireUpdate {
    /**
     * 
     * @type {number}
     * @memberof ChampComplMentaireChampcomplementaireUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireUpdate
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireUpdate
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireUpdate
     */
    'htmlFieldType': ChampComplMentaireChampcomplementaireUpdateHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof ChampComplMentaireChampcomplementaireUpdate
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof ChampComplMentaireChampcomplementaireUpdate
     */
    'mappingRcs'?: string | null;
}

export const ChampComplMentaireChampcomplementaireUpdateHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type ChampComplMentaireChampcomplementaireUpdateHtmlFieldTypeEnum = typeof ChampComplMentaireChampcomplementaireUpdateHtmlFieldTypeEnum[keyof typeof ChampComplMentaireChampcomplementaireUpdateHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface ChampComplMentaireJsonldChampcomplementaireCreate
 */
export interface ChampComplMentaireJsonldChampcomplementaireCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ChampComplMentaireJsonldChampcomplementaireCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChampComplMentaireJsonldChampcomplementaireCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireCreate
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireCreate
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireCreate
     */
    'htmlFieldType': ChampComplMentaireJsonldChampcomplementaireCreateHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof ChampComplMentaireJsonldChampcomplementaireCreate
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireCreate
     */
    'mappingRcs'?: string | null;
}

export const ChampComplMentaireJsonldChampcomplementaireCreateHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type ChampComplMentaireJsonldChampcomplementaireCreateHtmlFieldTypeEnum = typeof ChampComplMentaireJsonldChampcomplementaireCreateHtmlFieldTypeEnum[keyof typeof ChampComplMentaireJsonldChampcomplementaireCreateHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface ChampComplMentaireJsonldChampcomplementaireRead
 */
export interface ChampComplMentaireJsonldChampcomplementaireRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ChampComplMentaireJsonldChampcomplementaireRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChampComplMentaireJsonldChampcomplementaireRead
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ChampComplMentaireJsonldChampcomplementaireRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireRead
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireRead
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireRead
     */
    'htmlFieldType': ChampComplMentaireJsonldChampcomplementaireReadHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof ChampComplMentaireJsonldChampcomplementaireRead
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireRead
     */
    'mappingRcs'?: string | null;
}

export const ChampComplMentaireJsonldChampcomplementaireReadHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type ChampComplMentaireJsonldChampcomplementaireReadHtmlFieldTypeEnum = typeof ChampComplMentaireJsonldChampcomplementaireReadHtmlFieldTypeEnum[keyof typeof ChampComplMentaireJsonldChampcomplementaireReadHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface ChampComplMentaireJsonldChampcomplementaireUpdate
 */
export interface ChampComplMentaireJsonldChampcomplementaireUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ChampComplMentaireJsonldChampcomplementaireUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChampComplMentaireJsonldChampcomplementaireUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireUpdate
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireUpdate
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireUpdate
     */
    'htmlFieldType': ChampComplMentaireJsonldChampcomplementaireUpdateHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof ChampComplMentaireJsonldChampcomplementaireUpdate
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof ChampComplMentaireJsonldChampcomplementaireUpdate
     */
    'mappingRcs'?: string | null;
}

export const ChampComplMentaireJsonldChampcomplementaireUpdateHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type ChampComplMentaireJsonldChampcomplementaireUpdateHtmlFieldTypeEnum = typeof ChampComplMentaireJsonldChampcomplementaireUpdateHtmlFieldTypeEnum[keyof typeof ChampComplMentaireJsonldChampcomplementaireUpdateHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface ChampComplMentaireResourceDependencyDependencyRead
 */
export interface ChampComplMentaireResourceDependencyDependencyRead {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChampComplMentaireResourceDependencyDependencyRead
     */
    'dependencies'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ChampComplMentaireResourceDependencyJsonldDependencyRead
 */
export interface ChampComplMentaireResourceDependencyJsonldDependencyRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ChampComplMentaireResourceDependencyJsonldDependencyRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireResourceDependencyJsonldDependencyRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChampComplMentaireResourceDependencyJsonldDependencyRead
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChampComplMentaireResourceDependencyJsonldDependencyRead
     */
    'dependencies'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DomainesDomaineRead
 */
export interface DomainesDomaineRead {
    /**
     * 
     * @type {number}
     * @memberof DomainesDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomainesDomaineRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainesDomaineRead
     */
    'info'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainesDomaineRead
     */
    'code'?: string | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof DomainesDomaineRead
     */
    'origines'?: Array<object>;
}
/**
 * 
 * @export
 * @interface DomainesJsonldDomaineRead
 */
export interface DomainesJsonldDomaineRead {
    /**
     * 
     * @type {string}
     * @memberof DomainesJsonldDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainesJsonldDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DomainesJsonldDomaineRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof DomainesJsonldDomaineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomainesJsonldDomaineRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainesJsonldDomaineRead
     */
    'info'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainesJsonldDomaineRead
     */
    'code'?: string | null;
    /**
     * 
     * @type {Array<TblRelationDomaineOrigineJsonldDomaineRead>}
     * @memberof DomainesJsonldDomaineRead
     */
    'origines'?: Array<TblRelationDomaineOrigineJsonldDomaineRead>;
}
/**
 * 
 * @export
 * @interface FormaliteFormaliteRead
 */
export interface FormaliteFormaliteRead {
    /**
     * 
     * @type {number}
     * @memberof FormaliteFormaliteRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteRead
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface FormaliteJsonldFormaliteRead
 */
export interface FormaliteJsonldFormaliteRead {
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FormaliteJsonldFormaliteRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface FproFormaliteArticleJsonldPackageRead
 */
export interface FproFormaliteArticleJsonldPackageRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproFormaliteArticleJsonldPackageRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteArticleJsonldPackageRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteArticleJsonldPackageRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproFormaliteArticleJsonldPackageRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteArticleJsonldPackageRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteArticleJsonldPackageRead
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface FproFormaliteArticlePackageRead
 */
export interface FproFormaliteArticlePackageRead {
    /**
     * 
     * @type {number}
     * @memberof FproFormaliteArticlePackageRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteArticlePackageRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteArticlePackageRead
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface GroupementGroupementCreate
 */
export interface GroupementGroupementCreate {
    /**
     * 
     * @type {string}
     * @memberof GroupementGroupementCreate
     */
    'libelle': string;
    /**
     * 
     * @type {number}
     * @memberof GroupementGroupementCreate
     */
    'domaine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GroupementGroupementCreate
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface GroupementGroupementRead
 */
export interface GroupementGroupementRead {
    /**
     * 
     * @type {number}
     * @memberof GroupementGroupementRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GroupementGroupementRead
     */
    'libelle': string;
    /**
     * 
     * @type {TblDefDomaineGroupementRead}
     * @memberof GroupementGroupementRead
     */
    'domaine'?: TblDefDomaineGroupementRead | null;
    /**
     * 
     * @type {Array<TblCatalogueGroupementRead>}
     * @memberof GroupementGroupementRead
     */
    'dependencies'?: Array<TblCatalogueGroupementRead>;
    /**
     * 
     * @type {string}
     * @memberof GroupementGroupementRead
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroupementGroupementRead
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface GroupementGroupementUpdate
 */
export interface GroupementGroupementUpdate {
    /**
     * 
     * @type {string}
     * @memberof GroupementGroupementUpdate
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof GroupementGroupementUpdate
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface GroupementJsonldGroupementCreate
 */
export interface GroupementJsonldGroupementCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof GroupementJsonldGroupementCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldGroupementCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldGroupementCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldGroupementCreate
     */
    'libelle': string;
    /**
     * 
     * @type {number}
     * @memberof GroupementJsonldGroupementCreate
     */
    'domaine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldGroupementCreate
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface GroupementJsonldGroupementRead
 */
export interface GroupementJsonldGroupementRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof GroupementJsonldGroupementRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldGroupementRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldGroupementRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupementJsonldGroupementRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldGroupementRead
     */
    'libelle': string;
    /**
     * 
     * @type {TblDefDomaineJsonldGroupementRead}
     * @memberof GroupementJsonldGroupementRead
     */
    'domaine'?: TblDefDomaineJsonldGroupementRead | null;
    /**
     * 
     * @type {Array<TblCatalogueJsonldGroupementRead>}
     * @memberof GroupementJsonldGroupementRead
     */
    'dependencies'?: Array<TblCatalogueJsonldGroupementRead>;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldGroupementRead
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroupementJsonldGroupementRead
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface GroupementJsonldGroupementUpdate
 */
export interface GroupementJsonldGroupementUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof GroupementJsonldGroupementUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldGroupementUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldGroupementUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldGroupementUpdate
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldGroupementUpdate
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface GroupementJsonldOrdreGroupementOrigineCreate
 */
export interface GroupementJsonldOrdreGroupementOrigineCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof GroupementJsonldOrdreGroupementOrigineCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldOrdreGroupementOrigineCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldOrdreGroupementOrigineCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupementJsonldOrdreGroupementOrigineCreate
     */
    'origine': number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupementJsonldOrdreGroupementOrigineCreate
     */
    'domaine': number | null;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldOrdreGroupementOrigineCreate
     */
    'groupements': string;
}
/**
 * 
 * @export
 * @interface GroupementJsonldOrdreGroupementOrigineRead
 */
export interface GroupementJsonldOrdreGroupementOrigineRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof GroupementJsonldOrdreGroupementOrigineRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldOrdreGroupementOrigineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldOrdreGroupementOrigineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupementJsonldOrdreGroupementOrigineRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupementJsonldOrdreGroupementOrigineRead
     */
    'origine': number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupementJsonldOrdreGroupementOrigineRead
     */
    'domaine': number | null;
    /**
     * 
     * @type {string}
     * @memberof GroupementJsonldOrdreGroupementOrigineRead
     */
    'groupements': string;
}
/**
 * 
 * @export
 * @interface GroupementOrdreGroupementOrigineCreate
 */
export interface GroupementOrdreGroupementOrigineCreate {
    /**
     * 
     * @type {number}
     * @memberof GroupementOrdreGroupementOrigineCreate
     */
    'origine': number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupementOrdreGroupementOrigineCreate
     */
    'domaine': number | null;
    /**
     * 
     * @type {string}
     * @memberof GroupementOrdreGroupementOrigineCreate
     */
    'groupements': string;
}
/**
 * 
 * @export
 * @interface GroupementOrdreGroupementOrigineRead
 */
export interface GroupementOrdreGroupementOrigineRead {
    /**
     * 
     * @type {number}
     * @memberof GroupementOrdreGroupementOrigineRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupementOrdreGroupementOrigineRead
     */
    'origine': number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupementOrdreGroupementOrigineRead
     */
    'domaine': number | null;
    /**
     * 
     * @type {string}
     * @memberof GroupementOrdreGroupementOrigineRead
     */
    'groupements': string;
}
/**
 * 
 * @export
 * @interface OffresJsonldOffreCreate
 */
export interface OffresJsonldOffreCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OffresJsonldOffreCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OffresJsonldOffreCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OffresJsonldOffreCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof OffresJsonldOffreCreate
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OffresJsonldOffreCreate
     */
    'domaine'?: string;
    /**
     * 
     * @type {string}
     * @memberof OffresJsonldOffreCreate
     */
    'visibilite': OffresJsonldOffreCreateVisibiliteEnum;
    /**
     * 
     * @type {string}
     * @memberof OffresJsonldOffreCreate
     */
    'iconeName'?: string | null;
}

export const OffresJsonldOffreCreateVisibiliteEnum = {
    Full: 'FULL',
    Partial: 'PARTIAL'
} as const;

export type OffresJsonldOffreCreateVisibiliteEnum = typeof OffresJsonldOffreCreateVisibiliteEnum[keyof typeof OffresJsonldOffreCreateVisibiliteEnum];

/**
 * 
 * @export
 * @interface OffresJsonldOffreRead
 */
export interface OffresJsonldOffreRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OffresJsonldOffreRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OffresJsonldOffreRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OffresJsonldOffreRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OffresJsonldOffreRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OffresJsonldOffreRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {TblDefDomaineJsonldOffreRead}
     * @memberof OffresJsonldOffreRead
     */
    'domaine'?: TblDefDomaineJsonldOffreRead;
    /**
     * 
     * @type {boolean}
     * @memberof OffresJsonldOffreRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OffresJsonldOffreRead
     */
    'visibilite': OffresJsonldOffreReadVisibiliteEnum;
    /**
     * 
     * @type {string}
     * @memberof OffresJsonldOffreRead
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {Array<TblRelOrigineOffreJsonldOffreRead>}
     * @memberof OffresJsonldOffreRead
     */
    'origines'?: Array<TblRelOrigineOffreJsonldOffreRead>;
}

export const OffresJsonldOffreReadVisibiliteEnum = {
    Full: 'FULL',
    Partial: 'PARTIAL'
} as const;

export type OffresJsonldOffreReadVisibiliteEnum = typeof OffresJsonldOffreReadVisibiliteEnum[keyof typeof OffresJsonldOffreReadVisibiliteEnum];

/**
 * 
 * @export
 * @interface OffresJsonldOffreUpdate
 */
export interface OffresJsonldOffreUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OffresJsonldOffreUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OffresJsonldOffreUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OffresJsonldOffreUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof OffresJsonldOffreUpdate
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof OffresJsonldOffreUpdate
     */
    'domaine': string;
    /**
     * 
     * @type {string}
     * @memberof OffresJsonldOffreUpdate
     */
    'visibilite': OffresJsonldOffreUpdateVisibiliteEnum;
    /**
     * 
     * @type {string}
     * @memberof OffresJsonldOffreUpdate
     */
    'iconeName'?: string | null;
}

export const OffresJsonldOffreUpdateVisibiliteEnum = {
    Full: 'FULL',
    Partial: 'PARTIAL'
} as const;

export type OffresJsonldOffreUpdateVisibiliteEnum = typeof OffresJsonldOffreUpdateVisibiliteEnum[keyof typeof OffresJsonldOffreUpdateVisibiliteEnum];

/**
 * 
 * @export
 * @interface OffresOffreCreate
 */
export interface OffresOffreCreate {
    /**
     * 
     * @type {string}
     * @memberof OffresOffreCreate
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OffresOffreCreate
     */
    'domaine'?: string;
    /**
     * 
     * @type {string}
     * @memberof OffresOffreCreate
     */
    'visibilite': OffresOffreCreateVisibiliteEnum;
    /**
     * 
     * @type {string}
     * @memberof OffresOffreCreate
     */
    'iconeName'?: string | null;
}

export const OffresOffreCreateVisibiliteEnum = {
    Full: 'FULL',
    Partial: 'PARTIAL'
} as const;

export type OffresOffreCreateVisibiliteEnum = typeof OffresOffreCreateVisibiliteEnum[keyof typeof OffresOffreCreateVisibiliteEnum];

/**
 * 
 * @export
 * @interface OffresOffreRead
 */
export interface OffresOffreRead {
    /**
     * 
     * @type {number}
     * @memberof OffresOffreRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OffresOffreRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {TblDefDomaineOffreRead}
     * @memberof OffresOffreRead
     */
    'domaine'?: TblDefDomaineOffreRead;
    /**
     * 
     * @type {boolean}
     * @memberof OffresOffreRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OffresOffreRead
     */
    'visibilite': OffresOffreReadVisibiliteEnum;
    /**
     * 
     * @type {string}
     * @memberof OffresOffreRead
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {Array<TblRelOrigineOffreOffreRead>}
     * @memberof OffresOffreRead
     */
    'origines'?: Array<TblRelOrigineOffreOffreRead>;
}

export const OffresOffreReadVisibiliteEnum = {
    Full: 'FULL',
    Partial: 'PARTIAL'
} as const;

export type OffresOffreReadVisibiliteEnum = typeof OffresOffreReadVisibiliteEnum[keyof typeof OffresOffreReadVisibiliteEnum];

/**
 * 
 * @export
 * @interface OffresOffreUpdate
 */
export interface OffresOffreUpdate {
    /**
     * 
     * @type {string}
     * @memberof OffresOffreUpdate
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof OffresOffreUpdate
     */
    'domaine': string;
    /**
     * 
     * @type {string}
     * @memberof OffresOffreUpdate
     */
    'visibilite': OffresOffreUpdateVisibiliteEnum;
    /**
     * 
     * @type {string}
     * @memberof OffresOffreUpdate
     */
    'iconeName'?: string | null;
}

export const OffresOffreUpdateVisibiliteEnum = {
    Full: 'FULL',
    Partial: 'PARTIAL'
} as const;

export type OffresOffreUpdateVisibiliteEnum = typeof OffresOffreUpdateVisibiliteEnum[keyof typeof OffresOffreUpdateVisibiliteEnum];

/**
 * 
 * @export
 * @interface OffresResourceDependencyDependencyRead
 */
export interface OffresResourceDependencyDependencyRead {
    /**
     * 
     * @type {Array<string>}
     * @memberof OffresResourceDependencyDependencyRead
     */
    'dependencies'?: Array<string>;
}
/**
 * 
 * @export
 * @interface OffresResourceDependencyJsonldDependencyRead
 */
export interface OffresResourceDependencyJsonldDependencyRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OffresResourceDependencyJsonldDependencyRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OffresResourceDependencyJsonldDependencyRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OffresResourceDependencyJsonldDependencyRead
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OffresResourceDependencyJsonldDependencyRead
     */
    'dependencies'?: Array<string>;
}
/**
 * 
 * @export
 * @interface OptionValueJsonldOptionValueCreate
 */
export interface OptionValueJsonldOptionValueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OptionValueJsonldOptionValueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OptionValueJsonldOptionValueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionValueJsonldOptionValueCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OptionValueJsonldOptionValueCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OptionValueJsonldOptionValueCreate
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionValueJsonldOptionValueCreate
     */
    'description'?: string | null;
    /**
     * permet de jouer sur l\'ordre d\'apparition dans le front (0 = à la fin)
     * @type {number}
     * @memberof OptionValueJsonldOptionValueCreate
     */
    'rang'?: number;
    /**
     * 
     * @type {string}
     * @memberof OptionValueJsonldOptionValueCreate
     */
    'option'?: string | null;
    /**
     * 
     * @type {Array<TblRelArticleJsonldOptionValueCreate>}
     * @memberof OptionValueJsonldOptionValueCreate
     */
    'articles'?: Array<TblRelArticleJsonldOptionValueCreate>;
    /**
     * 
     * @type {Array<TblRelActionJsonldOptionValueCreate>}
     * @memberof OptionValueJsonldOptionValueCreate
     */
    'actions'?: Array<TblRelActionJsonldOptionValueCreate>;
    /**
     * 
     * @type {Array<TblRelTypeDocumentJsonldOptionValueCreate>}
     * @memberof OptionValueJsonldOptionValueCreate
     */
    'documents'?: Array<TblRelTypeDocumentJsonldOptionValueCreate>;
    /**
     * 
     * @type {Array<TblRelChampCompJsonldOptionValueCreate>}
     * @memberof OptionValueJsonldOptionValueCreate
     */
    'champsComplementaires'?: Array<TblRelChampCompJsonldOptionValueCreate>;
}
/**
 * 
 * @export
 * @interface OptionValueJsonldOptionValueRead
 */
export interface OptionValueJsonldOptionValueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OptionValueJsonldOptionValueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OptionValueJsonldOptionValueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionValueJsonldOptionValueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OptionValueJsonldOptionValueRead
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OptionValueJsonldOptionValueRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OptionValueJsonldOptionValueRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionValueJsonldOptionValueRead
     */
    'description'?: string | null;
    /**
     * permet de jouer sur l\'ordre d\'apparition dans le front (0 = à la fin)
     * @type {number}
     * @memberof OptionValueJsonldOptionValueRead
     */
    'rang'?: number;
    /**
     * 
     * @type {string}
     * @memberof OptionValueJsonldOptionValueRead
     */
    'option'?: string | null;
    /**
     * 
     * @type {Array<TblRelArticleJsonldOptionValueRead>}
     * @memberof OptionValueJsonldOptionValueRead
     */
    'articles'?: Array<TblRelArticleJsonldOptionValueRead>;
    /**
     * 
     * @type {Array<TblRelActionJsonldOptionValueRead>}
     * @memberof OptionValueJsonldOptionValueRead
     */
    'actions'?: Array<TblRelActionJsonldOptionValueRead>;
    /**
     * 
     * @type {Array<TblRelTypeDocumentJsonldOptionValueRead>}
     * @memberof OptionValueJsonldOptionValueRead
     */
    'documents'?: Array<TblRelTypeDocumentJsonldOptionValueRead>;
    /**
     * 
     * @type {Array<TblRelChampCompJsonldOptionValueRead>}
     * @memberof OptionValueJsonldOptionValueRead
     */
    'champsComplementaires'?: Array<TblRelChampCompJsonldOptionValueRead>;
}
/**
 * 
 * @export
 * @interface OptionValueJsonldOptionValueUpdate
 */
export interface OptionValueJsonldOptionValueUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OptionValueJsonldOptionValueUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OptionValueJsonldOptionValueUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionValueJsonldOptionValueUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OptionValueJsonldOptionValueUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OptionValueJsonldOptionValueUpdate
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof OptionValueJsonldOptionValueUpdate
     */
    'description'?: string | null;
    /**
     * permet de jouer sur l\'ordre d\'apparition dans le front (0 = à la fin)
     * @type {number}
     * @memberof OptionValueJsonldOptionValueUpdate
     */
    'rang'?: number;
    /**
     * 
     * @type {string}
     * @memberof OptionValueJsonldOptionValueUpdate
     */
    'option'?: string | null;
    /**
     * 
     * @type {Array<TblRelArticleJsonldOptionValueUpdate>}
     * @memberof OptionValueJsonldOptionValueUpdate
     */
    'articles'?: Array<TblRelArticleJsonldOptionValueUpdate>;
    /**
     * 
     * @type {Array<TblRelActionJsonldOptionValueUpdate>}
     * @memberof OptionValueJsonldOptionValueUpdate
     */
    'actions'?: Array<TblRelActionJsonldOptionValueUpdate>;
    /**
     * 
     * @type {Array<TblRelTypeDocumentJsonldOptionValueUpdate>}
     * @memberof OptionValueJsonldOptionValueUpdate
     */
    'documents'?: Array<TblRelTypeDocumentJsonldOptionValueUpdate>;
    /**
     * 
     * @type {Array<TblRelChampCompJsonldOptionValueUpdate>}
     * @memberof OptionValueJsonldOptionValueUpdate
     */
    'champsComplementaires'?: Array<TblRelChampCompJsonldOptionValueUpdate>;
}
/**
 * 
 * @export
 * @interface OptionValueOptionValueCreate
 */
export interface OptionValueOptionValueCreate {
    /**
     * 
     * @type {number}
     * @memberof OptionValueOptionValueCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OptionValueOptionValueCreate
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionValueOptionValueCreate
     */
    'description'?: string | null;
    /**
     * permet de jouer sur l\'ordre d\'apparition dans le front (0 = à la fin)
     * @type {number}
     * @memberof OptionValueOptionValueCreate
     */
    'rang'?: number;
    /**
     * 
     * @type {string}
     * @memberof OptionValueOptionValueCreate
     */
    'option'?: string | null;
    /**
     * 
     * @type {Array<TblRelArticleOptionValueCreate>}
     * @memberof OptionValueOptionValueCreate
     */
    'articles'?: Array<TblRelArticleOptionValueCreate>;
    /**
     * 
     * @type {Array<TblRelActionOptionValueCreate>}
     * @memberof OptionValueOptionValueCreate
     */
    'actions'?: Array<TblRelActionOptionValueCreate>;
    /**
     * 
     * @type {Array<TblRelTypeDocumentOptionValueCreate>}
     * @memberof OptionValueOptionValueCreate
     */
    'documents'?: Array<TblRelTypeDocumentOptionValueCreate>;
    /**
     * 
     * @type {Array<TblRelChampCompOptionValueCreate>}
     * @memberof OptionValueOptionValueCreate
     */
    'champsComplementaires'?: Array<TblRelChampCompOptionValueCreate>;
}
/**
 * 
 * @export
 * @interface OptionValueOptionValueRead
 */
export interface OptionValueOptionValueRead {
    /**
     * 
     * @type {number}
     * @memberof OptionValueOptionValueRead
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OptionValueOptionValueRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OptionValueOptionValueRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionValueOptionValueRead
     */
    'description'?: string | null;
    /**
     * permet de jouer sur l\'ordre d\'apparition dans le front (0 = à la fin)
     * @type {number}
     * @memberof OptionValueOptionValueRead
     */
    'rang'?: number;
    /**
     * 
     * @type {string}
     * @memberof OptionValueOptionValueRead
     */
    'option'?: string | null;
    /**
     * 
     * @type {Array<TblRelArticleOptionValueRead>}
     * @memberof OptionValueOptionValueRead
     */
    'articles'?: Array<TblRelArticleOptionValueRead>;
    /**
     * 
     * @type {Array<TblRelActionOptionValueRead>}
     * @memberof OptionValueOptionValueRead
     */
    'actions'?: Array<TblRelActionOptionValueRead>;
    /**
     * 
     * @type {Array<TblRelTypeDocumentOptionValueRead>}
     * @memberof OptionValueOptionValueRead
     */
    'documents'?: Array<TblRelTypeDocumentOptionValueRead>;
    /**
     * 
     * @type {Array<TblRelChampCompOptionValueRead>}
     * @memberof OptionValueOptionValueRead
     */
    'champsComplementaires'?: Array<TblRelChampCompOptionValueRead>;
}
/**
 * 
 * @export
 * @interface OptionValueOptionValueUpdate
 */
export interface OptionValueOptionValueUpdate {
    /**
     * 
     * @type {number}
     * @memberof OptionValueOptionValueUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OptionValueOptionValueUpdate
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof OptionValueOptionValueUpdate
     */
    'description'?: string | null;
    /**
     * permet de jouer sur l\'ordre d\'apparition dans le front (0 = à la fin)
     * @type {number}
     * @memberof OptionValueOptionValueUpdate
     */
    'rang'?: number;
    /**
     * 
     * @type {string}
     * @memberof OptionValueOptionValueUpdate
     */
    'option'?: string | null;
    /**
     * 
     * @type {Array<TblRelArticleOptionValueUpdate>}
     * @memberof OptionValueOptionValueUpdate
     */
    'articles'?: Array<TblRelArticleOptionValueUpdate>;
    /**
     * 
     * @type {Array<TblRelActionOptionValueUpdate>}
     * @memberof OptionValueOptionValueUpdate
     */
    'actions'?: Array<TblRelActionOptionValueUpdate>;
    /**
     * 
     * @type {Array<TblRelTypeDocumentOptionValueUpdate>}
     * @memberof OptionValueOptionValueUpdate
     */
    'documents'?: Array<TblRelTypeDocumentOptionValueUpdate>;
    /**
     * 
     * @type {Array<TblRelChampCompOptionValueUpdate>}
     * @memberof OptionValueOptionValueUpdate
     */
    'champsComplementaires'?: Array<TblRelChampCompOptionValueUpdate>;
}
/**
 * 
 * @export
 * @interface OptionValueResourceDependencyDependencyRead
 */
export interface OptionValueResourceDependencyDependencyRead {
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionValueResourceDependencyDependencyRead
     */
    'dependencies'?: Array<string>;
}
/**
 * 
 * @export
 * @interface OptionValueResourceDependencyJsonldDependencyRead
 */
export interface OptionValueResourceDependencyJsonldDependencyRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OptionValueResourceDependencyJsonldDependencyRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OptionValueResourceDependencyJsonldDependencyRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionValueResourceDependencyJsonldDependencyRead
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionValueResourceDependencyJsonldDependencyRead
     */
    'dependencies'?: Array<string>;
}
/**
 * 
 * @export
 * @interface OptionsJsonldOptionCreate
 */
export interface OptionsJsonldOptionCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OptionsJsonldOptionCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OptionsJsonldOptionCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionsJsonldOptionCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionsJsonldOptionCreate
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionsJsonldOptionCreate
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionsJsonldOptionCreate
     */
    'htmlFieldType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OptionsJsonldOptionCreate
     */
    'obligatoire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OptionsJsonldOptionCreate
     */
    'visibleFront'?: boolean;
    /**
     * 
     * @type {Array<TblOptionValueJsonldOptionCreate>}
     * @memberof OptionsJsonldOptionCreate
     */
    'values'?: Array<TblOptionValueJsonldOptionCreate>;
    /**
     * 
     * @type {string}
     * @memberof OptionsJsonldOptionCreate
     */
    'domaine'?: string;
}
/**
 * 
 * @export
 * @interface OptionsJsonldOptionRead
 */
export interface OptionsJsonldOptionRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OptionsJsonldOptionRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OptionsJsonldOptionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionsJsonldOptionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OptionsJsonldOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OptionsJsonldOptionRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionsJsonldOptionRead
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OptionsJsonldOptionRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OptionsJsonldOptionRead
     */
    'htmlFieldType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OptionsJsonldOptionRead
     */
    'obligatoire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OptionsJsonldOptionRead
     */
    'visibleFront'?: boolean;
    /**
     * 
     * @type {Array<TblOptionValueJsonldOptionRead>}
     * @memberof OptionsJsonldOptionRead
     */
    'values'?: Array<TblOptionValueJsonldOptionRead>;
    /**
     * 
     * @type {TblDefDomaineJsonldOptionRead}
     * @memberof OptionsJsonldOptionRead
     */
    'domaine'?: TblDefDomaineJsonldOptionRead;
}
/**
 * 
 * @export
 * @interface OptionsJsonldOptionUpdate
 */
export interface OptionsJsonldOptionUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OptionsJsonldOptionUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OptionsJsonldOptionUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionsJsonldOptionUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionsJsonldOptionUpdate
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof OptionsJsonldOptionUpdate
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof OptionsJsonldOptionUpdate
     */
    'htmlFieldType': OptionsJsonldOptionUpdateHtmlFieldTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OptionsJsonldOptionUpdate
     */
    'obligatoire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OptionsJsonldOptionUpdate
     */
    'visibleFront'?: boolean;
    /**
     * 
     * @type {Array<TblOptionValueJsonldOptionUpdate>}
     * @memberof OptionsJsonldOptionUpdate
     */
    'values'?: Array<TblOptionValueJsonldOptionUpdate>;
    /**
     * 
     * @type {string}
     * @memberof OptionsJsonldOptionUpdate
     */
    'domaine'?: string;
}

export const OptionsJsonldOptionUpdateHtmlFieldTypeEnum = {
    Checkbox: 'checkbox',
    Select: 'select',
    Radio: 'radio'
} as const;

export type OptionsJsonldOptionUpdateHtmlFieldTypeEnum = typeof OptionsJsonldOptionUpdateHtmlFieldTypeEnum[keyof typeof OptionsJsonldOptionUpdateHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface OptionsOptionCreate
 */
export interface OptionsOptionCreate {
    /**
     * 
     * @type {string}
     * @memberof OptionsOptionCreate
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionsOptionCreate
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionsOptionCreate
     */
    'htmlFieldType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OptionsOptionCreate
     */
    'obligatoire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OptionsOptionCreate
     */
    'visibleFront'?: boolean;
    /**
     * 
     * @type {Array<TblOptionValueOptionCreate>}
     * @memberof OptionsOptionCreate
     */
    'values'?: Array<TblOptionValueOptionCreate>;
    /**
     * 
     * @type {string}
     * @memberof OptionsOptionCreate
     */
    'domaine'?: string;
}
/**
 * 
 * @export
 * @interface OptionsOptionRead
 */
export interface OptionsOptionRead {
    /**
     * 
     * @type {number}
     * @memberof OptionsOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OptionsOptionRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionsOptionRead
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OptionsOptionRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OptionsOptionRead
     */
    'htmlFieldType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OptionsOptionRead
     */
    'obligatoire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OptionsOptionRead
     */
    'visibleFront'?: boolean;
    /**
     * 
     * @type {Array<TblOptionValueOptionRead>}
     * @memberof OptionsOptionRead
     */
    'values'?: Array<TblOptionValueOptionRead>;
    /**
     * 
     * @type {TblDefDomaineOptionRead}
     * @memberof OptionsOptionRead
     */
    'domaine'?: TblDefDomaineOptionRead;
}
/**
 * 
 * @export
 * @interface OptionsOptionUpdate
 */
export interface OptionsOptionUpdate {
    /**
     * 
     * @type {string}
     * @memberof OptionsOptionUpdate
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof OptionsOptionUpdate
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof OptionsOptionUpdate
     */
    'htmlFieldType': OptionsOptionUpdateHtmlFieldTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OptionsOptionUpdate
     */
    'obligatoire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OptionsOptionUpdate
     */
    'visibleFront'?: boolean;
    /**
     * 
     * @type {Array<TblOptionValueOptionUpdate>}
     * @memberof OptionsOptionUpdate
     */
    'values'?: Array<TblOptionValueOptionUpdate>;
    /**
     * 
     * @type {string}
     * @memberof OptionsOptionUpdate
     */
    'domaine'?: string;
}

export const OptionsOptionUpdateHtmlFieldTypeEnum = {
    Checkbox: 'checkbox',
    Select: 'select',
    Radio: 'radio'
} as const;

export type OptionsOptionUpdateHtmlFieldTypeEnum = typeof OptionsOptionUpdateHtmlFieldTypeEnum[keyof typeof OptionsOptionUpdateHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface OptionsResourceDependencyDependencyRead
 */
export interface OptionsResourceDependencyDependencyRead {
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionsResourceDependencyDependencyRead
     */
    'dependencies'?: Array<string>;
}
/**
 * 
 * @export
 * @interface OptionsResourceDependencyJsonldDependencyRead
 */
export interface OptionsResourceDependencyJsonldDependencyRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OptionsResourceDependencyJsonldDependencyRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OptionsResourceDependencyJsonldDependencyRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionsResourceDependencyJsonldDependencyRead
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionsResourceDependencyJsonldDependencyRead
     */
    'dependencies'?: Array<string>;
}
/**
 * 
 * @export
 * @interface OriginesJsonldOrigineRead
 */
export interface OriginesJsonldOrigineRead {
    /**
     * 
     * @type {string}
     * @memberof OriginesJsonldOrigineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesJsonldOrigineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OriginesJsonldOrigineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OriginesJsonldOrigineRead
     */
    'origine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OriginesJsonldOrigineRead
     */
    'initiale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OriginesJsonldOrigineRead
     */
    'societe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OriginesJsonldOrigineRead
     */
    'baseUrl'?: string;
    /**
     * 
     * @type {Array<TblRelationDomaineOrigineJsonldOrigineRead>}
     * @memberof OriginesJsonldOrigineRead
     */
    'domaines'?: Array<TblRelationDomaineOrigineJsonldOrigineRead>;
    /**
     * 
     * @type {Array<TblRelOrigineOffreJsonldOrigineRead>}
     * @memberof OriginesJsonldOrigineRead
     */
    'offres'?: Array<TblRelOrigineOffreJsonldOrigineRead>;
}
/**
 * 
 * @export
 * @interface OriginesOffresJsonldOrigineOffreCreate
 */
export interface OriginesOffresJsonldOrigineOffreCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OriginesOffresJsonldOrigineOffreCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresJsonldOrigineOffreCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresJsonldOrigineOffreCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OriginesOffresJsonldOrigineOffreCreate
     */
    'default'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresJsonldOrigineOffreCreate
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresJsonldOrigineOffreCreate
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresJsonldOrigineOffreCreate
     */
    'offre'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresJsonldOrigineOffreCreate
     */
    'origine'?: string;
}
/**
 * 
 * @export
 * @interface OriginesOffresJsonldOrigineOffreRead
 */
export interface OriginesOffresJsonldOrigineOffreRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OriginesOffresJsonldOrigineOffreRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresJsonldOrigineOffreRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresJsonldOrigineOffreRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OriginesOffresJsonldOrigineOffreRead
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OriginesOffresJsonldOrigineOffreRead
     */
    'default'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresJsonldOrigineOffreRead
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresJsonldOrigineOffreRead
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {TblOffreJsonldOrigineOffreRead}
     * @memberof OriginesOffresJsonldOrigineOffreRead
     */
    'offre'?: TblOffreJsonldOrigineOffreRead;
    /**
     * 
     * @type {TblOrigineJsonldOrigineOffreRead}
     * @memberof OriginesOffresJsonldOrigineOffreRead
     */
    'origine'?: TblOrigineJsonldOrigineOffreRead;
}
/**
 * 
 * @export
 * @interface OriginesOffresJsonldOrigineOffreUpdate
 */
export interface OriginesOffresJsonldOrigineOffreUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OriginesOffresJsonldOrigineOffreUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresJsonldOrigineOffreUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresJsonldOrigineOffreUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OriginesOffresJsonldOrigineOffreUpdate
     */
    'default'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresJsonldOrigineOffreUpdate
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresJsonldOrigineOffreUpdate
     */
    'offre'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresJsonldOrigineOffreUpdate
     */
    'origine'?: string;
}
/**
 * 
 * @export
 * @interface OriginesOffresOrigineOffreCreate
 */
export interface OriginesOffresOrigineOffreCreate {
    /**
     * 
     * @type {boolean}
     * @memberof OriginesOffresOrigineOffreCreate
     */
    'default'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresOrigineOffreCreate
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresOrigineOffreCreate
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresOrigineOffreCreate
     */
    'offre'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresOrigineOffreCreate
     */
    'origine'?: string;
}
/**
 * 
 * @export
 * @interface OriginesOffresOrigineOffreRead
 */
export interface OriginesOffresOrigineOffreRead {
    /**
     * 
     * @type {number}
     * @memberof OriginesOffresOrigineOffreRead
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OriginesOffresOrigineOffreRead
     */
    'default'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresOrigineOffreRead
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresOrigineOffreRead
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {TblOffreOrigineOffreRead}
     * @memberof OriginesOffresOrigineOffreRead
     */
    'offre'?: TblOffreOrigineOffreRead;
    /**
     * 
     * @type {TblOrigineOrigineOffreRead}
     * @memberof OriginesOffresOrigineOffreRead
     */
    'origine'?: TblOrigineOrigineOffreRead;
}
/**
 * 
 * @export
 * @interface OriginesOffresOrigineOffreUpdate
 */
export interface OriginesOffresOrigineOffreUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof OriginesOffresOrigineOffreUpdate
     */
    'default'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresOrigineOffreUpdate
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresOrigineOffreUpdate
     */
    'offre'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresOrigineOffreUpdate
     */
    'origine'?: string;
}
/**
 * 
 * @export
 * @interface OriginesOffresTiersJsonldOrigineOffreTiersCreate
 */
export interface OriginesOffresTiersJsonldOrigineOffreTiersCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersCreate
     */
    'default'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersCreate
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersCreate
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersCreate
     */
    'offreOrigine'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersCreate
     */
    'tiers'?: string;
}
/**
 * 
 * @export
 * @interface OriginesOffresTiersJsonldOrigineOffreTiersRead
 */
export interface OriginesOffresTiersJsonldOrigineOffreTiersRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersRead
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersRead
     */
    'default'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersRead
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersRead
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersRead
     */
    'offreOrigine'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersRead
     */
    'tiers'?: string;
}
/**
 * 
 * @export
 * @interface OriginesOffresTiersJsonldOrigineOffreTiersUpdate
 */
export interface OriginesOffresTiersJsonldOrigineOffreTiersUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersUpdate
     */
    'default'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersUpdate
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersUpdate
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersUpdate
     */
    'offreOrigine'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersJsonldOrigineOffreTiersUpdate
     */
    'tiers'?: string;
}
/**
 * 
 * @export
 * @interface OriginesOffresTiersOrigineOffreTiersCreate
 */
export interface OriginesOffresTiersOrigineOffreTiersCreate {
    /**
     * 
     * @type {boolean}
     * @memberof OriginesOffresTiersOrigineOffreTiersCreate
     */
    'default'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersOrigineOffreTiersCreate
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersOrigineOffreTiersCreate
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersOrigineOffreTiersCreate
     */
    'offreOrigine'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersOrigineOffreTiersCreate
     */
    'tiers'?: string;
}
/**
 * 
 * @export
 * @interface OriginesOffresTiersOrigineOffreTiersRead
 */
export interface OriginesOffresTiersOrigineOffreTiersRead {
    /**
     * 
     * @type {number}
     * @memberof OriginesOffresTiersOrigineOffreTiersRead
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OriginesOffresTiersOrigineOffreTiersRead
     */
    'default'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersOrigineOffreTiersRead
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersOrigineOffreTiersRead
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersOrigineOffreTiersRead
     */
    'offreOrigine'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersOrigineOffreTiersRead
     */
    'tiers'?: string;
}
/**
 * 
 * @export
 * @interface OriginesOffresTiersOrigineOffreTiersUpdate
 */
export interface OriginesOffresTiersOrigineOffreTiersUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof OriginesOffresTiersOrigineOffreTiersUpdate
     */
    'default'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersOrigineOffreTiersUpdate
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersOrigineOffreTiersUpdate
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersOrigineOffreTiersUpdate
     */
    'offreOrigine'?: string;
    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersOrigineOffreTiersUpdate
     */
    'tiers'?: string;
}
/**
 * 
 * @export
 * @interface OriginesOrigineRead
 */
export interface OriginesOrigineRead {
    /**
     * 
     * @type {number}
     * @memberof OriginesOrigineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OriginesOrigineRead
     */
    'origine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OriginesOrigineRead
     */
    'initiale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OriginesOrigineRead
     */
    'societe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OriginesOrigineRead
     */
    'baseUrl'?: string;
    /**
     * 
     * @type {Array<TblRelationDomaineOrigineOrigineRead>}
     * @memberof OriginesOrigineRead
     */
    'domaines'?: Array<TblRelationDomaineOrigineOrigineRead>;
    /**
     * 
     * @type {Array<TblRelOrigineOffreOrigineRead>}
     * @memberof OriginesOrigineRead
     */
    'offres'?: Array<TblRelOrigineOffreOrigineRead>;
}
/**
 * 
 * @export
 * @interface PackageJsonldPackageCreate
 */
export interface PackageJsonldPackageCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof PackageJsonldPackageCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof PackageJsonldPackageCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageJsonldPackageCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageJsonldPackageCreate
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageJsonldPackageCreate
     */
    'nomCommercial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageJsonldPackageCreate
     */
    'domaine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageJsonldPackageCreate
     */
    'formalite'?: string | null;
    /**
     * 
     * @type {Array<TblRelArticleJsonldPackageCreate>}
     * @memberof PackageJsonldPackageCreate
     */
    'articles'?: Array<TblRelArticleJsonldPackageCreate>;
    /**
     * 
     * @type {Array<TblRelChampCompJsonldPackageCreate>}
     * @memberof PackageJsonldPackageCreate
     */
    'champsComplementaires'?: Array<TblRelChampCompJsonldPackageCreate>;
    /**
     * 
     * @type {Array<TblRelActionJsonldPackageCreate>}
     * @memberof PackageJsonldPackageCreate
     */
    'actions'?: Array<TblRelActionJsonldPackageCreate>;
    /**
     * 
     * @type {Array<CatalogueCatalogueCreateDocumentsInner>}
     * @memberof PackageJsonldPackageCreate
     */
    'documents'?: Array<CatalogueCatalogueCreateDocumentsInner>;
}
/**
 * 
 * @export
 * @interface PackageJsonldPackageRead
 */
export interface PackageJsonldPackageRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof PackageJsonldPackageRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof PackageJsonldPackageRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageJsonldPackageRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof PackageJsonldPackageRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackageJsonldPackageRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PackageJsonldPackageRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PackageJsonldPackageRead
     */
    'nomCommercial'?: string | null;
    /**
     * 
     * @type {TblDefDomaineJsonldPackageRead}
     * @memberof PackageJsonldPackageRead
     */
    'domaine'?: TblDefDomaineJsonldPackageRead | null;
    /**
     * 
     * @type {FproFormaliteArticleJsonldPackageRead}
     * @memberof PackageJsonldPackageRead
     */
    'formalite'?: FproFormaliteArticleJsonldPackageRead | null;
    /**
     * 
     * @type {Array<TblRelArticleJsonldPackageRead>}
     * @memberof PackageJsonldPackageRead
     */
    'articles'?: Array<TblRelArticleJsonldPackageRead>;
    /**
     * 
     * @type {Array<TblRelChampCompJsonldPackageRead>}
     * @memberof PackageJsonldPackageRead
     */
    'champsComplementaires'?: Array<TblRelChampCompJsonldPackageRead>;
    /**
     * 
     * @type {Array<TblRelActionJsonldPackageRead>}
     * @memberof PackageJsonldPackageRead
     */
    'actions'?: Array<TblRelActionJsonldPackageRead>;
    /**
     * 
     * @type {Array<CatalogueCatalogueCreateDocumentsInner>}
     * @memberof PackageJsonldPackageRead
     */
    'documents'?: Array<CatalogueCatalogueCreateDocumentsInner>;
}
/**
 * 
 * @export
 * @interface PackageJsonldPackageUpdate
 */
export interface PackageJsonldPackageUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof PackageJsonldPackageUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof PackageJsonldPackageUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageJsonldPackageUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageJsonldPackageUpdate
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof PackageJsonldPackageUpdate
     */
    'nomCommercial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageJsonldPackageUpdate
     */
    'domaine': string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageJsonldPackageUpdate
     */
    'formalite'?: string | null;
    /**
     * 
     * @type {Array<TblRelArticleJsonldPackageUpdate>}
     * @memberof PackageJsonldPackageUpdate
     */
    'articles'?: Array<TblRelArticleJsonldPackageUpdate>;
    /**
     * 
     * @type {Array<TblRelChampCompJsonldPackageUpdate>}
     * @memberof PackageJsonldPackageUpdate
     */
    'champsComplementaires'?: Array<TblRelChampCompJsonldPackageUpdate>;
    /**
     * 
     * @type {Array<TblRelActionJsonldPackageUpdate>}
     * @memberof PackageJsonldPackageUpdate
     */
    'actions'?: Array<TblRelActionJsonldPackageUpdate>;
    /**
     * 
     * @type {Array<CatalogueCatalogueCreateDocumentsInner>}
     * @memberof PackageJsonldPackageUpdate
     */
    'documents'?: Array<CatalogueCatalogueCreateDocumentsInner>;
}
/**
 * 
 * @export
 * @interface PackagePackageCreate
 */
export interface PackagePackageCreate {
    /**
     * 
     * @type {string}
     * @memberof PackagePackageCreate
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackagePackageCreate
     */
    'nomCommercial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackagePackageCreate
     */
    'domaine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackagePackageCreate
     */
    'formalite'?: string | null;
    /**
     * 
     * @type {Array<TblRelArticlePackageCreate>}
     * @memberof PackagePackageCreate
     */
    'articles'?: Array<TblRelArticlePackageCreate>;
    /**
     * 
     * @type {Array<TblRelChampCompPackageCreate>}
     * @memberof PackagePackageCreate
     */
    'champsComplementaires'?: Array<TblRelChampCompPackageCreate>;
    /**
     * 
     * @type {Array<TblRelActionPackageCreate>}
     * @memberof PackagePackageCreate
     */
    'actions'?: Array<TblRelActionPackageCreate>;
    /**
     * 
     * @type {Array<CatalogueCatalogueCreateDocumentsInner>}
     * @memberof PackagePackageCreate
     */
    'documents'?: Array<CatalogueCatalogueCreateDocumentsInner>;
}
/**
 * 
 * @export
 * @interface PackagePackageRead
 */
export interface PackagePackageRead {
    /**
     * 
     * @type {number}
     * @memberof PackagePackageRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PackagePackageRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PackagePackageRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PackagePackageRead
     */
    'nomCommercial'?: string | null;
    /**
     * 
     * @type {TblDefDomainePackageRead}
     * @memberof PackagePackageRead
     */
    'domaine'?: TblDefDomainePackageRead | null;
    /**
     * 
     * @type {FproFormaliteArticlePackageRead}
     * @memberof PackagePackageRead
     */
    'formalite'?: FproFormaliteArticlePackageRead | null;
    /**
     * 
     * @type {Array<TblRelArticlePackageRead>}
     * @memberof PackagePackageRead
     */
    'articles'?: Array<TblRelArticlePackageRead>;
    /**
     * 
     * @type {Array<TblRelChampCompPackageRead>}
     * @memberof PackagePackageRead
     */
    'champsComplementaires'?: Array<TblRelChampCompPackageRead>;
    /**
     * 
     * @type {Array<TblRelActionPackageRead>}
     * @memberof PackagePackageRead
     */
    'actions'?: Array<TblRelActionPackageRead>;
    /**
     * 
     * @type {Array<CatalogueCatalogueCreateDocumentsInner>}
     * @memberof PackagePackageRead
     */
    'documents'?: Array<CatalogueCatalogueCreateDocumentsInner>;
}
/**
 * 
 * @export
 * @interface PackagePackageUpdate
 */
export interface PackagePackageUpdate {
    /**
     * 
     * @type {string}
     * @memberof PackagePackageUpdate
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof PackagePackageUpdate
     */
    'nomCommercial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackagePackageUpdate
     */
    'domaine': string | null;
    /**
     * 
     * @type {string}
     * @memberof PackagePackageUpdate
     */
    'formalite'?: string | null;
    /**
     * 
     * @type {Array<TblRelArticlePackageUpdate>}
     * @memberof PackagePackageUpdate
     */
    'articles'?: Array<TblRelArticlePackageUpdate>;
    /**
     * 
     * @type {Array<TblRelChampCompPackageUpdate>}
     * @memberof PackagePackageUpdate
     */
    'champsComplementaires'?: Array<TblRelChampCompPackageUpdate>;
    /**
     * 
     * @type {Array<TblRelActionPackageUpdate>}
     * @memberof PackagePackageUpdate
     */
    'actions'?: Array<TblRelActionPackageUpdate>;
    /**
     * 
     * @type {Array<CatalogueCatalogueCreateDocumentsInner>}
     * @memberof PackagePackageUpdate
     */
    'documents'?: Array<CatalogueCatalogueCreateDocumentsInner>;
}
/**
 * 
 * @export
 * @interface PackageResourceDependencyDependencyRead
 */
export interface PackageResourceDependencyDependencyRead {
    /**
     * 
     * @type {Array<string>}
     * @memberof PackageResourceDependencyDependencyRead
     */
    'dependencies'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PackageResourceDependencyJsonldDependencyRead
 */
export interface PackageResourceDependencyJsonldDependencyRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof PackageResourceDependencyJsonldDependencyRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof PackageResourceDependencyJsonldDependencyRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageResourceDependencyJsonldDependencyRead
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PackageResourceDependencyJsonldDependencyRead
     */
    'dependencies'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RubriqueFacturationJsonldRubriqueFacturationRead
 */
export interface RubriqueFacturationJsonldRubriqueFacturationRead {
    /**
     * 
     * @type {string}
     * @memberof RubriqueFacturationJsonldRubriqueFacturationRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RubriqueFacturationJsonldRubriqueFacturationRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof RubriqueFacturationJsonldRubriqueFacturationRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof RubriqueFacturationJsonldRubriqueFacturationRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RubriqueFacturationJsonldRubriqueFacturationRead
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RubriqueFacturationJsonldRubriqueFacturationRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Array<TblRubFacDomaineVentesJsonldRubriqueFacturationRead>}
     * @memberof RubriqueFacturationJsonldRubriqueFacturationRead
     */
    'domainesVentes'?: Array<TblRubFacDomaineVentesJsonldRubriqueFacturationRead>;
}
/**
 * 
 * @export
 * @interface RubriqueFacturationRubriqueFacturationRead
 */
export interface RubriqueFacturationRubriqueFacturationRead {
    /**
     * 
     * @type {number}
     * @memberof RubriqueFacturationRubriqueFacturationRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RubriqueFacturationRubriqueFacturationRead
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RubriqueFacturationRubriqueFacturationRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Array<TblRubFacDomaineVentesRubriqueFacturationRead>}
     * @memberof RubriqueFacturationRubriqueFacturationRead
     */
    'domainesVentes'?: Array<TblRubFacDomaineVentesRubriqueFacturationRead>;
}
/**
 * 
 * @export
 * @interface TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate
 */
export interface TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate
     */
    'prescripteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate
     */
    'catalogue'?: string | null;
}
/**
 * 
 * @export
 * @interface TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead
 */
export interface TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead
     */
    'prescripteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead
     */
    'catalogue'?: string | null;
}
/**
 * 
 * @export
 * @interface TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate
 */
export interface TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate
     */
    'tarif'?: string | null;
}
/**
 * 
 * @export
 * @interface TarifCataloguePrescripteurTarifCataloguePrescripteurCreate
 */
export interface TarifCataloguePrescripteurTarifCataloguePrescripteurCreate {
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurTarifCataloguePrescripteurCreate
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurTarifCataloguePrescripteurCreate
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurTarifCataloguePrescripteurCreate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurTarifCataloguePrescripteurCreate
     */
    'prescripteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurTarifCataloguePrescripteurCreate
     */
    'catalogue'?: string | null;
}
/**
 * 
 * @export
 * @interface TarifCataloguePrescripteurTarifCataloguePrescripteurRead
 */
export interface TarifCataloguePrescripteurTarifCataloguePrescripteurRead {
    /**
     * 
     * @type {number}
     * @memberof TarifCataloguePrescripteurTarifCataloguePrescripteurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurTarifCataloguePrescripteurRead
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurTarifCataloguePrescripteurRead
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurTarifCataloguePrescripteurRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurTarifCataloguePrescripteurRead
     */
    'prescripteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurTarifCataloguePrescripteurRead
     */
    'catalogue'?: string | null;
}
/**
 * 
 * @export
 * @interface TarifCataloguePrescripteurTarifCataloguePrescripteurUpdate
 */
export interface TarifCataloguePrescripteurTarifCataloguePrescripteurUpdate {
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurTarifCataloguePrescripteurUpdate
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TarifCataloguePrescripteurTarifCataloguePrescripteurUpdate
     */
    'tarif'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailActionDetailRead
 */
export interface TblActionDetailActionDetailRead {
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailActionDetailRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailActionDetailRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailJsonldActionDetailRead
 */
export interface TblActionDetailJsonldActionDetailRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblActionDetailJsonldActionDetailRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActionDetailRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActionDetailRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailJsonldActionDetailRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActionDetailRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailJsonldOptionRead
 */
export interface TblActionDetailJsonldOptionRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblActionDetailJsonldOptionRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldOptionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldOptionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailJsonldOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldOptionRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailJsonldOptionValueCreate
 */
export interface TblActionDetailJsonldOptionValueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblActionDetailJsonldOptionValueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldOptionValueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldOptionValueCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailJsonldOptionValueCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldOptionValueCreate
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailJsonldOptionValueRead
 */
export interface TblActionDetailJsonldOptionValueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblActionDetailJsonldOptionValueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldOptionValueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldOptionValueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailJsonldOptionValueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldOptionValueRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailJsonldPackageCreate
 */
export interface TblActionDetailJsonldPackageCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblActionDetailJsonldPackageCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldPackageCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldPackageCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailJsonldPackageCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldPackageCreate
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailJsonldPackageRead
 */
export interface TblActionDetailJsonldPackageRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblActionDetailJsonldPackageRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldPackageRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldPackageRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailJsonldPackageRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldPackageRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailOptionRead
 */
export interface TblActionDetailOptionRead {
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailOptionRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailOptionValueCreate
 */
export interface TblActionDetailOptionValueCreate {
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailOptionValueCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailOptionValueCreate
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailOptionValueRead
 */
export interface TblActionDetailOptionValueRead {
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailOptionValueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailOptionValueRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailPackageCreate
 */
export interface TblActionDetailPackageCreate {
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailPackageCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailPackageCreate
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailPackageRead
 */
export interface TblActionDetailPackageRead {
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailPackageRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailPackageRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeJsonldCatalogueCreate
 */
export interface TblAnnonceSTypeJsonldCatalogueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblAnnonceSTypeJsonldCatalogueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldCatalogueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldCatalogueCreate
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeJsonldCatalogueRead
 */
export interface TblAnnonceSTypeJsonldCatalogueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblAnnonceSTypeJsonldCatalogueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldCatalogueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldCatalogueRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeJsonldOptionCreate
 */
export interface TblAnnonceSTypeJsonldOptionCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblAnnonceSTypeJsonldOptionCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldOptionCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldOptionCreate
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeJsonldOptionRead
 */
export interface TblAnnonceSTypeJsonldOptionRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblAnnonceSTypeJsonldOptionRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldOptionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldOptionRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeJsonldOptionUpdate
 */
export interface TblAnnonceSTypeJsonldOptionUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblAnnonceSTypeJsonldOptionUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldOptionUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldOptionUpdate
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeJsonldOptionValueCreate
 */
export interface TblAnnonceSTypeJsonldOptionValueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblAnnonceSTypeJsonldOptionValueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldOptionValueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldOptionValueCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblAnnonceSTypeJsonldOptionValueCreate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeJsonldOptionValueRead
 */
export interface TblAnnonceSTypeJsonldOptionValueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblAnnonceSTypeJsonldOptionValueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldOptionValueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldOptionValueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblAnnonceSTypeJsonldOptionValueRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeJsonldOptionValueUpdate
 */
export interface TblAnnonceSTypeJsonldOptionValueUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblAnnonceSTypeJsonldOptionValueUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldOptionValueUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldOptionValueUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblAnnonceSTypeJsonldOptionValueUpdate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeJsonldPackageCreate
 */
export interface TblAnnonceSTypeJsonldPackageCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblAnnonceSTypeJsonldPackageCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldPackageCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldPackageCreate
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeJsonldPackagePrescripteurRead
 */
export interface TblAnnonceSTypeJsonldPackagePrescripteurRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblAnnonceSTypeJsonldPackagePrescripteurRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldPackagePrescripteurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldPackagePrescripteurRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeJsonldPackageRead
 */
export interface TblAnnonceSTypeJsonldPackageRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblAnnonceSTypeJsonldPackageRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldPackageRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldPackageRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblAnnonceSTypeJsonldPackageRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeJsonldPackageUpdate
 */
export interface TblAnnonceSTypeJsonldPackageUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblAnnonceSTypeJsonldPackageUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldPackageUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldPackageUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblAnnonceSTypeJsonldPackageUpdate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeOptionValueCreate
 */
export interface TblAnnonceSTypeOptionValueCreate {
    /**
     * 
     * @type {number}
     * @memberof TblAnnonceSTypeOptionValueCreate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeOptionValueRead
 */
export interface TblAnnonceSTypeOptionValueRead {
    /**
     * 
     * @type {number}
     * @memberof TblAnnonceSTypeOptionValueRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeOptionValueUpdate
 */
export interface TblAnnonceSTypeOptionValueUpdate {
    /**
     * 
     * @type {number}
     * @memberof TblAnnonceSTypeOptionValueUpdate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypePackageRead
 */
export interface TblAnnonceSTypePackageRead {
    /**
     * 
     * @type {number}
     * @memberof TblAnnonceSTypePackageRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypePackageUpdate
 */
export interface TblAnnonceSTypePackageUpdate {
    /**
     * 
     * @type {number}
     * @memberof TblAnnonceSTypePackageUpdate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblCatalogueGroupementRead
 */
export interface TblCatalogueGroupementRead {
    /**
     * 
     * @type {number}
     * @memberof TblCatalogueGroupementRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblCatalogueGroupementRead
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCatalogueGroupementRead
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCatalogueGroupementRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCatalogueGroupementRead
     */
    'nomCommercial'?: string;
}
/**
 * 
 * @export
 * @interface TblCatalogueJsonldGroupementRead
 */
export interface TblCatalogueJsonldGroupementRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblCatalogueJsonldGroupementRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblCatalogueJsonldGroupementRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCatalogueJsonldGroupementRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblCatalogueJsonldGroupementRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblCatalogueJsonldGroupementRead
     */
    'dateDebut'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCatalogueJsonldGroupementRead
     */
    'dateFin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCatalogueJsonldGroupementRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCatalogueJsonldGroupementRead
     */
    'nomCommercial'?: string;
}
/**
 * 
 * @export
 * @interface TblChampCompCatalogueRead
 */
export interface TblChampCompCatalogueRead {
    /**
     * 
     * @type {number}
     * @memberof TblChampCompCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompCatalogueRead
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof TblChampCompCatalogueRead
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompCatalogueRead
     */
    'htmlFieldType': TblChampCompCatalogueReadHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof TblChampCompCatalogueRead
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof TblChampCompCatalogueRead
     */
    'mappingRcs'?: string | null;
}

export const TblChampCompCatalogueReadHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type TblChampCompCatalogueReadHtmlFieldTypeEnum = typeof TblChampCompCatalogueReadHtmlFieldTypeEnum[keyof typeof TblChampCompCatalogueReadHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface TblChampCompJsonldCatalogueRead
 */
export interface TblChampCompJsonldCatalogueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblChampCompJsonldCatalogueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldCatalogueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldCatalogueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblChampCompJsonldCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldCatalogueRead
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof TblChampCompJsonldCatalogueRead
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldCatalogueRead
     */
    'htmlFieldType': TblChampCompJsonldCatalogueReadHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof TblChampCompJsonldCatalogueRead
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof TblChampCompJsonldCatalogueRead
     */
    'mappingRcs'?: string | null;
}

export const TblChampCompJsonldCatalogueReadHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type TblChampCompJsonldCatalogueReadHtmlFieldTypeEnum = typeof TblChampCompJsonldCatalogueReadHtmlFieldTypeEnum[keyof typeof TblChampCompJsonldCatalogueReadHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface TblChampCompJsonldOptionRead
 */
export interface TblChampCompJsonldOptionRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblChampCompJsonldOptionRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldOptionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldOptionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblChampCompJsonldOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldOptionRead
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof TblChampCompJsonldOptionRead
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldOptionRead
     */
    'htmlFieldType': TblChampCompJsonldOptionReadHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof TblChampCompJsonldOptionRead
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof TblChampCompJsonldOptionRead
     */
    'mappingRcs'?: string | null;
}

export const TblChampCompJsonldOptionReadHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type TblChampCompJsonldOptionReadHtmlFieldTypeEnum = typeof TblChampCompJsonldOptionReadHtmlFieldTypeEnum[keyof typeof TblChampCompJsonldOptionReadHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface TblChampCompJsonldOptionValueCreate
 */
export interface TblChampCompJsonldOptionValueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblChampCompJsonldOptionValueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldOptionValueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldOptionValueCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblChampCompJsonldOptionValueCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldOptionValueCreate
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof TblChampCompJsonldOptionValueCreate
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldOptionValueCreate
     */
    'htmlFieldType': TblChampCompJsonldOptionValueCreateHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof TblChampCompJsonldOptionValueCreate
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof TblChampCompJsonldOptionValueCreate
     */
    'mappingRcs'?: string | null;
}

export const TblChampCompJsonldOptionValueCreateHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type TblChampCompJsonldOptionValueCreateHtmlFieldTypeEnum = typeof TblChampCompJsonldOptionValueCreateHtmlFieldTypeEnum[keyof typeof TblChampCompJsonldOptionValueCreateHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface TblChampCompJsonldOptionValueRead
 */
export interface TblChampCompJsonldOptionValueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblChampCompJsonldOptionValueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldOptionValueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldOptionValueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblChampCompJsonldOptionValueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldOptionValueRead
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof TblChampCompJsonldOptionValueRead
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldOptionValueRead
     */
    'htmlFieldType': TblChampCompJsonldOptionValueReadHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof TblChampCompJsonldOptionValueRead
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof TblChampCompJsonldOptionValueRead
     */
    'mappingRcs'?: string | null;
}

export const TblChampCompJsonldOptionValueReadHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type TblChampCompJsonldOptionValueReadHtmlFieldTypeEnum = typeof TblChampCompJsonldOptionValueReadHtmlFieldTypeEnum[keyof typeof TblChampCompJsonldOptionValueReadHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface TblChampCompJsonldPackageCreate
 */
export interface TblChampCompJsonldPackageCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblChampCompJsonldPackageCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldPackageCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldPackageCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblChampCompJsonldPackageCreate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblChampCompJsonldPackagePrescripteurRead
 */
export interface TblChampCompJsonldPackagePrescripteurRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblChampCompJsonldPackagePrescripteurRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldPackagePrescripteurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldPackagePrescripteurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblChampCompJsonldPackagePrescripteurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldPackagePrescripteurRead
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof TblChampCompJsonldPackagePrescripteurRead
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldPackagePrescripteurRead
     */
    'htmlFieldType': TblChampCompJsonldPackagePrescripteurReadHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof TblChampCompJsonldPackagePrescripteurRead
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof TblChampCompJsonldPackagePrescripteurRead
     */
    'mappingRcs'?: string | null;
}

export const TblChampCompJsonldPackagePrescripteurReadHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type TblChampCompJsonldPackagePrescripteurReadHtmlFieldTypeEnum = typeof TblChampCompJsonldPackagePrescripteurReadHtmlFieldTypeEnum[keyof typeof TblChampCompJsonldPackagePrescripteurReadHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface TblChampCompJsonldPackageRead
 */
export interface TblChampCompJsonldPackageRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblChampCompJsonldPackageRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldPackageRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldPackageRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblChampCompJsonldPackageRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldPackageRead
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof TblChampCompJsonldPackageRead
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldPackageRead
     */
    'htmlFieldType': TblChampCompJsonldPackageReadHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof TblChampCompJsonldPackageRead
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof TblChampCompJsonldPackageRead
     */
    'mappingRcs'?: string | null;
}

export const TblChampCompJsonldPackageReadHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type TblChampCompJsonldPackageReadHtmlFieldTypeEnum = typeof TblChampCompJsonldPackageReadHtmlFieldTypeEnum[keyof typeof TblChampCompJsonldPackageReadHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface TblChampCompJsonldPackageUpdate
 */
export interface TblChampCompJsonldPackageUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblChampCompJsonldPackageUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldPackageUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompJsonldPackageUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblChampCompJsonldPackageUpdate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblChampCompOptionRead
 */
export interface TblChampCompOptionRead {
    /**
     * 
     * @type {number}
     * @memberof TblChampCompOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompOptionRead
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof TblChampCompOptionRead
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompOptionRead
     */
    'htmlFieldType': TblChampCompOptionReadHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof TblChampCompOptionRead
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof TblChampCompOptionRead
     */
    'mappingRcs'?: string | null;
}

export const TblChampCompOptionReadHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type TblChampCompOptionReadHtmlFieldTypeEnum = typeof TblChampCompOptionReadHtmlFieldTypeEnum[keyof typeof TblChampCompOptionReadHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface TblChampCompOptionValueCreate
 */
export interface TblChampCompOptionValueCreate {
    /**
     * 
     * @type {number}
     * @memberof TblChampCompOptionValueCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompOptionValueCreate
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof TblChampCompOptionValueCreate
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompOptionValueCreate
     */
    'htmlFieldType': TblChampCompOptionValueCreateHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof TblChampCompOptionValueCreate
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof TblChampCompOptionValueCreate
     */
    'mappingRcs'?: string | null;
}

export const TblChampCompOptionValueCreateHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type TblChampCompOptionValueCreateHtmlFieldTypeEnum = typeof TblChampCompOptionValueCreateHtmlFieldTypeEnum[keyof typeof TblChampCompOptionValueCreateHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface TblChampCompOptionValueRead
 */
export interface TblChampCompOptionValueRead {
    /**
     * 
     * @type {number}
     * @memberof TblChampCompOptionValueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompOptionValueRead
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof TblChampCompOptionValueRead
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompOptionValueRead
     */
    'htmlFieldType': TblChampCompOptionValueReadHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof TblChampCompOptionValueRead
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof TblChampCompOptionValueRead
     */
    'mappingRcs'?: string | null;
}

export const TblChampCompOptionValueReadHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type TblChampCompOptionValueReadHtmlFieldTypeEnum = typeof TblChampCompOptionValueReadHtmlFieldTypeEnum[keyof typeof TblChampCompOptionValueReadHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface TblChampCompPackageCreate
 */
export interface TblChampCompPackageCreate {
    /**
     * 
     * @type {number}
     * @memberof TblChampCompPackageCreate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblChampCompPackagePrescripteurRead
 */
export interface TblChampCompPackagePrescripteurRead {
    /**
     * 
     * @type {number}
     * @memberof TblChampCompPackagePrescripteurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompPackagePrescripteurRead
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof TblChampCompPackagePrescripteurRead
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompPackagePrescripteurRead
     */
    'htmlFieldType': TblChampCompPackagePrescripteurReadHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof TblChampCompPackagePrescripteurRead
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof TblChampCompPackagePrescripteurRead
     */
    'mappingRcs'?: string | null;
}

export const TblChampCompPackagePrescripteurReadHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type TblChampCompPackagePrescripteurReadHtmlFieldTypeEnum = typeof TblChampCompPackagePrescripteurReadHtmlFieldTypeEnum[keyof typeof TblChampCompPackagePrescripteurReadHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface TblChampCompPackageRead
 */
export interface TblChampCompPackageRead {
    /**
     * 
     * @type {number}
     * @memberof TblChampCompPackageRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompPackageRead
     */
    'libelle': string;
    /**
     * Tooltip pour le front
     * @type {string}
     * @memberof TblChampCompPackageRead
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TblChampCompPackageRead
     */
    'htmlFieldType': TblChampCompPackageReadHtmlFieldTypeEnum;
    /**
     * 
     * @type {ChampComplMentaireChampcomplementaireCreateConfiguration}
     * @memberof TblChampCompPackageRead
     */
    'configuration'?: ChampComplMentaireChampcomplementaireCreateConfiguration | null;
    /**
     * Nom du ou des champs associés du côté du RCS
     * @type {string}
     * @memberof TblChampCompPackageRead
     */
    'mappingRcs'?: string | null;
}

export const TblChampCompPackageReadHtmlFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea',
    Number: 'number',
    Date: 'date',
    Email: 'email'
} as const;

export type TblChampCompPackageReadHtmlFieldTypeEnum = typeof TblChampCompPackageReadHtmlFieldTypeEnum[keyof typeof TblChampCompPackageReadHtmlFieldTypeEnum];

/**
 * 
 * @export
 * @interface TblChampCompPackageUpdate
 */
export interface TblChampCompPackageUpdate {
    /**
     * 
     * @type {number}
     * @memberof TblChampCompPackageUpdate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblDefDomaineArticleRead
 */
export interface TblDefDomaineArticleRead {
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineArticleRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblDefDomaineCatalogueRead
 */
export interface TblDefDomaineCatalogueRead {
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineCatalogueRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDefDomaineGroupementRead
 */
export interface TblDefDomaineGroupementRead {
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineGroupementRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineGroupementRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDefDomaineJsonldArticleRead
 */
export interface TblDefDomaineJsonldArticleRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblDefDomaineJsonldArticleRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldArticleRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldArticleRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineJsonldArticleRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblDefDomaineJsonldCatalogueRead
 */
export interface TblDefDomaineJsonldCatalogueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblDefDomaineJsonldCatalogueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldCatalogueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldCatalogueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineJsonldCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldCatalogueRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDefDomaineJsonldGroupementRead
 */
export interface TblDefDomaineJsonldGroupementRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblDefDomaineJsonldGroupementRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldGroupementRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldGroupementRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineJsonldGroupementRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldGroupementRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDefDomaineJsonldOffreRead
 */
export interface TblDefDomaineJsonldOffreRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblDefDomaineJsonldOffreRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldOffreRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldOffreRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineJsonldOffreRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldOffreRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDefDomaineJsonldOptionRead
 */
export interface TblDefDomaineJsonldOptionRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblDefDomaineJsonldOptionRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldOptionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldOptionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineJsonldOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldOptionRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDefDomaineJsonldOrigineRead
 */
export interface TblDefDomaineJsonldOrigineRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblDefDomaineJsonldOrigineRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldOrigineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldOrigineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineJsonldOrigineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldOrigineRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldOrigineRead
     */
    'info'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldOrigineRead
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDefDomaineJsonldPackageRead
 */
export interface TblDefDomaineJsonldPackageRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblDefDomaineJsonldPackageRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldPackageRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldPackageRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineJsonldPackageRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldPackageRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDefDomaineJsonldRubriqueFacturationRead
 */
export interface TblDefDomaineJsonldRubriqueFacturationRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblDefDomaineJsonldRubriqueFacturationRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldRubriqueFacturationRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldRubriqueFacturationRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineJsonldRubriqueFacturationRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblDefDomaineJsonldTypedocumentRead
 */
export interface TblDefDomaineJsonldTypedocumentRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblDefDomaineJsonldTypedocumentRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldTypedocumentRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldTypedocumentRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldTypedocumentRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDefDomaineOffreRead
 */
export interface TblDefDomaineOffreRead {
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineOffreRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineOffreRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDefDomaineOptionRead
 */
export interface TblDefDomaineOptionRead {
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineOptionRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDefDomaineOrigineRead
 */
export interface TblDefDomaineOrigineRead {
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineOrigineRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineOrigineRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineOrigineRead
     */
    'info'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineOrigineRead
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDefDomainePackageRead
 */
export interface TblDefDomainePackageRead {
    /**
     * 
     * @type {number}
     * @memberof TblDefDomainePackageRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomainePackageRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDefDomaineRubriqueFacturationRead
 */
export interface TblDefDomaineRubriqueFacturationRead {
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineRubriqueFacturationRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblDefDomaineTypedocumentRead
 */
export interface TblDefDomaineTypedocumentRead {
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineTypedocumentRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactArticlesCatalogueRead
 */
export interface TblFactArticlesCatalogueRead {
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesCatalogueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesCatalogueRead
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {Array<TblRelTypeDocumentCatalogueRead>}
     * @memberof TblFactArticlesCatalogueRead
     */
    'documents'?: Array<TblRelTypeDocumentCatalogueRead>;
}
/**
 * 
 * @export
 * @interface TblFactArticlesJsonldCatalogueRead
 */
export interface TblFactArticlesJsonldCatalogueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblFactArticlesJsonldCatalogueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldCatalogueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldCatalogueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldCatalogueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldCatalogueRead
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {Array<TblRelTypeDocumentJsonldCatalogueRead>}
     * @memberof TblFactArticlesJsonldCatalogueRead
     */
    'documents'?: Array<TblRelTypeDocumentJsonldCatalogueRead>;
}
/**
 * 
 * @export
 * @interface TblFactArticlesJsonldOptionCreate
 */
export interface TblFactArticlesJsonldOptionCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblFactArticlesJsonldOptionCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesJsonldOptionCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionCreate
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionCreate
     */
    'rubArticle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactArticlesJsonldOptionRead
 */
export interface TblFactArticlesJsonldOptionRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblFactArticlesJsonldOptionRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesJsonldOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionRead
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionRead
     */
    'rubArticle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactArticlesJsonldOptionUpdate
 */
export interface TblFactArticlesJsonldOptionUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblFactArticlesJsonldOptionUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionUpdate
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionUpdate
     */
    'rubArticle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactArticlesJsonldOptionValueCreate
 */
export interface TblFactArticlesJsonldOptionValueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblFactArticlesJsonldOptionValueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionValueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionValueCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesJsonldOptionValueCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionValueCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionValueCreate
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionValueCreate
     */
    'rubArticle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactArticlesJsonldOptionValueRead
 */
export interface TblFactArticlesJsonldOptionValueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblFactArticlesJsonldOptionValueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionValueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionValueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesJsonldOptionValueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionValueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionValueRead
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionValueRead
     */
    'rubArticle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactArticlesJsonldOptionValueUpdate
 */
export interface TblFactArticlesJsonldOptionValueUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblFactArticlesJsonldOptionValueUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionValueUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionValueUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesJsonldOptionValueUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionValueUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionValueUpdate
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldOptionValueUpdate
     */
    'rubArticle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactArticlesJsonldPackageCreate
 */
export interface TblFactArticlesJsonldPackageCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblFactArticlesJsonldPackageCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPackageCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPackageCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesJsonldPackageCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPackageCreate
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactArticlesJsonldPackagePrescripteurRead
 */
export interface TblFactArticlesJsonldPackagePrescripteurRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblFactArticlesJsonldPackagePrescripteurRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPackagePrescripteurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPackagePrescripteurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesJsonldPackagePrescripteurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPackagePrescripteurRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPackagePrescripteurRead
     */
    'codeArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPackagePrescripteurRead
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPackagePrescripteurRead
     */
    'unite'?: string | null;
    /**
     * 
     * @type {Array<ArticlesArticleReadChampsComplementairesInner>}
     * @memberof TblFactArticlesJsonldPackagePrescripteurRead
     */
    'champsComplementaires'?: Array<ArticlesArticleReadChampsComplementairesInner>;
}
/**
 * 
 * @export
 * @interface TblFactArticlesJsonldPackageRead
 */
export interface TblFactArticlesJsonldPackageRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblFactArticlesJsonldPackageRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPackageRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPackageRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesJsonldPackageRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPackageRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPackageRead
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPackageRead
     */
    'rubArticle'?: string | null;
    /**
     * 
     * @type {Array<TblRelTypeDocumentJsonldPackageRead>}
     * @memberof TblFactArticlesJsonldPackageRead
     */
    'documents'?: Array<TblRelTypeDocumentJsonldPackageRead>;
}
/**
 * 
 * @export
 * @interface TblFactArticlesJsonldPackageUpdate
 */
export interface TblFactArticlesJsonldPackageUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblFactArticlesJsonldPackageUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPackageUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPackageUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesJsonldPackageUpdate
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactArticlesOptionCreate
 */
export interface TblFactArticlesOptionCreate {
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesOptionCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionCreate
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionCreate
     */
    'rubArticle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactArticlesOptionRead
 */
export interface TblFactArticlesOptionRead {
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionRead
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionRead
     */
    'rubArticle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactArticlesOptionUpdate
 */
export interface TblFactArticlesOptionUpdate {
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionUpdate
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionUpdate
     */
    'rubArticle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactArticlesOptionValueCreate
 */
export interface TblFactArticlesOptionValueCreate {
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesOptionValueCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionValueCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionValueCreate
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionValueCreate
     */
    'rubArticle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactArticlesOptionValueRead
 */
export interface TblFactArticlesOptionValueRead {
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesOptionValueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionValueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionValueRead
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionValueRead
     */
    'rubArticle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactArticlesOptionValueUpdate
 */
export interface TblFactArticlesOptionValueUpdate {
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesOptionValueUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionValueUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionValueUpdate
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesOptionValueUpdate
     */
    'rubArticle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactArticlesPackageCreate
 */
export interface TblFactArticlesPackageCreate {
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesPackageCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesPackageCreate
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactArticlesPackagePrescripteurRead
 */
export interface TblFactArticlesPackagePrescripteurRead {
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesPackagePrescripteurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesPackagePrescripteurRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesPackagePrescripteurRead
     */
    'codeArticle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesPackagePrescripteurRead
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesPackagePrescripteurRead
     */
    'unite'?: string | null;
    /**
     * 
     * @type {Array<ArticlesArticleReadChampsComplementairesInner>}
     * @memberof TblFactArticlesPackagePrescripteurRead
     */
    'champsComplementaires'?: Array<ArticlesArticleReadChampsComplementairesInner>;
}
/**
 * 
 * @export
 * @interface TblFactArticlesPackageRead
 */
export interface TblFactArticlesPackageRead {
    /**
     * 
     * @type {number}
     * @memberof TblFactArticlesPackageRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesPackageRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesPackageRead
     */
    'prixUnitaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesPackageRead
     */
    'rubArticle'?: string | null;
    /**
     * 
     * @type {Array<TblRelTypeDocumentPackageRead>}
     * @memberof TblFactArticlesPackageRead
     */
    'documents'?: Array<TblRelTypeDocumentPackageRead>;
}
/**
 * 
 * @export
 * @interface TblFactArticlesPackageUpdate
 */
export interface TblFactArticlesPackageUpdate {
    /**
     * 
     * @type {string}
     * @memberof TblFactArticlesPackageUpdate
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblGroupementCatalogueRead
 */
export interface TblGroupementCatalogueRead {
    /**
     * 
     * @type {number}
     * @memberof TblGroupementCatalogueRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TblGroupementCatalogueRead
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof TblGroupementCatalogueRead
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblGroupementCatalogueRead
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface TblGroupementJsonldCatalogueRead
 */
export interface TblGroupementJsonldCatalogueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblGroupementJsonldCatalogueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblGroupementJsonldCatalogueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblGroupementJsonldCatalogueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblGroupementJsonldCatalogueRead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TblGroupementJsonldCatalogueRead
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof TblGroupementJsonldCatalogueRead
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblGroupementJsonldCatalogueRead
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface TblOffreCatalogueRead
 */
export interface TblOffreCatalogueRead {
    /**
     * 
     * @type {number}
     * @memberof TblOffreCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblOffreCatalogueRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOffreCatalogueRead
     */
    'visibilite': TblOffreCatalogueReadVisibiliteEnum;
    /**
     * 
     * @type {string}
     * @memberof TblOffreCatalogueRead
     */
    'iconeName'?: string | null;
}

export const TblOffreCatalogueReadVisibiliteEnum = {
    Full: 'FULL',
    Partial: 'PARTIAL'
} as const;

export type TblOffreCatalogueReadVisibiliteEnum = typeof TblOffreCatalogueReadVisibiliteEnum[keyof typeof TblOffreCatalogueReadVisibiliteEnum];

/**
 * 
 * @export
 * @interface TblOffreJsonldCatalogueRead
 */
export interface TblOffreJsonldCatalogueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblOffreJsonldCatalogueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblOffreJsonldCatalogueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOffreJsonldCatalogueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblOffreJsonldCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblOffreJsonldCatalogueRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOffreJsonldCatalogueRead
     */
    'visibilite': TblOffreJsonldCatalogueReadVisibiliteEnum;
    /**
     * 
     * @type {string}
     * @memberof TblOffreJsonldCatalogueRead
     */
    'iconeName'?: string | null;
}

export const TblOffreJsonldCatalogueReadVisibiliteEnum = {
    Full: 'FULL',
    Partial: 'PARTIAL'
} as const;

export type TblOffreJsonldCatalogueReadVisibiliteEnum = typeof TblOffreJsonldCatalogueReadVisibiliteEnum[keyof typeof TblOffreJsonldCatalogueReadVisibiliteEnum];

/**
 * 
 * @export
 * @interface TblOffreJsonldOrigineOffreRead
 */
export interface TblOffreJsonldOrigineOffreRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblOffreJsonldOrigineOffreRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblOffreJsonldOrigineOffreRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOffreJsonldOrigineOffreRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblOffreJsonldOrigineOffreRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblOffreJsonldOrigineRead
 */
export interface TblOffreJsonldOrigineRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblOffreJsonldOrigineRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblOffreJsonldOrigineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOffreJsonldOrigineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblOffreJsonldOrigineRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblOffreJsonldPackagePrescripteurRead
 */
export interface TblOffreJsonldPackagePrescripteurRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblOffreJsonldPackagePrescripteurRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblOffreJsonldPackagePrescripteurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOffreJsonldPackagePrescripteurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblOffreJsonldPackagePrescripteurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblOffreJsonldPackagePrescripteurRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOffreJsonldPackagePrescripteurRead
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface TblOffreOrigineOffreRead
 */
export interface TblOffreOrigineOffreRead {
    /**
     * 
     * @type {number}
     * @memberof TblOffreOrigineOffreRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblOffreOrigineRead
 */
export interface TblOffreOrigineRead {
    /**
     * 
     * @type {number}
     * @memberof TblOffreOrigineRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblOffrePackagePrescripteurRead
 */
export interface TblOffrePackagePrescripteurRead {
    /**
     * 
     * @type {number}
     * @memberof TblOffrePackagePrescripteurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblOffrePackagePrescripteurRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOffrePackagePrescripteurRead
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface TblOptionCatalogueRead
 */
export interface TblOptionCatalogueRead {
    /**
     * 
     * @type {number}
     * @memberof TblOptionCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblOptionCatalogueRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOptionCatalogueRead
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface TblOptionJsonldCatalogueRead
 */
export interface TblOptionJsonldCatalogueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblOptionJsonldCatalogueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblOptionJsonldCatalogueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOptionJsonldCatalogueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblOptionJsonldCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblOptionJsonldCatalogueRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOptionJsonldCatalogueRead
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface TblOptionJsonldOptionRead
 */
export interface TblOptionJsonldOptionRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblOptionJsonldOptionRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblOptionJsonldOptionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOptionJsonldOptionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblOptionJsonldOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblOptionJsonldOptionRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOptionJsonldOptionRead
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblOptionJsonldOptionRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblOptionJsonldOptionRead
     */
    'htmlFieldType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblOptionJsonldOptionRead
     */
    'obligatoire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblOptionJsonldOptionRead
     */
    'visibleFront'?: boolean;
    /**
     * 
     * @type {Array<TblOptionValueJsonldOptionRead>}
     * @memberof TblOptionJsonldOptionRead
     */
    'values'?: Array<TblOptionValueJsonldOptionRead>;
    /**
     * 
     * @type {TblDefDomaineJsonldOptionRead}
     * @memberof TblOptionJsonldOptionRead
     */
    'domaine'?: TblDefDomaineJsonldOptionRead;
}
/**
 * 
 * @export
 * @interface TblOptionOptionRead
 */
export interface TblOptionOptionRead {
    /**
     * 
     * @type {number}
     * @memberof TblOptionOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblOptionOptionRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOptionOptionRead
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblOptionOptionRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblOptionOptionRead
     */
    'htmlFieldType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblOptionOptionRead
     */
    'obligatoire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblOptionOptionRead
     */
    'visibleFront'?: boolean;
    /**
     * 
     * @type {Array<TblOptionValueOptionRead>}
     * @memberof TblOptionOptionRead
     */
    'values'?: Array<TblOptionValueOptionRead>;
    /**
     * 
     * @type {TblDefDomaineOptionRead}
     * @memberof TblOptionOptionRead
     */
    'domaine'?: TblDefDomaineOptionRead;
}
/**
 * 
 * @export
 * @interface TblOptionValueJsonldOptionCreate
 */
export interface TblOptionValueJsonldOptionCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblOptionValueJsonldOptionCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblOptionValueJsonldOptionCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOptionValueJsonldOptionCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOptionValueJsonldOptionCreate
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOptionValueJsonldOptionCreate
     */
    'description'?: string | null;
    /**
     * permet de jouer sur l\'ordre d\'apparition dans le front (0 = à la fin)
     * @type {number}
     * @memberof TblOptionValueJsonldOptionCreate
     */
    'rang'?: number;
    /**
     * 
     * @type {Array<TblRelArticleJsonldOptionCreate>}
     * @memberof TblOptionValueJsonldOptionCreate
     */
    'articles'?: Array<TblRelArticleJsonldOptionCreate>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TblOptionValueJsonldOptionCreate
     */
    'actions'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TblOptionValueJsonldOptionCreate
     */
    'documents'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TblOptionValueJsonldOptionCreate
     */
    'champsComplementaires'?: Array<string>;
}
/**
 * 
 * @export
 * @interface TblOptionValueJsonldOptionRead
 */
export interface TblOptionValueJsonldOptionRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblOptionValueJsonldOptionRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblOptionValueJsonldOptionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOptionValueJsonldOptionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblOptionValueJsonldOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblOptionValueJsonldOptionRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblOptionValueJsonldOptionRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOptionValueJsonldOptionRead
     */
    'description'?: string | null;
    /**
     * permet de jouer sur l\'ordre d\'apparition dans le front (0 = à la fin)
     * @type {number}
     * @memberof TblOptionValueJsonldOptionRead
     */
    'rang'?: number;
    /**
     * 
     * @type {TblOptionJsonldOptionRead}
     * @memberof TblOptionValueJsonldOptionRead
     */
    'option'?: TblOptionJsonldOptionRead | null;
    /**
     * 
     * @type {Array<TblRelArticleJsonldOptionRead>}
     * @memberof TblOptionValueJsonldOptionRead
     */
    'articles'?: Array<TblRelArticleJsonldOptionRead>;
    /**
     * 
     * @type {Array<TblRelActionJsonldOptionRead>}
     * @memberof TblOptionValueJsonldOptionRead
     */
    'actions'?: Array<TblRelActionJsonldOptionRead>;
    /**
     * 
     * @type {Array<TblRelTypeDocumentJsonldOptionRead>}
     * @memberof TblOptionValueJsonldOptionRead
     */
    'documents'?: Array<TblRelTypeDocumentJsonldOptionRead>;
    /**
     * 
     * @type {Array<TblRelChampCompJsonldOptionRead>}
     * @memberof TblOptionValueJsonldOptionRead
     */
    'champsComplementaires'?: Array<TblRelChampCompJsonldOptionRead>;
}
/**
 * 
 * @export
 * @interface TblOptionValueJsonldOptionUpdate
 */
export interface TblOptionValueJsonldOptionUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblOptionValueJsonldOptionUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblOptionValueJsonldOptionUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOptionValueJsonldOptionUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {Array<TblRelArticleJsonldOptionUpdate>}
     * @memberof TblOptionValueJsonldOptionUpdate
     */
    'articles'?: Array<TblRelArticleJsonldOptionUpdate>;
}
/**
 * 
 * @export
 * @interface TblOptionValueOptionCreate
 */
export interface TblOptionValueOptionCreate {
    /**
     * 
     * @type {string}
     * @memberof TblOptionValueOptionCreate
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOptionValueOptionCreate
     */
    'description'?: string | null;
    /**
     * permet de jouer sur l\'ordre d\'apparition dans le front (0 = à la fin)
     * @type {number}
     * @memberof TblOptionValueOptionCreate
     */
    'rang'?: number;
    /**
     * 
     * @type {Array<TblRelArticleOptionCreate>}
     * @memberof TblOptionValueOptionCreate
     */
    'articles'?: Array<TblRelArticleOptionCreate>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TblOptionValueOptionCreate
     */
    'actions'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TblOptionValueOptionCreate
     */
    'documents'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TblOptionValueOptionCreate
     */
    'champsComplementaires'?: Array<string>;
}
/**
 * 
 * @export
 * @interface TblOptionValueOptionRead
 */
export interface TblOptionValueOptionRead {
    /**
     * 
     * @type {number}
     * @memberof TblOptionValueOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblOptionValueOptionRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblOptionValueOptionRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOptionValueOptionRead
     */
    'description'?: string | null;
    /**
     * permet de jouer sur l\'ordre d\'apparition dans le front (0 = à la fin)
     * @type {number}
     * @memberof TblOptionValueOptionRead
     */
    'rang'?: number;
    /**
     * 
     * @type {TblOptionOptionRead}
     * @memberof TblOptionValueOptionRead
     */
    'option'?: TblOptionOptionRead | null;
    /**
     * 
     * @type {Array<TblRelArticleOptionRead>}
     * @memberof TblOptionValueOptionRead
     */
    'articles'?: Array<TblRelArticleOptionRead>;
    /**
     * 
     * @type {Array<TblRelActionOptionRead>}
     * @memberof TblOptionValueOptionRead
     */
    'actions'?: Array<TblRelActionOptionRead>;
    /**
     * 
     * @type {Array<TblRelTypeDocumentOptionRead>}
     * @memberof TblOptionValueOptionRead
     */
    'documents'?: Array<TblRelTypeDocumentOptionRead>;
    /**
     * 
     * @type {Array<TblRelChampCompOptionRead>}
     * @memberof TblOptionValueOptionRead
     */
    'champsComplementaires'?: Array<TblRelChampCompOptionRead>;
}
/**
 * 
 * @export
 * @interface TblOptionValueOptionUpdate
 */
export interface TblOptionValueOptionUpdate {
    /**
     * 
     * @type {Array<TblRelArticleOptionUpdate>}
     * @memberof TblOptionValueOptionUpdate
     */
    'articles'?: Array<TblRelArticleOptionUpdate>;
}
/**
 * 
 * @export
 * @interface TblOrigineCatalogueRead
 */
export interface TblOrigineCatalogueRead {
    /**
     * 
     * @type {number}
     * @memberof TblOrigineCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblOrigineCatalogueRead
     */
    'origine'?: string | null;
}
/**
 * 
 * @export
 * @interface TblOrigineJsonldCatalogueRead
 */
export interface TblOrigineJsonldCatalogueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblOrigineJsonldCatalogueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblOrigineJsonldCatalogueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOrigineJsonldCatalogueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblOrigineJsonldCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblOrigineJsonldCatalogueRead
     */
    'origine'?: string | null;
}
/**
 * 
 * @export
 * @interface TblOrigineJsonldOffreRead
 */
export interface TblOrigineJsonldOffreRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblOrigineJsonldOffreRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblOrigineJsonldOffreRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOrigineJsonldOffreRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblOrigineJsonldOffreRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblOrigineJsonldOffreRead
     */
    'origine'?: string | null;
}
/**
 * 
 * @export
 * @interface TblOrigineJsonldOrigineOffreRead
 */
export interface TblOrigineJsonldOrigineOffreRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblOrigineJsonldOrigineOffreRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblOrigineJsonldOrigineOffreRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblOrigineJsonldOrigineOffreRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblOrigineJsonldOrigineOffreRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblOrigineOffreRead
 */
export interface TblOrigineOffreRead {
    /**
     * 
     * @type {number}
     * @memberof TblOrigineOffreRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblOrigineOffreRead
     */
    'origine'?: string | null;
}
/**
 * 
 * @export
 * @interface TblOrigineOrigineOffreRead
 */
export interface TblOrigineOrigineOffreRead {
    /**
     * 
     * @type {number}
     * @memberof TblOrigineOrigineOffreRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblPackageCatalogueRead
 */
export interface TblPackageCatalogueRead {
    /**
     * 
     * @type {number}
     * @memberof TblPackageCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPackageCatalogueRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPackageCatalogueRead
     */
    'nomCommercial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPackageCatalogueRead
     */
    'formalite'?: string | null;
    /**
     * 
     * @type {Array<TblRelArticleCatalogueRead>}
     * @memberof TblPackageCatalogueRead
     */
    'articles'?: Array<TblRelArticleCatalogueRead>;
}
/**
 * 
 * @export
 * @interface TblPackageJsonldCatalogueRead
 */
export interface TblPackageJsonldCatalogueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblPackageJsonldCatalogueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblPackageJsonldCatalogueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPackageJsonldCatalogueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblPackageJsonldCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPackageJsonldCatalogueRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPackageJsonldCatalogueRead
     */
    'nomCommercial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPackageJsonldCatalogueRead
     */
    'formalite'?: string | null;
    /**
     * 
     * @type {Array<TblRelArticleJsonldCatalogueRead>}
     * @memberof TblPackageJsonldCatalogueRead
     */
    'articles'?: Array<TblRelArticleJsonldCatalogueRead>;
}
/**
 * 
 * @export
 * @interface TblPackageJsonldPackagePrescripteurRead
 */
export interface TblPackageJsonldPackagePrescripteurRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblPackageJsonldPackagePrescripteurRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblPackageJsonldPackagePrescripteurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPackageJsonldPackagePrescripteurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblPackageJsonldPackagePrescripteurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPackageJsonldPackagePrescripteurRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPackageJsonldPackagePrescripteurRead
     */
    'nomCommercial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPackageJsonldPackagePrescripteurRead
     */
    'formalite'?: string | null;
    /**
     * 
     * @type {Array<TblRelArticleJsonldPackagePrescripteurRead>}
     * @memberof TblPackageJsonldPackagePrescripteurRead
     */
    'articles'?: Array<TblRelArticleJsonldPackagePrescripteurRead>;
    /**
     * 
     * @type {Array<TblRelChampCompJsonldPackagePrescripteurRead>}
     * @memberof TblPackageJsonldPackagePrescripteurRead
     */
    'champsComplementaires'?: Array<TblRelChampCompJsonldPackagePrescripteurRead>;
}
/**
 * 
 * @export
 * @interface TblPackagePackagePrescripteurRead
 */
export interface TblPackagePackagePrescripteurRead {
    /**
     * 
     * @type {number}
     * @memberof TblPackagePackagePrescripteurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPackagePackagePrescripteurRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPackagePackagePrescripteurRead
     */
    'nomCommercial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPackagePackagePrescripteurRead
     */
    'formalite'?: string | null;
    /**
     * 
     * @type {Array<TblRelArticlePackagePrescripteurRead>}
     * @memberof TblPackagePackagePrescripteurRead
     */
    'articles'?: Array<TblRelArticlePackagePrescripteurRead>;
    /**
     * 
     * @type {Array<TblRelChampCompPackagePrescripteurRead>}
     * @memberof TblPackagePackagePrescripteurRead
     */
    'champsComplementaires'?: Array<TblRelChampCompPackagePrescripteurRead>;
}
/**
 * 
 * @export
 * @interface TblRelActionCatalogueCreate
 */
export interface TblRelActionCatalogueCreate {
    /**
     * 
     * @type {string}
     * @memberof TblRelActionCatalogueCreate
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface TblRelActionCatalogueRead
 */
export interface TblRelActionCatalogueRead {
    /**
     * 
     * @type {string}
     * @memberof TblRelActionCatalogueRead
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface TblRelActionJsonldCatalogueCreate
 */
export interface TblRelActionJsonldCatalogueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelActionJsonldCatalogueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldCatalogueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldCatalogueCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldCatalogueCreate
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface TblRelActionJsonldCatalogueRead
 */
export interface TblRelActionJsonldCatalogueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelActionJsonldCatalogueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldCatalogueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldCatalogueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldCatalogueRead
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface TblRelActionJsonldOptionRead
 */
export interface TblRelActionJsonldOptionRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelActionJsonldOptionRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldOptionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldOptionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {TblActionDetailJsonldOptionRead}
     * @memberof TblRelActionJsonldOptionRead
     */
    'action'?: TblActionDetailJsonldOptionRead;
}
/**
 * 
 * @export
 * @interface TblRelActionJsonldOptionValueCreate
 */
export interface TblRelActionJsonldOptionValueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelActionJsonldOptionValueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldOptionValueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldOptionValueCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {TblActionDetailJsonldOptionValueCreate}
     * @memberof TblRelActionJsonldOptionValueCreate
     */
    'action'?: TblActionDetailJsonldOptionValueCreate;
}
/**
 * 
 * @export
 * @interface TblRelActionJsonldOptionValueRead
 */
export interface TblRelActionJsonldOptionValueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelActionJsonldOptionValueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldOptionValueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldOptionValueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {TblActionDetailJsonldOptionValueRead}
     * @memberof TblRelActionJsonldOptionValueRead
     */
    'action'?: TblActionDetailJsonldOptionValueRead;
}
/**
 * 
 * @export
 * @interface TblRelActionJsonldOptionValueUpdate
 */
export interface TblRelActionJsonldOptionValueUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelActionJsonldOptionValueUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldOptionValueUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldOptionValueUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldOptionValueUpdate
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface TblRelActionJsonldPackageCreate
 */
export interface TblRelActionJsonldPackageCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelActionJsonldPackageCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldPackageCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldPackageCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {TblActionDetailJsonldPackageCreate}
     * @memberof TblRelActionJsonldPackageCreate
     */
    'action'?: TblActionDetailJsonldPackageCreate;
}
/**
 * 
 * @export
 * @interface TblRelActionJsonldPackageRead
 */
export interface TblRelActionJsonldPackageRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelActionJsonldPackageRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldPackageRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldPackageRead
     */
    '@type'?: string;
    /**
     * 
     * @type {TblActionDetailJsonldPackageRead}
     * @memberof TblRelActionJsonldPackageRead
     */
    'action'?: TblActionDetailJsonldPackageRead;
}
/**
 * 
 * @export
 * @interface TblRelActionJsonldPackageUpdate
 */
export interface TblRelActionJsonldPackageUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelActionJsonldPackageUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldPackageUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldPackageUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelActionJsonldPackageUpdate
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface TblRelActionOptionRead
 */
export interface TblRelActionOptionRead {
    /**
     * 
     * @type {TblActionDetailOptionRead}
     * @memberof TblRelActionOptionRead
     */
    'action'?: TblActionDetailOptionRead;
}
/**
 * 
 * @export
 * @interface TblRelActionOptionValueCreate
 */
export interface TblRelActionOptionValueCreate {
    /**
     * 
     * @type {TblActionDetailOptionValueCreate}
     * @memberof TblRelActionOptionValueCreate
     */
    'action'?: TblActionDetailOptionValueCreate;
}
/**
 * 
 * @export
 * @interface TblRelActionOptionValueRead
 */
export interface TblRelActionOptionValueRead {
    /**
     * 
     * @type {TblActionDetailOptionValueRead}
     * @memberof TblRelActionOptionValueRead
     */
    'action'?: TblActionDetailOptionValueRead;
}
/**
 * 
 * @export
 * @interface TblRelActionOptionValueUpdate
 */
export interface TblRelActionOptionValueUpdate {
    /**
     * 
     * @type {string}
     * @memberof TblRelActionOptionValueUpdate
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface TblRelActionPackageCreate
 */
export interface TblRelActionPackageCreate {
    /**
     * 
     * @type {TblActionDetailPackageCreate}
     * @memberof TblRelActionPackageCreate
     */
    'action'?: TblActionDetailPackageCreate;
}
/**
 * 
 * @export
 * @interface TblRelActionPackageRead
 */
export interface TblRelActionPackageRead {
    /**
     * 
     * @type {TblActionDetailPackageRead}
     * @memberof TblRelActionPackageRead
     */
    'action'?: TblActionDetailPackageRead;
}
/**
 * 
 * @export
 * @interface TblRelActionPackageUpdate
 */
export interface TblRelActionPackageUpdate {
    /**
     * 
     * @type {string}
     * @memberof TblRelActionPackageUpdate
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface TblRelArticleCatalogueCreate
 */
export interface TblRelArticleCatalogueCreate {
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleCatalogueCreate
     */
    'article'?: string;
    /**
     * 
     * @type {object}
     * @memberof TblRelArticleCatalogueCreate
     */
    'sType'?: object;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleCatalogueCreate
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleCatalogueRead
 */
export interface TblRelArticleCatalogueRead {
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleCatalogueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleCatalogueRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesCatalogueRead}
     * @memberof TblRelArticleCatalogueRead
     */
    'article'?: TblFactArticlesCatalogueRead;
    /**
     * 
     * @type {object}
     * @memberof TblRelArticleCatalogueRead
     */
    'sType'?: object;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleCatalogueRead
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleCatalogueRead
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleJsonldCatalogueCreate
 */
export interface TblRelArticleJsonldCatalogueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelArticleJsonldCatalogueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldCatalogueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldCatalogueCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldCatalogueCreate
     */
    'article'?: string;
    /**
     * 
     * @type {TblAnnonceSTypeJsonldCatalogueCreate}
     * @memberof TblRelArticleJsonldCatalogueCreate
     */
    'sType'?: TblAnnonceSTypeJsonldCatalogueCreate | null;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldCatalogueCreate
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleJsonldCatalogueRead
 */
export interface TblRelArticleJsonldCatalogueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelArticleJsonldCatalogueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldCatalogueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldCatalogueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldCatalogueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldCatalogueRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesJsonldCatalogueRead}
     * @memberof TblRelArticleJsonldCatalogueRead
     */
    'article'?: TblFactArticlesJsonldCatalogueRead;
    /**
     * 
     * @type {TblAnnonceSTypeJsonldCatalogueRead}
     * @memberof TblRelArticleJsonldCatalogueRead
     */
    'sType'?: TblAnnonceSTypeJsonldCatalogueRead | null;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldCatalogueRead
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldCatalogueRead
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleJsonldOptionCreate
 */
export interface TblRelArticleJsonldOptionCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelArticleJsonldOptionCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionCreate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesJsonldOptionCreate}
     * @memberof TblRelArticleJsonldOptionCreate
     */
    'article'?: TblFactArticlesJsonldOptionCreate;
    /**
     * 
     * @type {TblAnnonceSTypeJsonldOptionCreate}
     * @memberof TblRelArticleJsonldOptionCreate
     */
    'sType'?: TblAnnonceSTypeJsonldOptionCreate | null;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionCreate
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionCreate
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleJsonldOptionRead
 */
export interface TblRelArticleJsonldOptionRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelArticleJsonldOptionRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesJsonldOptionRead}
     * @memberof TblRelArticleJsonldOptionRead
     */
    'article'?: TblFactArticlesJsonldOptionRead;
    /**
     * 
     * @type {TblAnnonceSTypeJsonldOptionRead}
     * @memberof TblRelArticleJsonldOptionRead
     */
    'sType'?: TblAnnonceSTypeJsonldOptionRead | null;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionRead
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionRead
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleJsonldOptionUpdate
 */
export interface TblRelArticleJsonldOptionUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelArticleJsonldOptionUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionUpdate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesJsonldOptionUpdate}
     * @memberof TblRelArticleJsonldOptionUpdate
     */
    'article'?: TblFactArticlesJsonldOptionUpdate;
    /**
     * 
     * @type {TblAnnonceSTypeJsonldOptionUpdate}
     * @memberof TblRelArticleJsonldOptionUpdate
     */
    'sType'?: TblAnnonceSTypeJsonldOptionUpdate | null;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionUpdate
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionUpdate
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleJsonldOptionValueCreate
 */
export interface TblRelArticleJsonldOptionValueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelArticleJsonldOptionValueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionValueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionValueCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionValueCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionValueCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionValueCreate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesJsonldOptionValueCreate}
     * @memberof TblRelArticleJsonldOptionValueCreate
     */
    'article'?: TblFactArticlesJsonldOptionValueCreate;
    /**
     * 
     * @type {TblAnnonceSTypeJsonldOptionValueCreate}
     * @memberof TblRelArticleJsonldOptionValueCreate
     */
    'sType'?: TblAnnonceSTypeJsonldOptionValueCreate | null;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionValueCreate
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionValueCreate
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleJsonldOptionValueRead
 */
export interface TblRelArticleJsonldOptionValueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelArticleJsonldOptionValueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionValueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionValueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionValueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionValueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionValueRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesJsonldOptionValueRead}
     * @memberof TblRelArticleJsonldOptionValueRead
     */
    'article'?: TblFactArticlesJsonldOptionValueRead;
    /**
     * 
     * @type {TblAnnonceSTypeJsonldOptionValueRead}
     * @memberof TblRelArticleJsonldOptionValueRead
     */
    'sType'?: TblAnnonceSTypeJsonldOptionValueRead | null;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionValueRead
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionValueRead
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleJsonldOptionValueUpdate
 */
export interface TblRelArticleJsonldOptionValueUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelArticleJsonldOptionValueUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionValueUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionValueUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionValueUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionValueUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldOptionValueUpdate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesJsonldOptionValueUpdate}
     * @memberof TblRelArticleJsonldOptionValueUpdate
     */
    'article'?: TblFactArticlesJsonldOptionValueUpdate;
    /**
     * 
     * @type {TblAnnonceSTypeJsonldOptionValueUpdate}
     * @memberof TblRelArticleJsonldOptionValueUpdate
     */
    'sType'?: TblAnnonceSTypeJsonldOptionValueUpdate | null;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionValueUpdate
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldOptionValueUpdate
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleJsonldPackageCreate
 */
export interface TblRelArticleJsonldPackageCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelArticleJsonldPackageCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldPackageCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldPackageCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldPackageCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldPackageCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldPackageCreate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesJsonldPackageCreate}
     * @memberof TblRelArticleJsonldPackageCreate
     */
    'article'?: TblFactArticlesJsonldPackageCreate;
    /**
     * 
     * @type {TblAnnonceSTypeJsonldPackageCreate}
     * @memberof TblRelArticleJsonldPackageCreate
     */
    'sType'?: TblAnnonceSTypeJsonldPackageCreate | null;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldPackageCreate
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldPackageCreate
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleJsonldPackagePrescripteurRead
 */
export interface TblRelArticleJsonldPackagePrescripteurRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelArticleJsonldPackagePrescripteurRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldPackagePrescripteurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldPackagePrescripteurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldPackagePrescripteurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldPackagePrescripteurRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldPackagePrescripteurRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesJsonldPackagePrescripteurRead}
     * @memberof TblRelArticleJsonldPackagePrescripteurRead
     */
    'article'?: TblFactArticlesJsonldPackagePrescripteurRead;
    /**
     * 
     * @type {TblAnnonceSTypeJsonldPackagePrescripteurRead}
     * @memberof TblRelArticleJsonldPackagePrescripteurRead
     */
    'sType'?: TblAnnonceSTypeJsonldPackagePrescripteurRead | null;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldPackagePrescripteurRead
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleJsonldPackageRead
 */
export interface TblRelArticleJsonldPackageRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelArticleJsonldPackageRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldPackageRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldPackageRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldPackageRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldPackageRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldPackageRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesJsonldPackageRead}
     * @memberof TblRelArticleJsonldPackageRead
     */
    'article'?: TblFactArticlesJsonldPackageRead;
    /**
     * 
     * @type {TblAnnonceSTypeJsonldPackageRead}
     * @memberof TblRelArticleJsonldPackageRead
     */
    'sType'?: TblAnnonceSTypeJsonldPackageRead | null;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldPackageRead
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldPackageRead
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleJsonldPackageUpdate
 */
export interface TblRelArticleJsonldPackageUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelArticleJsonldPackageUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldPackageUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldPackageUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldPackageUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldPackageUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleJsonldPackageUpdate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesJsonldPackageUpdate}
     * @memberof TblRelArticleJsonldPackageUpdate
     */
    'article'?: TblFactArticlesJsonldPackageUpdate;
    /**
     * 
     * @type {TblAnnonceSTypeJsonldPackageUpdate}
     * @memberof TblRelArticleJsonldPackageUpdate
     */
    'sType'?: TblAnnonceSTypeJsonldPackageUpdate | null;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldPackageUpdate
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleJsonldPackageUpdate
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleOptionCreate
 */
export interface TblRelArticleOptionCreate {
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleOptionCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleOptionCreate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesOptionCreate}
     * @memberof TblRelArticleOptionCreate
     */
    'article'?: TblFactArticlesOptionCreate;
    /**
     * 
     * @type {object}
     * @memberof TblRelArticleOptionCreate
     */
    'sType'?: object;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionCreate
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionCreate
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleOptionRead
 */
export interface TblRelArticleOptionRead {
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleOptionRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleOptionRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesOptionRead}
     * @memberof TblRelArticleOptionRead
     */
    'article'?: TblFactArticlesOptionRead;
    /**
     * 
     * @type {object}
     * @memberof TblRelArticleOptionRead
     */
    'sType'?: object;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionRead
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionRead
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleOptionUpdate
 */
export interface TblRelArticleOptionUpdate {
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleOptionUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleOptionUpdate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesOptionUpdate}
     * @memberof TblRelArticleOptionUpdate
     */
    'article'?: TblFactArticlesOptionUpdate;
    /**
     * 
     * @type {object}
     * @memberof TblRelArticleOptionUpdate
     */
    'sType'?: object;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionUpdate
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionUpdate
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleOptionValueCreate
 */
export interface TblRelArticleOptionValueCreate {
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionValueCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleOptionValueCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleOptionValueCreate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesOptionValueCreate}
     * @memberof TblRelArticleOptionValueCreate
     */
    'article'?: TblFactArticlesOptionValueCreate;
    /**
     * 
     * @type {TblAnnonceSTypeOptionValueCreate}
     * @memberof TblRelArticleOptionValueCreate
     */
    'sType'?: TblAnnonceSTypeOptionValueCreate | null;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionValueCreate
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionValueCreate
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleOptionValueRead
 */
export interface TblRelArticleOptionValueRead {
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionValueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleOptionValueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleOptionValueRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesOptionValueRead}
     * @memberof TblRelArticleOptionValueRead
     */
    'article'?: TblFactArticlesOptionValueRead;
    /**
     * 
     * @type {TblAnnonceSTypeOptionValueRead}
     * @memberof TblRelArticleOptionValueRead
     */
    'sType'?: TblAnnonceSTypeOptionValueRead | null;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionValueRead
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionValueRead
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticleOptionValueUpdate
 */
export interface TblRelArticleOptionValueUpdate {
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionValueUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleOptionValueUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticleOptionValueUpdate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesOptionValueUpdate}
     * @memberof TblRelArticleOptionValueUpdate
     */
    'article'?: TblFactArticlesOptionValueUpdate;
    /**
     * 
     * @type {TblAnnonceSTypeOptionValueUpdate}
     * @memberof TblRelArticleOptionValueUpdate
     */
    'sType'?: TblAnnonceSTypeOptionValueUpdate | null;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionValueUpdate
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticleOptionValueUpdate
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticlePackageCreate
 */
export interface TblRelArticlePackageCreate {
    /**
     * 
     * @type {number}
     * @memberof TblRelArticlePackageCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticlePackageCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticlePackageCreate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesPackageCreate}
     * @memberof TblRelArticlePackageCreate
     */
    'article'?: TblFactArticlesPackageCreate;
    /**
     * 
     * @type {object}
     * @memberof TblRelArticlePackageCreate
     */
    'sType'?: object;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticlePackageCreate
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticlePackageCreate
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticlePackagePrescripteurRead
 */
export interface TblRelArticlePackagePrescripteurRead {
    /**
     * 
     * @type {number}
     * @memberof TblRelArticlePackagePrescripteurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticlePackagePrescripteurRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticlePackagePrescripteurRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesPackagePrescripteurRead}
     * @memberof TblRelArticlePackagePrescripteurRead
     */
    'article'?: TblFactArticlesPackagePrescripteurRead;
    /**
     * 
     * @type {object}
     * @memberof TblRelArticlePackagePrescripteurRead
     */
    'sType'?: object;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticlePackagePrescripteurRead
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticlePackageRead
 */
export interface TblRelArticlePackageRead {
    /**
     * 
     * @type {number}
     * @memberof TblRelArticlePackageRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticlePackageRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticlePackageRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesPackageRead}
     * @memberof TblRelArticlePackageRead
     */
    'article'?: TblFactArticlesPackageRead;
    /**
     * 
     * @type {TblAnnonceSTypePackageRead}
     * @memberof TblRelArticlePackageRead
     */
    'sType'?: TblAnnonceSTypePackageRead | null;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticlePackageRead
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticlePackageRead
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelArticlePackageUpdate
 */
export interface TblRelArticlePackageUpdate {
    /**
     * 
     * @type {number}
     * @memberof TblRelArticlePackageUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticlePackageUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelArticlePackageUpdate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblFactArticlesPackageUpdate}
     * @memberof TblRelArticlePackageUpdate
     */
    'article'?: TblFactArticlesPackageUpdate;
    /**
     * 
     * @type {TblAnnonceSTypePackageUpdate}
     * @memberof TblRelArticlePackageUpdate
     */
    'sType'?: TblAnnonceSTypePackageUpdate | null;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticlePackageUpdate
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRelArticlePackageUpdate
     */
    'quantite'?: number;
}
/**
 * 
 * @export
 * @interface TblRelChampCompCatalogueCreate
 */
export interface TblRelChampCompCatalogueCreate {
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompCatalogueCreate
     */
    'champComplementaire'?: string;
}
/**
 * 
 * @export
 * @interface TblRelChampCompCatalogueRead
 */
export interface TblRelChampCompCatalogueRead {
    /**
     * 
     * @type {TblChampCompCatalogueRead}
     * @memberof TblRelChampCompCatalogueRead
     */
    'champComplementaire'?: TblChampCompCatalogueRead;
}
/**
 * 
 * @export
 * @interface TblRelChampCompJsonldCatalogueCreate
 */
export interface TblRelChampCompJsonldCatalogueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelChampCompJsonldCatalogueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldCatalogueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldCatalogueCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldCatalogueCreate
     */
    'champComplementaire'?: string;
}
/**
 * 
 * @export
 * @interface TblRelChampCompJsonldCatalogueRead
 */
export interface TblRelChampCompJsonldCatalogueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelChampCompJsonldCatalogueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldCatalogueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldCatalogueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {TblChampCompJsonldCatalogueRead}
     * @memberof TblRelChampCompJsonldCatalogueRead
     */
    'champComplementaire'?: TblChampCompJsonldCatalogueRead;
}
/**
 * 
 * @export
 * @interface TblRelChampCompJsonldOptionRead
 */
export interface TblRelChampCompJsonldOptionRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelChampCompJsonldOptionRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldOptionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldOptionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {TblChampCompJsonldOptionRead}
     * @memberof TblRelChampCompJsonldOptionRead
     */
    'champComplementaire'?: TblChampCompJsonldOptionRead;
}
/**
 * 
 * @export
 * @interface TblRelChampCompJsonldOptionValueCreate
 */
export interface TblRelChampCompJsonldOptionValueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelChampCompJsonldOptionValueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldOptionValueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldOptionValueCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {TblChampCompJsonldOptionValueCreate}
     * @memberof TblRelChampCompJsonldOptionValueCreate
     */
    'champComplementaire'?: TblChampCompJsonldOptionValueCreate;
}
/**
 * 
 * @export
 * @interface TblRelChampCompJsonldOptionValueRead
 */
export interface TblRelChampCompJsonldOptionValueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelChampCompJsonldOptionValueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldOptionValueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldOptionValueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {TblChampCompJsonldOptionValueRead}
     * @memberof TblRelChampCompJsonldOptionValueRead
     */
    'champComplementaire'?: TblChampCompJsonldOptionValueRead;
}
/**
 * 
 * @export
 * @interface TblRelChampCompJsonldOptionValueUpdate
 */
export interface TblRelChampCompJsonldOptionValueUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelChampCompJsonldOptionValueUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldOptionValueUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldOptionValueUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldOptionValueUpdate
     */
    'champComplementaire'?: string;
}
/**
 * 
 * @export
 * @interface TblRelChampCompJsonldPackageCreate
 */
export interface TblRelChampCompJsonldPackageCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelChampCompJsonldPackageCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldPackageCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldPackageCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {TblChampCompJsonldPackageCreate}
     * @memberof TblRelChampCompJsonldPackageCreate
     */
    'champComplementaire'?: TblChampCompJsonldPackageCreate;
}
/**
 * 
 * @export
 * @interface TblRelChampCompJsonldPackagePrescripteurRead
 */
export interface TblRelChampCompJsonldPackagePrescripteurRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelChampCompJsonldPackagePrescripteurRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldPackagePrescripteurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldPackagePrescripteurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRelChampCompJsonldPackagePrescripteurRead
     */
    'id'?: number;
    /**
     * 
     * @type {TblChampCompJsonldPackagePrescripteurRead}
     * @memberof TblRelChampCompJsonldPackagePrescripteurRead
     */
    'champComplementaire'?: TblChampCompJsonldPackagePrescripteurRead;
    /**
     * permet de jouer sur l\'ordre d\'apparition dans le front (0 = à la fin)
     * @type {number}
     * @memberof TblRelChampCompJsonldPackagePrescripteurRead
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface TblRelChampCompJsonldPackageRead
 */
export interface TblRelChampCompJsonldPackageRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelChampCompJsonldPackageRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldPackageRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldPackageRead
     */
    '@type'?: string;
    /**
     * 
     * @type {TblChampCompJsonldPackageRead}
     * @memberof TblRelChampCompJsonldPackageRead
     */
    'champComplementaire'?: TblChampCompJsonldPackageRead;
}
/**
 * 
 * @export
 * @interface TblRelChampCompJsonldPackageUpdate
 */
export interface TblRelChampCompJsonldPackageUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelChampCompJsonldPackageUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldPackageUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompJsonldPackageUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {TblChampCompJsonldPackageUpdate}
     * @memberof TblRelChampCompJsonldPackageUpdate
     */
    'champComplementaire'?: TblChampCompJsonldPackageUpdate;
}
/**
 * 
 * @export
 * @interface TblRelChampCompOptionRead
 */
export interface TblRelChampCompOptionRead {
    /**
     * 
     * @type {TblChampCompOptionRead}
     * @memberof TblRelChampCompOptionRead
     */
    'champComplementaire'?: TblChampCompOptionRead;
}
/**
 * 
 * @export
 * @interface TblRelChampCompOptionValueCreate
 */
export interface TblRelChampCompOptionValueCreate {
    /**
     * 
     * @type {TblChampCompOptionValueCreate}
     * @memberof TblRelChampCompOptionValueCreate
     */
    'champComplementaire'?: TblChampCompOptionValueCreate;
}
/**
 * 
 * @export
 * @interface TblRelChampCompOptionValueRead
 */
export interface TblRelChampCompOptionValueRead {
    /**
     * 
     * @type {TblChampCompOptionValueRead}
     * @memberof TblRelChampCompOptionValueRead
     */
    'champComplementaire'?: TblChampCompOptionValueRead;
}
/**
 * 
 * @export
 * @interface TblRelChampCompOptionValueUpdate
 */
export interface TblRelChampCompOptionValueUpdate {
    /**
     * 
     * @type {string}
     * @memberof TblRelChampCompOptionValueUpdate
     */
    'champComplementaire'?: string;
}
/**
 * 
 * @export
 * @interface TblRelChampCompPackageCreate
 */
export interface TblRelChampCompPackageCreate {
    /**
     * 
     * @type {TblChampCompPackageCreate}
     * @memberof TblRelChampCompPackageCreate
     */
    'champComplementaire'?: TblChampCompPackageCreate;
}
/**
 * 
 * @export
 * @interface TblRelChampCompPackagePrescripteurRead
 */
export interface TblRelChampCompPackagePrescripteurRead {
    /**
     * 
     * @type {number}
     * @memberof TblRelChampCompPackagePrescripteurRead
     */
    'id'?: number;
    /**
     * 
     * @type {TblChampCompPackagePrescripteurRead}
     * @memberof TblRelChampCompPackagePrescripteurRead
     */
    'champComplementaire'?: TblChampCompPackagePrescripteurRead;
    /**
     * permet de jouer sur l\'ordre d\'apparition dans le front (0 = à la fin)
     * @type {number}
     * @memberof TblRelChampCompPackagePrescripteurRead
     */
    'rang'?: number;
}
/**
 * 
 * @export
 * @interface TblRelChampCompPackageRead
 */
export interface TblRelChampCompPackageRead {
    /**
     * 
     * @type {TblChampCompPackageRead}
     * @memberof TblRelChampCompPackageRead
     */
    'champComplementaire'?: TblChampCompPackageRead;
}
/**
 * 
 * @export
 * @interface TblRelChampCompPackageUpdate
 */
export interface TblRelChampCompPackageUpdate {
    /**
     * 
     * @type {TblChampCompPackageUpdate}
     * @memberof TblRelChampCompPackageUpdate
     */
    'champComplementaire'?: TblChampCompPackageUpdate;
}
/**
 * 
 * @export
 * @interface TblRelOptionCatalogueCreate
 */
export interface TblRelOptionCatalogueCreate {
    /**
     * 
     * @type {string}
     * @memberof TblRelOptionCatalogueCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelOptionCatalogueCreate
     */
    'option'?: string;
}
/**
 * 
 * @export
 * @interface TblRelOptionCatalogueRead
 */
export interface TblRelOptionCatalogueRead {
    /**
     * 
     * @type {string}
     * @memberof TblRelOptionCatalogueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {TblOptionCatalogueRead}
     * @memberof TblRelOptionCatalogueRead
     */
    'option'?: TblOptionCatalogueRead;
}
/**
 * 
 * @export
 * @interface TblRelOptionJsonldCatalogueCreate
 */
export interface TblRelOptionJsonldCatalogueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelOptionJsonldCatalogueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelOptionJsonldCatalogueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelOptionJsonldCatalogueCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelOptionJsonldCatalogueCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblRelOptionJsonldCatalogueCreate
     */
    'option'?: string;
}
/**
 * 
 * @export
 * @interface TblRelOptionJsonldCatalogueRead
 */
export interface TblRelOptionJsonldCatalogueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelOptionJsonldCatalogueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelOptionJsonldCatalogueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelOptionJsonldCatalogueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelOptionJsonldCatalogueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {TblOptionJsonldCatalogueRead}
     * @memberof TblRelOptionJsonldCatalogueRead
     */
    'option'?: TblOptionJsonldCatalogueRead;
}
/**
 * 
 * @export
 * @interface TblRelOrigineOffreJsonldOffreRead
 */
export interface TblRelOrigineOffreJsonldOffreRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelOrigineOffreJsonldOffreRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelOrigineOffreJsonldOffreRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelOrigineOffreJsonldOffreRead
     */
    '@type'?: string;
    /**
     * 
     * @type {TblOrigineJsonldOffreRead}
     * @memberof TblRelOrigineOffreJsonldOffreRead
     */
    'origine'?: TblOrigineJsonldOffreRead;
}
/**
 * 
 * @export
 * @interface TblRelOrigineOffreJsonldOrigineRead
 */
export interface TblRelOrigineOffreJsonldOrigineRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelOrigineOffreJsonldOrigineRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelOrigineOffreJsonldOrigineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelOrigineOffreJsonldOrigineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRelOrigineOffreJsonldOrigineRead
     */
    'id'?: number;
    /**
     * 
     * @type {TblOffreJsonldOrigineRead}
     * @memberof TblRelOrigineOffreJsonldOrigineRead
     */
    'offre'?: TblOffreJsonldOrigineRead;
}
/**
 * 
 * @export
 * @interface TblRelOrigineOffreOffreRead
 */
export interface TblRelOrigineOffreOffreRead {
    /**
     * 
     * @type {TblOrigineOffreRead}
     * @memberof TblRelOrigineOffreOffreRead
     */
    'origine'?: TblOrigineOffreRead;
}
/**
 * 
 * @export
 * @interface TblRelOrigineOffreOrigineRead
 */
export interface TblRelOrigineOffreOrigineRead {
    /**
     * 
     * @type {number}
     * @memberof TblRelOrigineOffreOrigineRead
     */
    'id'?: number;
    /**
     * 
     * @type {TblOffreOrigineRead}
     * @memberof TblRelOrigineOffreOrigineRead
     */
    'offre'?: TblOffreOrigineRead;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentArticleCreate
 */
export interface TblRelTypeDocumentArticleCreate {
    /**
     * 
     * @type {TblTypeDocumentArticleCreate}
     * @memberof TblRelTypeDocumentArticleCreate
     */
    'typeDocument'?: TblTypeDocumentArticleCreate | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentArticleRead
 */
export interface TblRelTypeDocumentArticleRead {
    /**
     * 
     * @type {TblTypeDocumentArticleRead}
     * @memberof TblRelTypeDocumentArticleRead
     */
    'typeDocument'?: TblTypeDocumentArticleRead | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentArticleUpdate
 */
export interface TblRelTypeDocumentArticleUpdate {
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentArticleUpdate
     */
    'typeDocument'?: string | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentCatalogueCreate
 */
export interface TblRelTypeDocumentCatalogueCreate {
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentCatalogueCreate
     */
    'typeDocument'?: string | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentCatalogueRead
 */
export interface TblRelTypeDocumentCatalogueRead {
    /**
     * 
     * @type {TblTypeDocumentCatalogueRead}
     * @memberof TblRelTypeDocumentCatalogueRead
     */
    'typeDocument'?: TblTypeDocumentCatalogueRead | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentJsonldArticleCreate
 */
export interface TblRelTypeDocumentJsonldArticleCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelTypeDocumentJsonldArticleCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldArticleCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldArticleCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {TblTypeDocumentJsonldArticleCreate}
     * @memberof TblRelTypeDocumentJsonldArticleCreate
     */
    'typeDocument'?: TblTypeDocumentJsonldArticleCreate | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentJsonldArticleRead
 */
export interface TblRelTypeDocumentJsonldArticleRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelTypeDocumentJsonldArticleRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldArticleRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldArticleRead
     */
    '@type'?: string;
    /**
     * 
     * @type {TblTypeDocumentJsonldArticleRead}
     * @memberof TblRelTypeDocumentJsonldArticleRead
     */
    'typeDocument'?: TblTypeDocumentJsonldArticleRead | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentJsonldArticleUpdate
 */
export interface TblRelTypeDocumentJsonldArticleUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelTypeDocumentJsonldArticleUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldArticleUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldArticleUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldArticleUpdate
     */
    'typeDocument'?: string | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentJsonldCatalogueCreate
 */
export interface TblRelTypeDocumentJsonldCatalogueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelTypeDocumentJsonldCatalogueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldCatalogueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldCatalogueCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldCatalogueCreate
     */
    'typeDocument'?: string | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentJsonldCatalogueRead
 */
export interface TblRelTypeDocumentJsonldCatalogueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelTypeDocumentJsonldCatalogueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldCatalogueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldCatalogueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {TblTypeDocumentJsonldCatalogueRead}
     * @memberof TblRelTypeDocumentJsonldCatalogueRead
     */
    'typeDocument'?: TblTypeDocumentJsonldCatalogueRead | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentJsonldOptionRead
 */
export interface TblRelTypeDocumentJsonldOptionRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelTypeDocumentJsonldOptionRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldOptionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldOptionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {TblTypeDocumentJsonldOptionRead}
     * @memberof TblRelTypeDocumentJsonldOptionRead
     */
    'typeDocument'?: TblTypeDocumentJsonldOptionRead | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentJsonldOptionValueCreate
 */
export interface TblRelTypeDocumentJsonldOptionValueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelTypeDocumentJsonldOptionValueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldOptionValueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldOptionValueCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRelTypeDocumentJsonldOptionValueCreate
     */
    'id'?: number;
    /**
     * 
     * @type {TblTypeDocumentJsonldOptionValueCreate}
     * @memberof TblRelTypeDocumentJsonldOptionValueCreate
     */
    'typeDocument'?: TblTypeDocumentJsonldOptionValueCreate | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentJsonldOptionValueRead
 */
export interface TblRelTypeDocumentJsonldOptionValueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelTypeDocumentJsonldOptionValueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldOptionValueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldOptionValueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRelTypeDocumentJsonldOptionValueRead
     */
    'id'?: number;
    /**
     * 
     * @type {TblTypeDocumentJsonldOptionValueRead}
     * @memberof TblRelTypeDocumentJsonldOptionValueRead
     */
    'typeDocument'?: TblTypeDocumentJsonldOptionValueRead | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentJsonldOptionValueUpdate
 */
export interface TblRelTypeDocumentJsonldOptionValueUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelTypeDocumentJsonldOptionValueUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldOptionValueUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldOptionValueUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRelTypeDocumentJsonldOptionValueUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {TblTypeDocumentJsonldOptionValueUpdate}
     * @memberof TblRelTypeDocumentJsonldOptionValueUpdate
     */
    'typeDocument'?: TblTypeDocumentJsonldOptionValueUpdate | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentJsonldPackageCreate
 */
export interface TblRelTypeDocumentJsonldPackageCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelTypeDocumentJsonldPackageCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldPackageCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldPackageCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {TblTypeDocumentJsonldPackageCreate}
     * @memberof TblRelTypeDocumentJsonldPackageCreate
     */
    'typeDocument'?: TblTypeDocumentJsonldPackageCreate | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentJsonldPackageRead
 */
export interface TblRelTypeDocumentJsonldPackageRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelTypeDocumentJsonldPackageRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldPackageRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldPackageRead
     */
    '@type'?: string;
    /**
     * 
     * @type {TblTypeDocumentJsonldPackageRead}
     * @memberof TblRelTypeDocumentJsonldPackageRead
     */
    'typeDocument'?: TblTypeDocumentJsonldPackageRead | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentJsonldPackageUpdate
 */
export interface TblRelTypeDocumentJsonldPackageUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelTypeDocumentJsonldPackageUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldPackageUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelTypeDocumentJsonldPackageUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {TblTypeDocumentJsonldPackageUpdate}
     * @memberof TblRelTypeDocumentJsonldPackageUpdate
     */
    'typeDocument'?: TblTypeDocumentJsonldPackageUpdate | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentOptionRead
 */
export interface TblRelTypeDocumentOptionRead {
    /**
     * 
     * @type {TblTypeDocumentOptionRead}
     * @memberof TblRelTypeDocumentOptionRead
     */
    'typeDocument'?: TblTypeDocumentOptionRead | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentOptionValueCreate
 */
export interface TblRelTypeDocumentOptionValueCreate {
    /**
     * 
     * @type {number}
     * @memberof TblRelTypeDocumentOptionValueCreate
     */
    'id'?: number;
    /**
     * 
     * @type {TblTypeDocumentOptionValueCreate}
     * @memberof TblRelTypeDocumentOptionValueCreate
     */
    'typeDocument'?: TblTypeDocumentOptionValueCreate | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentOptionValueRead
 */
export interface TblRelTypeDocumentOptionValueRead {
    /**
     * 
     * @type {number}
     * @memberof TblRelTypeDocumentOptionValueRead
     */
    'id'?: number;
    /**
     * 
     * @type {TblTypeDocumentOptionValueRead}
     * @memberof TblRelTypeDocumentOptionValueRead
     */
    'typeDocument'?: TblTypeDocumentOptionValueRead | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentOptionValueUpdate
 */
export interface TblRelTypeDocumentOptionValueUpdate {
    /**
     * 
     * @type {number}
     * @memberof TblRelTypeDocumentOptionValueUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {TblTypeDocumentOptionValueUpdate}
     * @memberof TblRelTypeDocumentOptionValueUpdate
     */
    'typeDocument'?: TblTypeDocumentOptionValueUpdate | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentPackageCreate
 */
export interface TblRelTypeDocumentPackageCreate {
    /**
     * 
     * @type {TblTypeDocumentPackageCreate}
     * @memberof TblRelTypeDocumentPackageCreate
     */
    'typeDocument'?: TblTypeDocumentPackageCreate | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentPackageRead
 */
export interface TblRelTypeDocumentPackageRead {
    /**
     * 
     * @type {TblTypeDocumentPackageRead}
     * @memberof TblRelTypeDocumentPackageRead
     */
    'typeDocument'?: TblTypeDocumentPackageRead | null;
}
/**
 * 
 * @export
 * @interface TblRelTypeDocumentPackageUpdate
 */
export interface TblRelTypeDocumentPackageUpdate {
    /**
     * 
     * @type {TblTypeDocumentPackageUpdate}
     * @memberof TblRelTypeDocumentPackageUpdate
     */
    'typeDocument'?: TblTypeDocumentPackageUpdate | null;
}
/**
 * 
 * @export
 * @interface TblRelationDomaineOrigineJsonldDomaineRead
 */
export interface TblRelationDomaineOrigineJsonldDomaineRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelationDomaineOrigineJsonldDomaineRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelationDomaineOrigineJsonldDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelationDomaineOrigineJsonldDomaineRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblRelationDomaineOrigineJsonldOrigineRead
 */
export interface TblRelationDomaineOrigineJsonldOrigineRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRelationDomaineOrigineJsonldOrigineRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRelationDomaineOrigineJsonldOrigineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRelationDomaineOrigineJsonldOrigineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {TblDefDomaineJsonldOrigineRead}
     * @memberof TblRelationDomaineOrigineJsonldOrigineRead
     */
    'domaine'?: TblDefDomaineJsonldOrigineRead | null;
}
/**
 * 
 * @export
 * @interface TblRelationDomaineOrigineOrigineRead
 */
export interface TblRelationDomaineOrigineOrigineRead {
    /**
     * 
     * @type {TblDefDomaineOrigineRead}
     * @memberof TblRelationDomaineOrigineOrigineRead
     */
    'domaine'?: TblDefDomaineOrigineRead | null;
}
/**
 * 
 * @export
 * @interface TblRubFacDomaineVentesJsonldRubriqueFacturationRead
 */
export interface TblRubFacDomaineVentesJsonldRubriqueFacturationRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblRubFacDomaineVentesJsonldRubriqueFacturationRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRubFacDomaineVentesJsonldRubriqueFacturationRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRubFacDomaineVentesJsonldRubriqueFacturationRead
     */
    '@type'?: string;
    /**
     * 
     * @type {TblDefDomaineJsonldRubriqueFacturationRead}
     * @memberof TblRubFacDomaineVentesJsonldRubriqueFacturationRead
     */
    'domaine'?: TblDefDomaineJsonldRubriqueFacturationRead | null;
}
/**
 * 
 * @export
 * @interface TblRubFacDomaineVentesRubriqueFacturationRead
 */
export interface TblRubFacDomaineVentesRubriqueFacturationRead {
    /**
     * 
     * @type {TblDefDomaineRubriqueFacturationRead}
     * @memberof TblRubFacDomaineVentesRubriqueFacturationRead
     */
    'domaine'?: TblDefDomaineRubriqueFacturationRead | null;
}
/**
 * 
 * @export
 * @interface TblSocieteArticleRead
 */
export interface TblSocieteArticleRead {
    /**
     * 
     * @type {number}
     * @memberof TblSocieteArticleRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteArticleRead
     */
    'raisonSociale'?: string;
}
/**
 * 
 * @export
 * @interface TblSocieteJsonldArticleRead
 */
export interface TblSocieteJsonldArticleRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblSocieteJsonldArticleRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonldArticleRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonldArticleRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblSocieteJsonldArticleRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonldArticleRead
     */
    'raisonSociale'?: string;
}
/**
 * 
 * @export
 * @interface TblSurchargeArticlePackageCatalogueCreate
 */
export interface TblSurchargeArticlePackageCatalogueCreate {
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageCatalogueCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageCatalogueCreate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblRelArticleCatalogueCreate}
     * @memberof TblSurchargeArticlePackageCatalogueCreate
     */
    'relationArticle'?: TblRelArticleCatalogueCreate | null;
    /**
     * 
     * @type {number}
     * @memberof TblSurchargeArticlePackageCatalogueCreate
     */
    'quantite'?: number | null;
}
/**
 * 
 * @export
 * @interface TblSurchargeArticlePackageCatalogueRead
 */
export interface TblSurchargeArticlePackageCatalogueRead {
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageCatalogueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageCatalogueRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblRelArticleCatalogueRead}
     * @memberof TblSurchargeArticlePackageCatalogueRead
     */
    'relationArticle'?: TblRelArticleCatalogueRead | null;
    /**
     * 
     * @type {number}
     * @memberof TblSurchargeArticlePackageCatalogueRead
     */
    'quantite'?: number | null;
}
/**
 * 
 * @export
 * @interface TblSurchargeArticlePackageCatalogueUpdate
 */
export interface TblSurchargeArticlePackageCatalogueUpdate {
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageCatalogueUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageCatalogueUpdate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblSurchargeArticlePackageCatalogueUpdate
     */
    'relationArticle'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblSurchargeArticlePackageCatalogueUpdate
     */
    'quantite'?: number | null;
}
/**
 * 
 * @export
 * @interface TblSurchargeArticlePackageJsonldCatalogueCreate
 */
export interface TblSurchargeArticlePackageJsonldCatalogueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueCreate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblRelArticleJsonldCatalogueCreate}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueCreate
     */
    'relationArticle'?: TblRelArticleJsonldCatalogueCreate | null;
    /**
     * 
     * @type {number}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueCreate
     */
    'quantite'?: number | null;
}
/**
 * 
 * @export
 * @interface TblSurchargeArticlePackageJsonldCatalogueRead
 */
export interface TblSurchargeArticlePackageJsonldCatalogueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueRead
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {TblRelArticleJsonldCatalogueRead}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueRead
     */
    'relationArticle'?: TblRelArticleJsonldCatalogueRead | null;
    /**
     * 
     * @type {number}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueRead
     */
    'quantite'?: number | null;
}
/**
 * 
 * @export
 * @interface TblSurchargeArticlePackageJsonldCatalogueUpdate
 */
export interface TblSurchargeArticlePackageJsonldCatalogueUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueUpdate
     */
    'tarif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueUpdate
     */
    'relationArticle'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblSurchargeArticlePackageJsonldCatalogueUpdate
     */
    'quantite'?: number | null;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentArticleCreate
 */
export interface TblTypeDocumentArticleCreate {
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentArticleCreate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentArticleRead
 */
export interface TblTypeDocumentArticleRead {
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentArticleRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentArticleRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Text}
     * @memberof TblTypeDocumentArticleRead
     */
    'help'?: Text | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentArticleRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentArticleRead
     */
    'fichierHelp'?: string | null;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentCatalogueRead
 */
export interface TblTypeDocumentCatalogueRead {
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentCatalogueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Text}
     * @memberof TblTypeDocumentCatalogueRead
     */
    'help'?: Text | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentCatalogueRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentCatalogueRead
     */
    'fichierHelp'?: string | null;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentJsonldArticleCreate
 */
export interface TblTypeDocumentJsonldArticleCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblTypeDocumentJsonldArticleCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldArticleCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldArticleCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldArticleCreate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentJsonldArticleRead
 */
export interface TblTypeDocumentJsonldArticleRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblTypeDocumentJsonldArticleRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldArticleRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldArticleRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldArticleRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldArticleRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Text}
     * @memberof TblTypeDocumentJsonldArticleRead
     */
    'help'?: Text | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldArticleRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldArticleRead
     */
    'fichierHelp'?: string | null;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentJsonldCatalogueRead
 */
export interface TblTypeDocumentJsonldCatalogueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblTypeDocumentJsonldCatalogueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldCatalogueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldCatalogueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldCatalogueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldCatalogueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Text}
     * @memberof TblTypeDocumentJsonldCatalogueRead
     */
    'help'?: Text | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldCatalogueRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldCatalogueRead
     */
    'fichierHelp'?: string | null;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentJsonldOptionRead
 */
export interface TblTypeDocumentJsonldOptionRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblTypeDocumentJsonldOptionRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Text}
     * @memberof TblTypeDocumentJsonldOptionRead
     */
    'help'?: Text | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldOptionRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionRead
     */
    'fichierHelp'?: string | null;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentJsonldOptionValueCreate
 */
export interface TblTypeDocumentJsonldOptionValueCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblTypeDocumentJsonldOptionValueCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldOptionValueCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Text}
     * @memberof TblTypeDocumentJsonldOptionValueCreate
     */
    'help'?: Text | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueCreate
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldOptionValueCreate
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldOptionValueCreate
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueCreate
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldOptionValueCreate
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueCreate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldOptionValueCreate
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldOptionValueCreate
     */
    'signable'?: boolean;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentJsonldOptionValueRead
 */
export interface TblTypeDocumentJsonldOptionValueRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblTypeDocumentJsonldOptionValueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldOptionValueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Text}
     * @memberof TblTypeDocumentJsonldOptionValueRead
     */
    'help'?: Text | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldOptionValueRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldOptionValueRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueRead
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldOptionValueRead
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueRead
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldOptionValueRead
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldOptionValueRead
     */
    'signable'?: boolean;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentJsonldOptionValueUpdate
 */
export interface TblTypeDocumentJsonldOptionValueUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblTypeDocumentJsonldOptionValueUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldOptionValueUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Text}
     * @memberof TblTypeDocumentJsonldOptionValueUpdate
     */
    'help'?: Text | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueUpdate
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldOptionValueUpdate
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldOptionValueUpdate
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueUpdate
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldOptionValueUpdate
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldOptionValueUpdate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldOptionValueUpdate
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldOptionValueUpdate
     */
    'signable'?: boolean;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentJsonldPackageCreate
 */
export interface TblTypeDocumentJsonldPackageCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblTypeDocumentJsonldPackageCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldPackageCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldPackageCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldPackageCreate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentJsonldPackageRead
 */
export interface TblTypeDocumentJsonldPackageRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblTypeDocumentJsonldPackageRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldPackageRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldPackageRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldPackageRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldPackageRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Text}
     * @memberof TblTypeDocumentJsonldPackageRead
     */
    'help'?: Text | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldPackageRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldPackageRead
     */
    'fichierHelp'?: string | null;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentJsonldPackageUpdate
 */
export interface TblTypeDocumentJsonldPackageUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblTypeDocumentJsonldPackageUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldPackageUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldPackageUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldPackageUpdate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentJsonldTypedocumentRead
 */
export interface TblTypeDocumentJsonldTypedocumentRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblTypeDocumentJsonldTypedocumentRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldTypedocumentRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldTypedocumentRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldTypedocumentRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldTypedocumentRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Text}
     * @memberof TblTypeDocumentJsonldTypedocumentRead
     */
    'help'?: Text | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldTypedocumentRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldTypedocumentRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldTypedocumentRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldTypedocumentRead
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldTypedocumentRead
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldTypedocumentRead
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldTypedocumentRead
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldTypedocumentRead
     */
    'signable'?: boolean;
    /**
     * 
     * @type {TblDefDomaineJsonldTypedocumentRead}
     * @memberof TblTypeDocumentJsonldTypedocumentRead
     */
    'domaine'?: TblDefDomaineJsonldTypedocumentRead | null;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentOptionRead
 */
export interface TblTypeDocumentOptionRead {
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentOptionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentOptionRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Text}
     * @memberof TblTypeDocumentOptionRead
     */
    'help'?: Text | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentOptionRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentOptionRead
     */
    'fichierHelp'?: string | null;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentOptionValueCreate
 */
export interface TblTypeDocumentOptionValueCreate {
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentOptionValueCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentOptionValueCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Text}
     * @memberof TblTypeDocumentOptionValueCreate
     */
    'help'?: Text | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentOptionValueCreate
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentOptionValueCreate
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentOptionValueCreate
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentOptionValueCreate
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentOptionValueCreate
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentOptionValueCreate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentOptionValueCreate
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentOptionValueCreate
     */
    'signable'?: boolean;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentOptionValueRead
 */
export interface TblTypeDocumentOptionValueRead {
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentOptionValueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentOptionValueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Text}
     * @memberof TblTypeDocumentOptionValueRead
     */
    'help'?: Text | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentOptionValueRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentOptionValueRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentOptionValueRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentOptionValueRead
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentOptionValueRead
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentOptionValueRead
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentOptionValueRead
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentOptionValueRead
     */
    'signable'?: boolean;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentOptionValueUpdate
 */
export interface TblTypeDocumentOptionValueUpdate {
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentOptionValueUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentOptionValueUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Text}
     * @memberof TblTypeDocumentOptionValueUpdate
     */
    'help'?: Text | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentOptionValueUpdate
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentOptionValueUpdate
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentOptionValueUpdate
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentOptionValueUpdate
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentOptionValueUpdate
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentOptionValueUpdate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentOptionValueUpdate
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentOptionValueUpdate
     */
    'signable'?: boolean;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentPackageCreate
 */
export interface TblTypeDocumentPackageCreate {
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentPackageCreate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentPackageRead
 */
export interface TblTypeDocumentPackageRead {
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentPackageRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentPackageRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Text}
     * @memberof TblTypeDocumentPackageRead
     */
    'help'?: Text | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentPackageRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentPackageRead
     */
    'fichierHelp'?: string | null;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentPackageUpdate
 */
export interface TblTypeDocumentPackageUpdate {
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentPackageUpdate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentTypedocumentRead
 */
export interface TblTypeDocumentTypedocumentRead {
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentTypedocumentRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentTypedocumentRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {Text}
     * @memberof TblTypeDocumentTypedocumentRead
     */
    'help'?: Text | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentTypedocumentRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentTypedocumentRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentTypedocumentRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentTypedocumentRead
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentTypedocumentRead
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentTypedocumentRead
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentTypedocumentRead
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentTypedocumentRead
     */
    'signable'?: boolean;
    /**
     * 
     * @type {TblDefDomaineTypedocumentRead}
     * @memberof TblTypeDocumentTypedocumentRead
     */
    'domaine'?: TblDefDomaineTypedocumentRead | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentJsonldTypedocumentCreate
 */
export interface TypeDeDocumentJsonldTypedocumentCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {Text}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'help'?: Text | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'name': string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'type': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'fichierHelp': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'signable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'domaine'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentJsonldTypedocumentCreated
 */
export interface TypeDeDocumentJsonldTypedocumentCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'libelle': string | null;
    /**
     * 
     * @type {Text}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'help'?: Text | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'name': string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'type': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'fichierHelp': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'signable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'domaine'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentJsonldTypedocumentRead
 */
export interface TypeDeDocumentJsonldTypedocumentRead {
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'package'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'option'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'article'?: string | null;
    /**
     * 
     * @type {TblTypeDocumentJsonldTypedocumentRead}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'typeDocument'?: TblTypeDocumentJsonldTypedocumentRead | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentJsonldTypedocumentUpdate
 */
export interface TypeDeDocumentJsonldTypedocumentUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'libelle': string | null;
    /**
     * 
     * @type {Text}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'help'?: Text | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'name': string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'type': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'fichierHelp': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'signable'?: boolean;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentTypedocumentCreate
 */
export interface TypeDeDocumentTypedocumentCreate {
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {Text}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'help'?: Text | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'name': string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'type': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'fichierHelp': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'signable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'domaine'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentTypedocumentCreated
 */
export interface TypeDeDocumentTypedocumentCreated {
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'libelle': string | null;
    /**
     * 
     * @type {Text}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'help'?: Text | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'name': string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'type': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'fichierHelp': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'signable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'domaine'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentTypedocumentRead
 */
export interface TypeDeDocumentTypedocumentRead {
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'package'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'option'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'catalogue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'article'?: string | null;
    /**
     * 
     * @type {TblTypeDocumentTypedocumentRead}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'typeDocument'?: TblTypeDocumentTypedocumentRead | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentTypedocumentUpdate
 */
export interface TypeDeDocumentTypedocumentUpdate {
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'libelle': string | null;
    /**
     * 
     * @type {Text}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'help'?: Text | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'name': string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'type': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'fichierHelp': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'signable'?: boolean;
}
/**
 * 
 * @export
 * @interface TypeTvaJsonldTvaRead
 */
export interface TypeTvaJsonldTvaRead {
    /**
     * 
     * @type {string}
     * @memberof TypeTvaJsonldTvaRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeTvaJsonldTvaRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeTvaJsonldTvaRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof TypeTvaJsonldTvaRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeTvaJsonldTvaRead
     */
    'regime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeTvaJsonldTvaRead
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeTvaJsonldTvaRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeTvaJsonldTvaRead
     */
    'tauxAchat'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TypeTvaJsonldTvaRead
     */
    'compteAchat'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeTvaJsonldTvaRead
     */
    'tauxVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TypeTvaJsonldTvaRead
     */
    'compteVente'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeTvaTvaRead
 */
export interface TypeTvaTvaRead {
    /**
     * 
     * @type {number}
     * @memberof TypeTvaTvaRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeTvaTvaRead
     */
    'regime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeTvaTvaRead
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeTvaTvaRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeTvaTvaRead
     */
    'tauxAchat'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TypeTvaTvaRead
     */
    'compteAchat'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeTvaTvaRead
     */
    'tauxVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TypeTvaTvaRead
     */
    'compteVente'?: string | null;
}

/**
 * ActionDetailApi - axios parameter creator
 * @export
 */
export const ActionDetailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des action-details
         * @summary Récupérer la liste des action-details
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionDetailsGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/action-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une action-detail
         * @summary Récupérer les informations d\'une action-detail
         * @param {string} id TblActionDetail identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionDetailsIdGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiActionDetailsIdGet', 'id', id)
            const localVarPath = `/offre/action-details/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionDetailApi - functional programming interface
 * @export
 */
export const ActionDetailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionDetailApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des action-details
         * @summary Récupérer la liste des action-details
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActionDetailsGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiActionDetailsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActionDetailsGetCollection(xIdSociete, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActionDetailApi.apiActionDetailsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une action-detail
         * @summary Récupérer les informations d\'une action-detail
         * @param {string} id TblActionDetail identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActionDetailsIdGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionDetailJsonldActionDetailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActionDetailsIdGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActionDetailApi.apiActionDetailsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ActionDetailApi - factory interface
 * @export
 */
export const ActionDetailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionDetailApiFp(configuration)
    return {
        /**
         * Récupérer la liste des action-details
         * @summary Récupérer la liste des action-details
         * @param {ActionDetailApiApiActionDetailsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionDetailsGetCollection(requestParameters: ActionDetailApiApiActionDetailsGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiActionDetailsGetCollection200Response> {
            return localVarFp.apiActionDetailsGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une action-detail
         * @summary Récupérer les informations d\'une action-detail
         * @param {ActionDetailApiApiActionDetailsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionDetailsIdGet(requestParameters: ActionDetailApiApiActionDetailsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ActionDetailJsonldActionDetailRead> {
            return localVarFp.apiActionDetailsIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiActionDetailsGetCollection operation in ActionDetailApi.
 * @export
 * @interface ActionDetailApiApiActionDetailsGetCollectionRequest
 */
export interface ActionDetailApiApiActionDetailsGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ActionDetailApiApiActionDetailsGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof ActionDetailApiApiActionDetailsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ActionDetailApiApiActionDetailsGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiActionDetailsIdGet operation in ActionDetailApi.
 * @export
 * @interface ActionDetailApiApiActionDetailsIdGetRequest
 */
export interface ActionDetailApiApiActionDetailsIdGetRequest {
    /**
     * TblActionDetail identifier
     * @type {string}
     * @memberof ActionDetailApiApiActionDetailsIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ActionDetailApiApiActionDetailsIdGet
     */
    readonly xIdSociete?: number
}

/**
 * ActionDetailApi - object-oriented interface
 * @export
 * @class ActionDetailApi
 * @extends {BaseAPI}
 */
export class ActionDetailApi extends BaseAPI {
    /**
     * Récupérer la liste des action-details
     * @summary Récupérer la liste des action-details
     * @param {ActionDetailApiApiActionDetailsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionDetailApi
     */
    public apiActionDetailsGetCollection(requestParameters: ActionDetailApiApiActionDetailsGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return ActionDetailApiFp(this.configuration).apiActionDetailsGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une action-detail
     * @summary Récupérer les informations d\'une action-detail
     * @param {ActionDetailApiApiActionDetailsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionDetailApi
     */
    public apiActionDetailsIdGet(requestParameters: ActionDetailApiApiActionDetailsIdGetRequest, options?: RawAxiosRequestConfig) {
        return ActionDetailApiFp(this.configuration).apiActionDetailsIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ArticlesApi - axios parameter creator
 * @export
 */
export const ArticlesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les informations des articles spécifiques LIB
         * @summary Récupérer les informations des articles spécifiques LIB
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticleLibGet: async (xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/article-lib`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des articles
         * @summary Récupérer la liste des articles
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [libelle] 
         * @param {string} [codeArticle] 
         * @param {string} [cegidCompte] 
         * @param {string} [rubArticle] 
         * @param {number} [domaineId] 
         * @param {Array<number>} [domaineId2] 
         * @param {boolean} [deleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, libelle?: string, codeArticle?: string, cegidCompte?: string, rubArticle?: string, domaineId?: number, domaineId2?: Array<number>, deleted?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (libelle !== undefined) {
                localVarQueryParameter['libelle'] = libelle;
            }

            if (codeArticle !== undefined) {
                localVarQueryParameter['codeArticle'] = codeArticle;
            }

            if (cegidCompte !== undefined) {
                localVarQueryParameter['cegidCompte'] = cegidCompte;
            }

            if (rubArticle !== undefined) {
                localVarQueryParameter['rubArticle'] = rubArticle;
            }

            if (domaineId !== undefined) {
                localVarQueryParameter['domaine.id'] = domaineId;
            }

            if (domaineId2) {
                localVarQueryParameter['domaine.id[]'] = domaineId2;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer un article
         * @summary Supprimer un article
         * @param {string} id TblFactArticles identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesIdDelete: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiArticlesIdDelete', 'id', id)
            const localVarPath = `/offre/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les ressources dépendante d\'un article
         * @summary Lister les ressources dépendante d\'un article
         * @param {string} id TblFactArticles identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesIdDependenciesGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiArticlesIdDependenciesGet', 'id', id)
            const localVarPath = `/offre/articles/{id}/dependencies`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un article
         * @summary Récupérer les informations d\'un article
         * @param {string} id TblFactArticles identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesIdGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiArticlesIdGet', 'id', id)
            const localVarPath = `/offre/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les informations d\'un article
         * @summary Mettre à jour les informations d\'un article
         * @param {string} id TblFactArticles identifier
         * @param {ArticlesJsonldArticleUpdate} articlesJsonldArticleUpdate The updated Articles resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesIdPut: async (id: string, articlesJsonldArticleUpdate: ArticlesJsonldArticleUpdate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiArticlesIdPut', 'id', id)
            // verify required parameter 'articlesJsonldArticleUpdate' is not null or undefined
            assertParamExists('apiArticlesIdPut', 'articlesJsonldArticleUpdate', articlesJsonldArticleUpdate)
            const localVarPath = `/offre/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articlesJsonldArticleUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer un article
         * @summary Créer un article
         * @param {ArticlesJsonldArticleCreate} articlesJsonldArticleCreate The new Articles resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesPost: async (articlesJsonldArticleCreate: ArticlesJsonldArticleCreate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articlesJsonldArticleCreate' is not null or undefined
            assertParamExists('apiArticlesPost', 'articlesJsonldArticleCreate', articlesJsonldArticleCreate)
            const localVarPath = `/offre/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articlesJsonldArticleCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticlesApi - functional programming interface
 * @export
 */
export const ArticlesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticlesApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les informations des articles spécifiques LIB
         * @summary Récupérer les informations des articles spécifiques LIB
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiArticleLibGet(xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesJsonldArticleLibRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiArticleLibGet(xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticlesApi.apiArticleLibGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer la liste des articles
         * @summary Récupérer la liste des articles
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [libelle] 
         * @param {string} [codeArticle] 
         * @param {string} [cegidCompte] 
         * @param {string} [rubArticle] 
         * @param {number} [domaineId] 
         * @param {Array<number>} [domaineId2] 
         * @param {boolean} [deleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiArticlesGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, libelle?: string, codeArticle?: string, cegidCompte?: string, rubArticle?: string, domaineId?: number, domaineId2?: Array<number>, deleted?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiArticlesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiArticlesGetCollection(xIdSociete, page, itemsPerPage, libelle, codeArticle, cegidCompte, rubArticle, domaineId, domaineId2, deleted, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticlesApi.apiArticlesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Supprimer un article
         * @summary Supprimer un article
         * @param {string} id TblFactArticles identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiArticlesIdDelete(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiArticlesIdDelete(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticlesApi.apiArticlesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lister les ressources dépendante d\'un article
         * @summary Lister les ressources dépendante d\'un article
         * @param {string} id TblFactArticles identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiArticlesIdDependenciesGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesResourceDependencyJsonldDependencyRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiArticlesIdDependenciesGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticlesApi.apiArticlesIdDependenciesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un article
         * @summary Récupérer les informations d\'un article
         * @param {string} id TblFactArticles identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiArticlesIdGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesJsonldArticleRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiArticlesIdGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticlesApi.apiArticlesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour les informations d\'un article
         * @summary Mettre à jour les informations d\'un article
         * @param {string} id TblFactArticles identifier
         * @param {ArticlesJsonldArticleUpdate} articlesJsonldArticleUpdate The updated Articles resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiArticlesIdPut(id: string, articlesJsonldArticleUpdate: ArticlesJsonldArticleUpdate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesJsonldArticleRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiArticlesIdPut(id, articlesJsonldArticleUpdate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticlesApi.apiArticlesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Créer un article
         * @summary Créer un article
         * @param {ArticlesJsonldArticleCreate} articlesJsonldArticleCreate The new Articles resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiArticlesPost(articlesJsonldArticleCreate: ArticlesJsonldArticleCreate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesJsonldArticleRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiArticlesPost(articlesJsonldArticleCreate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticlesApi.apiArticlesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ArticlesApi - factory interface
 * @export
 */
export const ArticlesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticlesApiFp(configuration)
    return {
        /**
         * Récupérer les informations des articles spécifiques LIB
         * @summary Récupérer les informations des articles spécifiques LIB
         * @param {ArticlesApiApiArticleLibGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticleLibGet(requestParameters: ArticlesApiApiArticleLibGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ArticlesJsonldArticleLibRead> {
            return localVarFp.apiArticleLibGet(requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des articles
         * @summary Récupérer la liste des articles
         * @param {ArticlesApiApiArticlesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesGetCollection(requestParameters: ArticlesApiApiArticlesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiArticlesGetCollection200Response> {
            return localVarFp.apiArticlesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.libelle, requestParameters.codeArticle, requestParameters.cegidCompte, requestParameters.rubArticle, requestParameters.domaineId, requestParameters.domaineId2, requestParameters.deleted, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer un article
         * @summary Supprimer un article
         * @param {ArticlesApiApiArticlesIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesIdDelete(requestParameters: ArticlesApiApiArticlesIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiArticlesIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les ressources dépendante d\'un article
         * @summary Lister les ressources dépendante d\'un article
         * @param {ArticlesApiApiArticlesIdDependenciesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesIdDependenciesGet(requestParameters: ArticlesApiApiArticlesIdDependenciesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ArticlesResourceDependencyJsonldDependencyRead> {
            return localVarFp.apiArticlesIdDependenciesGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un article
         * @summary Récupérer les informations d\'un article
         * @param {ArticlesApiApiArticlesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesIdGet(requestParameters: ArticlesApiApiArticlesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ArticlesJsonldArticleRead> {
            return localVarFp.apiArticlesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les informations d\'un article
         * @summary Mettre à jour les informations d\'un article
         * @param {ArticlesApiApiArticlesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesIdPut(requestParameters: ArticlesApiApiArticlesIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ArticlesJsonldArticleRead> {
            return localVarFp.apiArticlesIdPut(requestParameters.id, requestParameters.articlesJsonldArticleUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer un article
         * @summary Créer un article
         * @param {ArticlesApiApiArticlesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArticlesPost(requestParameters: ArticlesApiApiArticlesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ArticlesJsonldArticleRead> {
            return localVarFp.apiArticlesPost(requestParameters.articlesJsonldArticleCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiArticleLibGet operation in ArticlesApi.
 * @export
 * @interface ArticlesApiApiArticleLibGetRequest
 */
export interface ArticlesApiApiArticleLibGetRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ArticlesApiApiArticleLibGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiArticlesGetCollection operation in ArticlesApi.
 * @export
 * @interface ArticlesApiApiArticlesGetCollectionRequest
 */
export interface ArticlesApiApiArticlesGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ArticlesApiApiArticlesGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof ArticlesApiApiArticlesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ArticlesApiApiArticlesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof ArticlesApiApiArticlesGetCollection
     */
    readonly libelle?: string

    /**
     * 
     * @type {string}
     * @memberof ArticlesApiApiArticlesGetCollection
     */
    readonly codeArticle?: string

    /**
     * 
     * @type {string}
     * @memberof ArticlesApiApiArticlesGetCollection
     */
    readonly cegidCompte?: string

    /**
     * 
     * @type {string}
     * @memberof ArticlesApiApiArticlesGetCollection
     */
    readonly rubArticle?: string

    /**
     * 
     * @type {number}
     * @memberof ArticlesApiApiArticlesGetCollection
     */
    readonly domaineId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof ArticlesApiApiArticlesGetCollection
     */
    readonly domaineId2?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof ArticlesApiApiArticlesGetCollection
     */
    readonly deleted?: boolean
}

/**
 * Request parameters for apiArticlesIdDelete operation in ArticlesApi.
 * @export
 * @interface ArticlesApiApiArticlesIdDeleteRequest
 */
export interface ArticlesApiApiArticlesIdDeleteRequest {
    /**
     * TblFactArticles identifier
     * @type {string}
     * @memberof ArticlesApiApiArticlesIdDelete
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ArticlesApiApiArticlesIdDelete
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiArticlesIdDependenciesGet operation in ArticlesApi.
 * @export
 * @interface ArticlesApiApiArticlesIdDependenciesGetRequest
 */
export interface ArticlesApiApiArticlesIdDependenciesGetRequest {
    /**
     * TblFactArticles identifier
     * @type {string}
     * @memberof ArticlesApiApiArticlesIdDependenciesGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ArticlesApiApiArticlesIdDependenciesGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiArticlesIdGet operation in ArticlesApi.
 * @export
 * @interface ArticlesApiApiArticlesIdGetRequest
 */
export interface ArticlesApiApiArticlesIdGetRequest {
    /**
     * TblFactArticles identifier
     * @type {string}
     * @memberof ArticlesApiApiArticlesIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ArticlesApiApiArticlesIdGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiArticlesIdPut operation in ArticlesApi.
 * @export
 * @interface ArticlesApiApiArticlesIdPutRequest
 */
export interface ArticlesApiApiArticlesIdPutRequest {
    /**
     * TblFactArticles identifier
     * @type {string}
     * @memberof ArticlesApiApiArticlesIdPut
     */
    readonly id: string

    /**
     * The updated Articles resource
     * @type {ArticlesJsonldArticleUpdate}
     * @memberof ArticlesApiApiArticlesIdPut
     */
    readonly articlesJsonldArticleUpdate: ArticlesJsonldArticleUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ArticlesApiApiArticlesIdPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiArticlesPost operation in ArticlesApi.
 * @export
 * @interface ArticlesApiApiArticlesPostRequest
 */
export interface ArticlesApiApiArticlesPostRequest {
    /**
     * The new Articles resource
     * @type {ArticlesJsonldArticleCreate}
     * @memberof ArticlesApiApiArticlesPost
     */
    readonly articlesJsonldArticleCreate: ArticlesJsonldArticleCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ArticlesApiApiArticlesPost
     */
    readonly xIdSociete?: number
}

/**
 * ArticlesApi - object-oriented interface
 * @export
 * @class ArticlesApi
 * @extends {BaseAPI}
 */
export class ArticlesApi extends BaseAPI {
    /**
     * Récupérer les informations des articles spécifiques LIB
     * @summary Récupérer les informations des articles spécifiques LIB
     * @param {ArticlesApiApiArticleLibGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApi
     */
    public apiArticleLibGet(requestParameters: ArticlesApiApiArticleLibGetRequest = {}, options?: RawAxiosRequestConfig) {
        return ArticlesApiFp(this.configuration).apiArticleLibGet(requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des articles
     * @summary Récupérer la liste des articles
     * @param {ArticlesApiApiArticlesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApi
     */
    public apiArticlesGetCollection(requestParameters: ArticlesApiApiArticlesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return ArticlesApiFp(this.configuration).apiArticlesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.libelle, requestParameters.codeArticle, requestParameters.cegidCompte, requestParameters.rubArticle, requestParameters.domaineId, requestParameters.domaineId2, requestParameters.deleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer un article
     * @summary Supprimer un article
     * @param {ArticlesApiApiArticlesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApi
     */
    public apiArticlesIdDelete(requestParameters: ArticlesApiApiArticlesIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return ArticlesApiFp(this.configuration).apiArticlesIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les ressources dépendante d\'un article
     * @summary Lister les ressources dépendante d\'un article
     * @param {ArticlesApiApiArticlesIdDependenciesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApi
     */
    public apiArticlesIdDependenciesGet(requestParameters: ArticlesApiApiArticlesIdDependenciesGetRequest, options?: RawAxiosRequestConfig) {
        return ArticlesApiFp(this.configuration).apiArticlesIdDependenciesGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un article
     * @summary Récupérer les informations d\'un article
     * @param {ArticlesApiApiArticlesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApi
     */
    public apiArticlesIdGet(requestParameters: ArticlesApiApiArticlesIdGetRequest, options?: RawAxiosRequestConfig) {
        return ArticlesApiFp(this.configuration).apiArticlesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les informations d\'un article
     * @summary Mettre à jour les informations d\'un article
     * @param {ArticlesApiApiArticlesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApi
     */
    public apiArticlesIdPut(requestParameters: ArticlesApiApiArticlesIdPutRequest, options?: RawAxiosRequestConfig) {
        return ArticlesApiFp(this.configuration).apiArticlesIdPut(requestParameters.id, requestParameters.articlesJsonldArticleUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer un article
     * @summary Créer un article
     * @param {ArticlesApiApiArticlesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticlesApi
     */
    public apiArticlesPost(requestParameters: ArticlesApiApiArticlesPostRequest, options?: RawAxiosRequestConfig) {
        return ArticlesApiFp(this.configuration).apiArticlesPost(requestParameters.articlesJsonldArticleCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CatalogueApi - axios parameter creator
 * @export
 */
export const CatalogueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des catalogues
         * @summary Récupérer la liste des catalogues
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [offreId] 
         * @param {Array<number>} [offreId2] 
         * @param {number} [origineId] 
         * @param {Array<number>} [origineId2] 
         * @param {number} [societeId] 
         * @param {Array<number>} [societeId2] 
         * @param {number} [packageId] 
         * @param {Array<number>} [packageId2] 
         * @param {number} [groupementId] 
         * @param {Array<number>} [groupementId2] 
         * @param {string} [nomCommercial] 
         * @param {string} [packageNomCommercial] 
         * @param {string} [groupementLibelle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, offreId?: number, offreId2?: Array<number>, origineId?: number, origineId2?: Array<number>, societeId?: number, societeId2?: Array<number>, packageId?: number, packageId2?: Array<number>, groupementId?: number, groupementId2?: Array<number>, nomCommercial?: string, packageNomCommercial?: string, groupementLibelle?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/catalogues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (offreId !== undefined) {
                localVarQueryParameter['offre.id'] = offreId;
            }

            if (offreId2) {
                localVarQueryParameter['offre.id[]'] = offreId2;
            }

            if (origineId !== undefined) {
                localVarQueryParameter['origine.id'] = origineId;
            }

            if (origineId2) {
                localVarQueryParameter['origine.id[]'] = origineId2;
            }

            if (societeId !== undefined) {
                localVarQueryParameter['societe.id'] = societeId;
            }

            if (societeId2) {
                localVarQueryParameter['societe.id[]'] = societeId2;
            }

            if (packageId !== undefined) {
                localVarQueryParameter['package.id'] = packageId;
            }

            if (packageId2) {
                localVarQueryParameter['package.id[]'] = packageId2;
            }

            if (groupementId !== undefined) {
                localVarQueryParameter['groupement.id'] = groupementId;
            }

            if (groupementId2) {
                localVarQueryParameter['groupement.id[]'] = groupementId2;
            }

            if (nomCommercial !== undefined) {
                localVarQueryParameter['nomCommercial'] = nomCommercial;
            }

            if (packageNomCommercial !== undefined) {
                localVarQueryParameter['package.nomCommercial'] = packageNomCommercial;
            }

            if (groupementLibelle !== undefined) {
                localVarQueryParameter['groupement.libelle'] = groupementLibelle;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des action à effectuer pour une entrée de catalogue
         * @summary Récupérer la liste des action à effectuer pour une entrée de catalogue
         * @param {string} id TblCatalogue identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {Array<number>} [_options] la liste des identifiants des options choisies
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesIdActionsAEffectuerGetCollection: async (id: string, xIdSociete?: number, _options?: Array<number>, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCataloguesIdActionsAEffectuerGetCollection', 'id', id)
            const localVarPath = `/offre/catalogues/{id}/actions-a-effectuer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (_options) {
                localVarQueryParameter['options'] = _options.join(COLLECTION_FORMATS.csv);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer un catalogue
         * @summary Supprimer un catalogue
         * @param {string} id TblCatalogue identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesIdDelete: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCataloguesIdDelete', 'id', id)
            const localVarPath = `/offre/catalogues/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les ressources dépendante d\'un catalogue
         * @summary Lister les ressources dépendante d\'un catalogue
         * @param {string} id TblCatalogue identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesIdDependenciesGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCataloguesIdDependenciesGet', 'id', id)
            const localVarPath = `/offre/catalogues/{id}/dependencies`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des documents à fournir pour une entrée de catalogue
         * @summary Récupérer la liste des documents à fournir pour une entrée de catalogue
         * @param {string} id TblCatalogue identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {Array<number>} [_options] la liste des identifiants des options choisies
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesIdDocumentsAFournirGetCollection: async (id: string, xIdSociete?: number, _options?: Array<number>, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCataloguesIdDocumentsAFournirGetCollection', 'id', id)
            const localVarPath = `/offre/catalogues/{id}/documents-a-fournir`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (_options) {
                localVarQueryParameter['options'] = _options.join(COLLECTION_FORMATS.csv);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une catalogues
         * @summary Récupérer les informations d\'une catalogue
         * @param {string} id TblCatalogue identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesIdGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCataloguesIdGet', 'id', id)
            const localVarPath = `/offre/catalogues/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les informations d\'un catalogue
         * @summary Mettre à jour les informations d\'un catalogue
         * @param {string} id TblCatalogue identifier
         * @param {CatalogueJsonldCatalogueUpdate} catalogueJsonldCatalogueUpdate The updated Catalogue resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesIdPut: async (id: string, catalogueJsonldCatalogueUpdate: CatalogueJsonldCatalogueUpdate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCataloguesIdPut', 'id', id)
            // verify required parameter 'catalogueJsonldCatalogueUpdate' is not null or undefined
            assertParamExists('apiCataloguesIdPut', 'catalogueJsonldCatalogueUpdate', catalogueJsonldCatalogueUpdate)
            const localVarPath = `/offre/catalogues/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(catalogueJsonldCatalogueUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer un catalogue
         * @summary Créer un catalogue
         * @param {CatalogueJsonldCatalogueCreate} catalogueJsonldCatalogueCreate The new Catalogue resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesPost: async (catalogueJsonldCatalogueCreate: CatalogueJsonldCatalogueCreate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'catalogueJsonldCatalogueCreate' is not null or undefined
            assertParamExists('apiCataloguesPost', 'catalogueJsonldCatalogueCreate', catalogueJsonldCatalogueCreate)
            const localVarPath = `/offre/catalogues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(catalogueJsonldCatalogueCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des catalogues pour un prescripteur et pour une origine
         * @summary Récupérer la liste des catalogues pour un prescripteur et pour une origine
         * @param {number} prescripteur L\&#39;identifiant du prescripteur
         * @param {number} origine L\&#39;identifiant de l\&#39;origine
         * @param {number} domaine L\&#39;identifiant du domaine
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [nomCommercial] Le nom commercial du catalogue
         * @param {string} [packageNomCommercial] Le nom commercial du package
         * @param {string} [groupementLibelle] Le libellé du groupement
         * @param {number} [offreId] 
         * @param {Array<number>} [offreId2] 
         * @param {number} [origineId] 
         * @param {Array<number>} [origineId2] 
         * @param {number} [societeId] 
         * @param {Array<number>} [societeId2] 
         * @param {number} [packageId] 
         * @param {Array<number>} [packageId2] 
         * @param {number} [groupementId] 
         * @param {Array<number>} [groupementId2] 
         * @param {string} [packageNomCommercial2] 
         * @param {string} [groupementLibelle2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrescripteursCataloguesGetCollection: async (prescripteur: number, origine: number, domaine: number, xIdSociete?: number, page?: number, itemsPerPage?: number, nomCommercial?: string, packageNomCommercial?: string, groupementLibelle?: string, offreId?: number, offreId2?: Array<number>, origineId?: number, origineId2?: Array<number>, societeId?: number, societeId2?: Array<number>, packageId?: number, packageId2?: Array<number>, groupementId?: number, groupementId2?: Array<number>, packageNomCommercial2?: string, groupementLibelle2?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prescripteur' is not null or undefined
            assertParamExists('apiPrescripteursCataloguesGetCollection', 'prescripteur', prescripteur)
            // verify required parameter 'origine' is not null or undefined
            assertParamExists('apiPrescripteursCataloguesGetCollection', 'origine', origine)
            // verify required parameter 'domaine' is not null or undefined
            assertParamExists('apiPrescripteursCataloguesGetCollection', 'domaine', domaine)
            const localVarPath = `/offre/prescripteurs/catalogues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (prescripteur !== undefined) {
                localVarQueryParameter['prescripteur'] = prescripteur;
            }

            if (origine !== undefined) {
                localVarQueryParameter['origine'] = origine;
            }

            if (domaine !== undefined) {
                localVarQueryParameter['domaine'] = domaine;
            }

            if (nomCommercial !== undefined) {
                localVarQueryParameter['nomCommercial'] = nomCommercial;
            }

            if (packageNomCommercial !== undefined) {
                localVarQueryParameter['packageNomCommercial'] = packageNomCommercial;
            }

            if (groupementLibelle !== undefined) {
                localVarQueryParameter['groupementLibelle'] = groupementLibelle;
            }

            if (offreId !== undefined) {
                localVarQueryParameter['offre.id'] = offreId;
            }

            if (offreId2) {
                localVarQueryParameter['offre.id[]'] = offreId2;
            }

            if (origineId !== undefined) {
                localVarQueryParameter['origine.id'] = origineId;
            }

            if (origineId2) {
                localVarQueryParameter['origine.id[]'] = origineId2;
            }

            if (societeId !== undefined) {
                localVarQueryParameter['societe.id'] = societeId;
            }

            if (societeId2) {
                localVarQueryParameter['societe.id[]'] = societeId2;
            }

            if (packageId !== undefined) {
                localVarQueryParameter['package.id'] = packageId;
            }

            if (packageId2) {
                localVarQueryParameter['package.id[]'] = packageId2;
            }

            if (groupementId !== undefined) {
                localVarQueryParameter['groupement.id'] = groupementId;
            }

            if (groupementId2) {
                localVarQueryParameter['groupement.id[]'] = groupementId2;
            }

            if (packageNomCommercial2 !== undefined) {
                localVarQueryParameter['package.nomCommercial'] = packageNomCommercial2;
            }

            if (groupementLibelle2 !== undefined) {
                localVarQueryParameter['groupement.libelle'] = groupementLibelle2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CatalogueApi - functional programming interface
 * @export
 */
export const CatalogueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CatalogueApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des catalogues
         * @summary Récupérer la liste des catalogues
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [offreId] 
         * @param {Array<number>} [offreId2] 
         * @param {number} [origineId] 
         * @param {Array<number>} [origineId2] 
         * @param {number} [societeId] 
         * @param {Array<number>} [societeId2] 
         * @param {number} [packageId] 
         * @param {Array<number>} [packageId2] 
         * @param {number} [groupementId] 
         * @param {Array<number>} [groupementId2] 
         * @param {string} [nomCommercial] 
         * @param {string} [packageNomCommercial] 
         * @param {string} [groupementLibelle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCataloguesGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, offreId?: number, offreId2?: Array<number>, origineId?: number, origineId2?: Array<number>, societeId?: number, societeId2?: Array<number>, packageId?: number, packageId2?: Array<number>, groupementId?: number, groupementId2?: Array<number>, nomCommercial?: string, packageNomCommercial?: string, groupementLibelle?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiCataloguesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCataloguesGetCollection(xIdSociete, page, itemsPerPage, offreId, offreId2, origineId, origineId2, societeId, societeId2, packageId, packageId2, groupementId, groupementId2, nomCommercial, packageNomCommercial, groupementLibelle, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogueApi.apiCataloguesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer la liste des action à effectuer pour une entrée de catalogue
         * @summary Récupérer la liste des action à effectuer pour une entrée de catalogue
         * @param {string} id TblCatalogue identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {Array<number>} [_options] la liste des identifiants des options choisies
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCataloguesIdActionsAEffectuerGetCollection(id: string, xIdSociete?: number, _options?: Array<number>, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiCataloguesIdActionsAEffectuerGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCataloguesIdActionsAEffectuerGetCollection(id, xIdSociete, _options, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogueApi.apiCataloguesIdActionsAEffectuerGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Supprimer un catalogue
         * @summary Supprimer un catalogue
         * @param {string} id TblCatalogue identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCataloguesIdDelete(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCataloguesIdDelete(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogueApi.apiCataloguesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lister les ressources dépendante d\'un catalogue
         * @summary Lister les ressources dépendante d\'un catalogue
         * @param {string} id TblCatalogue identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCataloguesIdDependenciesGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogueResourceDependencyJsonldDependencyRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCataloguesIdDependenciesGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogueApi.apiCataloguesIdDependenciesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer la liste des documents à fournir pour une entrée de catalogue
         * @summary Récupérer la liste des documents à fournir pour une entrée de catalogue
         * @param {string} id TblCatalogue identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {Array<number>} [_options] la liste des identifiants des options choisies
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCataloguesIdDocumentsAFournirGetCollection(id: string, xIdSociete?: number, _options?: Array<number>, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiCataloguesIdDocumentsAFournirGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCataloguesIdDocumentsAFournirGetCollection(id, xIdSociete, _options, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogueApi.apiCataloguesIdDocumentsAFournirGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une catalogues
         * @summary Récupérer les informations d\'une catalogue
         * @param {string} id TblCatalogue identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCataloguesIdGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogueJsonldCatalogueRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCataloguesIdGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogueApi.apiCataloguesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour les informations d\'un catalogue
         * @summary Mettre à jour les informations d\'un catalogue
         * @param {string} id TblCatalogue identifier
         * @param {CatalogueJsonldCatalogueUpdate} catalogueJsonldCatalogueUpdate The updated Catalogue resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCataloguesIdPut(id: string, catalogueJsonldCatalogueUpdate: CatalogueJsonldCatalogueUpdate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogueJsonldCatalogueRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCataloguesIdPut(id, catalogueJsonldCatalogueUpdate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogueApi.apiCataloguesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Créer un catalogue
         * @summary Créer un catalogue
         * @param {CatalogueJsonldCatalogueCreate} catalogueJsonldCatalogueCreate The new Catalogue resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCataloguesPost(catalogueJsonldCatalogueCreate: CatalogueJsonldCatalogueCreate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogueJsonldCatalogueRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCataloguesPost(catalogueJsonldCatalogueCreate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogueApi.apiCataloguesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer la liste des catalogues pour un prescripteur et pour une origine
         * @summary Récupérer la liste des catalogues pour un prescripteur et pour une origine
         * @param {number} prescripteur L\&#39;identifiant du prescripteur
         * @param {number} origine L\&#39;identifiant de l\&#39;origine
         * @param {number} domaine L\&#39;identifiant du domaine
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [nomCommercial] Le nom commercial du catalogue
         * @param {string} [packageNomCommercial] Le nom commercial du package
         * @param {string} [groupementLibelle] Le libellé du groupement
         * @param {number} [offreId] 
         * @param {Array<number>} [offreId2] 
         * @param {number} [origineId] 
         * @param {Array<number>} [origineId2] 
         * @param {number} [societeId] 
         * @param {Array<number>} [societeId2] 
         * @param {number} [packageId] 
         * @param {Array<number>} [packageId2] 
         * @param {number} [groupementId] 
         * @param {Array<number>} [groupementId2] 
         * @param {string} [packageNomCommercial2] 
         * @param {string} [groupementLibelle2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrescripteursCataloguesGetCollection(prescripteur: number, origine: number, domaine: number, xIdSociete?: number, page?: number, itemsPerPage?: number, nomCommercial?: string, packageNomCommercial?: string, groupementLibelle?: string, offreId?: number, offreId2?: Array<number>, origineId?: number, origineId2?: Array<number>, societeId?: number, societeId2?: Array<number>, packageId?: number, packageId2?: Array<number>, groupementId?: number, groupementId2?: Array<number>, packageNomCommercial2?: string, groupementLibelle2?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiCataloguesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrescripteursCataloguesGetCollection(prescripteur, origine, domaine, xIdSociete, page, itemsPerPage, nomCommercial, packageNomCommercial, groupementLibelle, offreId, offreId2, origineId, origineId2, societeId, societeId2, packageId, packageId2, groupementId, groupementId2, packageNomCommercial2, groupementLibelle2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CatalogueApi.apiPrescripteursCataloguesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CatalogueApi - factory interface
 * @export
 */
export const CatalogueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CatalogueApiFp(configuration)
    return {
        /**
         * Récupérer la liste des catalogues
         * @summary Récupérer la liste des catalogues
         * @param {CatalogueApiApiCataloguesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesGetCollection(requestParameters: CatalogueApiApiCataloguesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiCataloguesGetCollection200Response> {
            return localVarFp.apiCataloguesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.offreId, requestParameters.offreId2, requestParameters.origineId, requestParameters.origineId2, requestParameters.societeId, requestParameters.societeId2, requestParameters.packageId, requestParameters.packageId2, requestParameters.groupementId, requestParameters.groupementId2, requestParameters.nomCommercial, requestParameters.packageNomCommercial, requestParameters.groupementLibelle, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des action à effectuer pour une entrée de catalogue
         * @summary Récupérer la liste des action à effectuer pour une entrée de catalogue
         * @param {CatalogueApiApiCataloguesIdActionsAEffectuerGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesIdActionsAEffectuerGetCollection(requestParameters: CatalogueApiApiCataloguesIdActionsAEffectuerGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiCataloguesIdActionsAEffectuerGetCollection200Response> {
            return localVarFp.apiCataloguesIdActionsAEffectuerGetCollection(requestParameters.id, requestParameters.xIdSociete, requestParameters._options, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer un catalogue
         * @summary Supprimer un catalogue
         * @param {CatalogueApiApiCataloguesIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesIdDelete(requestParameters: CatalogueApiApiCataloguesIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiCataloguesIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les ressources dépendante d\'un catalogue
         * @summary Lister les ressources dépendante d\'un catalogue
         * @param {CatalogueApiApiCataloguesIdDependenciesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesIdDependenciesGet(requestParameters: CatalogueApiApiCataloguesIdDependenciesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CatalogueResourceDependencyJsonldDependencyRead> {
            return localVarFp.apiCataloguesIdDependenciesGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des documents à fournir pour une entrée de catalogue
         * @summary Récupérer la liste des documents à fournir pour une entrée de catalogue
         * @param {CatalogueApiApiCataloguesIdDocumentsAFournirGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesIdDocumentsAFournirGetCollection(requestParameters: CatalogueApiApiCataloguesIdDocumentsAFournirGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiCataloguesIdDocumentsAFournirGetCollection200Response> {
            return localVarFp.apiCataloguesIdDocumentsAFournirGetCollection(requestParameters.id, requestParameters.xIdSociete, requestParameters._options, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une catalogues
         * @summary Récupérer les informations d\'une catalogue
         * @param {CatalogueApiApiCataloguesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesIdGet(requestParameters: CatalogueApiApiCataloguesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CatalogueJsonldCatalogueRead> {
            return localVarFp.apiCataloguesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les informations d\'un catalogue
         * @summary Mettre à jour les informations d\'un catalogue
         * @param {CatalogueApiApiCataloguesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesIdPut(requestParameters: CatalogueApiApiCataloguesIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<CatalogueJsonldCatalogueRead> {
            return localVarFp.apiCataloguesIdPut(requestParameters.id, requestParameters.catalogueJsonldCatalogueUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer un catalogue
         * @summary Créer un catalogue
         * @param {CatalogueApiApiCataloguesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCataloguesPost(requestParameters: CatalogueApiApiCataloguesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<CatalogueJsonldCatalogueRead> {
            return localVarFp.apiCataloguesPost(requestParameters.catalogueJsonldCatalogueCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des catalogues pour un prescripteur et pour une origine
         * @summary Récupérer la liste des catalogues pour un prescripteur et pour une origine
         * @param {CatalogueApiApiPrescripteursCataloguesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrescripteursCataloguesGetCollection(requestParameters: CatalogueApiApiPrescripteursCataloguesGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiCataloguesGetCollection200Response> {
            return localVarFp.apiPrescripteursCataloguesGetCollection(requestParameters.prescripteur, requestParameters.origine, requestParameters.domaine, requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.nomCommercial, requestParameters.packageNomCommercial, requestParameters.groupementLibelle, requestParameters.offreId, requestParameters.offreId2, requestParameters.origineId, requestParameters.origineId2, requestParameters.societeId, requestParameters.societeId2, requestParameters.packageId, requestParameters.packageId2, requestParameters.groupementId, requestParameters.groupementId2, requestParameters.packageNomCommercial2, requestParameters.groupementLibelle2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCataloguesGetCollection operation in CatalogueApi.
 * @export
 * @interface CatalogueApiApiCataloguesGetCollectionRequest
 */
export interface CatalogueApiApiCataloguesGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CatalogueApiApiCataloguesGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof CatalogueApiApiCataloguesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof CatalogueApiApiCataloguesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof CatalogueApiApiCataloguesGetCollection
     */
    readonly offreId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CatalogueApiApiCataloguesGetCollection
     */
    readonly offreId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof CatalogueApiApiCataloguesGetCollection
     */
    readonly origineId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CatalogueApiApiCataloguesGetCollection
     */
    readonly origineId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof CatalogueApiApiCataloguesGetCollection
     */
    readonly societeId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CatalogueApiApiCataloguesGetCollection
     */
    readonly societeId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof CatalogueApiApiCataloguesGetCollection
     */
    readonly packageId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CatalogueApiApiCataloguesGetCollection
     */
    readonly packageId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof CatalogueApiApiCataloguesGetCollection
     */
    readonly groupementId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CatalogueApiApiCataloguesGetCollection
     */
    readonly groupementId2?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof CatalogueApiApiCataloguesGetCollection
     */
    readonly nomCommercial?: string

    /**
     * 
     * @type {string}
     * @memberof CatalogueApiApiCataloguesGetCollection
     */
    readonly packageNomCommercial?: string

    /**
     * 
     * @type {string}
     * @memberof CatalogueApiApiCataloguesGetCollection
     */
    readonly groupementLibelle?: string
}

/**
 * Request parameters for apiCataloguesIdActionsAEffectuerGetCollection operation in CatalogueApi.
 * @export
 * @interface CatalogueApiApiCataloguesIdActionsAEffectuerGetCollectionRequest
 */
export interface CatalogueApiApiCataloguesIdActionsAEffectuerGetCollectionRequest {
    /**
     * TblCatalogue identifier
     * @type {string}
     * @memberof CatalogueApiApiCataloguesIdActionsAEffectuerGetCollection
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CatalogueApiApiCataloguesIdActionsAEffectuerGetCollection
     */
    readonly xIdSociete?: number

    /**
     * la liste des identifiants des options choisies
     * @type {Array<number>}
     * @memberof CatalogueApiApiCataloguesIdActionsAEffectuerGetCollection
     */
    readonly _options?: Array<number>

    /**
     * The collection page number
     * @type {number}
     * @memberof CatalogueApiApiCataloguesIdActionsAEffectuerGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof CatalogueApiApiCataloguesIdActionsAEffectuerGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiCataloguesIdDelete operation in CatalogueApi.
 * @export
 * @interface CatalogueApiApiCataloguesIdDeleteRequest
 */
export interface CatalogueApiApiCataloguesIdDeleteRequest {
    /**
     * TblCatalogue identifier
     * @type {string}
     * @memberof CatalogueApiApiCataloguesIdDelete
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CatalogueApiApiCataloguesIdDelete
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiCataloguesIdDependenciesGet operation in CatalogueApi.
 * @export
 * @interface CatalogueApiApiCataloguesIdDependenciesGetRequest
 */
export interface CatalogueApiApiCataloguesIdDependenciesGetRequest {
    /**
     * TblCatalogue identifier
     * @type {string}
     * @memberof CatalogueApiApiCataloguesIdDependenciesGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CatalogueApiApiCataloguesIdDependenciesGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiCataloguesIdDocumentsAFournirGetCollection operation in CatalogueApi.
 * @export
 * @interface CatalogueApiApiCataloguesIdDocumentsAFournirGetCollectionRequest
 */
export interface CatalogueApiApiCataloguesIdDocumentsAFournirGetCollectionRequest {
    /**
     * TblCatalogue identifier
     * @type {string}
     * @memberof CatalogueApiApiCataloguesIdDocumentsAFournirGetCollection
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CatalogueApiApiCataloguesIdDocumentsAFournirGetCollection
     */
    readonly xIdSociete?: number

    /**
     * la liste des identifiants des options choisies
     * @type {Array<number>}
     * @memberof CatalogueApiApiCataloguesIdDocumentsAFournirGetCollection
     */
    readonly _options?: Array<number>

    /**
     * The collection page number
     * @type {number}
     * @memberof CatalogueApiApiCataloguesIdDocumentsAFournirGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof CatalogueApiApiCataloguesIdDocumentsAFournirGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiCataloguesIdGet operation in CatalogueApi.
 * @export
 * @interface CatalogueApiApiCataloguesIdGetRequest
 */
export interface CatalogueApiApiCataloguesIdGetRequest {
    /**
     * TblCatalogue identifier
     * @type {string}
     * @memberof CatalogueApiApiCataloguesIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CatalogueApiApiCataloguesIdGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiCataloguesIdPut operation in CatalogueApi.
 * @export
 * @interface CatalogueApiApiCataloguesIdPutRequest
 */
export interface CatalogueApiApiCataloguesIdPutRequest {
    /**
     * TblCatalogue identifier
     * @type {string}
     * @memberof CatalogueApiApiCataloguesIdPut
     */
    readonly id: string

    /**
     * The updated Catalogue resource
     * @type {CatalogueJsonldCatalogueUpdate}
     * @memberof CatalogueApiApiCataloguesIdPut
     */
    readonly catalogueJsonldCatalogueUpdate: CatalogueJsonldCatalogueUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CatalogueApiApiCataloguesIdPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiCataloguesPost operation in CatalogueApi.
 * @export
 * @interface CatalogueApiApiCataloguesPostRequest
 */
export interface CatalogueApiApiCataloguesPostRequest {
    /**
     * The new Catalogue resource
     * @type {CatalogueJsonldCatalogueCreate}
     * @memberof CatalogueApiApiCataloguesPost
     */
    readonly catalogueJsonldCatalogueCreate: CatalogueJsonldCatalogueCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CatalogueApiApiCataloguesPost
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiPrescripteursCataloguesGetCollection operation in CatalogueApi.
 * @export
 * @interface CatalogueApiApiPrescripteursCataloguesGetCollectionRequest
 */
export interface CatalogueApiApiPrescripteursCataloguesGetCollectionRequest {
    /**
     * L\&#39;identifiant du prescripteur
     * @type {number}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly prescripteur: number

    /**
     * L\&#39;identifiant de l\&#39;origine
     * @type {number}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly origine: number

    /**
     * L\&#39;identifiant du domaine
     * @type {number}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly domaine: number

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * Le nom commercial du catalogue
     * @type {string}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly nomCommercial?: string

    /**
     * Le nom commercial du package
     * @type {string}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly packageNomCommercial?: string

    /**
     * Le libellé du groupement
     * @type {string}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly groupementLibelle?: string

    /**
     * 
     * @type {number}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly offreId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly offreId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly origineId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly origineId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly societeId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly societeId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly packageId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly packageId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly groupementId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly groupementId2?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly packageNomCommercial2?: string

    /**
     * 
     * @type {string}
     * @memberof CatalogueApiApiPrescripteursCataloguesGetCollection
     */
    readonly groupementLibelle2?: string
}

/**
 * CatalogueApi - object-oriented interface
 * @export
 * @class CatalogueApi
 * @extends {BaseAPI}
 */
export class CatalogueApi extends BaseAPI {
    /**
     * Récupérer la liste des catalogues
     * @summary Récupérer la liste des catalogues
     * @param {CatalogueApiApiCataloguesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueApi
     */
    public apiCataloguesGetCollection(requestParameters: CatalogueApiApiCataloguesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return CatalogueApiFp(this.configuration).apiCataloguesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.offreId, requestParameters.offreId2, requestParameters.origineId, requestParameters.origineId2, requestParameters.societeId, requestParameters.societeId2, requestParameters.packageId, requestParameters.packageId2, requestParameters.groupementId, requestParameters.groupementId2, requestParameters.nomCommercial, requestParameters.packageNomCommercial, requestParameters.groupementLibelle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des action à effectuer pour une entrée de catalogue
     * @summary Récupérer la liste des action à effectuer pour une entrée de catalogue
     * @param {CatalogueApiApiCataloguesIdActionsAEffectuerGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueApi
     */
    public apiCataloguesIdActionsAEffectuerGetCollection(requestParameters: CatalogueApiApiCataloguesIdActionsAEffectuerGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return CatalogueApiFp(this.configuration).apiCataloguesIdActionsAEffectuerGetCollection(requestParameters.id, requestParameters.xIdSociete, requestParameters._options, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer un catalogue
     * @summary Supprimer un catalogue
     * @param {CatalogueApiApiCataloguesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueApi
     */
    public apiCataloguesIdDelete(requestParameters: CatalogueApiApiCataloguesIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return CatalogueApiFp(this.configuration).apiCataloguesIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les ressources dépendante d\'un catalogue
     * @summary Lister les ressources dépendante d\'un catalogue
     * @param {CatalogueApiApiCataloguesIdDependenciesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueApi
     */
    public apiCataloguesIdDependenciesGet(requestParameters: CatalogueApiApiCataloguesIdDependenciesGetRequest, options?: RawAxiosRequestConfig) {
        return CatalogueApiFp(this.configuration).apiCataloguesIdDependenciesGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des documents à fournir pour une entrée de catalogue
     * @summary Récupérer la liste des documents à fournir pour une entrée de catalogue
     * @param {CatalogueApiApiCataloguesIdDocumentsAFournirGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueApi
     */
    public apiCataloguesIdDocumentsAFournirGetCollection(requestParameters: CatalogueApiApiCataloguesIdDocumentsAFournirGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return CatalogueApiFp(this.configuration).apiCataloguesIdDocumentsAFournirGetCollection(requestParameters.id, requestParameters.xIdSociete, requestParameters._options, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une catalogues
     * @summary Récupérer les informations d\'une catalogue
     * @param {CatalogueApiApiCataloguesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueApi
     */
    public apiCataloguesIdGet(requestParameters: CatalogueApiApiCataloguesIdGetRequest, options?: RawAxiosRequestConfig) {
        return CatalogueApiFp(this.configuration).apiCataloguesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les informations d\'un catalogue
     * @summary Mettre à jour les informations d\'un catalogue
     * @param {CatalogueApiApiCataloguesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueApi
     */
    public apiCataloguesIdPut(requestParameters: CatalogueApiApiCataloguesIdPutRequest, options?: RawAxiosRequestConfig) {
        return CatalogueApiFp(this.configuration).apiCataloguesIdPut(requestParameters.id, requestParameters.catalogueJsonldCatalogueUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer un catalogue
     * @summary Créer un catalogue
     * @param {CatalogueApiApiCataloguesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueApi
     */
    public apiCataloguesPost(requestParameters: CatalogueApiApiCataloguesPostRequest, options?: RawAxiosRequestConfig) {
        return CatalogueApiFp(this.configuration).apiCataloguesPost(requestParameters.catalogueJsonldCatalogueCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des catalogues pour un prescripteur et pour une origine
     * @summary Récupérer la liste des catalogues pour un prescripteur et pour une origine
     * @param {CatalogueApiApiPrescripteursCataloguesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueApi
     */
    public apiPrescripteursCataloguesGetCollection(requestParameters: CatalogueApiApiPrescripteursCataloguesGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return CatalogueApiFp(this.configuration).apiPrescripteursCataloguesGetCollection(requestParameters.prescripteur, requestParameters.origine, requestParameters.domaine, requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.nomCommercial, requestParameters.packageNomCommercial, requestParameters.groupementLibelle, requestParameters.offreId, requestParameters.offreId2, requestParameters.origineId, requestParameters.origineId2, requestParameters.societeId, requestParameters.societeId2, requestParameters.packageId, requestParameters.packageId2, requestParameters.groupementId, requestParameters.groupementId2, requestParameters.packageNomCommercial2, requestParameters.groupementLibelle2, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CataloguePrescripteurApi - axios parameter creator
 * @export
 */
export const CataloguePrescripteurApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les détails d\'un catalogue pour un prescripteur
         * @summary Récupérer les détails d\'un catalogue pour un prescripteur
         * @param {string} prescripteur TblTiers identifier
         * @param {string} catalogue PackagePrescripteur identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrescripteursPrescripteurCataloguesCatalogueGet: async (prescripteur: string, catalogue: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prescripteur' is not null or undefined
            assertParamExists('apiPrescripteursPrescripteurCataloguesCatalogueGet', 'prescripteur', prescripteur)
            // verify required parameter 'catalogue' is not null or undefined
            assertParamExists('apiPrescripteursPrescripteurCataloguesCatalogueGet', 'catalogue', catalogue)
            const localVarPath = `/offre/prescripteurs/{prescripteur}/catalogues/{catalogue}`
                .replace(`{${"prescripteur"}}`, encodeURIComponent(String(prescripteur)))
                .replace(`{${"catalogue"}}`, encodeURIComponent(String(catalogue)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les détails d\'un catalogue pour un prescripteur
         * @summary Récupérer les détails d\'un catalogue pour un prescripteur
         * @param {string} prescripteur TblTiers identifier
         * @param {string} catalogue TarifPrescripteur identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {Array<number>} [_options] la liste des identifiants des options choisies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrescripteursPrescripteurCataloguesCatalogueTarifGet: async (prescripteur: string, catalogue: string, xIdSociete?: number, _options?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prescripteur' is not null or undefined
            assertParamExists('apiPrescripteursPrescripteurCataloguesCatalogueTarifGet', 'prescripteur', prescripteur)
            // verify required parameter 'catalogue' is not null or undefined
            assertParamExists('apiPrescripteursPrescripteurCataloguesCatalogueTarifGet', 'catalogue', catalogue)
            const localVarPath = `/offre/prescripteurs/{prescripteur}/catalogues/{catalogue}/tarif`
                .replace(`{${"prescripteur"}}`, encodeURIComponent(String(prescripteur)))
                .replace(`{${"catalogue"}}`, encodeURIComponent(String(catalogue)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (_options) {
                localVarQueryParameter['options'] = _options.join(COLLECTION_FORMATS.csv);
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CataloguePrescripteurApi - functional programming interface
 * @export
 */
export const CataloguePrescripteurApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CataloguePrescripteurApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les détails d\'un catalogue pour un prescripteur
         * @summary Récupérer les détails d\'un catalogue pour un prescripteur
         * @param {string} prescripteur TblTiers identifier
         * @param {string} catalogue PackagePrescripteur identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrescripteursPrescripteurCataloguesCatalogueGet(prescripteur: string, catalogue: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CataloguePrescripteurJsonldPackagePrescripteurRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrescripteursPrescripteurCataloguesCatalogueGet(prescripteur, catalogue, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CataloguePrescripteurApi.apiPrescripteursPrescripteurCataloguesCatalogueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les détails d\'un catalogue pour un prescripteur
         * @summary Récupérer les détails d\'un catalogue pour un prescripteur
         * @param {string} prescripteur TblTiers identifier
         * @param {string} catalogue TarifPrescripteur identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {Array<number>} [_options] la liste des identifiants des options choisies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrescripteursPrescripteurCataloguesCatalogueTarifGet(prescripteur: string, catalogue: string, xIdSociete?: number, _options?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CataloguePrescripteurJsonldTarifPrescripteurRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrescripteursPrescripteurCataloguesCatalogueTarifGet(prescripteur, catalogue, xIdSociete, _options, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CataloguePrescripteurApi.apiPrescripteursPrescripteurCataloguesCatalogueTarifGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CataloguePrescripteurApi - factory interface
 * @export
 */
export const CataloguePrescripteurApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CataloguePrescripteurApiFp(configuration)
    return {
        /**
         * Récupérer les détails d\'un catalogue pour un prescripteur
         * @summary Récupérer les détails d\'un catalogue pour un prescripteur
         * @param {CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrescripteursPrescripteurCataloguesCatalogueGet(requestParameters: CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CataloguePrescripteurJsonldPackagePrescripteurRead> {
            return localVarFp.apiPrescripteursPrescripteurCataloguesCatalogueGet(requestParameters.prescripteur, requestParameters.catalogue, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les détails d\'un catalogue pour un prescripteur
         * @summary Récupérer les détails d\'un catalogue pour un prescripteur
         * @param {CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueTarifGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrescripteursPrescripteurCataloguesCatalogueTarifGet(requestParameters: CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueTarifGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CataloguePrescripteurJsonldTarifPrescripteurRead> {
            return localVarFp.apiPrescripteursPrescripteurCataloguesCatalogueTarifGet(requestParameters.prescripteur, requestParameters.catalogue, requestParameters.xIdSociete, requestParameters._options, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiPrescripteursPrescripteurCataloguesCatalogueGet operation in CataloguePrescripteurApi.
 * @export
 * @interface CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueGetRequest
 */
export interface CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueGetRequest {
    /**
     * TblTiers identifier
     * @type {string}
     * @memberof CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueGet
     */
    readonly prescripteur: string

    /**
     * PackagePrescripteur identifier
     * @type {string}
     * @memberof CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueGet
     */
    readonly catalogue: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiPrescripteursPrescripteurCataloguesCatalogueTarifGet operation in CataloguePrescripteurApi.
 * @export
 * @interface CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueTarifGetRequest
 */
export interface CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueTarifGetRequest {
    /**
     * TblTiers identifier
     * @type {string}
     * @memberof CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueTarifGet
     */
    readonly prescripteur: string

    /**
     * TarifPrescripteur identifier
     * @type {string}
     * @memberof CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueTarifGet
     */
    readonly catalogue: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueTarifGet
     */
    readonly xIdSociete?: number

    /**
     * la liste des identifiants des options choisies
     * @type {Array<number>}
     * @memberof CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueTarifGet
     */
    readonly _options?: Array<number>
}

/**
 * CataloguePrescripteurApi - object-oriented interface
 * @export
 * @class CataloguePrescripteurApi
 * @extends {BaseAPI}
 */
export class CataloguePrescripteurApi extends BaseAPI {
    /**
     * Récupérer les détails d\'un catalogue pour un prescripteur
     * @summary Récupérer les détails d\'un catalogue pour un prescripteur
     * @param {CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CataloguePrescripteurApi
     */
    public apiPrescripteursPrescripteurCataloguesCatalogueGet(requestParameters: CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueGetRequest, options?: RawAxiosRequestConfig) {
        return CataloguePrescripteurApiFp(this.configuration).apiPrescripteursPrescripteurCataloguesCatalogueGet(requestParameters.prescripteur, requestParameters.catalogue, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les détails d\'un catalogue pour un prescripteur
     * @summary Récupérer les détails d\'un catalogue pour un prescripteur
     * @param {CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueTarifGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CataloguePrescripteurApi
     */
    public apiPrescripteursPrescripteurCataloguesCatalogueTarifGet(requestParameters: CataloguePrescripteurApiApiPrescripteursPrescripteurCataloguesCatalogueTarifGetRequest, options?: RawAxiosRequestConfig) {
        return CataloguePrescripteurApiFp(this.configuration).apiPrescripteursPrescripteurCataloguesCatalogueTarifGet(requestParameters.prescripteur, requestParameters.catalogue, requestParameters.xIdSociete, requestParameters._options, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChampComplmentaireApi - axios parameter creator
 * @export
 */
export const ChampComplmentaireApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des champs complémentaires
         * @summary Obtenir la liste des champs complémentaires
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [libelle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChampsComplementairesGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, libelle?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/champs-complementaires`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (libelle !== undefined) {
                localVarQueryParameter['libelle'] = libelle;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer un champ complémentaire
         * @summary Supprimer un champ complémentaire
         * @param {string} id TblChampComp identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChampsComplementairesIdDelete: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiChampsComplementairesIdDelete', 'id', id)
            const localVarPath = `/offre/champs-complementaires/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les ressources dépendante d\'une offre
         * @summary Lister les ressources dépendante d\'une offre
         * @param {string} id TblChampComp identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChampsComplementairesIdDependenciesGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiChampsComplementairesIdDependenciesGet', 'id', id)
            const localVarPath = `/offre/champs-complementaires/{id}/dependencies`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un champ complémentaire
         * @summary Récupérer les informations d\'un champ complémentaire
         * @param {string} id TblChampComp identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChampsComplementairesIdGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiChampsComplementairesIdGet', 'id', id)
            const localVarPath = `/offre/champs-complementaires/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Champ Complémentaire resource.
         * @summary Modifier un champ complementaire
         * @param {string} id TblChampComp identifier
         * @param {ChampComplMentaireJsonldChampcomplementaireUpdate} champComplMentaireJsonldChampcomplementaireUpdate The updated Champ Complémentaire resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChampsComplementairesIdPut: async (id: string, champComplMentaireJsonldChampcomplementaireUpdate: ChampComplMentaireJsonldChampcomplementaireUpdate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiChampsComplementairesIdPut', 'id', id)
            // verify required parameter 'champComplMentaireJsonldChampcomplementaireUpdate' is not null or undefined
            assertParamExists('apiChampsComplementairesIdPut', 'champComplMentaireJsonldChampcomplementaireUpdate', champComplMentaireJsonldChampcomplementaireUpdate)
            const localVarPath = `/offre/champs-complementaires/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(champComplMentaireJsonldChampcomplementaireUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Champ Complémentaire resource.
         * @summary Créer un champ complémentaire
         * @param {ChampComplMentaireJsonldChampcomplementaireCreate} champComplMentaireJsonldChampcomplementaireCreate The new Champ Complémentaire resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChampsComplementairesPost: async (champComplMentaireJsonldChampcomplementaireCreate: ChampComplMentaireJsonldChampcomplementaireCreate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'champComplMentaireJsonldChampcomplementaireCreate' is not null or undefined
            assertParamExists('apiChampsComplementairesPost', 'champComplMentaireJsonldChampcomplementaireCreate', champComplMentaireJsonldChampcomplementaireCreate)
            const localVarPath = `/offre/champs-complementaires`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(champComplMentaireJsonldChampcomplementaireCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChampComplmentaireApi - functional programming interface
 * @export
 */
export const ChampComplmentaireApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChampComplmentaireApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des champs complémentaires
         * @summary Obtenir la liste des champs complémentaires
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [libelle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChampsComplementairesGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, libelle?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiChampsComplementairesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChampsComplementairesGetCollection(xIdSociete, page, itemsPerPage, libelle, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChampComplmentaireApi.apiChampsComplementairesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Supprimer un champ complémentaire
         * @summary Supprimer un champ complémentaire
         * @param {string} id TblChampComp identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChampsComplementairesIdDelete(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChampsComplementairesIdDelete(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChampComplmentaireApi.apiChampsComplementairesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lister les ressources dépendante d\'une offre
         * @summary Lister les ressources dépendante d\'une offre
         * @param {string} id TblChampComp identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChampsComplementairesIdDependenciesGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChampComplMentaireResourceDependencyJsonldDependencyRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChampsComplementairesIdDependenciesGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChampComplmentaireApi.apiChampsComplementairesIdDependenciesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un champ complémentaire
         * @summary Récupérer les informations d\'un champ complémentaire
         * @param {string} id TblChampComp identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChampsComplementairesIdGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChampComplMentaireJsonldChampcomplementaireRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChampsComplementairesIdGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChampComplmentaireApi.apiChampsComplementairesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the Champ Complémentaire resource.
         * @summary Modifier un champ complementaire
         * @param {string} id TblChampComp identifier
         * @param {ChampComplMentaireJsonldChampcomplementaireUpdate} champComplMentaireJsonldChampcomplementaireUpdate The updated Champ Complémentaire resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChampsComplementairesIdPut(id: string, champComplMentaireJsonldChampcomplementaireUpdate: ChampComplMentaireJsonldChampcomplementaireUpdate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChampComplMentaireJsonldChampcomplementaireRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChampsComplementairesIdPut(id, champComplMentaireJsonldChampcomplementaireUpdate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChampComplmentaireApi.apiChampsComplementairesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a Champ Complémentaire resource.
         * @summary Créer un champ complémentaire
         * @param {ChampComplMentaireJsonldChampcomplementaireCreate} champComplMentaireJsonldChampcomplementaireCreate The new Champ Complémentaire resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChampsComplementairesPost(champComplMentaireJsonldChampcomplementaireCreate: ChampComplMentaireJsonldChampcomplementaireCreate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChampComplMentaireJsonldChampcomplementaireRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChampsComplementairesPost(champComplMentaireJsonldChampcomplementaireCreate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChampComplmentaireApi.apiChampsComplementairesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ChampComplmentaireApi - factory interface
 * @export
 */
export const ChampComplmentaireApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChampComplmentaireApiFp(configuration)
    return {
        /**
         * Obtenir la liste des champs complémentaires
         * @summary Obtenir la liste des champs complémentaires
         * @param {ChampComplmentaireApiApiChampsComplementairesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChampsComplementairesGetCollection(requestParameters: ChampComplmentaireApiApiChampsComplementairesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiChampsComplementairesGetCollection200Response> {
            return localVarFp.apiChampsComplementairesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.libelle, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer un champ complémentaire
         * @summary Supprimer un champ complémentaire
         * @param {ChampComplmentaireApiApiChampsComplementairesIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChampsComplementairesIdDelete(requestParameters: ChampComplmentaireApiApiChampsComplementairesIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiChampsComplementairesIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les ressources dépendante d\'une offre
         * @summary Lister les ressources dépendante d\'une offre
         * @param {ChampComplmentaireApiApiChampsComplementairesIdDependenciesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChampsComplementairesIdDependenciesGet(requestParameters: ChampComplmentaireApiApiChampsComplementairesIdDependenciesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChampComplMentaireResourceDependencyJsonldDependencyRead> {
            return localVarFp.apiChampsComplementairesIdDependenciesGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un champ complémentaire
         * @summary Récupérer les informations d\'un champ complémentaire
         * @param {ChampComplmentaireApiApiChampsComplementairesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChampsComplementairesIdGet(requestParameters: ChampComplmentaireApiApiChampsComplementairesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChampComplMentaireJsonldChampcomplementaireRead> {
            return localVarFp.apiChampsComplementairesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Champ Complémentaire resource.
         * @summary Modifier un champ complementaire
         * @param {ChampComplmentaireApiApiChampsComplementairesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChampsComplementairesIdPut(requestParameters: ChampComplmentaireApiApiChampsComplementairesIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChampComplMentaireJsonldChampcomplementaireRead> {
            return localVarFp.apiChampsComplementairesIdPut(requestParameters.id, requestParameters.champComplMentaireJsonldChampcomplementaireUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Champ Complémentaire resource.
         * @summary Créer un champ complémentaire
         * @param {ChampComplmentaireApiApiChampsComplementairesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChampsComplementairesPost(requestParameters: ChampComplmentaireApiApiChampsComplementairesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ChampComplMentaireJsonldChampcomplementaireRead> {
            return localVarFp.apiChampsComplementairesPost(requestParameters.champComplMentaireJsonldChampcomplementaireCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiChampsComplementairesGetCollection operation in ChampComplmentaireApi.
 * @export
 * @interface ChampComplmentaireApiApiChampsComplementairesGetCollectionRequest
 */
export interface ChampComplmentaireApiApiChampsComplementairesGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ChampComplmentaireApiApiChampsComplementairesGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof ChampComplmentaireApiApiChampsComplementairesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ChampComplmentaireApiApiChampsComplementairesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof ChampComplmentaireApiApiChampsComplementairesGetCollection
     */
    readonly libelle?: string
}

/**
 * Request parameters for apiChampsComplementairesIdDelete operation in ChampComplmentaireApi.
 * @export
 * @interface ChampComplmentaireApiApiChampsComplementairesIdDeleteRequest
 */
export interface ChampComplmentaireApiApiChampsComplementairesIdDeleteRequest {
    /**
     * TblChampComp identifier
     * @type {string}
     * @memberof ChampComplmentaireApiApiChampsComplementairesIdDelete
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ChampComplmentaireApiApiChampsComplementairesIdDelete
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiChampsComplementairesIdDependenciesGet operation in ChampComplmentaireApi.
 * @export
 * @interface ChampComplmentaireApiApiChampsComplementairesIdDependenciesGetRequest
 */
export interface ChampComplmentaireApiApiChampsComplementairesIdDependenciesGetRequest {
    /**
     * TblChampComp identifier
     * @type {string}
     * @memberof ChampComplmentaireApiApiChampsComplementairesIdDependenciesGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ChampComplmentaireApiApiChampsComplementairesIdDependenciesGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiChampsComplementairesIdGet operation in ChampComplmentaireApi.
 * @export
 * @interface ChampComplmentaireApiApiChampsComplementairesIdGetRequest
 */
export interface ChampComplmentaireApiApiChampsComplementairesIdGetRequest {
    /**
     * TblChampComp identifier
     * @type {string}
     * @memberof ChampComplmentaireApiApiChampsComplementairesIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ChampComplmentaireApiApiChampsComplementairesIdGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiChampsComplementairesIdPut operation in ChampComplmentaireApi.
 * @export
 * @interface ChampComplmentaireApiApiChampsComplementairesIdPutRequest
 */
export interface ChampComplmentaireApiApiChampsComplementairesIdPutRequest {
    /**
     * TblChampComp identifier
     * @type {string}
     * @memberof ChampComplmentaireApiApiChampsComplementairesIdPut
     */
    readonly id: string

    /**
     * The updated Champ Complémentaire resource
     * @type {ChampComplMentaireJsonldChampcomplementaireUpdate}
     * @memberof ChampComplmentaireApiApiChampsComplementairesIdPut
     */
    readonly champComplMentaireJsonldChampcomplementaireUpdate: ChampComplMentaireJsonldChampcomplementaireUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ChampComplmentaireApiApiChampsComplementairesIdPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiChampsComplementairesPost operation in ChampComplmentaireApi.
 * @export
 * @interface ChampComplmentaireApiApiChampsComplementairesPostRequest
 */
export interface ChampComplmentaireApiApiChampsComplementairesPostRequest {
    /**
     * The new Champ Complémentaire resource
     * @type {ChampComplMentaireJsonldChampcomplementaireCreate}
     * @memberof ChampComplmentaireApiApiChampsComplementairesPost
     */
    readonly champComplMentaireJsonldChampcomplementaireCreate: ChampComplMentaireJsonldChampcomplementaireCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ChampComplmentaireApiApiChampsComplementairesPost
     */
    readonly xIdSociete?: number
}

/**
 * ChampComplmentaireApi - object-oriented interface
 * @export
 * @class ChampComplmentaireApi
 * @extends {BaseAPI}
 */
export class ChampComplmentaireApi extends BaseAPI {
    /**
     * Obtenir la liste des champs complémentaires
     * @summary Obtenir la liste des champs complémentaires
     * @param {ChampComplmentaireApiApiChampsComplementairesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChampComplmentaireApi
     */
    public apiChampsComplementairesGetCollection(requestParameters: ChampComplmentaireApiApiChampsComplementairesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return ChampComplmentaireApiFp(this.configuration).apiChampsComplementairesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.libelle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer un champ complémentaire
     * @summary Supprimer un champ complémentaire
     * @param {ChampComplmentaireApiApiChampsComplementairesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChampComplmentaireApi
     */
    public apiChampsComplementairesIdDelete(requestParameters: ChampComplmentaireApiApiChampsComplementairesIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return ChampComplmentaireApiFp(this.configuration).apiChampsComplementairesIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les ressources dépendante d\'une offre
     * @summary Lister les ressources dépendante d\'une offre
     * @param {ChampComplmentaireApiApiChampsComplementairesIdDependenciesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChampComplmentaireApi
     */
    public apiChampsComplementairesIdDependenciesGet(requestParameters: ChampComplmentaireApiApiChampsComplementairesIdDependenciesGetRequest, options?: RawAxiosRequestConfig) {
        return ChampComplmentaireApiFp(this.configuration).apiChampsComplementairesIdDependenciesGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un champ complémentaire
     * @summary Récupérer les informations d\'un champ complémentaire
     * @param {ChampComplmentaireApiApiChampsComplementairesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChampComplmentaireApi
     */
    public apiChampsComplementairesIdGet(requestParameters: ChampComplmentaireApiApiChampsComplementairesIdGetRequest, options?: RawAxiosRequestConfig) {
        return ChampComplmentaireApiFp(this.configuration).apiChampsComplementairesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Champ Complémentaire resource.
     * @summary Modifier un champ complementaire
     * @param {ChampComplmentaireApiApiChampsComplementairesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChampComplmentaireApi
     */
    public apiChampsComplementairesIdPut(requestParameters: ChampComplmentaireApiApiChampsComplementairesIdPutRequest, options?: RawAxiosRequestConfig) {
        return ChampComplmentaireApiFp(this.configuration).apiChampsComplementairesIdPut(requestParameters.id, requestParameters.champComplMentaireJsonldChampcomplementaireUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Champ Complémentaire resource.
     * @summary Créer un champ complémentaire
     * @param {ChampComplmentaireApiApiChampsComplementairesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChampComplmentaireApi
     */
    public apiChampsComplementairesPost(requestParameters: ChampComplmentaireApiApiChampsComplementairesPostRequest, options?: RawAxiosRequestConfig) {
        return ChampComplmentaireApiFp(this.configuration).apiChampsComplementairesPost(requestParameters.champComplMentaireJsonldChampcomplementaireCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DomainesApi - axios parameter creator
 * @export
 */
export const DomainesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des domaines
         * @summary Récupérer la liste des domaines
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDomainesGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/domaines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un domaine
         * @summary Récupérer les informations d\'un domaine
         * @param {string} id TblDefDomaine identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDomainesIdGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDomainesIdGet', 'id', id)
            const localVarPath = `/offre/domaines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DomainesApi - functional programming interface
 * @export
 */
export const DomainesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DomainesApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des domaines
         * @summary Récupérer la liste des domaines
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDomainesGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDomainesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDomainesGetCollection(xIdSociete, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DomainesApi.apiDomainesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un domaine
         * @summary Récupérer les informations d\'un domaine
         * @param {string} id TblDefDomaine identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDomainesIdGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomainesJsonldDomaineRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDomainesIdGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DomainesApi.apiDomainesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DomainesApi - factory interface
 * @export
 */
export const DomainesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DomainesApiFp(configuration)
    return {
        /**
         * Récupérer la liste des domaines
         * @summary Récupérer la liste des domaines
         * @param {DomainesApiApiDomainesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDomainesGetCollection(requestParameters: DomainesApiApiDomainesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiDomainesGetCollection200Response> {
            return localVarFp.apiDomainesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un domaine
         * @summary Récupérer les informations d\'un domaine
         * @param {DomainesApiApiDomainesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDomainesIdGet(requestParameters: DomainesApiApiDomainesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DomainesJsonldDomaineRead> {
            return localVarFp.apiDomainesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDomainesGetCollection operation in DomainesApi.
 * @export
 * @interface DomainesApiApiDomainesGetCollectionRequest
 */
export interface DomainesApiApiDomainesGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DomainesApiApiDomainesGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof DomainesApiApiDomainesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DomainesApiApiDomainesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiDomainesIdGet operation in DomainesApi.
 * @export
 * @interface DomainesApiApiDomainesIdGetRequest
 */
export interface DomainesApiApiDomainesIdGetRequest {
    /**
     * TblDefDomaine identifier
     * @type {string}
     * @memberof DomainesApiApiDomainesIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DomainesApiApiDomainesIdGet
     */
    readonly xIdSociete?: number
}

/**
 * DomainesApi - object-oriented interface
 * @export
 * @class DomainesApi
 * @extends {BaseAPI}
 */
export class DomainesApi extends BaseAPI {
    /**
     * Récupérer la liste des domaines
     * @summary Récupérer la liste des domaines
     * @param {DomainesApiApiDomainesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainesApi
     */
    public apiDomainesGetCollection(requestParameters: DomainesApiApiDomainesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return DomainesApiFp(this.configuration).apiDomainesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un domaine
     * @summary Récupérer les informations d\'un domaine
     * @param {DomainesApiApiDomainesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainesApi
     */
    public apiDomainesIdGet(requestParameters: DomainesApiApiDomainesIdGetRequest, options?: RawAxiosRequestConfig) {
        return DomainesApiFp(this.configuration).apiDomainesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FormaliteApi - axios parameter creator
 * @export
 */
export const FormaliteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les informations d\'un domaine
         * @summary Récupérer les informations d\'un domaine
         * @param {string} id FproFormaliteArticle identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormaliteIdGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormaliteIdGet', 'id', id)
            const localVarPath = `/offre/formalite/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des formalites
         * @summary Récupérer la liste des formalites
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/formalites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormaliteApi - functional programming interface
 * @export
 */
export const FormaliteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormaliteApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les informations d\'un domaine
         * @summary Récupérer les informations d\'un domaine
         * @param {string} id FproFormaliteArticle identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormaliteIdGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormaliteJsonldFormaliteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormaliteIdGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormaliteApi.apiFormaliteIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer la liste des formalites
         * @summary Récupérer la liste des formalites
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormalitesGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFormalitesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormalitesGetCollection(xIdSociete, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FormaliteApi.apiFormalitesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FormaliteApi - factory interface
 * @export
 */
export const FormaliteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormaliteApiFp(configuration)
    return {
        /**
         * Récupérer les informations d\'un domaine
         * @summary Récupérer les informations d\'un domaine
         * @param {FormaliteApiApiFormaliteIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormaliteIdGet(requestParameters: FormaliteApiApiFormaliteIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<FormaliteJsonldFormaliteRead> {
            return localVarFp.apiFormaliteIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des formalites
         * @summary Récupérer la liste des formalites
         * @param {FormaliteApiApiFormalitesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesGetCollection(requestParameters: FormaliteApiApiFormalitesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiFormalitesGetCollection200Response> {
            return localVarFp.apiFormalitesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiFormaliteIdGet operation in FormaliteApi.
 * @export
 * @interface FormaliteApiApiFormaliteIdGetRequest
 */
export interface FormaliteApiApiFormaliteIdGetRequest {
    /**
     * FproFormaliteArticle identifier
     * @type {string}
     * @memberof FormaliteApiApiFormaliteIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FormaliteApiApiFormaliteIdGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiFormalitesGetCollection operation in FormaliteApi.
 * @export
 * @interface FormaliteApiApiFormalitesGetCollectionRequest
 */
export interface FormaliteApiApiFormalitesGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * FormaliteApi - object-oriented interface
 * @export
 * @class FormaliteApi
 * @extends {BaseAPI}
 */
export class FormaliteApi extends BaseAPI {
    /**
     * Récupérer les informations d\'un domaine
     * @summary Récupérer les informations d\'un domaine
     * @param {FormaliteApiApiFormaliteIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormaliteApi
     */
    public apiFormaliteIdGet(requestParameters: FormaliteApiApiFormaliteIdGetRequest, options?: RawAxiosRequestConfig) {
        return FormaliteApiFp(this.configuration).apiFormaliteIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des formalites
     * @summary Récupérer la liste des formalites
     * @param {FormaliteApiApiFormalitesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormaliteApi
     */
    public apiFormalitesGetCollection(requestParameters: FormaliteApiApiFormalitesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return FormaliteApiFp(this.configuration).apiFormalitesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GroupementApi - axios parameter creator
 * @export
 */
export const GroupementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des groupements
         * @summary Récupérer la liste des groupements
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [domaineId] 
         * @param {Array<number>} [domaineId2] 
         * @param {ApiGroupementsGetCollectionOrderLibelleEnum} [orderLibelle] 
         * @param {ApiGroupementsGetCollectionOrderRangEnum} [orderRang] 
         * @param {ApiGroupementsGetCollectionOrderDomaineLibelleEnum} [orderDomaineLibelle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupementsGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, domaineId?: number, domaineId2?: Array<number>, orderLibelle?: ApiGroupementsGetCollectionOrderLibelleEnum, orderRang?: ApiGroupementsGetCollectionOrderRangEnum, orderDomaineLibelle?: ApiGroupementsGetCollectionOrderDomaineLibelleEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/groupements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (domaineId !== undefined) {
                localVarQueryParameter['domaine.id'] = domaineId;
            }

            if (domaineId2) {
                localVarQueryParameter['domaine.id[]'] = domaineId2;
            }

            if (orderLibelle !== undefined) {
                localVarQueryParameter['order[libelle]'] = orderLibelle;
            }

            if (orderRang !== undefined) {
                localVarQueryParameter['order[rang]'] = orderRang;
            }

            if (orderDomaineLibelle !== undefined) {
                localVarQueryParameter['order[domaine.libelle]'] = orderDomaineLibelle;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer un groupement
         * @summary Supprimer un groupement
         * @param {string} id TblGroupement identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupementsIdDelete: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiGroupementsIdDelete', 'id', id)
            const localVarPath = `/offre/groupements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un groupement
         * @summary Récupérer les informations d\'un groupement
         * @param {string} id TblGroupement identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupementsIdGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiGroupementsIdGet', 'id', id)
            const localVarPath = `/offre/groupements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les informations d\'un groupement
         * @summary Mettre à jour les informations d\'un groupement
         * @param {string} id TblGroupement identifier
         * @param {GroupementJsonldGroupementUpdate} groupementJsonldGroupementUpdate The updated Groupement resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupementsIdPut: async (id: string, groupementJsonldGroupementUpdate: GroupementJsonldGroupementUpdate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiGroupementsIdPut', 'id', id)
            // verify required parameter 'groupementJsonldGroupementUpdate' is not null or undefined
            assertParamExists('apiGroupementsIdPut', 'groupementJsonldGroupementUpdate', groupementJsonldGroupementUpdate)
            const localVarPath = `/offre/groupements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupementJsonldGroupementUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer un groupement
         * @summary Créer un groupement
         * @param {GroupementJsonldGroupementCreate} groupementJsonldGroupementCreate The new Groupement resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupementsPost: async (groupementJsonldGroupementCreate: GroupementJsonldGroupementCreate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupementJsonldGroupementCreate' is not null or undefined
            assertParamExists('apiGroupementsPost', 'groupementJsonldGroupementCreate', groupementJsonldGroupementCreate)
            const localVarPath = `/offre/groupements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupementJsonldGroupementCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des surcharges d\'ordonnancement de groupement par domaine
         * @summary Récupérer la liste des surcharges d\'ordonnancement de groupement par domaine
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdresGroupementsDomainesGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/ordres-groupements-domaines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une surcharge d\'ordonnancement de groupement par domaine
         * @summary Supprimer une surcharge d\'ordonnancement de groupement par domaine
         * @param {string} id TblOrderGroupementOrigine identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdresGroupementsDomainesIdDelete: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrdresGroupementsDomainesIdDelete', 'id', id)
            const localVarPath = `/offre/ordres-groupements-domaines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une surcharge d\'ordonnancement de groupement par domaine
         * @summary Récupérer les informations d\'une surcharge d\'ordonnancement de groupement par domaine
         * @param {string} id TblOrderGroupementOrigine identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdresGroupementsDomainesIdGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrdresGroupementsDomainesIdGet', 'id', id)
            const localVarPath = `/offre/ordres-groupements-domaines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer une surcharge d\'ordonnancement de groupement par domaine
         * @summary Créer une surcharge d\'ordonnancement de groupement par domaine
         * @param {GroupementJsonldOrdreGroupementOrigineCreate} groupementJsonldOrdreGroupementOrigineCreate The new Groupement resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdresGroupementsDomainesPost: async (groupementJsonldOrdreGroupementOrigineCreate: GroupementJsonldOrdreGroupementOrigineCreate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupementJsonldOrdreGroupementOrigineCreate' is not null or undefined
            assertParamExists('apiOrdresGroupementsDomainesPost', 'groupementJsonldOrdreGroupementOrigineCreate', groupementJsonldOrdreGroupementOrigineCreate)
            const localVarPath = `/offre/ordres-groupements-domaines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupementJsonldOrdreGroupementOrigineCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupementApi - functional programming interface
 * @export
 */
export const GroupementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupementApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des groupements
         * @summary Récupérer la liste des groupements
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [domaineId] 
         * @param {Array<number>} [domaineId2] 
         * @param {ApiGroupementsGetCollectionOrderLibelleEnum} [orderLibelle] 
         * @param {ApiGroupementsGetCollectionOrderRangEnum} [orderRang] 
         * @param {ApiGroupementsGetCollectionOrderDomaineLibelleEnum} [orderDomaineLibelle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupementsGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, domaineId?: number, domaineId2?: Array<number>, orderLibelle?: ApiGroupementsGetCollectionOrderLibelleEnum, orderRang?: ApiGroupementsGetCollectionOrderRangEnum, orderDomaineLibelle?: ApiGroupementsGetCollectionOrderDomaineLibelleEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGroupementsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupementsGetCollection(xIdSociete, page, itemsPerPage, domaineId, domaineId2, orderLibelle, orderRang, orderDomaineLibelle, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupementApi.apiGroupementsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Supprimer un groupement
         * @summary Supprimer un groupement
         * @param {string} id TblGroupement identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupementsIdDelete(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupementsIdDelete(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupementApi.apiGroupementsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un groupement
         * @summary Récupérer les informations d\'un groupement
         * @param {string} id TblGroupement identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupementsIdGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupementJsonldGroupementRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupementsIdGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupementApi.apiGroupementsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour les informations d\'un groupement
         * @summary Mettre à jour les informations d\'un groupement
         * @param {string} id TblGroupement identifier
         * @param {GroupementJsonldGroupementUpdate} groupementJsonldGroupementUpdate The updated Groupement resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupementsIdPut(id: string, groupementJsonldGroupementUpdate: GroupementJsonldGroupementUpdate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupementJsonldGroupementRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupementsIdPut(id, groupementJsonldGroupementUpdate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupementApi.apiGroupementsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Créer un groupement
         * @summary Créer un groupement
         * @param {GroupementJsonldGroupementCreate} groupementJsonldGroupementCreate The new Groupement resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupementsPost(groupementJsonldGroupementCreate: GroupementJsonldGroupementCreate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupementJsonldGroupementRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupementsPost(groupementJsonldGroupementCreate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupementApi.apiGroupementsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer la liste des surcharges d\'ordonnancement de groupement par domaine
         * @summary Récupérer la liste des surcharges d\'ordonnancement de groupement par domaine
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdresGroupementsDomainesGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOrdresGroupementsDomainesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdresGroupementsDomainesGetCollection(xIdSociete, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupementApi.apiOrdresGroupementsDomainesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Supprimer une surcharge d\'ordonnancement de groupement par domaine
         * @summary Supprimer une surcharge d\'ordonnancement de groupement par domaine
         * @param {string} id TblOrderGroupementOrigine identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdresGroupementsDomainesIdDelete(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdresGroupementsDomainesIdDelete(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupementApi.apiOrdresGroupementsDomainesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une surcharge d\'ordonnancement de groupement par domaine
         * @summary Récupérer les informations d\'une surcharge d\'ordonnancement de groupement par domaine
         * @param {string} id TblOrderGroupementOrigine identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdresGroupementsDomainesIdGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupementJsonldOrdreGroupementOrigineRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdresGroupementsDomainesIdGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupementApi.apiOrdresGroupementsDomainesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Créer une surcharge d\'ordonnancement de groupement par domaine
         * @summary Créer une surcharge d\'ordonnancement de groupement par domaine
         * @param {GroupementJsonldOrdreGroupementOrigineCreate} groupementJsonldOrdreGroupementOrigineCreate The new Groupement resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdresGroupementsDomainesPost(groupementJsonldOrdreGroupementOrigineCreate: GroupementJsonldOrdreGroupementOrigineCreate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupementJsonldOrdreGroupementOrigineRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdresGroupementsDomainesPost(groupementJsonldOrdreGroupementOrigineCreate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupementApi.apiOrdresGroupementsDomainesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GroupementApi - factory interface
 * @export
 */
export const GroupementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupementApiFp(configuration)
    return {
        /**
         * Récupérer la liste des groupements
         * @summary Récupérer la liste des groupements
         * @param {GroupementApiApiGroupementsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupementsGetCollection(requestParameters: GroupementApiApiGroupementsGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiGroupementsGetCollection200Response> {
            return localVarFp.apiGroupementsGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.domaineId, requestParameters.domaineId2, requestParameters.orderLibelle, requestParameters.orderRang, requestParameters.orderDomaineLibelle, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer un groupement
         * @summary Supprimer un groupement
         * @param {GroupementApiApiGroupementsIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupementsIdDelete(requestParameters: GroupementApiApiGroupementsIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiGroupementsIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un groupement
         * @summary Récupérer les informations d\'un groupement
         * @param {GroupementApiApiGroupementsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupementsIdGet(requestParameters: GroupementApiApiGroupementsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GroupementJsonldGroupementRead> {
            return localVarFp.apiGroupementsIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les informations d\'un groupement
         * @summary Mettre à jour les informations d\'un groupement
         * @param {GroupementApiApiGroupementsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupementsIdPut(requestParameters: GroupementApiApiGroupementsIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<GroupementJsonldGroupementRead> {
            return localVarFp.apiGroupementsIdPut(requestParameters.id, requestParameters.groupementJsonldGroupementUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer un groupement
         * @summary Créer un groupement
         * @param {GroupementApiApiGroupementsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupementsPost(requestParameters: GroupementApiApiGroupementsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<GroupementJsonldGroupementRead> {
            return localVarFp.apiGroupementsPost(requestParameters.groupementJsonldGroupementCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des surcharges d\'ordonnancement de groupement par domaine
         * @summary Récupérer la liste des surcharges d\'ordonnancement de groupement par domaine
         * @param {GroupementApiApiOrdresGroupementsDomainesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdresGroupementsDomainesGetCollection(requestParameters: GroupementApiApiOrdresGroupementsDomainesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiOrdresGroupementsDomainesGetCollection200Response> {
            return localVarFp.apiOrdresGroupementsDomainesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une surcharge d\'ordonnancement de groupement par domaine
         * @summary Supprimer une surcharge d\'ordonnancement de groupement par domaine
         * @param {GroupementApiApiOrdresGroupementsDomainesIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdresGroupementsDomainesIdDelete(requestParameters: GroupementApiApiOrdresGroupementsDomainesIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOrdresGroupementsDomainesIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une surcharge d\'ordonnancement de groupement par domaine
         * @summary Récupérer les informations d\'une surcharge d\'ordonnancement de groupement par domaine
         * @param {GroupementApiApiOrdresGroupementsDomainesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdresGroupementsDomainesIdGet(requestParameters: GroupementApiApiOrdresGroupementsDomainesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GroupementJsonldOrdreGroupementOrigineRead> {
            return localVarFp.apiOrdresGroupementsDomainesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer une surcharge d\'ordonnancement de groupement par domaine
         * @summary Créer une surcharge d\'ordonnancement de groupement par domaine
         * @param {GroupementApiApiOrdresGroupementsDomainesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdresGroupementsDomainesPost(requestParameters: GroupementApiApiOrdresGroupementsDomainesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<GroupementJsonldOrdreGroupementOrigineRead> {
            return localVarFp.apiOrdresGroupementsDomainesPost(requestParameters.groupementJsonldOrdreGroupementOrigineCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiGroupementsGetCollection operation in GroupementApi.
 * @export
 * @interface GroupementApiApiGroupementsGetCollectionRequest
 */
export interface GroupementApiApiGroupementsGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof GroupementApiApiGroupementsGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof GroupementApiApiGroupementsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof GroupementApiApiGroupementsGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof GroupementApiApiGroupementsGetCollection
     */
    readonly domaineId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof GroupementApiApiGroupementsGetCollection
     */
    readonly domaineId2?: Array<number>

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof GroupementApiApiGroupementsGetCollection
     */
    readonly orderLibelle?: ApiGroupementsGetCollectionOrderLibelleEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof GroupementApiApiGroupementsGetCollection
     */
    readonly orderRang?: ApiGroupementsGetCollectionOrderRangEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof GroupementApiApiGroupementsGetCollection
     */
    readonly orderDomaineLibelle?: ApiGroupementsGetCollectionOrderDomaineLibelleEnum
}

/**
 * Request parameters for apiGroupementsIdDelete operation in GroupementApi.
 * @export
 * @interface GroupementApiApiGroupementsIdDeleteRequest
 */
export interface GroupementApiApiGroupementsIdDeleteRequest {
    /**
     * TblGroupement identifier
     * @type {string}
     * @memberof GroupementApiApiGroupementsIdDelete
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof GroupementApiApiGroupementsIdDelete
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiGroupementsIdGet operation in GroupementApi.
 * @export
 * @interface GroupementApiApiGroupementsIdGetRequest
 */
export interface GroupementApiApiGroupementsIdGetRequest {
    /**
     * TblGroupement identifier
     * @type {string}
     * @memberof GroupementApiApiGroupementsIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof GroupementApiApiGroupementsIdGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiGroupementsIdPut operation in GroupementApi.
 * @export
 * @interface GroupementApiApiGroupementsIdPutRequest
 */
export interface GroupementApiApiGroupementsIdPutRequest {
    /**
     * TblGroupement identifier
     * @type {string}
     * @memberof GroupementApiApiGroupementsIdPut
     */
    readonly id: string

    /**
     * The updated Groupement resource
     * @type {GroupementJsonldGroupementUpdate}
     * @memberof GroupementApiApiGroupementsIdPut
     */
    readonly groupementJsonldGroupementUpdate: GroupementJsonldGroupementUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof GroupementApiApiGroupementsIdPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiGroupementsPost operation in GroupementApi.
 * @export
 * @interface GroupementApiApiGroupementsPostRequest
 */
export interface GroupementApiApiGroupementsPostRequest {
    /**
     * The new Groupement resource
     * @type {GroupementJsonldGroupementCreate}
     * @memberof GroupementApiApiGroupementsPost
     */
    readonly groupementJsonldGroupementCreate: GroupementJsonldGroupementCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof GroupementApiApiGroupementsPost
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiOrdresGroupementsDomainesGetCollection operation in GroupementApi.
 * @export
 * @interface GroupementApiApiOrdresGroupementsDomainesGetCollectionRequest
 */
export interface GroupementApiApiOrdresGroupementsDomainesGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof GroupementApiApiOrdresGroupementsDomainesGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof GroupementApiApiOrdresGroupementsDomainesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof GroupementApiApiOrdresGroupementsDomainesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiOrdresGroupementsDomainesIdDelete operation in GroupementApi.
 * @export
 * @interface GroupementApiApiOrdresGroupementsDomainesIdDeleteRequest
 */
export interface GroupementApiApiOrdresGroupementsDomainesIdDeleteRequest {
    /**
     * TblOrderGroupementOrigine identifier
     * @type {string}
     * @memberof GroupementApiApiOrdresGroupementsDomainesIdDelete
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof GroupementApiApiOrdresGroupementsDomainesIdDelete
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiOrdresGroupementsDomainesIdGet operation in GroupementApi.
 * @export
 * @interface GroupementApiApiOrdresGroupementsDomainesIdGetRequest
 */
export interface GroupementApiApiOrdresGroupementsDomainesIdGetRequest {
    /**
     * TblOrderGroupementOrigine identifier
     * @type {string}
     * @memberof GroupementApiApiOrdresGroupementsDomainesIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof GroupementApiApiOrdresGroupementsDomainesIdGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiOrdresGroupementsDomainesPost operation in GroupementApi.
 * @export
 * @interface GroupementApiApiOrdresGroupementsDomainesPostRequest
 */
export interface GroupementApiApiOrdresGroupementsDomainesPostRequest {
    /**
     * The new Groupement resource
     * @type {GroupementJsonldOrdreGroupementOrigineCreate}
     * @memberof GroupementApiApiOrdresGroupementsDomainesPost
     */
    readonly groupementJsonldOrdreGroupementOrigineCreate: GroupementJsonldOrdreGroupementOrigineCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof GroupementApiApiOrdresGroupementsDomainesPost
     */
    readonly xIdSociete?: number
}

/**
 * GroupementApi - object-oriented interface
 * @export
 * @class GroupementApi
 * @extends {BaseAPI}
 */
export class GroupementApi extends BaseAPI {
    /**
     * Récupérer la liste des groupements
     * @summary Récupérer la liste des groupements
     * @param {GroupementApiApiGroupementsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupementApi
     */
    public apiGroupementsGetCollection(requestParameters: GroupementApiApiGroupementsGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return GroupementApiFp(this.configuration).apiGroupementsGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.domaineId, requestParameters.domaineId2, requestParameters.orderLibelle, requestParameters.orderRang, requestParameters.orderDomaineLibelle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer un groupement
     * @summary Supprimer un groupement
     * @param {GroupementApiApiGroupementsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupementApi
     */
    public apiGroupementsIdDelete(requestParameters: GroupementApiApiGroupementsIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return GroupementApiFp(this.configuration).apiGroupementsIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un groupement
     * @summary Récupérer les informations d\'un groupement
     * @param {GroupementApiApiGroupementsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupementApi
     */
    public apiGroupementsIdGet(requestParameters: GroupementApiApiGroupementsIdGetRequest, options?: RawAxiosRequestConfig) {
        return GroupementApiFp(this.configuration).apiGroupementsIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les informations d\'un groupement
     * @summary Mettre à jour les informations d\'un groupement
     * @param {GroupementApiApiGroupementsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupementApi
     */
    public apiGroupementsIdPut(requestParameters: GroupementApiApiGroupementsIdPutRequest, options?: RawAxiosRequestConfig) {
        return GroupementApiFp(this.configuration).apiGroupementsIdPut(requestParameters.id, requestParameters.groupementJsonldGroupementUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer un groupement
     * @summary Créer un groupement
     * @param {GroupementApiApiGroupementsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupementApi
     */
    public apiGroupementsPost(requestParameters: GroupementApiApiGroupementsPostRequest, options?: RawAxiosRequestConfig) {
        return GroupementApiFp(this.configuration).apiGroupementsPost(requestParameters.groupementJsonldGroupementCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des surcharges d\'ordonnancement de groupement par domaine
     * @summary Récupérer la liste des surcharges d\'ordonnancement de groupement par domaine
     * @param {GroupementApiApiOrdresGroupementsDomainesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupementApi
     */
    public apiOrdresGroupementsDomainesGetCollection(requestParameters: GroupementApiApiOrdresGroupementsDomainesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return GroupementApiFp(this.configuration).apiOrdresGroupementsDomainesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une surcharge d\'ordonnancement de groupement par domaine
     * @summary Supprimer une surcharge d\'ordonnancement de groupement par domaine
     * @param {GroupementApiApiOrdresGroupementsDomainesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupementApi
     */
    public apiOrdresGroupementsDomainesIdDelete(requestParameters: GroupementApiApiOrdresGroupementsDomainesIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return GroupementApiFp(this.configuration).apiOrdresGroupementsDomainesIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une surcharge d\'ordonnancement de groupement par domaine
     * @summary Récupérer les informations d\'une surcharge d\'ordonnancement de groupement par domaine
     * @param {GroupementApiApiOrdresGroupementsDomainesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupementApi
     */
    public apiOrdresGroupementsDomainesIdGet(requestParameters: GroupementApiApiOrdresGroupementsDomainesIdGetRequest, options?: RawAxiosRequestConfig) {
        return GroupementApiFp(this.configuration).apiOrdresGroupementsDomainesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer une surcharge d\'ordonnancement de groupement par domaine
     * @summary Créer une surcharge d\'ordonnancement de groupement par domaine
     * @param {GroupementApiApiOrdresGroupementsDomainesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupementApi
     */
    public apiOrdresGroupementsDomainesPost(requestParameters: GroupementApiApiOrdresGroupementsDomainesPostRequest, options?: RawAxiosRequestConfig) {
        return GroupementApiFp(this.configuration).apiOrdresGroupementsDomainesPost(requestParameters.groupementJsonldOrdreGroupementOrigineCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiGroupementsGetCollectionOrderLibelleEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiGroupementsGetCollectionOrderLibelleEnum = typeof ApiGroupementsGetCollectionOrderLibelleEnum[keyof typeof ApiGroupementsGetCollectionOrderLibelleEnum];
/**
 * @export
 */
export const ApiGroupementsGetCollectionOrderRangEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiGroupementsGetCollectionOrderRangEnum = typeof ApiGroupementsGetCollectionOrderRangEnum[keyof typeof ApiGroupementsGetCollectionOrderRangEnum];
/**
 * @export
 */
export const ApiGroupementsGetCollectionOrderDomaineLibelleEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiGroupementsGetCollectionOrderDomaineLibelleEnum = typeof ApiGroupementsGetCollectionOrderDomaineLibelleEnum[keyof typeof ApiGroupementsGetCollectionOrderDomaineLibelleEnum];


/**
 * OffresApi - axios parameter creator
 * @export
 */
export const OffresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des offres
         * @summary Récupérer la liste des offres
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [domaineId] 
         * @param {Array<number>} [domaineId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOffresGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, domaineId?: number, domaineId2?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/offres`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (domaineId !== undefined) {
                localVarQueryParameter['domaine.id'] = domaineId;
            }

            if (domaineId2) {
                localVarQueryParameter['domaine.id[]'] = domaineId2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une offre
         * @summary Supprimer une offre
         * @param {string} id TblOffre identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOffresIdDelete: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOffresIdDelete', 'id', id)
            const localVarPath = `/offre/offres/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les ressources dépendante d\'une offre
         * @summary Lister les ressources dépendante d\'une offre
         * @param {string} id TblOffre identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOffresIdDependenciesGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOffresIdDependenciesGet', 'id', id)
            const localVarPath = `/offre/offres/{id}/dependencies`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une offre
         * @summary Récupérer les informations d\'une offre
         * @param {string} id TblOffre identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOffresIdGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOffresIdGet', 'id', id)
            const localVarPath = `/offre/offres/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les informations d\'une offre
         * @summary Mettre à jour les informations d\'une offre
         * @param {string} id TblOffre identifier
         * @param {OffresJsonldOffreUpdate} offresJsonldOffreUpdate The updated Offres resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOffresIdPut: async (id: string, offresJsonldOffreUpdate: OffresJsonldOffreUpdate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOffresIdPut', 'id', id)
            // verify required parameter 'offresJsonldOffreUpdate' is not null or undefined
            assertParamExists('apiOffresIdPut', 'offresJsonldOffreUpdate', offresJsonldOffreUpdate)
            const localVarPath = `/offre/offres/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offresJsonldOffreUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer une offre
         * @summary Créer une offre
         * @param {OffresJsonldOffreCreate} offresJsonldOffreCreate The new Offres resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOffresPost: async (offresJsonldOffreCreate: OffresJsonldOffreCreate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offresJsonldOffreCreate' is not null or undefined
            assertParamExists('apiOffresPost', 'offresJsonldOffreCreate', offresJsonldOffreCreate)
            const localVarPath = `/offre/offres`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offresJsonldOffreCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OffresApi - functional programming interface
 * @export
 */
export const OffresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OffresApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des offres
         * @summary Récupérer la liste des offres
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [domaineId] 
         * @param {Array<number>} [domaineId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOffresGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, domaineId?: number, domaineId2?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOffresGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOffresGetCollection(xIdSociete, page, itemsPerPage, domaineId, domaineId2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffresApi.apiOffresGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Supprimer une offre
         * @summary Supprimer une offre
         * @param {string} id TblOffre identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOffresIdDelete(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOffresIdDelete(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffresApi.apiOffresIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lister les ressources dépendante d\'une offre
         * @summary Lister les ressources dépendante d\'une offre
         * @param {string} id TblOffre identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOffresIdDependenciesGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OffresResourceDependencyJsonldDependencyRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOffresIdDependenciesGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffresApi.apiOffresIdDependenciesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une offre
         * @summary Récupérer les informations d\'une offre
         * @param {string} id TblOffre identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOffresIdGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OffresJsonldOffreRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOffresIdGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffresApi.apiOffresIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour les informations d\'une offre
         * @summary Mettre à jour les informations d\'une offre
         * @param {string} id TblOffre identifier
         * @param {OffresJsonldOffreUpdate} offresJsonldOffreUpdate The updated Offres resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOffresIdPut(id: string, offresJsonldOffreUpdate: OffresJsonldOffreUpdate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OffresJsonldOffreRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOffresIdPut(id, offresJsonldOffreUpdate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffresApi.apiOffresIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Créer une offre
         * @summary Créer une offre
         * @param {OffresJsonldOffreCreate} offresJsonldOffreCreate The new Offres resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOffresPost(offresJsonldOffreCreate: OffresJsonldOffreCreate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OffresJsonldOffreRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOffresPost(offresJsonldOffreCreate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OffresApi.apiOffresPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OffresApi - factory interface
 * @export
 */
export const OffresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OffresApiFp(configuration)
    return {
        /**
         * Récupérer la liste des offres
         * @summary Récupérer la liste des offres
         * @param {OffresApiApiOffresGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOffresGetCollection(requestParameters: OffresApiApiOffresGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiOffresGetCollection200Response> {
            return localVarFp.apiOffresGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.domaineId, requestParameters.domaineId2, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une offre
         * @summary Supprimer une offre
         * @param {OffresApiApiOffresIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOffresIdDelete(requestParameters: OffresApiApiOffresIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOffresIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les ressources dépendante d\'une offre
         * @summary Lister les ressources dépendante d\'une offre
         * @param {OffresApiApiOffresIdDependenciesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOffresIdDependenciesGet(requestParameters: OffresApiApiOffresIdDependenciesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<OffresResourceDependencyJsonldDependencyRead> {
            return localVarFp.apiOffresIdDependenciesGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une offre
         * @summary Récupérer les informations d\'une offre
         * @param {OffresApiApiOffresIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOffresIdGet(requestParameters: OffresApiApiOffresIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<OffresJsonldOffreRead> {
            return localVarFp.apiOffresIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les informations d\'une offre
         * @summary Mettre à jour les informations d\'une offre
         * @param {OffresApiApiOffresIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOffresIdPut(requestParameters: OffresApiApiOffresIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<OffresJsonldOffreRead> {
            return localVarFp.apiOffresIdPut(requestParameters.id, requestParameters.offresJsonldOffreUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer une offre
         * @summary Créer une offre
         * @param {OffresApiApiOffresPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOffresPost(requestParameters: OffresApiApiOffresPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<OffresJsonldOffreRead> {
            return localVarFp.apiOffresPost(requestParameters.offresJsonldOffreCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOffresGetCollection operation in OffresApi.
 * @export
 * @interface OffresApiApiOffresGetCollectionRequest
 */
export interface OffresApiApiOffresGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OffresApiApiOffresGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof OffresApiApiOffresGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof OffresApiApiOffresGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof OffresApiApiOffresGetCollection
     */
    readonly domaineId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof OffresApiApiOffresGetCollection
     */
    readonly domaineId2?: Array<number>
}

/**
 * Request parameters for apiOffresIdDelete operation in OffresApi.
 * @export
 * @interface OffresApiApiOffresIdDeleteRequest
 */
export interface OffresApiApiOffresIdDeleteRequest {
    /**
     * TblOffre identifier
     * @type {string}
     * @memberof OffresApiApiOffresIdDelete
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OffresApiApiOffresIdDelete
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiOffresIdDependenciesGet operation in OffresApi.
 * @export
 * @interface OffresApiApiOffresIdDependenciesGetRequest
 */
export interface OffresApiApiOffresIdDependenciesGetRequest {
    /**
     * TblOffre identifier
     * @type {string}
     * @memberof OffresApiApiOffresIdDependenciesGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OffresApiApiOffresIdDependenciesGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiOffresIdGet operation in OffresApi.
 * @export
 * @interface OffresApiApiOffresIdGetRequest
 */
export interface OffresApiApiOffresIdGetRequest {
    /**
     * TblOffre identifier
     * @type {string}
     * @memberof OffresApiApiOffresIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OffresApiApiOffresIdGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiOffresIdPut operation in OffresApi.
 * @export
 * @interface OffresApiApiOffresIdPutRequest
 */
export interface OffresApiApiOffresIdPutRequest {
    /**
     * TblOffre identifier
     * @type {string}
     * @memberof OffresApiApiOffresIdPut
     */
    readonly id: string

    /**
     * The updated Offres resource
     * @type {OffresJsonldOffreUpdate}
     * @memberof OffresApiApiOffresIdPut
     */
    readonly offresJsonldOffreUpdate: OffresJsonldOffreUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OffresApiApiOffresIdPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiOffresPost operation in OffresApi.
 * @export
 * @interface OffresApiApiOffresPostRequest
 */
export interface OffresApiApiOffresPostRequest {
    /**
     * The new Offres resource
     * @type {OffresJsonldOffreCreate}
     * @memberof OffresApiApiOffresPost
     */
    readonly offresJsonldOffreCreate: OffresJsonldOffreCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OffresApiApiOffresPost
     */
    readonly xIdSociete?: number
}

/**
 * OffresApi - object-oriented interface
 * @export
 * @class OffresApi
 * @extends {BaseAPI}
 */
export class OffresApi extends BaseAPI {
    /**
     * Récupérer la liste des offres
     * @summary Récupérer la liste des offres
     * @param {OffresApiApiOffresGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffresApi
     */
    public apiOffresGetCollection(requestParameters: OffresApiApiOffresGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return OffresApiFp(this.configuration).apiOffresGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.domaineId, requestParameters.domaineId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une offre
     * @summary Supprimer une offre
     * @param {OffresApiApiOffresIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffresApi
     */
    public apiOffresIdDelete(requestParameters: OffresApiApiOffresIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return OffresApiFp(this.configuration).apiOffresIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les ressources dépendante d\'une offre
     * @summary Lister les ressources dépendante d\'une offre
     * @param {OffresApiApiOffresIdDependenciesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffresApi
     */
    public apiOffresIdDependenciesGet(requestParameters: OffresApiApiOffresIdDependenciesGetRequest, options?: RawAxiosRequestConfig) {
        return OffresApiFp(this.configuration).apiOffresIdDependenciesGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une offre
     * @summary Récupérer les informations d\'une offre
     * @param {OffresApiApiOffresIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffresApi
     */
    public apiOffresIdGet(requestParameters: OffresApiApiOffresIdGetRequest, options?: RawAxiosRequestConfig) {
        return OffresApiFp(this.configuration).apiOffresIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les informations d\'une offre
     * @summary Mettre à jour les informations d\'une offre
     * @param {OffresApiApiOffresIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffresApi
     */
    public apiOffresIdPut(requestParameters: OffresApiApiOffresIdPutRequest, options?: RawAxiosRequestConfig) {
        return OffresApiFp(this.configuration).apiOffresIdPut(requestParameters.id, requestParameters.offresJsonldOffreUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer une offre
     * @summary Créer une offre
     * @param {OffresApiApiOffresPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OffresApi
     */
    public apiOffresPost(requestParameters: OffresApiApiOffresPostRequest, options?: RawAxiosRequestConfig) {
        return OffresApiFp(this.configuration).apiOffresPost(requestParameters.offresJsonldOffreCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OptionValueApi - axios parameter creator
 * @export
 */
export const OptionValueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des option-values
         * @summary Récupérer la liste des option-values
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionValuesGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/option-values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les ressources dépendante d\'une option-value
         * @summary Lister les ressources dépendante d\'une option-value
         * @param {string} id TblOptionValue identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionValuesIdDependenciesGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOptionValuesIdDependenciesGet', 'id', id)
            const localVarPath = `/offre/option-values/{id}/dependencies`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une option-value
         * @summary Récupérer les informations d\'une option-value
         * @param {string} id TblOptionValue identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionValuesIdGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOptionValuesIdGet', 'id', id)
            const localVarPath = `/offre/option-values/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les informations d\'un option-value
         * @summary Mettre à jour les informations d\'un option-value
         * @param {string} id TblOptionValue identifier
         * @param {OptionValueJsonldOptionValueUpdate} optionValueJsonldOptionValueUpdate The updated Option Value resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionValuesIdPut: async (id: string, optionValueJsonldOptionValueUpdate: OptionValueJsonldOptionValueUpdate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOptionValuesIdPut', 'id', id)
            // verify required parameter 'optionValueJsonldOptionValueUpdate' is not null or undefined
            assertParamExists('apiOptionValuesIdPut', 'optionValueJsonldOptionValueUpdate', optionValueJsonldOptionValueUpdate)
            const localVarPath = `/offre/option-values/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(optionValueJsonldOptionValueUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer un option-value
         * @summary Créer un option-value
         * @param {OptionValueJsonldOptionValueCreate} optionValueJsonldOptionValueCreate The new Option Value resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionValuesPost: async (optionValueJsonldOptionValueCreate: OptionValueJsonldOptionValueCreate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'optionValueJsonldOptionValueCreate' is not null or undefined
            assertParamExists('apiOptionValuesPost', 'optionValueJsonldOptionValueCreate', optionValueJsonldOptionValueCreate)
            const localVarPath = `/offre/option-values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(optionValueJsonldOptionValueCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OptionValueApi - functional programming interface
 * @export
 */
export const OptionValueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OptionValueApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des option-values
         * @summary Récupérer la liste des option-values
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOptionValuesGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOptionValuesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOptionValuesGetCollection(xIdSociete, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OptionValueApi.apiOptionValuesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lister les ressources dépendante d\'une option-value
         * @summary Lister les ressources dépendante d\'une option-value
         * @param {string} id TblOptionValue identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOptionValuesIdDependenciesGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionValueResourceDependencyJsonldDependencyRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOptionValuesIdDependenciesGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OptionValueApi.apiOptionValuesIdDependenciesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une option-value
         * @summary Récupérer les informations d\'une option-value
         * @param {string} id TblOptionValue identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOptionValuesIdGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionValueJsonldOptionValueRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOptionValuesIdGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OptionValueApi.apiOptionValuesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour les informations d\'un option-value
         * @summary Mettre à jour les informations d\'un option-value
         * @param {string} id TblOptionValue identifier
         * @param {OptionValueJsonldOptionValueUpdate} optionValueJsonldOptionValueUpdate The updated Option Value resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOptionValuesIdPut(id: string, optionValueJsonldOptionValueUpdate: OptionValueJsonldOptionValueUpdate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionValueJsonldOptionValueRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOptionValuesIdPut(id, optionValueJsonldOptionValueUpdate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OptionValueApi.apiOptionValuesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Créer un option-value
         * @summary Créer un option-value
         * @param {OptionValueJsonldOptionValueCreate} optionValueJsonldOptionValueCreate The new Option Value resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOptionValuesPost(optionValueJsonldOptionValueCreate: OptionValueJsonldOptionValueCreate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionValueJsonldOptionValueRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOptionValuesPost(optionValueJsonldOptionValueCreate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OptionValueApi.apiOptionValuesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OptionValueApi - factory interface
 * @export
 */
export const OptionValueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OptionValueApiFp(configuration)
    return {
        /**
         * Récupérer la liste des option-values
         * @summary Récupérer la liste des option-values
         * @param {OptionValueApiApiOptionValuesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionValuesGetCollection(requestParameters: OptionValueApiApiOptionValuesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiOptionValuesGetCollection200Response> {
            return localVarFp.apiOptionValuesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les ressources dépendante d\'une option-value
         * @summary Lister les ressources dépendante d\'une option-value
         * @param {OptionValueApiApiOptionValuesIdDependenciesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionValuesIdDependenciesGet(requestParameters: OptionValueApiApiOptionValuesIdDependenciesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<OptionValueResourceDependencyJsonldDependencyRead> {
            return localVarFp.apiOptionValuesIdDependenciesGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une option-value
         * @summary Récupérer les informations d\'une option-value
         * @param {OptionValueApiApiOptionValuesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionValuesIdGet(requestParameters: OptionValueApiApiOptionValuesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<OptionValueJsonldOptionValueRead> {
            return localVarFp.apiOptionValuesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les informations d\'un option-value
         * @summary Mettre à jour les informations d\'un option-value
         * @param {OptionValueApiApiOptionValuesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionValuesIdPut(requestParameters: OptionValueApiApiOptionValuesIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<OptionValueJsonldOptionValueRead> {
            return localVarFp.apiOptionValuesIdPut(requestParameters.id, requestParameters.optionValueJsonldOptionValueUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer un option-value
         * @summary Créer un option-value
         * @param {OptionValueApiApiOptionValuesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionValuesPost(requestParameters: OptionValueApiApiOptionValuesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<OptionValueJsonldOptionValueRead> {
            return localVarFp.apiOptionValuesPost(requestParameters.optionValueJsonldOptionValueCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOptionValuesGetCollection operation in OptionValueApi.
 * @export
 * @interface OptionValueApiApiOptionValuesGetCollectionRequest
 */
export interface OptionValueApiApiOptionValuesGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OptionValueApiApiOptionValuesGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof OptionValueApiApiOptionValuesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof OptionValueApiApiOptionValuesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiOptionValuesIdDependenciesGet operation in OptionValueApi.
 * @export
 * @interface OptionValueApiApiOptionValuesIdDependenciesGetRequest
 */
export interface OptionValueApiApiOptionValuesIdDependenciesGetRequest {
    /**
     * TblOptionValue identifier
     * @type {string}
     * @memberof OptionValueApiApiOptionValuesIdDependenciesGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OptionValueApiApiOptionValuesIdDependenciesGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiOptionValuesIdGet operation in OptionValueApi.
 * @export
 * @interface OptionValueApiApiOptionValuesIdGetRequest
 */
export interface OptionValueApiApiOptionValuesIdGetRequest {
    /**
     * TblOptionValue identifier
     * @type {string}
     * @memberof OptionValueApiApiOptionValuesIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OptionValueApiApiOptionValuesIdGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiOptionValuesIdPut operation in OptionValueApi.
 * @export
 * @interface OptionValueApiApiOptionValuesIdPutRequest
 */
export interface OptionValueApiApiOptionValuesIdPutRequest {
    /**
     * TblOptionValue identifier
     * @type {string}
     * @memberof OptionValueApiApiOptionValuesIdPut
     */
    readonly id: string

    /**
     * The updated Option Value resource
     * @type {OptionValueJsonldOptionValueUpdate}
     * @memberof OptionValueApiApiOptionValuesIdPut
     */
    readonly optionValueJsonldOptionValueUpdate: OptionValueJsonldOptionValueUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OptionValueApiApiOptionValuesIdPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiOptionValuesPost operation in OptionValueApi.
 * @export
 * @interface OptionValueApiApiOptionValuesPostRequest
 */
export interface OptionValueApiApiOptionValuesPostRequest {
    /**
     * The new Option Value resource
     * @type {OptionValueJsonldOptionValueCreate}
     * @memberof OptionValueApiApiOptionValuesPost
     */
    readonly optionValueJsonldOptionValueCreate: OptionValueJsonldOptionValueCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OptionValueApiApiOptionValuesPost
     */
    readonly xIdSociete?: number
}

/**
 * OptionValueApi - object-oriented interface
 * @export
 * @class OptionValueApi
 * @extends {BaseAPI}
 */
export class OptionValueApi extends BaseAPI {
    /**
     * Récupérer la liste des option-values
     * @summary Récupérer la liste des option-values
     * @param {OptionValueApiApiOptionValuesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionValueApi
     */
    public apiOptionValuesGetCollection(requestParameters: OptionValueApiApiOptionValuesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return OptionValueApiFp(this.configuration).apiOptionValuesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les ressources dépendante d\'une option-value
     * @summary Lister les ressources dépendante d\'une option-value
     * @param {OptionValueApiApiOptionValuesIdDependenciesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionValueApi
     */
    public apiOptionValuesIdDependenciesGet(requestParameters: OptionValueApiApiOptionValuesIdDependenciesGetRequest, options?: RawAxiosRequestConfig) {
        return OptionValueApiFp(this.configuration).apiOptionValuesIdDependenciesGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une option-value
     * @summary Récupérer les informations d\'une option-value
     * @param {OptionValueApiApiOptionValuesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionValueApi
     */
    public apiOptionValuesIdGet(requestParameters: OptionValueApiApiOptionValuesIdGetRequest, options?: RawAxiosRequestConfig) {
        return OptionValueApiFp(this.configuration).apiOptionValuesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les informations d\'un option-value
     * @summary Mettre à jour les informations d\'un option-value
     * @param {OptionValueApiApiOptionValuesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionValueApi
     */
    public apiOptionValuesIdPut(requestParameters: OptionValueApiApiOptionValuesIdPutRequest, options?: RawAxiosRequestConfig) {
        return OptionValueApiFp(this.configuration).apiOptionValuesIdPut(requestParameters.id, requestParameters.optionValueJsonldOptionValueUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer un option-value
     * @summary Créer un option-value
     * @param {OptionValueApiApiOptionValuesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionValueApi
     */
    public apiOptionValuesPost(requestParameters: OptionValueApiApiOptionValuesPostRequest, options?: RawAxiosRequestConfig) {
        return OptionValueApiFp(this.configuration).apiOptionValuesPost(requestParameters.optionValueJsonldOptionValueCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OptionsApi - axios parameter creator
 * @export
 */
export const OptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des options
         * @summary Récupérer la liste des options
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [domaineId] 
         * @param {Array<number>} [domaineId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionsGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, domaineId?: number, domaineId2?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (domaineId !== undefined) {
                localVarQueryParameter['domaine.id'] = domaineId;
            }

            if (domaineId2) {
                localVarQueryParameter['domaine.id[]'] = domaineId2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les ressources dépendante d\'une option
         * @summary Lister les ressources dépendante d\'une option
         * @param {string} id TblOption identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionsIdDependenciesGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOptionsIdDependenciesGet', 'id', id)
            const localVarPath = `/offre/options/{id}/dependencies`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une option
         * @summary Récupérer les informations d\'une option
         * @param {string} id TblOption identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionsIdGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOptionsIdGet', 'id', id)
            const localVarPath = `/offre/options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les informations d\'un option
         * @summary Mettre à jour les informations d\'un option
         * @param {string} id TblOption identifier
         * @param {OptionsJsonldOptionUpdate} optionsJsonldOptionUpdate The updated Options resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionsIdPut: async (id: string, optionsJsonldOptionUpdate: OptionsJsonldOptionUpdate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOptionsIdPut', 'id', id)
            // verify required parameter 'optionsJsonldOptionUpdate' is not null or undefined
            assertParamExists('apiOptionsIdPut', 'optionsJsonldOptionUpdate', optionsJsonldOptionUpdate)
            const localVarPath = `/offre/options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(optionsJsonldOptionUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer un option
         * @summary Créer un option
         * @param {OptionsJsonldOptionCreate} optionsJsonldOptionCreate The new Options resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionsPost: async (optionsJsonldOptionCreate: OptionsJsonldOptionCreate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'optionsJsonldOptionCreate' is not null or undefined
            assertParamExists('apiOptionsPost', 'optionsJsonldOptionCreate', optionsJsonldOptionCreate)
            const localVarPath = `/offre/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(optionsJsonldOptionCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OptionsApi - functional programming interface
 * @export
 */
export const OptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des options
         * @summary Récupérer la liste des options
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [domaineId] 
         * @param {Array<number>} [domaineId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOptionsGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, domaineId?: number, domaineId2?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOptionsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOptionsGetCollection(xIdSociete, page, itemsPerPage, domaineId, domaineId2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OptionsApi.apiOptionsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lister les ressources dépendante d\'une option
         * @summary Lister les ressources dépendante d\'une option
         * @param {string} id TblOption identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOptionsIdDependenciesGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionsResourceDependencyJsonldDependencyRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOptionsIdDependenciesGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OptionsApi.apiOptionsIdDependenciesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une option
         * @summary Récupérer les informations d\'une option
         * @param {string} id TblOption identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOptionsIdGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionsJsonldOptionRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOptionsIdGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OptionsApi.apiOptionsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour les informations d\'un option
         * @summary Mettre à jour les informations d\'un option
         * @param {string} id TblOption identifier
         * @param {OptionsJsonldOptionUpdate} optionsJsonldOptionUpdate The updated Options resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOptionsIdPut(id: string, optionsJsonldOptionUpdate: OptionsJsonldOptionUpdate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionsJsonldOptionRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOptionsIdPut(id, optionsJsonldOptionUpdate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OptionsApi.apiOptionsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Créer un option
         * @summary Créer un option
         * @param {OptionsJsonldOptionCreate} optionsJsonldOptionCreate The new Options resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOptionsPost(optionsJsonldOptionCreate: OptionsJsonldOptionCreate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionsJsonldOptionRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOptionsPost(optionsJsonldOptionCreate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OptionsApi.apiOptionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OptionsApi - factory interface
 * @export
 */
export const OptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OptionsApiFp(configuration)
    return {
        /**
         * Récupérer la liste des options
         * @summary Récupérer la liste des options
         * @param {OptionsApiApiOptionsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionsGetCollection(requestParameters: OptionsApiApiOptionsGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiOptionsGetCollection200Response> {
            return localVarFp.apiOptionsGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.domaineId, requestParameters.domaineId2, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les ressources dépendante d\'une option
         * @summary Lister les ressources dépendante d\'une option
         * @param {OptionsApiApiOptionsIdDependenciesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionsIdDependenciesGet(requestParameters: OptionsApiApiOptionsIdDependenciesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<OptionsResourceDependencyJsonldDependencyRead> {
            return localVarFp.apiOptionsIdDependenciesGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une option
         * @summary Récupérer les informations d\'une option
         * @param {OptionsApiApiOptionsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionsIdGet(requestParameters: OptionsApiApiOptionsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<OptionsJsonldOptionRead> {
            return localVarFp.apiOptionsIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les informations d\'un option
         * @summary Mettre à jour les informations d\'un option
         * @param {OptionsApiApiOptionsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionsIdPut(requestParameters: OptionsApiApiOptionsIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<OptionsJsonldOptionRead> {
            return localVarFp.apiOptionsIdPut(requestParameters.id, requestParameters.optionsJsonldOptionUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer un option
         * @summary Créer un option
         * @param {OptionsApiApiOptionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOptionsPost(requestParameters: OptionsApiApiOptionsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<OptionsJsonldOptionRead> {
            return localVarFp.apiOptionsPost(requestParameters.optionsJsonldOptionCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOptionsGetCollection operation in OptionsApi.
 * @export
 * @interface OptionsApiApiOptionsGetCollectionRequest
 */
export interface OptionsApiApiOptionsGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OptionsApiApiOptionsGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof OptionsApiApiOptionsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof OptionsApiApiOptionsGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof OptionsApiApiOptionsGetCollection
     */
    readonly domaineId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof OptionsApiApiOptionsGetCollection
     */
    readonly domaineId2?: Array<number>
}

/**
 * Request parameters for apiOptionsIdDependenciesGet operation in OptionsApi.
 * @export
 * @interface OptionsApiApiOptionsIdDependenciesGetRequest
 */
export interface OptionsApiApiOptionsIdDependenciesGetRequest {
    /**
     * TblOption identifier
     * @type {string}
     * @memberof OptionsApiApiOptionsIdDependenciesGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OptionsApiApiOptionsIdDependenciesGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiOptionsIdGet operation in OptionsApi.
 * @export
 * @interface OptionsApiApiOptionsIdGetRequest
 */
export interface OptionsApiApiOptionsIdGetRequest {
    /**
     * TblOption identifier
     * @type {string}
     * @memberof OptionsApiApiOptionsIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OptionsApiApiOptionsIdGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiOptionsIdPut operation in OptionsApi.
 * @export
 * @interface OptionsApiApiOptionsIdPutRequest
 */
export interface OptionsApiApiOptionsIdPutRequest {
    /**
     * TblOption identifier
     * @type {string}
     * @memberof OptionsApiApiOptionsIdPut
     */
    readonly id: string

    /**
     * The updated Options resource
     * @type {OptionsJsonldOptionUpdate}
     * @memberof OptionsApiApiOptionsIdPut
     */
    readonly optionsJsonldOptionUpdate: OptionsJsonldOptionUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OptionsApiApiOptionsIdPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiOptionsPost operation in OptionsApi.
 * @export
 * @interface OptionsApiApiOptionsPostRequest
 */
export interface OptionsApiApiOptionsPostRequest {
    /**
     * The new Options resource
     * @type {OptionsJsonldOptionCreate}
     * @memberof OptionsApiApiOptionsPost
     */
    readonly optionsJsonldOptionCreate: OptionsJsonldOptionCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OptionsApiApiOptionsPost
     */
    readonly xIdSociete?: number
}

/**
 * OptionsApi - object-oriented interface
 * @export
 * @class OptionsApi
 * @extends {BaseAPI}
 */
export class OptionsApi extends BaseAPI {
    /**
     * Récupérer la liste des options
     * @summary Récupérer la liste des options
     * @param {OptionsApiApiOptionsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public apiOptionsGetCollection(requestParameters: OptionsApiApiOptionsGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return OptionsApiFp(this.configuration).apiOptionsGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.domaineId, requestParameters.domaineId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les ressources dépendante d\'une option
     * @summary Lister les ressources dépendante d\'une option
     * @param {OptionsApiApiOptionsIdDependenciesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public apiOptionsIdDependenciesGet(requestParameters: OptionsApiApiOptionsIdDependenciesGetRequest, options?: RawAxiosRequestConfig) {
        return OptionsApiFp(this.configuration).apiOptionsIdDependenciesGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une option
     * @summary Récupérer les informations d\'une option
     * @param {OptionsApiApiOptionsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public apiOptionsIdGet(requestParameters: OptionsApiApiOptionsIdGetRequest, options?: RawAxiosRequestConfig) {
        return OptionsApiFp(this.configuration).apiOptionsIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les informations d\'un option
     * @summary Mettre à jour les informations d\'un option
     * @param {OptionsApiApiOptionsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public apiOptionsIdPut(requestParameters: OptionsApiApiOptionsIdPutRequest, options?: RawAxiosRequestConfig) {
        return OptionsApiFp(this.configuration).apiOptionsIdPut(requestParameters.id, requestParameters.optionsJsonldOptionUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer un option
     * @summary Créer un option
     * @param {OptionsApiApiOptionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public apiOptionsPost(requestParameters: OptionsApiApiOptionsPostRequest, options?: RawAxiosRequestConfig) {
        return OptionsApiFp(this.configuration).apiOptionsPost(requestParameters.optionsJsonldOptionCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OriginesApi - axios parameter creator
 * @export
 */
export const OriginesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des origines
         * @summary Récupérer la liste des origines
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [origine] 
         * @param {string} [baseUrl] 
         * @param {Array<string>} [baseUrl2] 
         * @param {number} [domainesDomaineId] 
         * @param {Array<number>} [domainesDomaineId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, origine?: string, baseUrl?: string, baseUrl2?: Array<string>, domainesDomaineId?: number, domainesDomaineId2?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/origines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (origine !== undefined) {
                localVarQueryParameter['origine'] = origine;
            }

            if (baseUrl !== undefined) {
                localVarQueryParameter['baseUrl'] = baseUrl;
            }

            if (baseUrl2) {
                localVarQueryParameter['baseUrl[]'] = baseUrl2;
            }

            if (domainesDomaineId !== undefined) {
                localVarQueryParameter['domaines.domaine.id'] = domainesDomaineId;
            }

            if (domainesDomaineId2) {
                localVarQueryParameter['domaines.domaine.id[]'] = domainesDomaineId2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OriginesApi - functional programming interface
 * @export
 */
export const OriginesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OriginesApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des origines
         * @summary Récupérer la liste des origines
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [origine] 
         * @param {string} [baseUrl] 
         * @param {Array<string>} [baseUrl2] 
         * @param {number} [domainesDomaineId] 
         * @param {Array<number>} [domainesDomaineId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOriginesGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, origine?: string, baseUrl?: string, baseUrl2?: Array<string>, domainesDomaineId?: number, domainesDomaineId2?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOriginesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOriginesGetCollection(xIdSociete, page, itemsPerPage, origine, baseUrl, baseUrl2, domainesDomaineId, domainesDomaineId2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OriginesApi.apiOriginesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OriginesApi - factory interface
 * @export
 */
export const OriginesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OriginesApiFp(configuration)
    return {
        /**
         * Récupérer la liste des origines
         * @summary Récupérer la liste des origines
         * @param {OriginesApiApiOriginesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesGetCollection(requestParameters: OriginesApiApiOriginesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiOriginesGetCollection200Response> {
            return localVarFp.apiOriginesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.origine, requestParameters.baseUrl, requestParameters.baseUrl2, requestParameters.domainesDomaineId, requestParameters.domainesDomaineId2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOriginesGetCollection operation in OriginesApi.
 * @export
 * @interface OriginesApiApiOriginesGetCollectionRequest
 */
export interface OriginesApiApiOriginesGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OriginesApiApiOriginesGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof OriginesApiApiOriginesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof OriginesApiApiOriginesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof OriginesApiApiOriginesGetCollection
     */
    readonly origine?: string

    /**
     * 
     * @type {string}
     * @memberof OriginesApiApiOriginesGetCollection
     */
    readonly baseUrl?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof OriginesApiApiOriginesGetCollection
     */
    readonly baseUrl2?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof OriginesApiApiOriginesGetCollection
     */
    readonly domainesDomaineId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof OriginesApiApiOriginesGetCollection
     */
    readonly domainesDomaineId2?: Array<number>
}

/**
 * OriginesApi - object-oriented interface
 * @export
 * @class OriginesApi
 * @extends {BaseAPI}
 */
export class OriginesApi extends BaseAPI {
    /**
     * Récupérer la liste des origines
     * @summary Récupérer la liste des origines
     * @param {OriginesApiApiOriginesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OriginesApi
     */
    public apiOriginesGetCollection(requestParameters: OriginesApiApiOriginesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return OriginesApiFp(this.configuration).apiOriginesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.origine, requestParameters.baseUrl, requestParameters.baseUrl2, requestParameters.domainesDomaineId, requestParameters.domainesDomaineId2, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OriginesOffresApi - axios parameter creator
 * @export
 */
export const OriginesOffresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des relations entre offres et origines
         * @summary Récupérer la liste des relations entre offres et origines
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [origineId] 
         * @param {Array<number>} [origineId2] 
         * @param {number} [offreId] 
         * @param {Array<number>} [offreId2] 
         * @param {boolean} [_default] 
         * @param {string} [dateDebutBefore] 
         * @param {string} [dateDebutStrictlyBefore] 
         * @param {string} [dateDebutAfter] 
         * @param {string} [dateDebutStrictlyAfter] 
         * @param {string} [dateFinBefore] 
         * @param {string} [dateFinStrictlyBefore] 
         * @param {string} [dateFinAfter] 
         * @param {string} [dateFinStrictlyAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesOffresGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, origineId?: number, origineId2?: Array<number>, offreId?: number, offreId2?: Array<number>, _default?: boolean, dateDebutBefore?: string, dateDebutStrictlyBefore?: string, dateDebutAfter?: string, dateDebutStrictlyAfter?: string, dateFinBefore?: string, dateFinStrictlyBefore?: string, dateFinAfter?: string, dateFinStrictlyAfter?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/origines-offres`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (origineId !== undefined) {
                localVarQueryParameter['origine.id'] = origineId;
            }

            if (origineId2) {
                localVarQueryParameter['origine.id[]'] = origineId2;
            }

            if (offreId !== undefined) {
                localVarQueryParameter['offre.id'] = offreId;
            }

            if (offreId2) {
                localVarQueryParameter['offre.id[]'] = offreId2;
            }

            if (_default !== undefined) {
                localVarQueryParameter['default'] = _default;
            }

            if (dateDebutBefore !== undefined) {
                localVarQueryParameter['dateDebut[before]'] = dateDebutBefore;
            }

            if (dateDebutStrictlyBefore !== undefined) {
                localVarQueryParameter['dateDebut[strictly_before]'] = dateDebutStrictlyBefore;
            }

            if (dateDebutAfter !== undefined) {
                localVarQueryParameter['dateDebut[after]'] = dateDebutAfter;
            }

            if (dateDebutStrictlyAfter !== undefined) {
                localVarQueryParameter['dateDebut[strictly_after]'] = dateDebutStrictlyAfter;
            }

            if (dateFinBefore !== undefined) {
                localVarQueryParameter['dateFin[before]'] = dateFinBefore;
            }

            if (dateFinStrictlyBefore !== undefined) {
                localVarQueryParameter['dateFin[strictly_before]'] = dateFinStrictlyBefore;
            }

            if (dateFinAfter !== undefined) {
                localVarQueryParameter['dateFin[after]'] = dateFinAfter;
            }

            if (dateFinStrictlyAfter !== undefined) {
                localVarQueryParameter['dateFin[strictly_after]'] = dateFinStrictlyAfter;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifier une relation en une offre et une origine
         * @summary Modifier une relation en une offre et une origine
         * @param {string} id TblRelOrigineOffre identifier
         * @param {OriginesOffresJsonldOrigineOffreUpdate} originesOffresJsonldOrigineOffreUpdate The updated Origines Offres resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesOffresIdPut: async (id: string, originesOffresJsonldOrigineOffreUpdate: OriginesOffresJsonldOrigineOffreUpdate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOriginesOffresIdPut', 'id', id)
            // verify required parameter 'originesOffresJsonldOrigineOffreUpdate' is not null or undefined
            assertParamExists('apiOriginesOffresIdPut', 'originesOffresJsonldOrigineOffreUpdate', originesOffresJsonldOrigineOffreUpdate)
            const localVarPath = `/offre/origines-offres/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(originesOffresJsonldOrigineOffreUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer une relation en une offre et une origine
         * @summary Créer une relation en une offre et une origine
         * @param {OriginesOffresJsonldOrigineOffreCreate} originesOffresJsonldOrigineOffreCreate The new Origines Offres resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesOffresPost: async (originesOffresJsonldOrigineOffreCreate: OriginesOffresJsonldOrigineOffreCreate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'originesOffresJsonldOrigineOffreCreate' is not null or undefined
            assertParamExists('apiOriginesOffresPost', 'originesOffresJsonldOrigineOffreCreate', originesOffresJsonldOrigineOffreCreate)
            const localVarPath = `/offre/origines-offres`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(originesOffresJsonldOrigineOffreCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OriginesOffresApi - functional programming interface
 * @export
 */
export const OriginesOffresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OriginesOffresApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des relations entre offres et origines
         * @summary Récupérer la liste des relations entre offres et origines
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [origineId] 
         * @param {Array<number>} [origineId2] 
         * @param {number} [offreId] 
         * @param {Array<number>} [offreId2] 
         * @param {boolean} [_default] 
         * @param {string} [dateDebutBefore] 
         * @param {string} [dateDebutStrictlyBefore] 
         * @param {string} [dateDebutAfter] 
         * @param {string} [dateDebutStrictlyAfter] 
         * @param {string} [dateFinBefore] 
         * @param {string} [dateFinStrictlyBefore] 
         * @param {string} [dateFinAfter] 
         * @param {string} [dateFinStrictlyAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOriginesOffresGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, origineId?: number, origineId2?: Array<number>, offreId?: number, offreId2?: Array<number>, _default?: boolean, dateDebutBefore?: string, dateDebutStrictlyBefore?: string, dateDebutAfter?: string, dateDebutStrictlyAfter?: string, dateFinBefore?: string, dateFinStrictlyBefore?: string, dateFinAfter?: string, dateFinStrictlyAfter?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOriginesOffresGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOriginesOffresGetCollection(xIdSociete, page, itemsPerPage, origineId, origineId2, offreId, offreId2, _default, dateDebutBefore, dateDebutStrictlyBefore, dateDebutAfter, dateDebutStrictlyAfter, dateFinBefore, dateFinStrictlyBefore, dateFinAfter, dateFinStrictlyAfter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OriginesOffresApi.apiOriginesOffresGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Modifier une relation en une offre et une origine
         * @summary Modifier une relation en une offre et une origine
         * @param {string} id TblRelOrigineOffre identifier
         * @param {OriginesOffresJsonldOrigineOffreUpdate} originesOffresJsonldOrigineOffreUpdate The updated Origines Offres resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOriginesOffresIdPut(id: string, originesOffresJsonldOrigineOffreUpdate: OriginesOffresJsonldOrigineOffreUpdate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OriginesOffresJsonldOrigineOffreRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOriginesOffresIdPut(id, originesOffresJsonldOrigineOffreUpdate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OriginesOffresApi.apiOriginesOffresIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Créer une relation en une offre et une origine
         * @summary Créer une relation en une offre et une origine
         * @param {OriginesOffresJsonldOrigineOffreCreate} originesOffresJsonldOrigineOffreCreate The new Origines Offres resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOriginesOffresPost(originesOffresJsonldOrigineOffreCreate: OriginesOffresJsonldOrigineOffreCreate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OriginesOffresJsonldOrigineOffreRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOriginesOffresPost(originesOffresJsonldOrigineOffreCreate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OriginesOffresApi.apiOriginesOffresPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OriginesOffresApi - factory interface
 * @export
 */
export const OriginesOffresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OriginesOffresApiFp(configuration)
    return {
        /**
         * Récupérer la liste des relations entre offres et origines
         * @summary Récupérer la liste des relations entre offres et origines
         * @param {OriginesOffresApiApiOriginesOffresGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesOffresGetCollection(requestParameters: OriginesOffresApiApiOriginesOffresGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiOriginesOffresGetCollection200Response> {
            return localVarFp.apiOriginesOffresGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.origineId, requestParameters.origineId2, requestParameters.offreId, requestParameters.offreId2, requestParameters._default, requestParameters.dateDebutBefore, requestParameters.dateDebutStrictlyBefore, requestParameters.dateDebutAfter, requestParameters.dateDebutStrictlyAfter, requestParameters.dateFinBefore, requestParameters.dateFinStrictlyBefore, requestParameters.dateFinAfter, requestParameters.dateFinStrictlyAfter, options).then((request) => request(axios, basePath));
        },
        /**
         * Modifier une relation en une offre et une origine
         * @summary Modifier une relation en une offre et une origine
         * @param {OriginesOffresApiApiOriginesOffresIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesOffresIdPut(requestParameters: OriginesOffresApiApiOriginesOffresIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<OriginesOffresJsonldOrigineOffreRead> {
            return localVarFp.apiOriginesOffresIdPut(requestParameters.id, requestParameters.originesOffresJsonldOrigineOffreUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer une relation en une offre et une origine
         * @summary Créer une relation en une offre et une origine
         * @param {OriginesOffresApiApiOriginesOffresPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesOffresPost(requestParameters: OriginesOffresApiApiOriginesOffresPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<OriginesOffresJsonldOrigineOffreRead> {
            return localVarFp.apiOriginesOffresPost(requestParameters.originesOffresJsonldOrigineOffreCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOriginesOffresGetCollection operation in OriginesOffresApi.
 * @export
 * @interface OriginesOffresApiApiOriginesOffresGetCollectionRequest
 */
export interface OriginesOffresApiApiOriginesOffresGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OriginesOffresApiApiOriginesOffresGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof OriginesOffresApiApiOriginesOffresGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof OriginesOffresApiApiOriginesOffresGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof OriginesOffresApiApiOriginesOffresGetCollection
     */
    readonly origineId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof OriginesOffresApiApiOriginesOffresGetCollection
     */
    readonly origineId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof OriginesOffresApiApiOriginesOffresGetCollection
     */
    readonly offreId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof OriginesOffresApiApiOriginesOffresGetCollection
     */
    readonly offreId2?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof OriginesOffresApiApiOriginesOffresGetCollection
     */
    readonly _default?: boolean

    /**
     * 
     * @type {string}
     * @memberof OriginesOffresApiApiOriginesOffresGetCollection
     */
    readonly dateDebutBefore?: string

    /**
     * 
     * @type {string}
     * @memberof OriginesOffresApiApiOriginesOffresGetCollection
     */
    readonly dateDebutStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof OriginesOffresApiApiOriginesOffresGetCollection
     */
    readonly dateDebutAfter?: string

    /**
     * 
     * @type {string}
     * @memberof OriginesOffresApiApiOriginesOffresGetCollection
     */
    readonly dateDebutStrictlyAfter?: string

    /**
     * 
     * @type {string}
     * @memberof OriginesOffresApiApiOriginesOffresGetCollection
     */
    readonly dateFinBefore?: string

    /**
     * 
     * @type {string}
     * @memberof OriginesOffresApiApiOriginesOffresGetCollection
     */
    readonly dateFinStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof OriginesOffresApiApiOriginesOffresGetCollection
     */
    readonly dateFinAfter?: string

    /**
     * 
     * @type {string}
     * @memberof OriginesOffresApiApiOriginesOffresGetCollection
     */
    readonly dateFinStrictlyAfter?: string
}

/**
 * Request parameters for apiOriginesOffresIdPut operation in OriginesOffresApi.
 * @export
 * @interface OriginesOffresApiApiOriginesOffresIdPutRequest
 */
export interface OriginesOffresApiApiOriginesOffresIdPutRequest {
    /**
     * TblRelOrigineOffre identifier
     * @type {string}
     * @memberof OriginesOffresApiApiOriginesOffresIdPut
     */
    readonly id: string

    /**
     * The updated Origines Offres resource
     * @type {OriginesOffresJsonldOrigineOffreUpdate}
     * @memberof OriginesOffresApiApiOriginesOffresIdPut
     */
    readonly originesOffresJsonldOrigineOffreUpdate: OriginesOffresJsonldOrigineOffreUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OriginesOffresApiApiOriginesOffresIdPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiOriginesOffresPost operation in OriginesOffresApi.
 * @export
 * @interface OriginesOffresApiApiOriginesOffresPostRequest
 */
export interface OriginesOffresApiApiOriginesOffresPostRequest {
    /**
     * The new Origines Offres resource
     * @type {OriginesOffresJsonldOrigineOffreCreate}
     * @memberof OriginesOffresApiApiOriginesOffresPost
     */
    readonly originesOffresJsonldOrigineOffreCreate: OriginesOffresJsonldOrigineOffreCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OriginesOffresApiApiOriginesOffresPost
     */
    readonly xIdSociete?: number
}

/**
 * OriginesOffresApi - object-oriented interface
 * @export
 * @class OriginesOffresApi
 * @extends {BaseAPI}
 */
export class OriginesOffresApi extends BaseAPI {
    /**
     * Récupérer la liste des relations entre offres et origines
     * @summary Récupérer la liste des relations entre offres et origines
     * @param {OriginesOffresApiApiOriginesOffresGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OriginesOffresApi
     */
    public apiOriginesOffresGetCollection(requestParameters: OriginesOffresApiApiOriginesOffresGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return OriginesOffresApiFp(this.configuration).apiOriginesOffresGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.origineId, requestParameters.origineId2, requestParameters.offreId, requestParameters.offreId2, requestParameters._default, requestParameters.dateDebutBefore, requestParameters.dateDebutStrictlyBefore, requestParameters.dateDebutAfter, requestParameters.dateDebutStrictlyAfter, requestParameters.dateFinBefore, requestParameters.dateFinStrictlyBefore, requestParameters.dateFinAfter, requestParameters.dateFinStrictlyAfter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modifier une relation en une offre et une origine
     * @summary Modifier une relation en une offre et une origine
     * @param {OriginesOffresApiApiOriginesOffresIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OriginesOffresApi
     */
    public apiOriginesOffresIdPut(requestParameters: OriginesOffresApiApiOriginesOffresIdPutRequest, options?: RawAxiosRequestConfig) {
        return OriginesOffresApiFp(this.configuration).apiOriginesOffresIdPut(requestParameters.id, requestParameters.originesOffresJsonldOrigineOffreUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer une relation en une offre et une origine
     * @summary Créer une relation en une offre et une origine
     * @param {OriginesOffresApiApiOriginesOffresPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OriginesOffresApi
     */
    public apiOriginesOffresPost(requestParameters: OriginesOffresApiApiOriginesOffresPostRequest, options?: RawAxiosRequestConfig) {
        return OriginesOffresApiFp(this.configuration).apiOriginesOffresPost(requestParameters.originesOffresJsonldOrigineOffreCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OriginesOffresTiersApi - axios parameter creator
 * @export
 */
export const OriginesOffresTiersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des association entre tiers et relations offres/origines
         * @summary Récupérer la liste des association entre tiers et relations offres/origines
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [tiersId] 
         * @param {Array<number>} [tiersId2] 
         * @param {number} [offreOrigineId] 
         * @param {Array<number>} [offreOrigineId2] 
         * @param {number} [offreOrigineOffreId] 
         * @param {Array<number>} [offreOrigineOffreId2] 
         * @param {number} [offreOrigineOrigineId] 
         * @param {Array<number>} [offreOrigineOrigineId2] 
         * @param {boolean} [_default] 
         * @param {string} [dateDebutBefore] 
         * @param {string} [dateDebutStrictlyBefore] 
         * @param {string} [dateDebutAfter] 
         * @param {string} [dateDebutStrictlyAfter] 
         * @param {string} [dateFinBefore] 
         * @param {string} [dateFinStrictlyBefore] 
         * @param {string} [dateFinAfter] 
         * @param {string} [dateFinStrictlyAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesOffresTiersGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, tiersId?: number, tiersId2?: Array<number>, offreOrigineId?: number, offreOrigineId2?: Array<number>, offreOrigineOffreId?: number, offreOrigineOffreId2?: Array<number>, offreOrigineOrigineId?: number, offreOrigineOrigineId2?: Array<number>, _default?: boolean, dateDebutBefore?: string, dateDebutStrictlyBefore?: string, dateDebutAfter?: string, dateDebutStrictlyAfter?: string, dateFinBefore?: string, dateFinStrictlyBefore?: string, dateFinAfter?: string, dateFinStrictlyAfter?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/origines-offres-tiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (tiersId !== undefined) {
                localVarQueryParameter['tiers.id'] = tiersId;
            }

            if (tiersId2) {
                localVarQueryParameter['tiers.id[]'] = tiersId2;
            }

            if (offreOrigineId !== undefined) {
                localVarQueryParameter['offreOrigine.id'] = offreOrigineId;
            }

            if (offreOrigineId2) {
                localVarQueryParameter['offreOrigine.id[]'] = offreOrigineId2;
            }

            if (offreOrigineOffreId !== undefined) {
                localVarQueryParameter['offreOrigine.offre.id'] = offreOrigineOffreId;
            }

            if (offreOrigineOffreId2) {
                localVarQueryParameter['offreOrigine.offre.id[]'] = offreOrigineOffreId2;
            }

            if (offreOrigineOrigineId !== undefined) {
                localVarQueryParameter['offreOrigine.origine.id'] = offreOrigineOrigineId;
            }

            if (offreOrigineOrigineId2) {
                localVarQueryParameter['offreOrigine.origine.id[]'] = offreOrigineOrigineId2;
            }

            if (_default !== undefined) {
                localVarQueryParameter['default'] = _default;
            }

            if (dateDebutBefore !== undefined) {
                localVarQueryParameter['dateDebut[before]'] = dateDebutBefore;
            }

            if (dateDebutStrictlyBefore !== undefined) {
                localVarQueryParameter['dateDebut[strictly_before]'] = dateDebutStrictlyBefore;
            }

            if (dateDebutAfter !== undefined) {
                localVarQueryParameter['dateDebut[after]'] = dateDebutAfter;
            }

            if (dateDebutStrictlyAfter !== undefined) {
                localVarQueryParameter['dateDebut[strictly_after]'] = dateDebutStrictlyAfter;
            }

            if (dateFinBefore !== undefined) {
                localVarQueryParameter['dateFin[before]'] = dateFinBefore;
            }

            if (dateFinStrictlyBefore !== undefined) {
                localVarQueryParameter['dateFin[strictly_before]'] = dateFinStrictlyBefore;
            }

            if (dateFinAfter !== undefined) {
                localVarQueryParameter['dateFin[after]'] = dateFinAfter;
            }

            if (dateFinStrictlyAfter !== undefined) {
                localVarQueryParameter['dateFin[strictly_after]'] = dateFinStrictlyAfter;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifier association entre un tiers et une relation offre/origine
         * @summary Modifier association entre un tiers et une relation offre/origine
         * @param {string} id TblRelOffreTiers identifier
         * @param {OriginesOffresTiersJsonldOrigineOffreTiersUpdate} originesOffresTiersJsonldOrigineOffreTiersUpdate The updated Origines Offres Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesOffresTiersIdPut: async (id: string, originesOffresTiersJsonldOrigineOffreTiersUpdate: OriginesOffresTiersJsonldOrigineOffreTiersUpdate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOriginesOffresTiersIdPut', 'id', id)
            // verify required parameter 'originesOffresTiersJsonldOrigineOffreTiersUpdate' is not null or undefined
            assertParamExists('apiOriginesOffresTiersIdPut', 'originesOffresTiersJsonldOrigineOffreTiersUpdate', originesOffresTiersJsonldOrigineOffreTiersUpdate)
            const localVarPath = `/offre/origines-offres-tiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(originesOffresTiersJsonldOrigineOffreTiersUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Associer une relation offre/origine à un tiers
         * @summary Associer une relation offre/origine à un tiers
         * @param {OriginesOffresTiersJsonldOrigineOffreTiersCreate} originesOffresTiersJsonldOrigineOffreTiersCreate The new Origines Offres Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesOffresTiersPost: async (originesOffresTiersJsonldOrigineOffreTiersCreate: OriginesOffresTiersJsonldOrigineOffreTiersCreate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'originesOffresTiersJsonldOrigineOffreTiersCreate' is not null or undefined
            assertParamExists('apiOriginesOffresTiersPost', 'originesOffresTiersJsonldOrigineOffreTiersCreate', originesOffresTiersJsonldOrigineOffreTiersCreate)
            const localVarPath = `/offre/origines-offres-tiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(originesOffresTiersJsonldOrigineOffreTiersCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OriginesOffresTiersApi - functional programming interface
 * @export
 */
export const OriginesOffresTiersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OriginesOffresTiersApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des association entre tiers et relations offres/origines
         * @summary Récupérer la liste des association entre tiers et relations offres/origines
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [tiersId] 
         * @param {Array<number>} [tiersId2] 
         * @param {number} [offreOrigineId] 
         * @param {Array<number>} [offreOrigineId2] 
         * @param {number} [offreOrigineOffreId] 
         * @param {Array<number>} [offreOrigineOffreId2] 
         * @param {number} [offreOrigineOrigineId] 
         * @param {Array<number>} [offreOrigineOrigineId2] 
         * @param {boolean} [_default] 
         * @param {string} [dateDebutBefore] 
         * @param {string} [dateDebutStrictlyBefore] 
         * @param {string} [dateDebutAfter] 
         * @param {string} [dateDebutStrictlyAfter] 
         * @param {string} [dateFinBefore] 
         * @param {string} [dateFinStrictlyBefore] 
         * @param {string} [dateFinAfter] 
         * @param {string} [dateFinStrictlyAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOriginesOffresTiersGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, tiersId?: number, tiersId2?: Array<number>, offreOrigineId?: number, offreOrigineId2?: Array<number>, offreOrigineOffreId?: number, offreOrigineOffreId2?: Array<number>, offreOrigineOrigineId?: number, offreOrigineOrigineId2?: Array<number>, _default?: boolean, dateDebutBefore?: string, dateDebutStrictlyBefore?: string, dateDebutAfter?: string, dateDebutStrictlyAfter?: string, dateFinBefore?: string, dateFinStrictlyBefore?: string, dateFinAfter?: string, dateFinStrictlyAfter?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiOriginesOffresTiersGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOriginesOffresTiersGetCollection(xIdSociete, page, itemsPerPage, tiersId, tiersId2, offreOrigineId, offreOrigineId2, offreOrigineOffreId, offreOrigineOffreId2, offreOrigineOrigineId, offreOrigineOrigineId2, _default, dateDebutBefore, dateDebutStrictlyBefore, dateDebutAfter, dateDebutStrictlyAfter, dateFinBefore, dateFinStrictlyBefore, dateFinAfter, dateFinStrictlyAfter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OriginesOffresTiersApi.apiOriginesOffresTiersGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Modifier association entre un tiers et une relation offre/origine
         * @summary Modifier association entre un tiers et une relation offre/origine
         * @param {string} id TblRelOffreTiers identifier
         * @param {OriginesOffresTiersJsonldOrigineOffreTiersUpdate} originesOffresTiersJsonldOrigineOffreTiersUpdate The updated Origines Offres Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOriginesOffresTiersIdPut(id: string, originesOffresTiersJsonldOrigineOffreTiersUpdate: OriginesOffresTiersJsonldOrigineOffreTiersUpdate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OriginesOffresTiersJsonldOrigineOffreTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOriginesOffresTiersIdPut(id, originesOffresTiersJsonldOrigineOffreTiersUpdate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OriginesOffresTiersApi.apiOriginesOffresTiersIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Associer une relation offre/origine à un tiers
         * @summary Associer une relation offre/origine à un tiers
         * @param {OriginesOffresTiersJsonldOrigineOffreTiersCreate} originesOffresTiersJsonldOrigineOffreTiersCreate The new Origines Offres Tiers resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOriginesOffresTiersPost(originesOffresTiersJsonldOrigineOffreTiersCreate: OriginesOffresTiersJsonldOrigineOffreTiersCreate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OriginesOffresTiersJsonldOrigineOffreTiersRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOriginesOffresTiersPost(originesOffresTiersJsonldOrigineOffreTiersCreate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OriginesOffresTiersApi.apiOriginesOffresTiersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OriginesOffresTiersApi - factory interface
 * @export
 */
export const OriginesOffresTiersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OriginesOffresTiersApiFp(configuration)
    return {
        /**
         * Récupérer la liste des association entre tiers et relations offres/origines
         * @summary Récupérer la liste des association entre tiers et relations offres/origines
         * @param {OriginesOffresTiersApiApiOriginesOffresTiersGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesOffresTiersGetCollection(requestParameters: OriginesOffresTiersApiApiOriginesOffresTiersGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiOriginesOffresTiersGetCollection200Response> {
            return localVarFp.apiOriginesOffresTiersGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.tiersId, requestParameters.tiersId2, requestParameters.offreOrigineId, requestParameters.offreOrigineId2, requestParameters.offreOrigineOffreId, requestParameters.offreOrigineOffreId2, requestParameters.offreOrigineOrigineId, requestParameters.offreOrigineOrigineId2, requestParameters._default, requestParameters.dateDebutBefore, requestParameters.dateDebutStrictlyBefore, requestParameters.dateDebutAfter, requestParameters.dateDebutStrictlyAfter, requestParameters.dateFinBefore, requestParameters.dateFinStrictlyBefore, requestParameters.dateFinAfter, requestParameters.dateFinStrictlyAfter, options).then((request) => request(axios, basePath));
        },
        /**
         * Modifier association entre un tiers et une relation offre/origine
         * @summary Modifier association entre un tiers et une relation offre/origine
         * @param {OriginesOffresTiersApiApiOriginesOffresTiersIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesOffresTiersIdPut(requestParameters: OriginesOffresTiersApiApiOriginesOffresTiersIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<OriginesOffresTiersJsonldOrigineOffreTiersRead> {
            return localVarFp.apiOriginesOffresTiersIdPut(requestParameters.id, requestParameters.originesOffresTiersJsonldOrigineOffreTiersUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Associer une relation offre/origine à un tiers
         * @summary Associer une relation offre/origine à un tiers
         * @param {OriginesOffresTiersApiApiOriginesOffresTiersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesOffresTiersPost(requestParameters: OriginesOffresTiersApiApiOriginesOffresTiersPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<OriginesOffresTiersJsonldOrigineOffreTiersRead> {
            return localVarFp.apiOriginesOffresTiersPost(requestParameters.originesOffresTiersJsonldOrigineOffreTiersCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOriginesOffresTiersGetCollection operation in OriginesOffresTiersApi.
 * @export
 * @interface OriginesOffresTiersApiApiOriginesOffresTiersGetCollectionRequest
 */
export interface OriginesOffresTiersApiApiOriginesOffresTiersGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly tiersId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly tiersId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly offreOrigineId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly offreOrigineId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly offreOrigineOffreId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly offreOrigineOffreId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly offreOrigineOrigineId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly offreOrigineOrigineId2?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly _default?: boolean

    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly dateDebutBefore?: string

    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly dateDebutStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly dateDebutAfter?: string

    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly dateDebutStrictlyAfter?: string

    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly dateFinBefore?: string

    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly dateFinStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly dateFinAfter?: string

    /**
     * 
     * @type {string}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersGetCollection
     */
    readonly dateFinStrictlyAfter?: string
}

/**
 * Request parameters for apiOriginesOffresTiersIdPut operation in OriginesOffresTiersApi.
 * @export
 * @interface OriginesOffresTiersApiApiOriginesOffresTiersIdPutRequest
 */
export interface OriginesOffresTiersApiApiOriginesOffresTiersIdPutRequest {
    /**
     * TblRelOffreTiers identifier
     * @type {string}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersIdPut
     */
    readonly id: string

    /**
     * The updated Origines Offres Tiers resource
     * @type {OriginesOffresTiersJsonldOrigineOffreTiersUpdate}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersIdPut
     */
    readonly originesOffresTiersJsonldOrigineOffreTiersUpdate: OriginesOffresTiersJsonldOrigineOffreTiersUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersIdPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiOriginesOffresTiersPost operation in OriginesOffresTiersApi.
 * @export
 * @interface OriginesOffresTiersApiApiOriginesOffresTiersPostRequest
 */
export interface OriginesOffresTiersApiApiOriginesOffresTiersPostRequest {
    /**
     * The new Origines Offres Tiers resource
     * @type {OriginesOffresTiersJsonldOrigineOffreTiersCreate}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersPost
     */
    readonly originesOffresTiersJsonldOrigineOffreTiersCreate: OriginesOffresTiersJsonldOrigineOffreTiersCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof OriginesOffresTiersApiApiOriginesOffresTiersPost
     */
    readonly xIdSociete?: number
}

/**
 * OriginesOffresTiersApi - object-oriented interface
 * @export
 * @class OriginesOffresTiersApi
 * @extends {BaseAPI}
 */
export class OriginesOffresTiersApi extends BaseAPI {
    /**
     * Récupérer la liste des association entre tiers et relations offres/origines
     * @summary Récupérer la liste des association entre tiers et relations offres/origines
     * @param {OriginesOffresTiersApiApiOriginesOffresTiersGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OriginesOffresTiersApi
     */
    public apiOriginesOffresTiersGetCollection(requestParameters: OriginesOffresTiersApiApiOriginesOffresTiersGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return OriginesOffresTiersApiFp(this.configuration).apiOriginesOffresTiersGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.tiersId, requestParameters.tiersId2, requestParameters.offreOrigineId, requestParameters.offreOrigineId2, requestParameters.offreOrigineOffreId, requestParameters.offreOrigineOffreId2, requestParameters.offreOrigineOrigineId, requestParameters.offreOrigineOrigineId2, requestParameters._default, requestParameters.dateDebutBefore, requestParameters.dateDebutStrictlyBefore, requestParameters.dateDebutAfter, requestParameters.dateDebutStrictlyAfter, requestParameters.dateFinBefore, requestParameters.dateFinStrictlyBefore, requestParameters.dateFinAfter, requestParameters.dateFinStrictlyAfter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modifier association entre un tiers et une relation offre/origine
     * @summary Modifier association entre un tiers et une relation offre/origine
     * @param {OriginesOffresTiersApiApiOriginesOffresTiersIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OriginesOffresTiersApi
     */
    public apiOriginesOffresTiersIdPut(requestParameters: OriginesOffresTiersApiApiOriginesOffresTiersIdPutRequest, options?: RawAxiosRequestConfig) {
        return OriginesOffresTiersApiFp(this.configuration).apiOriginesOffresTiersIdPut(requestParameters.id, requestParameters.originesOffresTiersJsonldOrigineOffreTiersUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Associer une relation offre/origine à un tiers
     * @summary Associer une relation offre/origine à un tiers
     * @param {OriginesOffresTiersApiApiOriginesOffresTiersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OriginesOffresTiersApi
     */
    public apiOriginesOffresTiersPost(requestParameters: OriginesOffresTiersApiApiOriginesOffresTiersPostRequest, options?: RawAxiosRequestConfig) {
        return OriginesOffresTiersApiFp(this.configuration).apiOriginesOffresTiersPost(requestParameters.originesOffresTiersJsonldOrigineOffreTiersCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PackageApi - axios parameter creator
 * @export
 */
export const PackageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des packages
         * @summary Récupérer la liste des packages
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [domaineId] 
         * @param {Array<number>} [domaineId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPackagesGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, domaineId?: number, domaineId2?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (domaineId !== undefined) {
                localVarQueryParameter['domaine.id'] = domaineId;
            }

            if (domaineId2) {
                localVarQueryParameter['domaine.id[]'] = domaineId2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer un package
         * @summary Supprimer un package
         * @param {string} id TblPackage identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPackagesIdDelete: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPackagesIdDelete', 'id', id)
            const localVarPath = `/offre/packages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les ressources dépendante d\'un package
         * @summary Lister les ressources dépendante d\'un package
         * @param {string} id TblPackage identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPackagesIdDependenciesGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPackagesIdDependenciesGet', 'id', id)
            const localVarPath = `/offre/packages/{id}/dependencies`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un package
         * @summary Récupérer les informations d\'un package
         * @param {string} id TblPackage identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPackagesIdGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPackagesIdGet', 'id', id)
            const localVarPath = `/offre/packages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les informations d\'un package
         * @summary Mettre à jour les informations d\'un package
         * @param {string} id TblPackage identifier
         * @param {PackageJsonldPackageUpdate} packageJsonldPackageUpdate The updated Package resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPackagesIdPut: async (id: string, packageJsonldPackageUpdate: PackageJsonldPackageUpdate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPackagesIdPut', 'id', id)
            // verify required parameter 'packageJsonldPackageUpdate' is not null or undefined
            assertParamExists('apiPackagesIdPut', 'packageJsonldPackageUpdate', packageJsonldPackageUpdate)
            const localVarPath = `/offre/packages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(packageJsonldPackageUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer un package
         * @summary Créer un package
         * @param {PackageJsonldPackageCreate} packageJsonldPackageCreate The new Package resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPackagesPost: async (packageJsonldPackageCreate: PackageJsonldPackageCreate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageJsonldPackageCreate' is not null or undefined
            assertParamExists('apiPackagesPost', 'packageJsonldPackageCreate', packageJsonldPackageCreate)
            const localVarPath = `/offre/packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(packageJsonldPackageCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PackageApi - functional programming interface
 * @export
 */
export const PackageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PackageApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des packages
         * @summary Récupérer la liste des packages
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [domaineId] 
         * @param {Array<number>} [domaineId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPackagesGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, domaineId?: number, domaineId2?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPackagesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPackagesGetCollection(xIdSociete, page, itemsPerPage, domaineId, domaineId2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PackageApi.apiPackagesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Supprimer un package
         * @summary Supprimer un package
         * @param {string} id TblPackage identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPackagesIdDelete(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPackagesIdDelete(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PackageApi.apiPackagesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lister les ressources dépendante d\'un package
         * @summary Lister les ressources dépendante d\'un package
         * @param {string} id TblPackage identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPackagesIdDependenciesGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageResourceDependencyJsonldDependencyRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPackagesIdDependenciesGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PackageApi.apiPackagesIdDependenciesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un package
         * @summary Récupérer les informations d\'un package
         * @param {string} id TblPackage identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPackagesIdGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageJsonldPackageRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPackagesIdGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PackageApi.apiPackagesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour les informations d\'un package
         * @summary Mettre à jour les informations d\'un package
         * @param {string} id TblPackage identifier
         * @param {PackageJsonldPackageUpdate} packageJsonldPackageUpdate The updated Package resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPackagesIdPut(id: string, packageJsonldPackageUpdate: PackageJsonldPackageUpdate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageJsonldPackageRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPackagesIdPut(id, packageJsonldPackageUpdate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PackageApi.apiPackagesIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Créer un package
         * @summary Créer un package
         * @param {PackageJsonldPackageCreate} packageJsonldPackageCreate The new Package resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPackagesPost(packageJsonldPackageCreate: PackageJsonldPackageCreate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageJsonldPackageRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPackagesPost(packageJsonldPackageCreate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PackageApi.apiPackagesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PackageApi - factory interface
 * @export
 */
export const PackageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PackageApiFp(configuration)
    return {
        /**
         * Récupérer la liste des packages
         * @summary Récupérer la liste des packages
         * @param {PackageApiApiPackagesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPackagesGetCollection(requestParameters: PackageApiApiPackagesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiPackagesGetCollection200Response> {
            return localVarFp.apiPackagesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.domaineId, requestParameters.domaineId2, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer un package
         * @summary Supprimer un package
         * @param {PackageApiApiPackagesIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPackagesIdDelete(requestParameters: PackageApiApiPackagesIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiPackagesIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les ressources dépendante d\'un package
         * @summary Lister les ressources dépendante d\'un package
         * @param {PackageApiApiPackagesIdDependenciesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPackagesIdDependenciesGet(requestParameters: PackageApiApiPackagesIdDependenciesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PackageResourceDependencyJsonldDependencyRead> {
            return localVarFp.apiPackagesIdDependenciesGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un package
         * @summary Récupérer les informations d\'un package
         * @param {PackageApiApiPackagesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPackagesIdGet(requestParameters: PackageApiApiPackagesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PackageJsonldPackageRead> {
            return localVarFp.apiPackagesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les informations d\'un package
         * @summary Mettre à jour les informations d\'un package
         * @param {PackageApiApiPackagesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPackagesIdPut(requestParameters: PackageApiApiPackagesIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<PackageJsonldPackageRead> {
            return localVarFp.apiPackagesIdPut(requestParameters.id, requestParameters.packageJsonldPackageUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer un package
         * @summary Créer un package
         * @param {PackageApiApiPackagesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPackagesPost(requestParameters: PackageApiApiPackagesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PackageJsonldPackageRead> {
            return localVarFp.apiPackagesPost(requestParameters.packageJsonldPackageCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiPackagesGetCollection operation in PackageApi.
 * @export
 * @interface PackageApiApiPackagesGetCollectionRequest
 */
export interface PackageApiApiPackagesGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PackageApiApiPackagesGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof PackageApiApiPackagesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof PackageApiApiPackagesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof PackageApiApiPackagesGetCollection
     */
    readonly domaineId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof PackageApiApiPackagesGetCollection
     */
    readonly domaineId2?: Array<number>
}

/**
 * Request parameters for apiPackagesIdDelete operation in PackageApi.
 * @export
 * @interface PackageApiApiPackagesIdDeleteRequest
 */
export interface PackageApiApiPackagesIdDeleteRequest {
    /**
     * TblPackage identifier
     * @type {string}
     * @memberof PackageApiApiPackagesIdDelete
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PackageApiApiPackagesIdDelete
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiPackagesIdDependenciesGet operation in PackageApi.
 * @export
 * @interface PackageApiApiPackagesIdDependenciesGetRequest
 */
export interface PackageApiApiPackagesIdDependenciesGetRequest {
    /**
     * TblPackage identifier
     * @type {string}
     * @memberof PackageApiApiPackagesIdDependenciesGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PackageApiApiPackagesIdDependenciesGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiPackagesIdGet operation in PackageApi.
 * @export
 * @interface PackageApiApiPackagesIdGetRequest
 */
export interface PackageApiApiPackagesIdGetRequest {
    /**
     * TblPackage identifier
     * @type {string}
     * @memberof PackageApiApiPackagesIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PackageApiApiPackagesIdGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiPackagesIdPut operation in PackageApi.
 * @export
 * @interface PackageApiApiPackagesIdPutRequest
 */
export interface PackageApiApiPackagesIdPutRequest {
    /**
     * TblPackage identifier
     * @type {string}
     * @memberof PackageApiApiPackagesIdPut
     */
    readonly id: string

    /**
     * The updated Package resource
     * @type {PackageJsonldPackageUpdate}
     * @memberof PackageApiApiPackagesIdPut
     */
    readonly packageJsonldPackageUpdate: PackageJsonldPackageUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PackageApiApiPackagesIdPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiPackagesPost operation in PackageApi.
 * @export
 * @interface PackageApiApiPackagesPostRequest
 */
export interface PackageApiApiPackagesPostRequest {
    /**
     * The new Package resource
     * @type {PackageJsonldPackageCreate}
     * @memberof PackageApiApiPackagesPost
     */
    readonly packageJsonldPackageCreate: PackageJsonldPackageCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PackageApiApiPackagesPost
     */
    readonly xIdSociete?: number
}

/**
 * PackageApi - object-oriented interface
 * @export
 * @class PackageApi
 * @extends {BaseAPI}
 */
export class PackageApi extends BaseAPI {
    /**
     * Récupérer la liste des packages
     * @summary Récupérer la liste des packages
     * @param {PackageApiApiPackagesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public apiPackagesGetCollection(requestParameters: PackageApiApiPackagesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return PackageApiFp(this.configuration).apiPackagesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.domaineId, requestParameters.domaineId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer un package
     * @summary Supprimer un package
     * @param {PackageApiApiPackagesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public apiPackagesIdDelete(requestParameters: PackageApiApiPackagesIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return PackageApiFp(this.configuration).apiPackagesIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les ressources dépendante d\'un package
     * @summary Lister les ressources dépendante d\'un package
     * @param {PackageApiApiPackagesIdDependenciesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public apiPackagesIdDependenciesGet(requestParameters: PackageApiApiPackagesIdDependenciesGetRequest, options?: RawAxiosRequestConfig) {
        return PackageApiFp(this.configuration).apiPackagesIdDependenciesGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un package
     * @summary Récupérer les informations d\'un package
     * @param {PackageApiApiPackagesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public apiPackagesIdGet(requestParameters: PackageApiApiPackagesIdGetRequest, options?: RawAxiosRequestConfig) {
        return PackageApiFp(this.configuration).apiPackagesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les informations d\'un package
     * @summary Mettre à jour les informations d\'un package
     * @param {PackageApiApiPackagesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public apiPackagesIdPut(requestParameters: PackageApiApiPackagesIdPutRequest, options?: RawAxiosRequestConfig) {
        return PackageApiFp(this.configuration).apiPackagesIdPut(requestParameters.id, requestParameters.packageJsonldPackageUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer un package
     * @summary Créer un package
     * @param {PackageApiApiPackagesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public apiPackagesPost(requestParameters: PackageApiApiPackagesPostRequest, options?: RawAxiosRequestConfig) {
        return PackageApiFp(this.configuration).apiPackagesPost(requestParameters.packageJsonldPackageCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RubriqueFacturationApi - axios parameter creator
 * @export
 */
export const RubriqueFacturationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des rubrique de facturation
         * @summary Obtenir la liste des rubrique de facturation
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRubriqueFacturationsGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/rubrique_facturations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une rubrique de facturation
         * @summary Récupérer les informations d\'une rubrique de facturation
         * @param {string} id TblRubfac identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRubriqueFacturationsGetItem: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRubriqueFacturationsGetItem', 'id', id)
            const localVarPath = `/offre/rubrique_facturations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RubriqueFacturationApi - functional programming interface
 * @export
 */
export const RubriqueFacturationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RubriqueFacturationApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des rubrique de facturation
         * @summary Obtenir la liste des rubrique de facturation
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRubriqueFacturationsGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiRubriqueFacturationsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRubriqueFacturationsGetCollection(xIdSociete, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RubriqueFacturationApi.apiRubriqueFacturationsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'une rubrique de facturation
         * @summary Récupérer les informations d\'une rubrique de facturation
         * @param {string} id TblRubfac identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRubriqueFacturationsGetItem(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RubriqueFacturationJsonldRubriqueFacturationRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRubriqueFacturationsGetItem(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RubriqueFacturationApi.apiRubriqueFacturationsGetItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RubriqueFacturationApi - factory interface
 * @export
 */
export const RubriqueFacturationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RubriqueFacturationApiFp(configuration)
    return {
        /**
         * Obtenir la liste des rubrique de facturation
         * @summary Obtenir la liste des rubrique de facturation
         * @param {RubriqueFacturationApiApiRubriqueFacturationsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRubriqueFacturationsGetCollection(requestParameters: RubriqueFacturationApiApiRubriqueFacturationsGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiRubriqueFacturationsGetCollection200Response> {
            return localVarFp.apiRubriqueFacturationsGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une rubrique de facturation
         * @summary Récupérer les informations d\'une rubrique de facturation
         * @param {RubriqueFacturationApiApiRubriqueFacturationsGetItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRubriqueFacturationsGetItem(requestParameters: RubriqueFacturationApiApiRubriqueFacturationsGetItemRequest, options?: RawAxiosRequestConfig): AxiosPromise<RubriqueFacturationJsonldRubriqueFacturationRead> {
            return localVarFp.apiRubriqueFacturationsGetItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiRubriqueFacturationsGetCollection operation in RubriqueFacturationApi.
 * @export
 * @interface RubriqueFacturationApiApiRubriqueFacturationsGetCollectionRequest
 */
export interface RubriqueFacturationApiApiRubriqueFacturationsGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof RubriqueFacturationApiApiRubriqueFacturationsGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof RubriqueFacturationApiApiRubriqueFacturationsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof RubriqueFacturationApiApiRubriqueFacturationsGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiRubriqueFacturationsGetItem operation in RubriqueFacturationApi.
 * @export
 * @interface RubriqueFacturationApiApiRubriqueFacturationsGetItemRequest
 */
export interface RubriqueFacturationApiApiRubriqueFacturationsGetItemRequest {
    /**
     * TblRubfac identifier
     * @type {string}
     * @memberof RubriqueFacturationApiApiRubriqueFacturationsGetItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof RubriqueFacturationApiApiRubriqueFacturationsGetItem
     */
    readonly xIdSociete?: number
}

/**
 * RubriqueFacturationApi - object-oriented interface
 * @export
 * @class RubriqueFacturationApi
 * @extends {BaseAPI}
 */
export class RubriqueFacturationApi extends BaseAPI {
    /**
     * Obtenir la liste des rubrique de facturation
     * @summary Obtenir la liste des rubrique de facturation
     * @param {RubriqueFacturationApiApiRubriqueFacturationsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RubriqueFacturationApi
     */
    public apiRubriqueFacturationsGetCollection(requestParameters: RubriqueFacturationApiApiRubriqueFacturationsGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return RubriqueFacturationApiFp(this.configuration).apiRubriqueFacturationsGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une rubrique de facturation
     * @summary Récupérer les informations d\'une rubrique de facturation
     * @param {RubriqueFacturationApiApiRubriqueFacturationsGetItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RubriqueFacturationApi
     */
    public apiRubriqueFacturationsGetItem(requestParameters: RubriqueFacturationApiApiRubriqueFacturationsGetItemRequest, options?: RawAxiosRequestConfig) {
        return RubriqueFacturationApiFp(this.configuration).apiRubriqueFacturationsGetItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TarifCataloguePrescripteurApi - axios parameter creator
 * @export
 */
export const TarifCataloguePrescripteurApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des tarifs prescripteurs
         * @summary Récupérer la liste des tarifs prescripteurs
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [prescripteurId] 
         * @param {Array<number>} [prescripteurId2] 
         * @param {number} [catalogueId] 
         * @param {Array<number>} [catalogueId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTarifsPrescripteursGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, prescripteurId?: number, prescripteurId2?: Array<number>, catalogueId?: number, catalogueId2?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/tarifs-prescripteurs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (prescripteurId !== undefined) {
                localVarQueryParameter['prescripteur.id'] = prescripteurId;
            }

            if (prescripteurId2) {
                localVarQueryParameter['prescripteur.id[]'] = prescripteurId2;
            }

            if (catalogueId !== undefined) {
                localVarQueryParameter['catalogue.id'] = catalogueId;
            }

            if (catalogueId2) {
                localVarQueryParameter['catalogue.id[]'] = catalogueId2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un tarif prescripteur
         * @summary Récupérer les informations d\'un tarif prescripteur
         * @param {string} id TblRelCataloguePrescripteur identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTarifsPrescripteursIdGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTarifsPrescripteursIdGet', 'id', id)
            const localVarPath = `/offre/tarifs-prescripteurs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les informations d\'un tarif prescripteur
         * @summary Mettre à jour les informations d\'un tarif prescripteur
         * @param {string} id TblRelCataloguePrescripteur identifier
         * @param {TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate} tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate The updated Tarif Catalogue Prescripteur resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTarifsPrescripteursIdPut: async (id: string, tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate: TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTarifsPrescripteursIdPut', 'id', id)
            // verify required parameter 'tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate' is not null or undefined
            assertParamExists('apiTarifsPrescripteursIdPut', 'tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate', tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate)
            const localVarPath = `/offre/tarifs-prescripteurs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer un tarif prescripteur
         * @summary Créer un tarif prescripteur
         * @param {TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate} tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate The new Tarif Catalogue Prescripteur resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTarifsPrescripteursPost: async (tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate: TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate' is not null or undefined
            assertParamExists('apiTarifsPrescripteursPost', 'tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate', tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate)
            const localVarPath = `/offre/tarifs-prescripteurs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TarifCataloguePrescripteurApi - functional programming interface
 * @export
 */
export const TarifCataloguePrescripteurApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TarifCataloguePrescripteurApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des tarifs prescripteurs
         * @summary Récupérer la liste des tarifs prescripteurs
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [prescripteurId] 
         * @param {Array<number>} [prescripteurId2] 
         * @param {number} [catalogueId] 
         * @param {Array<number>} [catalogueId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTarifsPrescripteursGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, prescripteurId?: number, prescripteurId2?: Array<number>, catalogueId?: number, catalogueId2?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTarifsPrescripteursGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTarifsPrescripteursGetCollection(xIdSociete, page, itemsPerPage, prescripteurId, prescripteurId2, catalogueId, catalogueId2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TarifCataloguePrescripteurApi.apiTarifsPrescripteursGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un tarif prescripteur
         * @summary Récupérer les informations d\'un tarif prescripteur
         * @param {string} id TblRelCataloguePrescripteur identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTarifsPrescripteursIdGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTarifsPrescripteursIdGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TarifCataloguePrescripteurApi.apiTarifsPrescripteursIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mettre à jour les informations d\'un tarif prescripteur
         * @summary Mettre à jour les informations d\'un tarif prescripteur
         * @param {string} id TblRelCataloguePrescripteur identifier
         * @param {TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate} tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate The updated Tarif Catalogue Prescripteur resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTarifsPrescripteursIdPut(id: string, tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate: TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTarifsPrescripteursIdPut(id, tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TarifCataloguePrescripteurApi.apiTarifsPrescripteursIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Créer un tarif prescripteur
         * @summary Créer un tarif prescripteur
         * @param {TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate} tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate The new Tarif Catalogue Prescripteur resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTarifsPrescripteursPost(tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate: TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTarifsPrescripteursPost(tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TarifCataloguePrescripteurApi.apiTarifsPrescripteursPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TarifCataloguePrescripteurApi - factory interface
 * @export
 */
export const TarifCataloguePrescripteurApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TarifCataloguePrescripteurApiFp(configuration)
    return {
        /**
         * Récupérer la liste des tarifs prescripteurs
         * @summary Récupérer la liste des tarifs prescripteurs
         * @param {TarifCataloguePrescripteurApiApiTarifsPrescripteursGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTarifsPrescripteursGetCollection(requestParameters: TarifCataloguePrescripteurApiApiTarifsPrescripteursGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiTarifsPrescripteursGetCollection200Response> {
            return localVarFp.apiTarifsPrescripteursGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.prescripteurId, requestParameters.prescripteurId2, requestParameters.catalogueId, requestParameters.catalogueId2, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un tarif prescripteur
         * @summary Récupérer les informations d\'un tarif prescripteur
         * @param {TarifCataloguePrescripteurApiApiTarifsPrescripteursIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTarifsPrescripteursIdGet(requestParameters: TarifCataloguePrescripteurApiApiTarifsPrescripteursIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead> {
            return localVarFp.apiTarifsPrescripteursIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les informations d\'un tarif prescripteur
         * @summary Mettre à jour les informations d\'un tarif prescripteur
         * @param {TarifCataloguePrescripteurApiApiTarifsPrescripteursIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTarifsPrescripteursIdPut(requestParameters: TarifCataloguePrescripteurApiApiTarifsPrescripteursIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead> {
            return localVarFp.apiTarifsPrescripteursIdPut(requestParameters.id, requestParameters.tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer un tarif prescripteur
         * @summary Créer un tarif prescripteur
         * @param {TarifCataloguePrescripteurApiApiTarifsPrescripteursPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTarifsPrescripteursPost(requestParameters: TarifCataloguePrescripteurApiApiTarifsPrescripteursPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurRead> {
            return localVarFp.apiTarifsPrescripteursPost(requestParameters.tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiTarifsPrescripteursGetCollection operation in TarifCataloguePrescripteurApi.
 * @export
 * @interface TarifCataloguePrescripteurApiApiTarifsPrescripteursGetCollectionRequest
 */
export interface TarifCataloguePrescripteurApiApiTarifsPrescripteursGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TarifCataloguePrescripteurApiApiTarifsPrescripteursGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof TarifCataloguePrescripteurApiApiTarifsPrescripteursGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof TarifCataloguePrescripteurApiApiTarifsPrescripteursGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof TarifCataloguePrescripteurApiApiTarifsPrescripteursGetCollection
     */
    readonly prescripteurId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof TarifCataloguePrescripteurApiApiTarifsPrescripteursGetCollection
     */
    readonly prescripteurId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof TarifCataloguePrescripteurApiApiTarifsPrescripteursGetCollection
     */
    readonly catalogueId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof TarifCataloguePrescripteurApiApiTarifsPrescripteursGetCollection
     */
    readonly catalogueId2?: Array<number>
}

/**
 * Request parameters for apiTarifsPrescripteursIdGet operation in TarifCataloguePrescripteurApi.
 * @export
 * @interface TarifCataloguePrescripteurApiApiTarifsPrescripteursIdGetRequest
 */
export interface TarifCataloguePrescripteurApiApiTarifsPrescripteursIdGetRequest {
    /**
     * TblRelCataloguePrescripteur identifier
     * @type {string}
     * @memberof TarifCataloguePrescripteurApiApiTarifsPrescripteursIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TarifCataloguePrescripteurApiApiTarifsPrescripteursIdGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiTarifsPrescripteursIdPut operation in TarifCataloguePrescripteurApi.
 * @export
 * @interface TarifCataloguePrescripteurApiApiTarifsPrescripteursIdPutRequest
 */
export interface TarifCataloguePrescripteurApiApiTarifsPrescripteursIdPutRequest {
    /**
     * TblRelCataloguePrescripteur identifier
     * @type {string}
     * @memberof TarifCataloguePrescripteurApiApiTarifsPrescripteursIdPut
     */
    readonly id: string

    /**
     * The updated Tarif Catalogue Prescripteur resource
     * @type {TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate}
     * @memberof TarifCataloguePrescripteurApiApiTarifsPrescripteursIdPut
     */
    readonly tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate: TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TarifCataloguePrescripteurApiApiTarifsPrescripteursIdPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiTarifsPrescripteursPost operation in TarifCataloguePrescripteurApi.
 * @export
 * @interface TarifCataloguePrescripteurApiApiTarifsPrescripteursPostRequest
 */
export interface TarifCataloguePrescripteurApiApiTarifsPrescripteursPostRequest {
    /**
     * The new Tarif Catalogue Prescripteur resource
     * @type {TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate}
     * @memberof TarifCataloguePrescripteurApiApiTarifsPrescripteursPost
     */
    readonly tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate: TarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TarifCataloguePrescripteurApiApiTarifsPrescripteursPost
     */
    readonly xIdSociete?: number
}

/**
 * TarifCataloguePrescripteurApi - object-oriented interface
 * @export
 * @class TarifCataloguePrescripteurApi
 * @extends {BaseAPI}
 */
export class TarifCataloguePrescripteurApi extends BaseAPI {
    /**
     * Récupérer la liste des tarifs prescripteurs
     * @summary Récupérer la liste des tarifs prescripteurs
     * @param {TarifCataloguePrescripteurApiApiTarifsPrescripteursGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TarifCataloguePrescripteurApi
     */
    public apiTarifsPrescripteursGetCollection(requestParameters: TarifCataloguePrescripteurApiApiTarifsPrescripteursGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return TarifCataloguePrescripteurApiFp(this.configuration).apiTarifsPrescripteursGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.prescripteurId, requestParameters.prescripteurId2, requestParameters.catalogueId, requestParameters.catalogueId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un tarif prescripteur
     * @summary Récupérer les informations d\'un tarif prescripteur
     * @param {TarifCataloguePrescripteurApiApiTarifsPrescripteursIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TarifCataloguePrescripteurApi
     */
    public apiTarifsPrescripteursIdGet(requestParameters: TarifCataloguePrescripteurApiApiTarifsPrescripteursIdGetRequest, options?: RawAxiosRequestConfig) {
        return TarifCataloguePrescripteurApiFp(this.configuration).apiTarifsPrescripteursIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les informations d\'un tarif prescripteur
     * @summary Mettre à jour les informations d\'un tarif prescripteur
     * @param {TarifCataloguePrescripteurApiApiTarifsPrescripteursIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TarifCataloguePrescripteurApi
     */
    public apiTarifsPrescripteursIdPut(requestParameters: TarifCataloguePrescripteurApiApiTarifsPrescripteursIdPutRequest, options?: RawAxiosRequestConfig) {
        return TarifCataloguePrescripteurApiFp(this.configuration).apiTarifsPrescripteursIdPut(requestParameters.id, requestParameters.tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer un tarif prescripteur
     * @summary Créer un tarif prescripteur
     * @param {TarifCataloguePrescripteurApiApiTarifsPrescripteursPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TarifCataloguePrescripteurApi
     */
    public apiTarifsPrescripteursPost(requestParameters: TarifCataloguePrescripteurApiApiTarifsPrescripteursPostRequest, options?: RawAxiosRequestConfig) {
        return TarifCataloguePrescripteurApiFp(this.configuration).apiTarifsPrescripteursPost(requestParameters.tarifCataloguePrescripteurJsonldTarifCataloguePrescripteurCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TypeDeDocumentApi - axios parameter creator
 * @export
 */
export const TypeDeDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des types de documents
         * @summary Obtenir la liste des types de documents
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [libelle] 
         * @param {string} [name] 
         * @param {number} [type] 
         * @param {Array<number>} [type2] 
         * @param {number} [domaineId] 
         * @param {Array<number>} [domaineId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, libelle?: string, name?: string, type?: number, type2?: Array<number>, domaineId?: number, domaineId2?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/types-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (libelle !== undefined) {
                localVarQueryParameter['libelle'] = libelle;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (type2) {
                localVarQueryParameter['type[]'] = type2;
            }

            if (domaineId !== undefined) {
                localVarQueryParameter['domaine.id'] = domaineId;
            }

            if (domaineId2) {
                localVarQueryParameter['domaine.id[]'] = domaineId2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Suprimmer un type de document
         * @summary Suprimmer un type de document
         * @param {string} id TblTypeDocument identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdDelete: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTypesDocumentsIdDelete', 'id', id)
            const localVarPath = `/offre/types-documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un type de document
         * @summary Récupérer les informations d\'un type de document
         * @param {string} id TblTypeDocument identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTypesDocumentsIdGet', 'id', id)
            const localVarPath = `/offre/types-documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Type de document resource.
         * @summary Modifier un type document
         * @param {string} id TblTypeDocument identifier
         * @param {TypeDeDocumentJsonldTypedocumentUpdate} typeDeDocumentJsonldTypedocumentUpdate The updated Type de document resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdPut: async (id: string, typeDeDocumentJsonldTypedocumentUpdate: TypeDeDocumentJsonldTypedocumentUpdate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTypesDocumentsIdPut', 'id', id)
            // verify required parameter 'typeDeDocumentJsonldTypedocumentUpdate' is not null or undefined
            assertParamExists('apiTypesDocumentsIdPut', 'typeDeDocumentJsonldTypedocumentUpdate', typeDeDocumentJsonldTypedocumentUpdate)
            const localVarPath = `/offre/types-documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(typeDeDocumentJsonldTypedocumentUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Type de document resource.
         * @summary Créer un type de document
         * @param {TypeDeDocumentJsonldTypedocumentCreate} typeDeDocumentJsonldTypedocumentCreate The new Type de document resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsPost: async (typeDeDocumentJsonldTypedocumentCreate: TypeDeDocumentJsonldTypedocumentCreate, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeDeDocumentJsonldTypedocumentCreate' is not null or undefined
            assertParamExists('apiTypesDocumentsPost', 'typeDeDocumentJsonldTypedocumentCreate', typeDeDocumentJsonldTypedocumentCreate)
            const localVarPath = `/offre/types-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(typeDeDocumentJsonldTypedocumentCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TypeDeDocumentApi - functional programming interface
 * @export
 */
export const TypeDeDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TypeDeDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des types de documents
         * @summary Obtenir la liste des types de documents
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [libelle] 
         * @param {string} [name] 
         * @param {number} [type] 
         * @param {Array<number>} [type2] 
         * @param {number} [domaineId] 
         * @param {Array<number>} [domaineId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocumentsGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, libelle?: string, name?: string, type?: number, type2?: Array<number>, domaineId?: number, domaineId2?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiCataloguesIdDocumentsAFournirGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocumentsGetCollection(xIdSociete, page, itemsPerPage, libelle, name, type, type2, domaineId, domaineId2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeDeDocumentApi.apiTypesDocumentsGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Suprimmer un type de document
         * @summary Suprimmer un type de document
         * @param {string} id TblTypeDocument identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocumentsIdDelete(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocumentsIdDelete(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeDeDocumentApi.apiTypesDocumentsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un type de document
         * @summary Récupérer les informations d\'un type de document
         * @param {string} id TblTypeDocument identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocumentsIdGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeDeDocumentJsonldTypedocumentRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocumentsIdGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeDeDocumentApi.apiTypesDocumentsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replaces the Type de document resource.
         * @summary Modifier un type document
         * @param {string} id TblTypeDocument identifier
         * @param {TypeDeDocumentJsonldTypedocumentUpdate} typeDeDocumentJsonldTypedocumentUpdate The updated Type de document resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocumentsIdPut(id: string, typeDeDocumentJsonldTypedocumentUpdate: TypeDeDocumentJsonldTypedocumentUpdate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeDeDocumentJsonldTypedocumentRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocumentsIdPut(id, typeDeDocumentJsonldTypedocumentUpdate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeDeDocumentApi.apiTypesDocumentsIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a Type de document resource.
         * @summary Créer un type de document
         * @param {TypeDeDocumentJsonldTypedocumentCreate} typeDeDocumentJsonldTypedocumentCreate The new Type de document resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocumentsPost(typeDeDocumentJsonldTypedocumentCreate: TypeDeDocumentJsonldTypedocumentCreate, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeDeDocumentJsonldTypedocumentCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocumentsPost(typeDeDocumentJsonldTypedocumentCreate, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeDeDocumentApi.apiTypesDocumentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TypeDeDocumentApi - factory interface
 * @export
 */
export const TypeDeDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TypeDeDocumentApiFp(configuration)
    return {
        /**
         * Obtenir la liste des types de documents
         * @summary Obtenir la liste des types de documents
         * @param {TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsGetCollection(requestParameters: TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiCataloguesIdDocumentsAFournirGetCollection200Response> {
            return localVarFp.apiTypesDocumentsGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.libelle, requestParameters.name, requestParameters.type, requestParameters.type2, requestParameters.domaineId, requestParameters.domaineId2, options).then((request) => request(axios, basePath));
        },
        /**
         * Suprimmer un type de document
         * @summary Suprimmer un type de document
         * @param {TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdDelete(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiTypesDocumentsIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un type de document
         * @summary Récupérer les informations d\'un type de document
         * @param {TypeDeDocumentApiApiTypesDocumentsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdGet(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TypeDeDocumentJsonldTypedocumentRead> {
            return localVarFp.apiTypesDocumentsIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Type de document resource.
         * @summary Modifier un type document
         * @param {TypeDeDocumentApiApiTypesDocumentsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdPut(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<TypeDeDocumentJsonldTypedocumentRead> {
            return localVarFp.apiTypesDocumentsIdPut(requestParameters.id, requestParameters.typeDeDocumentJsonldTypedocumentUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Type de document resource.
         * @summary Créer un type de document
         * @param {TypeDeDocumentApiApiTypesDocumentsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsPost(requestParameters: TypeDeDocumentApiApiTypesDocumentsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TypeDeDocumentJsonldTypedocumentCreated> {
            return localVarFp.apiTypesDocumentsPost(requestParameters.typeDeDocumentJsonldTypedocumentCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiTypesDocumentsGetCollection operation in TypeDeDocumentApi.
 * @export
 * @interface TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest
 */
export interface TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly libelle?: string

    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly type?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly type2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly domaineId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly domaineId2?: Array<number>
}

/**
 * Request parameters for apiTypesDocumentsIdDelete operation in TypeDeDocumentApi.
 * @export
 * @interface TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest
 */
export interface TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest {
    /**
     * TblTypeDocument identifier
     * @type {string}
     * @memberof TypeDeDocumentApiApiTypesDocumentsIdDelete
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TypeDeDocumentApiApiTypesDocumentsIdDelete
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiTypesDocumentsIdGet operation in TypeDeDocumentApi.
 * @export
 * @interface TypeDeDocumentApiApiTypesDocumentsIdGetRequest
 */
export interface TypeDeDocumentApiApiTypesDocumentsIdGetRequest {
    /**
     * TblTypeDocument identifier
     * @type {string}
     * @memberof TypeDeDocumentApiApiTypesDocumentsIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TypeDeDocumentApiApiTypesDocumentsIdGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiTypesDocumentsIdPut operation in TypeDeDocumentApi.
 * @export
 * @interface TypeDeDocumentApiApiTypesDocumentsIdPutRequest
 */
export interface TypeDeDocumentApiApiTypesDocumentsIdPutRequest {
    /**
     * TblTypeDocument identifier
     * @type {string}
     * @memberof TypeDeDocumentApiApiTypesDocumentsIdPut
     */
    readonly id: string

    /**
     * The updated Type de document resource
     * @type {TypeDeDocumentJsonldTypedocumentUpdate}
     * @memberof TypeDeDocumentApiApiTypesDocumentsIdPut
     */
    readonly typeDeDocumentJsonldTypedocumentUpdate: TypeDeDocumentJsonldTypedocumentUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TypeDeDocumentApiApiTypesDocumentsIdPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiTypesDocumentsPost operation in TypeDeDocumentApi.
 * @export
 * @interface TypeDeDocumentApiApiTypesDocumentsPostRequest
 */
export interface TypeDeDocumentApiApiTypesDocumentsPostRequest {
    /**
     * The new Type de document resource
     * @type {TypeDeDocumentJsonldTypedocumentCreate}
     * @memberof TypeDeDocumentApiApiTypesDocumentsPost
     */
    readonly typeDeDocumentJsonldTypedocumentCreate: TypeDeDocumentJsonldTypedocumentCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TypeDeDocumentApiApiTypesDocumentsPost
     */
    readonly xIdSociete?: number
}

/**
 * TypeDeDocumentApi - object-oriented interface
 * @export
 * @class TypeDeDocumentApi
 * @extends {BaseAPI}
 */
export class TypeDeDocumentApi extends BaseAPI {
    /**
     * Obtenir la liste des types de documents
     * @summary Obtenir la liste des types de documents
     * @param {TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentApi
     */
    public apiTypesDocumentsGetCollection(requestParameters: TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return TypeDeDocumentApiFp(this.configuration).apiTypesDocumentsGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.libelle, requestParameters.name, requestParameters.type, requestParameters.type2, requestParameters.domaineId, requestParameters.domaineId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Suprimmer un type de document
     * @summary Suprimmer un type de document
     * @param {TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentApi
     */
    public apiTypesDocumentsIdDelete(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return TypeDeDocumentApiFp(this.configuration).apiTypesDocumentsIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un type de document
     * @summary Récupérer les informations d\'un type de document
     * @param {TypeDeDocumentApiApiTypesDocumentsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentApi
     */
    public apiTypesDocumentsIdGet(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdGetRequest, options?: RawAxiosRequestConfig) {
        return TypeDeDocumentApiFp(this.configuration).apiTypesDocumentsIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Type de document resource.
     * @summary Modifier un type document
     * @param {TypeDeDocumentApiApiTypesDocumentsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentApi
     */
    public apiTypesDocumentsIdPut(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdPutRequest, options?: RawAxiosRequestConfig) {
        return TypeDeDocumentApiFp(this.configuration).apiTypesDocumentsIdPut(requestParameters.id, requestParameters.typeDeDocumentJsonldTypedocumentUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Type de document resource.
     * @summary Créer un type de document
     * @param {TypeDeDocumentApiApiTypesDocumentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentApi
     */
    public apiTypesDocumentsPost(requestParameters: TypeDeDocumentApiApiTypesDocumentsPostRequest, options?: RawAxiosRequestConfig) {
        return TypeDeDocumentApiFp(this.configuration).apiTypesDocumentsPost(requestParameters.typeDeDocumentJsonldTypedocumentCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TypeTvaApi - axios parameter creator
 * @export
 */
export const TypeTvaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des types de tva
         * @summary Récupérer la liste des types de tva
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTvaGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offre/tva`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un type de  tva
         * @summary Récupérer les informations d\'un type de  tva
         * @param {string} id TblTva identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTvaIdGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTvaIdGet', 'id', id)
            const localVarPath = `/offre/tva/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TypeTvaApi - functional programming interface
 * @export
 */
export const TypeTvaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TypeTvaApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des types de tva
         * @summary Récupérer la liste des types de tva
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTvaGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTvaGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTvaGetCollection(xIdSociete, page, itemsPerPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeTvaApi.apiTvaGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Récupérer les informations d\'un type de  tva
         * @summary Récupérer les informations d\'un type de  tva
         * @param {string} id TblTva identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTvaIdGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeTvaJsonldTvaRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTvaIdGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TypeTvaApi.apiTvaIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TypeTvaApi - factory interface
 * @export
 */
export const TypeTvaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TypeTvaApiFp(configuration)
    return {
        /**
         * Récupérer la liste des types de tva
         * @summary Récupérer la liste des types de tva
         * @param {TypeTvaApiApiTvaGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTvaGetCollection(requestParameters: TypeTvaApiApiTvaGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiTvaGetCollection200Response> {
            return localVarFp.apiTvaGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un type de  tva
         * @summary Récupérer les informations d\'un type de  tva
         * @param {TypeTvaApiApiTvaIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTvaIdGet(requestParameters: TypeTvaApiApiTvaIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TypeTvaJsonldTvaRead> {
            return localVarFp.apiTvaIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiTvaGetCollection operation in TypeTvaApi.
 * @export
 * @interface TypeTvaApiApiTvaGetCollectionRequest
 */
export interface TypeTvaApiApiTvaGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TypeTvaApiApiTvaGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof TypeTvaApiApiTvaGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof TypeTvaApiApiTvaGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiTvaIdGet operation in TypeTvaApi.
 * @export
 * @interface TypeTvaApiApiTvaIdGetRequest
 */
export interface TypeTvaApiApiTvaIdGetRequest {
    /**
     * TblTva identifier
     * @type {string}
     * @memberof TypeTvaApiApiTvaIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TypeTvaApiApiTvaIdGet
     */
    readonly xIdSociete?: number
}

/**
 * TypeTvaApi - object-oriented interface
 * @export
 * @class TypeTvaApi
 * @extends {BaseAPI}
 */
export class TypeTvaApi extends BaseAPI {
    /**
     * Récupérer la liste des types de tva
     * @summary Récupérer la liste des types de tva
     * @param {TypeTvaApiApiTvaGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeTvaApi
     */
    public apiTvaGetCollection(requestParameters: TypeTvaApiApiTvaGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return TypeTvaApiFp(this.configuration).apiTvaGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un type de  tva
     * @summary Récupérer les informations d\'un type de  tva
     * @param {TypeTvaApiApiTvaIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeTvaApi
     */
    public apiTvaIdGet(requestParameters: TypeTvaApiApiTvaIdGetRequest, options?: RawAxiosRequestConfig) {
        return TypeTvaApiFp(this.configuration).apiTvaIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



