import React, { useCallback, useEffect } from 'react';
import {
    FileUpload,
    getBase64Statut,
    Previous,
    UploadedFile,
    useUploader,
} from '@europrocurement/flexy-components';
import { useFormaliteService } from '@europrocurement/l2d-domain';
import { FichiersDossierJsonldDocumentfileRead } from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { Button, Collapse, Divider, List, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@mui/system';
import { TransitionGroup } from 'react-transition-group';
import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';
import { useParcoursFormalite } from '../provider/useParcoursFormalite';
import { parcoursFormalitePathResolver } from '../parcoursFormalitePathResolver';
import CurrentFormalityAndClient from '../components/currentFormalityAndClient';
import { loadDossier, uploadDossierFile, uploadDossierFiles } from '../functions/docFunctions';
import { useFileUploadProps } from '../hooks/useFileUploadProps';
import { useChangeHeaderParcours } from '../hooks/useChangeHeaderParcours';
import { DocToSend } from '../components/Docs/DocToSend';

const StepDocuments: React.FunctionComponent = function () {
    const { getCurrentDossier, containerWidth, setLoading } = useParcoursFormalite();

    React.useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    const { files, removeFile, filesBase64 } = useUploader();

    const [currentDocs, setCurrentDocs] =
        React.useState<Array<FichiersDossierJsonldDocumentfileRead> | null>(null);

    const [uploadingFiles, setUploadingFiles] = React.useState<Array<number>>([]);

    const dossier = getCurrentDossier();
    const navigate = useNavigate();
    const theme = useTheme();
    const { fichiersDossierApi } = useFormaliteService();

    const reloadDossierAndDocs = useCallback(async () => {
        if (dossier) {
            const prom = loadDossier(dossier, fichiersDossierApi);
            return await prom.then((res) => {
                const fichiers = res.data['hydra:member'];
                setCurrentDocs(fichiers);
            });
        }
        return Promise.resolve();
    }, [dossier, fichiersDossierApi]);

    useEffect(() => {
        if (dossier && dossier.id && !currentDocs) {
            reloadDossierAndDocs();
        }
    }, [currentDocs, dossier, fichiersDossierApi, reloadDossierAndDocs]);

    const onPrevious = () => {
        navigate(parcoursFormalitePathResolver('stepForm'));
    };

    const { setCustomHeader } = useFullLayout();
    const changeHeaderParcours = useChangeHeaderParcours();

    React.useEffect(() => {
        changeHeaderParcours(3);
        return () => {
            setCustomHeader(null);
        };
    }, [setCustomHeader, changeHeaderParcours]);

    const onDropFileWrapper = useCallback(
        (file: File, docType: string, idDoc: number) => {
            if (dossier) {
                setUploadingFiles((curr) => [...curr, idDoc]);
                uploadDossierFile(dossier, fichiersDossierApi, file, docType)
                    .then(reloadDossierAndDocs)
                    .then(() => {
                        setUploadingFiles((curr) => {
                            const index = curr.indexOf(idDoc);
                            if (index > -1) {
                                return [...curr.splice(index, 1)];
                            }
                            return [...curr];
                        });
                    });
            }
        },
        [dossier, fichiersDossierApi, reloadDossierAndDocs],
    );

    const fileUploadProps = useFileUploadProps();

    return (
        <Container maxWidth={containerWidth}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '32px',
                }}
            >
                <CurrentFormalityAndClient />
            </Box>
            <Previous onClick={onPrevious} />
            <Typography variant="l2dh4">Veuillez envoyer les documents manquants</Typography>
            <Typography
                sx={{ fontWeight: 500 }}
                variant="l2dbodyxs"
                color="text.secondary"
            >
                Formats : {fileUploadProps.formatInfo} - Poids maximum : 10Mo
            </Typography>
            <Box sx={{ paddingTop: '40px' }}>
                <FileUpload {...fileUploadProps} />
            </Box>

            <List>
                <TransitionGroup>
                    {files.map((file: File, index) => (
                        <Collapse
                            timeout={300}
                            key={`fichier-${file.name}-${file.size}-${file.lastModified}`}
                        >
                            <UploadedFile
                                mode="inline"
                                key={`${file.name}-${file.size}-${file.lastModified}`}
                                file={file}
                                status={getBase64Statut(filesBase64[index])}
                                onDelete={() => {
                                    removeFile(index);
                                }}
                            />
                            {index < files.length - 1 ? <Divider /> : null}
                        </Collapse>
                    ))}
                </TransitionGroup>
            </List>

            {dossier ? (
                <DocToSend
                    currentDocs={currentDocs}
                    dossier={dossier}
                    onDropFileWrapper={onDropFileWrapper}
                    uploadingFiles={uploadingFiles}
                />
            ) : null}

            <Button
                type="submit"
                className="submit-btn"
                variant="contained"
                color="primary"
                data-test-id="submit-create-group"
                onClick={async () => {
                    if (dossier) {
                        setLoading(true);
                        await uploadDossierFiles(dossier, fichiersDossierApi, files);
                        navigate(parcoursFormalitePathResolver('stepRecap'));
                    }
                }}
                sx={{
                    backgroundColor: theme.palette.primary.dark,
                    width: '100%',
                    marginTop: '30px',
                    padding: '15px',
                    fontSize: '1rem',
                    borderRadius: '15px',
                }}
            >
                Continuer
            </Button>
        </Container>
    );
};

export default StepDocuments;
