import React, { useCallback } from 'react';
import {
    CustomDivider,
    InformationCard,
    InformationCardProps,
    NoteInformation,
    Previous,
    SelectItems,
} from '@europrocurement/flexy-components';
import {
    CollectionDossierFormalite,
    DossierCollectionFormaliteApiObject,
    PrescripteurApi,
    SousClientApi,
    useFormaliteService,
} from '@europrocurement/l2d-domain';
import { castIriToId, formatPrice } from '@europrocurement/l2d-utils';
import { Button, Grid, Switch, TextField, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import {
    FichiersDossierJsonldDocumentfileRead,
    DossierJsonldDossierRead,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import PriceLigne from '@europrocurement/flexy-components/components/atoms/PriceLine/PriceLine';
import { FaOptionIcon, todoIcon } from '@europrocurement/l2d-icons';
import { faPaperPlane, faWarning } from '@fortawesome/pro-regular-svg-icons';
import { AdresseJsonldTiersRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import CardHeader from '@europrocurement/flexy-components/components/atoms/CardHeader';
import CardSection from '@europrocurement/flexy-components/components/atoms/CardSection';

type Items = InformationCardProps['items'];

type RecapFormalityShowProps = {
    collection: CollectionDossierFormalite;
    fichiers: FichiersDossierJsonldDocumentfileRead[];
    onPrevious?: () => void;
    onSubmit?: (
        collection: CollectionDossierFormalite,
        refInterne: string | null,
        commentaires: string | null,
        dossierUrgent: boolean | null,
    ) => void;
    prescripteur: PrescripteurApi;
    sousClient?: SousClientApi | undefined;
};
type RecapArticlesLinesProps = {
    montantPubPubns: number;
    montantVac: number;
    montantFrnFrs: number;
    montantAutre: number;
};

export const AdresseComposee = (adresse: AdresseJsonldTiersRead): string => {
    let adresseCompo = adresse.adresse1;
    if (adresse.adresse2) {
        adresseCompo += ` ${adresse.adresse2}`;
    }
    if (adresse.adresse3) {
        adresseCompo += ` ${adresse.adresse3}`;
    }
    if (adresse.adresse4) {
        adresseCompo += ` ${adresse.adresse4}`;
    }
    adresseCompo += ` ${adresse.codePostal}`;
    adresseCompo += ` ${adresse.ville}`;
    return adresseCompo;
};

export const reduceLines = function (
    dossier: DossierCollectionFormaliteApiObject,
): RecapArticlesLinesProps {
    const montantReduit: RecapArticlesLinesProps = {
        montantPubPubns: 0,
        montantVac: 0,
        montantFrnFrs: 0,
        montantAutre: 0,
    };

    if (!dossier.prestations || dossier.prestations.length === 0) {
        return montantReduit;
    }
    dossier.prestations.map((prestation) => {
        if (prestation.articles) {
            prestation.articles.map((article) => {
                if (article.tarif && !article.fMasqueCalcul && article.factArticle.rubArticle) {
                    switch (article.factArticle.rubArticle) {
                        case 'FRN':
                        case 'FRS':
                            montantReduit.montantFrnFrs +=
                                Number(article.tarif) * Number(article.quantite);
                            break;
                        case 'VAC':
                            montantReduit.montantVac +=
                                Number(article.tarif) * Number(article.quantite);
                            break;
                        case 'PUB':
                        case 'PUBNS':
                            montantReduit.montantPubPubns +=
                                Number(article.tarif) * Number(article.quantite);
                            break;
                        default:
                            montantReduit.montantAutre +=
                                Number(article.tarif) * Number(article.quantite);
                            break;
                    }
                }
                return montantReduit;
            }, []);
        }
        return montantReduit;
    });

    return montantReduit;
};

const RecapFormalityShow: React.FunctionComponent<RecapFormalityShowProps> = function ({
    collection,
    fichiers,
    prescripteur,
    sousClient = prescripteur,
    onPrevious,
    onSubmit,
}) {
    const [refInterne, setRefInterne] = React.useState<string | null>(null);
    const [commentaires, setCommentaires] = React.useState<string | null>(null);
    const [innerDossier, setInnerDossier] = React.useState<DossierJsonldDossierRead | null>(null);
    const [dossierUrgent, setDossierUrgent] = React.useState<boolean | null>(null);

    const { dossierApi } = useFormaliteService();

    const theme = useTheme();

    const onSubmitHandler = useCallback(() => {
        if (onSubmit) {
            onSubmit(collection, refInterne, commentaires, dossierUrgent);
        }
    }, [commentaires, collection, dossierUrgent, onSubmit, refInterne]);

    if (!collection.dossiers || collection.dossiers.length === 0) {
        console.error("Demande d'affichage d'une collection sans dossier en récap.");
        throw new Error("Demande d'affichage d'une collection sans dossier en récap.");
    }
    if (collection.dossiers.length > 1) {
        console.error("Demande d'affichage d'une collection multiples en récap.");
        throw new Error("Demande d'affichage d'une collection multiples en récap.");
    }

    const premierDossier = collection?.dossiers[0];

    React.useEffect(() => {
        dossierApi.apiDossiersIdGet({ id: `${premierDossier.id}` }).then(({ data }) => {
            setInnerDossier(data);
        });
    }, [dossierApi, premierDossier.id]);

    let cardItemsSocite: InformationCardProps['items'] = [
        {
            label: 'Société concernée',
            value: premierDossier.societeDenomination,
        },
    ];

    if (premierDossier.societeSiren) {
        cardItemsSocite = [
            ...cardItemsSocite,
            {
                label: 'Numéro Siren',
                value: premierDossier.societeSiren,
            },
        ];
    }

    const cardItemsFormalite: () => Items = () => {
        if (!premierDossier.prestations || premierDossier.prestations.length === 0) {
            return [];
        }
        return premierDossier.prestations?.map((prestation, index) => ({
            label: `Formalité n°${index + 1}`,
            value: prestation.libelle,
        }));
    };

    const lignesArticle: RecapArticlesLinesProps = reduceLines(premierDossier);

    const cardProps = {
        backgroundColor: theme.palette.background.dark,
        boxShadow: '0px 2px 2.5px 0px rgba(193, 202, 215, 0.25)',
    };

    const informationsDocuments: InformationCardProps['items'] =
        innerDossier?.documents
            ?.map((typedoc) => ({
                ...typedoc,
                libelle: typedoc.libelle || 'Document non typé',
                id: typedoc.id || -1,
            }))
            .map((requiredType) => {
                const docOk = fichiers?.find(
                    (item) => item.typeDocDossier && item.typeDocDossier.id === requiredType.id,
                );
                if (docOk) {
                    return {
                        label: requiredType.libelle,
                        value: (
                            <Typography color={theme.palette.primary.main}>
                                {docOk.fichier}
                            </Typography>
                        ),
                    };
                }
                return {
                    label: requiredType.libelle,
                    value: (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '15px',
                                alignItems: 'start',
                            }}
                        >
                            <Typography color="text.gris70">Manquant</Typography>
                            <FaOptionIcon
                                icon={faWarning}
                                color={theme.palette.warning.main}
                                sx={{ marginRight: '5px' }}
                            />
                        </Box>
                    ),
                    MarkAsEmpty: true,
                };
            }) || [];

    const mainAddressSousClient = sousClient
        ? sousClient.adresses.find((address) => address.defaut)
        : undefined;

    const mainAddressePrescripteur = prescripteur.adresses.find((address) => address.defaut);

    return (
        <>
            {onPrevious ? <Previous onClick={onPrevious} /> : null}
            <Typography
                variant="l2dh4"
                sx={{
                    marginBottom: '10px',
                }}
            >
                Récapitulatif du dossier #{premierDossier.numero}
            </Typography>
            <Grid
                container
                flexWrap="nowrap"
                spacing={6}
            >
                <Grid
                    item
                    lg={8}
                    display="flex"
                    flexDirection="column"
                    gap="16px"
                >
                    <CardSection
                        header={
                            <CardHeader
                                title={
                                    <Typography variant="l2dh6">Paramètres du dossier</Typography>
                                }
                            />
                        }
                    >
                        <Box sx={{ padding: '20px' }}>
                            <Box>
                                <Typography
                                    variant="l2dbodym"
                                    fontWeight={700}
                                >
                                    Référence interne du dossier
                                </Typography>
                                <Typography
                                    sx={{ marginLeft: '5px' }}
                                    variant="l2dbodym"
                                    color="text.secondary"
                                >
                                    (facultatif)
                                </Typography>
                            </Box>
                            <TextField
                                fullWidth
                                value={premierDossier.societeRef}
                                placeholder="Saisissez une référence pour ce dossier"
                                sx={{ marginTop: '10px', marginBottom: '25px' }}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                                ) => {
                                    if (e.target.value && e.target.value !== '') {
                                        setRefInterne(e.target.value);
                                    } else {
                                        setRefInterne(null);
                                    }
                                }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '15px',
                                    padding: '10px',
                                    alignItems: 'start',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    variant="l2dbodym"
                                    fontWeight={700}
                                >
                                    Marquer ce dossier comme urgent ?
                                </Typography>

                                <Switch
                                    size="small"
                                    aria-label="switch"
                                    value={collection.urgent}
                                    onChange={(e, checked: boolean) => {
                                        setDossierUrgent(checked);
                                    }}
                                />
                            </Box>
                            <Box>
                                <NoteInformation title="Cette option entraînera des frais de traitement supplémentaires." />
                            </Box>

                            <Box>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        marginTop: '25px',
                                    }}
                                >
                                    <b>Commentaires</b> (facultatif)
                                </Typography>
                                <textarea
                                    style={{ width: '100%', borderRadius: '10px', padding: '5px' }}
                                    placeholder="Ajouter des informations complémentaires au dossier"
                                    onChange={(
                                        e: React.ChangeEvent<
                                            HTMLInputElement | HTMLTextAreaElement
                                        >,
                                    ) => {
                                        if (e.target.value && e.target.value !== '') {
                                            setCommentaires(e.target.value);
                                        } else {
                                            setCommentaires(null);
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                    </CardSection>

                    <CardSection
                        header={
                            <CardHeader
                                title={<Typography variant="l2dh6">Facturation</Typography>}
                            />
                        }
                    >
                        <Box sx={{ padding: '20px' }}>
                            <Typography
                                variant="l2dbodym"
                                fontWeight={700}
                            >
                                Facture éditée au nom de :
                            </Typography>
                            <SelectItems
                                items={[
                                    {
                                        label: prescripteur.raisonSociale,
                                        value: '1',
                                        isChecked: false,
                                        baseline:
                                            mainAddressePrescripteur &&
                                            mainAddressePrescripteur !== undefined
                                                ? AdresseComposee(mainAddressePrescripteur)
                                                : undefined,
                                    },
                                    {
                                        label: 'Nom Sous-client',
                                        value: '6',
                                        isChecked: true,
                                        baseline:
                                            mainAddressSousClient &&
                                            mainAddressSousClient !== undefined
                                                ? AdresseComposee(mainAddressSousClient)
                                                : undefined,
                                    },
                                ]}
                                mode="inline"
                                onChange={() => {}}
                                multiple={false}
                            />
                            <Typography
                                variant="l2dbodym"
                                fontWeight={700}
                            >
                                Recouvrement effectué par :
                            </Typography>
                            <SelectItems
                                items={[
                                    {
                                        label: prescripteur.raisonSociale,
                                        value: '1',
                                        isChecked: true,
                                    },
                                    { label: 'Legal2Digital', value: '6', isChecked: false },
                                ]}
                                mode="inline"
                                onChange={() => {}}
                                multiple={false}
                            />
                        </Box>
                    </CardSection>
                    <InformationCard
                        title="Société"
                        subtitle=""
                        items={cardItemsSocite}
                        sx={{ ...cardProps }}
                        button={{
                            label: 'Revenir à cette étape',
                            icon: todoIcon,
                            sx: { color: 'text.gris70' },
                            onClick: () => {},
                        }}
                    />
                    <InformationCard
                        title="Formalité"
                        items={cardItemsFormalite()}
                        sx={{ ...cardProps }}
                        subtitle=""
                        button={{
                            label: 'Revenir à cette étape',
                            icon: todoIcon,
                            sx: { color: 'text.gris70' },
                            onClick: () => {},
                        }}
                    />
                    {innerDossier &&
                    innerDossier.documents &&
                    innerDossier.documents.filter(
                        (item) => item.statut && castIriToId(item.statut) === 7,
                    ) ? (
                        <InformationCard
                            title="Documents"
                            sx={{ ...cardProps }}
                            items={informationsDocuments}
                            labelCount={{
                                plurialLabel: 'documents transmis',
                                singularLabel: 'document transmis',
                            }}
                            button={{
                                label: 'Revenir à cette étape',
                                icon: todoIcon,
                                sx: { color: 'text.gris70' },
                                onClick: () => {},
                            }}
                        />
                    ) : null}
                </Grid>
                <Grid
                    item
                    lg={4}
                    display="flex"
                    flexDirection="column"
                    gap="16px"
                >
                    <Box
                        className="information-card"
                        sx={{ ...cardProps, padding: '24px  32px' }}
                    >
                        <Typography
                            variant="l2dbodyl"
                            sx={{ fontWeight: 400 }}
                        >
                            Estimation
                        </Typography>
                        <Box>
                            {lignesArticle.montantFrnFrs ? (
                                <PriceLigne
                                    label="Frais de registre"
                                    price={`${formatPrice(lignesArticle.montantFrnFrs)} €`}
                                />
                            ) : null}
                            {lignesArticle.montantPubPubns ? (
                                <PriceLigne
                                    label="Annonce légale"
                                    price={`${formatPrice(lignesArticle.montantPubPubns)} €`}
                                />
                            ) : null}
                            {lignesArticle.montantVac ? (
                                <PriceLigne
                                    label="Honoraires"
                                    price={`${formatPrice(lignesArticle.montantVac)} €`}
                                />
                            ) : null}

                            {lignesArticle.montantAutre ? (
                                <PriceLigne
                                    label="Annonce légale"
                                    price={`${formatPrice(lignesArticle.montantAutre)} €`}
                                />
                            ) : null}
                        </Box>
                        <CustomDivider />
                        <PriceLigne
                            labelTypographyProps={{
                                fontWeight: 600,
                                color: 'text.primary',
                            }}
                            priceTypographyProps={{
                                fontWeight: 600,
                                color: 'text.primary',
                            }}
                            label="Prix HT"
                            price={`${formatPrice(premierDossier.ht)} €`}
                        />
                        <PriceLigne
                            labelTypographyProps={{
                                fontWeight: 600,
                                color: 'text.primary',
                            }}
                            priceTypographyProps={{
                                fontWeight: 600,
                                color: 'text.primary',
                            }}
                            label="TVA"
                            price={`${formatPrice(premierDossier.tva)} €`}
                        />
                        <CustomDivider />

                        <PriceLigne
                            labelTypographyProps={{
                                fontWeight: 600,
                                color: 'text.primary',
                            }}
                            priceTypographyProps={{
                                variant: 'l2dh6',
                                fontWeight: 600,
                                color: 'text.primary',
                            }}
                            label="Prix TTC"
                            price={`${formatPrice(premierDossier.ttc)} €`}
                        />
                    </Box>
                    <Box>
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{
                                width: '100%',
                                backgroundColor: theme.palette.primary.dark,
                                height: '56px',
                            }}
                            onClick={onSubmitHandler}
                        >
                            <FaOptionIcon
                                icon={faPaperPlane}
                                sx={{ marginRight: '5px' }}
                            />
                            Envoyer le dossier
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default RecapFormalityShow;
