import React from 'react';
import { RouteObject } from 'react-router-dom';
import { dossiersBasePath, listeDossiersPath, detailDossierPath } from './constants';
import DossiersRoot from './dossiersRoot';
import ListeDossiers from './pages/liste/ListeDossiers';
import DetailDossier from './pages/detail/DetailDossier';

export const DossiersRouter: RouteObject = {
    path: dossiersBasePath,
    element: <DossiersRoot />,
    children: [
        {
            path: listeDossiersPath,
            element: <ListeDossiers />,
        },
        {
            path: detailDossierPath,
            element: <DetailDossier />,
        },
    ],
};
