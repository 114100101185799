import React, { useCallback, useEffect } from 'react';
import RecapFormalityShow from '@europrocurement/l2d-modules/modules/ParcoursFormalite/components/RecapFormalityShow';
import { Container } from '@mui/system';
import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';
import {
    CollectionDeDossierCollectiondossierRead,
    FichiersDossierJsonldDocumentfileRead,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { useFormaliteService } from '@europrocurement/l2d-domain';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { useParcoursFormalite } from '../provider';
import { parcoursFormalitePathResolver } from '../parcoursFormalitePathResolver';
import { useChangeHeaderParcours } from '../hooks/useChangeHeaderParcours';
import { loadDossier } from '../functions/docFunctions';

const StepRecap: React.FunctionComponent = function () {
    const snackbar = useSnackbar();

    const {
        getCurrentDossier,
        getCurrentCollection,
        getPrescripteur,
        currentSousClient,
        setLoading,
    } = useParcoursFormalite();

    React.useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    const { fichiersDossierApi, dossierApi, collectionApi, messageApi } = useFormaliteService();

    const kc = UseKeycloakService();

    const collection = getCurrentCollection();
    const dossier = getCurrentDossier();
    const navigate = useNavigate();
    const prescripteur = getPrescripteur();
    const sousClient = currentSousClient;

    const [currentDocs, setCurrentDocs] =
        React.useState<Array<FichiersDossierJsonldDocumentfileRead> | null>(null);

    const reloadDossierAndDocs = useCallback(async () => {
        if (dossier) {
            const prom = loadDossier(dossier, fichiersDossierApi);
            return await prom.then((res) => {
                const fichiers = res.data['hydra:member'];
                setCurrentDocs(fichiers);
            });
        }
        return Promise.resolve();
    }, [dossier, fichiersDossierApi]);

    useEffect(() => {
        if (dossier && dossier.id && !currentDocs) {
            reloadDossierAndDocs();
        }
    }, [currentDocs, dossier, fichiersDossierApi, reloadDossierAndDocs]);

    const { setCustomHeader } = useFullLayout();
    const changeHeaderParcours = useChangeHeaderParcours();

    React.useEffect(() => {
        changeHeaderParcours(4);
        return () => {
            setCustomHeader(null);
        };
    }, [setCustomHeader, changeHeaderParcours]);

    const onSubmitHandler = useCallback(
        async (
            innerCollection: CollectionDeDossierCollectiondossierRead,
            refInterne: string | null,
            commentaires: string | null,
            dossierUrgent: boolean | null,
        ) => {
            setLoading(true);
            if (innerCollection.dossiers?.length !== 1) {
                throw new Error('error in dossier length');
            }
            const innerDossier = innerCollection.dossiers[0];

            const freshDossier = (await dossierApi.apiDossiersIdGet({ id: `${innerDossier.id}` }))
                .data;
            await dossierApi.apiDossiersIdPut({
                id: `${innerDossier.id}`,
                dossierJsonldDossierUpdate: {
                    ...freshDossier,
                    societeRef: refInterne,
                },
            });

            if (!collection || !collection.id) {
                throw new Error('error in Collection');
            }

            const freshCollection = (
                await collectionApi.apiCollectionsDossiersIdGet({ id: `${collection.id}` })
            ).data;

            if (!freshCollection || !freshCollection.id) {
                throw new Error('missing freshCollection');
            }

            if (!freshCollection.client) {
                throw new Error('missing client');
            }
            if (!freshCollection.prescripteur) {
                throw new Error('missing prescripteur');
            }

            await collectionApi.apiCollectionsDossiersIdValidatePut({
                id: `${freshCollection?.id}`,
                collectionDeDossierJsonldCollectiondossierUpdate: {
                    prescripteur: freshCollection.prescripteur?.replace(
                        '/tiers/tiers',
                        '/formalite/tiers',
                    ),
                    urgent: dossierUrgent === true,
                    client: freshCollection.client?.replace('/tiers/tiers', '/formalite/tiers'),
                    infoComp: freshCollection.infoComp,
                    libelle: freshCollection.libelle,
                },
            });

            if (commentaires && commentaires !== '') {
                await messageApi.apiMessagesPost({
                    messageJsonldMessageCreate: {
                        expediteur: 1,
                        expediteurNom: kc.getName(),
                        message: commentaires,
                        dossier: `/formalite/dossiers/${innerDossier.id}`,
                    },
                });
            }

            snackbar.enqueueSnackbar('Dossier envoyé !');
            navigate('/dossiers/');
        },
        [setLoading, dossierApi, collection, collectionApi, snackbar, navigate, messageApi, kc],
    );

    if (!collection || !currentDocs) {
        return null;
    }

    return (
        <Container maxWidth="lg">
            <RecapFormalityShow
                onPrevious={() => {
                    navigate(parcoursFormalitePathResolver('stepDocs'));
                }}
                collection={collection}
                fichiers={currentDocs}
                prescripteur={prescripteur}
                sousClient={sousClient}
                onSubmit={onSubmitHandler}
            />
        </Container>
    );
};

export default StepRecap;
