import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { FlexyTextField } from '../../atoms';

type FormData = {
    namegroup: string;
};
export type ObjectGroupsCreateProps = {
    title: string;
    fieldLabel: string;
    submitButtonLabel: string;
    placeholderLabel?: string;
    onSubmit: (data: FormData) => void;
    onCancel?: () => void;
};

const ObjectGroupsCreate: React.FunctionComponent<ObjectGroupsCreateProps> = function ({
    title,
    fieldLabel,
    submitButtonLabel,
    placeholderLabel,
    onSubmit,
    onCancel,
}) {
    const [formData, setFormData] = React.useState<FormData>({ namegroup: '' });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCancel = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setFormData({ namegroup: '' });
        if (onCancel) {
            onCancel();
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(formData);
    };

    return (
        <Box className="groupment-modal">
            <form onSubmit={handleSubmit}>
                <Box className="body">
                    <Box
                        sx={{
                            width: '100%',
                        }}
                    >
                        <Box className="title">{title}</Box>
                        <Box sx={{ width: '100%', borderRadius: '10px' }}>
                            <FlexyTextField
                                label={fieldLabel}
                                variant="outlined"
                                value={formData.namegroup}
                                name="namegroup"
                                onChange={handleInputChange}
                                placeholder={placeholderLabel || ''}
                                sx={{ width: '100%' }}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box className="actions">
                    <Button
                        variant="text"
                        onClick={handleCancel}
                    >
                        <Typography className="cancel-label">Annuler</Typography>
                    </Button>
                    <Button
                        type="submit"
                        className="submit-btn"
                        variant="contained"
                        color="primary"
                        data-test-id="submit-create-group"
                        sx={{ backgroundColor: '#011B58' }}
                    >
                        {submitButtonLabel || 'Ajouter'}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default ObjectGroupsCreate;
