import {
    FlexySelectFormStructure,
    FormObject,
    FormStructure,
} from '@europrocurement/flexy-form/components';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { jsonSelectInput } from '../types/formTypes';

const jsonSelectInputToFormElement: (input: jsonSelectInput) => FormStructure = (input) => {
    const baseField: FlexySelectFormStructure = {
        type: 'select',
        name: input.name,
        inputlabel: input.inputlabel,
        placeholder: input.placeholder,
        sx: {
            fontSize: '14px',
            marginTop: '15px',
            marginBottom: '15px',
            fontWeight: '500',
        },
        tooltip: input.tooltip,
    };

    if (input.valuesfrom.options) {
        const optionsFlexySelect = Object.entries(input.valuesfrom.options).map((option) => ({
            value: option[0],
            label: option[1],
        }));
        baseField.options = optionsFlexySelect;
    }

    if (input.valuesfrom.api) {
        baseField.optionsResolver = {
            name: 'listeSelectionDataResolver',
            params: {
                api: input.valuesfrom.api.name,
                endpoint: input.valuesfrom.api.endpoint,
                discriminant: input.valuesfrom.api.params.discriminant,
            },
        };
    }

    // comportement sur rules display
    if (input.rules && input.rules.display) {
        if (input.rules.display.fieldIsEqualTo) {
            const fieldNameToWatch = input.rules.display.fieldIsEqualTo.field;
            const valueToWatch = input.rules.display.fieldIsEqualTo.value;

            baseField.display = (formContext: UseFormReturn<FieldValues, FormObject>) => {
                const valueWatch: number = parseInt(
                    formContext.watch(fieldNameToWatch as string)?.value,
                    10,
                );

                return Array.isArray(valueToWatch)
                    ? valueToWatch.includes(valueWatch)
                    : valueWatch === valueToWatch;
            };
        }
    }

    return baseField;
};

export default jsonSelectInputToFormElement;
