import {
    SliceFactory,
    FetchCollectionData,
    ApiCollectionResponse,
    FetchItemDataType,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as DossierOpenSearchApiConfigurationParameters,
    Configuration,
    DossierOpenSearchJsonldOpensearchDossierRead,
    DossierOpenSearchApi,
    DossierOpenSearchApiApiOpensearchDossiersGetCollectionRequest,
    DossierOpenSearchApiApiOpensearchDossiersIdGetRequest,
} from '../../../openApi/ApiFormalite';
import { DOSSIERS_OPEN_SEARCH_SLICE_NAME, FORMALITES_REDUCER_NAME } from '../constants';

export type DossierOpenSearchApiObject = DossierOpenSearchJsonldOpensearchDossierRead;

export type NestedDossierFormaliteOpenSearch = { id: number; libelle: string };

export type DossierOpenSearch = {
    formalites: Array<NestedDossierFormaliteOpenSearch>;
} & Record<string, unknown> &
    DossierOpenSearchApiObject;

export const mapperOpenSearchDossier = function (dossier: DossierOpenSearch) {
    return {
        ...dossier,
    } as DossierOpenSearch;
};

export function createDossierOpenSearchSlice(
    configuration: DossierOpenSearchApiConfigurationParameters,
) {
    const conf = new Configuration(configuration);
    const api = new DossierOpenSearchApi(conf);

    const fetchDossierOpenSearchCollection: FetchCollectionData<DossierOpenSearch> = ({
        pagination,
        orders,
        filters,
        preFilters,
    }) => {
        const options = undefined;
        let orderNumero: 'asc' | 'desc' | undefined;
        let orderContactNom: 'asc' | 'desc' | undefined;
        let orderSocieteDenomination: 'asc' | 'desc' | undefined;
        let orderDateCreation: 'asc' | 'desc' | undefined;
        let orderStatutLibelle: 'asc' | 'desc' | undefined;
        let orderFormalisteNom: 'asc' | 'desc' | undefined;
        const {
            groupId,
            collectionId,
            clientId,
            formalisteId,
            formaliteId,
            dateCreation,
            numero,
            societeReference,
        } = filters;
        const { statutId } = preFilters;
        let startDate: string | undefined;
        let endDate: string | undefined;

        if (dateCreation?.startDate) {
            startDate = dateCreation.startDate;
        }

        if (dateCreation?.endDate) {
            endDate = dateCreation.endDate;
        }

        orders.forEach((order) => {
            if (order.field === 'numero') {
                orderNumero = order.value;
            }

            if (order.field === 'societeDenomination') {
                orderSocieteDenomination = order.value;
            }

            if (order.field === 'contactNom') {
                orderContactNom = order.value;
            }

            if (order.field === 'dateCreation') {
                orderDateCreation = order.value;
            }

            if (order.field === 'statutLibelle') {
                orderStatutLibelle = order.value;
            }

            if (order.field === 'formaliste') {
                orderFormalisteNom = order.value;
            }
        });

        const requestParameters: DossierOpenSearchApiApiOpensearchDossiersGetCollectionRequest = {
            page: pagination.page + 1,
            itemsPerPage: pagination.itemsPerPage,
            groupId: [groupId as number],
            collectionId: [collectionId as number],
            clientId: clientId as number[],
            formalisteId: [formalisteId as number],
            formaliteId: formaliteId as number[],
            statutId: [statutId as number],
            numero: [numero as string],
            societeReference: [societeReference as string],
            startDate: startDate ? new Date(startDate).toDateString() : undefined,
            endDate: endDate ? new Date(endDate).toDateString() : undefined,
            orderNumero,
            orderSocieteDenomination,
            orderContactNom,
            orderDateCreation,
            orderStatutLibelle,
            orderFormalisteNom,
        };

        return api
            .apiOpensearchDossiersGetCollection(requestParameters, options)
            .then((res) => res.data) as Promise<ApiCollectionResponse<DossierOpenSearch>>;
    };

    const fetchDossierOpenSearchItem: FetchItemDataType<DossierOpenSearch> = ({ idItem }) => {
        const requestParameters: DossierOpenSearchApiApiOpensearchDossiersIdGetRequest = {
            id: String(idItem),
        };

        return api
            .apiOpensearchDossiersIdGet(requestParameters)
            .then((res) => res.data) as Promise<DossierOpenSearch>;
    };

    const { slice: dossierOpenSearchSlice, dataSourcesThunks: dossierOpenSearchDataSourcesThunks } =
        SliceFactory.createSlice<DossierOpenSearch, DossierOpenSearch>(
            DOSSIERS_OPEN_SEARCH_SLICE_NAME,
            FORMALITES_REDUCER_NAME,
            [],
            fetchDossierOpenSearchCollection,
            fetchDossierOpenSearchItem,
            mapperOpenSearchDossier,
            {},
            undefined,
            [{ field: 'dateCreation', value: 'desc' }],
        );

    return { dossierOpenSearchSlice, dossierOpenSearchDataSourcesThunks };
}
