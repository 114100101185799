import React, { FocusEventHandler, ReactNode, useCallback } from 'react';
import {
    Checkbox,
    Radio,
    Tooltip,
    Typography,
    Box,
    type RadioProps,
    type CheckboxProps,
    BaseTextFieldProps,
    SxProps,
} from '@mui/material';

import Switch, { type SwitchProps } from '../../atoms/Switch';
import FlexyDate, { FlexyDateProps } from '../../atoms/FlexyDate';
import FlexySelect, { FlexySelectProps } from '../../atoms/FlexySelect';
import FlexyFormLabel, { FlexyFormTooltipProps } from '../../atoms/FlexyFormLabel';
import FlexyTextField, { FlexyTextFieldProps } from '../../atoms/FlexyTextField';

export type FlexyInputType =
    | 'displayData'
    | 'boolean'
    | 'radio'
    | 'range'
    | 'checkbox'
    | 'date'
    | 'datetime'
    | 'time'
    | 'mobiledate'
    | 'mobiledatetime'
    | 'mobiletime'
    | 'email'
    | 'text'
    | 'number'
    | 'textarea'
    | 'password'
    | 'hidden';

type FieldGroup = FlexyInputType[];

export const textFieldGroup: FieldGroup = ['email', 'text', 'number', 'textarea'];

export const isInFieldGroup = (type: FlexyInputType, fieldGroup: FieldGroup): boolean =>
    fieldGroup.includes(type);

export type RenderFieldInputType =
    | SwitchProps
    | RadioProps
    | CheckboxProps
    | FlexyDateProps
    | FlexySelectProps
    | FlexyTextFieldProps;

export type FlexyInputProps<T = RenderFieldInputType> = T & {
    inputlabel?: string | ReactNode;
    type: FlexyInputType | 'select';
    name: string;
    required?: boolean;
    verticalSwitch?: boolean;
    display?: boolean;
    inputRef?: React.Ref<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
    sx?: SxProps;
    tooltip?: FlexyFormTooltipProps;
} & BaseTextFieldProps;

/**
 * Ce composant gère la création de tout composants input en fonction de son type.
 * la props 'type' peut prendre les valeurs : 'boolean' 'radio' 'range' 'checkbox' 'date' 'select' 'datetime' 'time' 'mobiledate' 'mobiledatetime' 'mobiletime'.
 * Ansi que tout les types acceptable dans [la norme HTML5](https://developer.mozilla.org/fr/docs/Web/HTML/Element/Input).
 *
 * Par défaut : 'text'.
 *
 * inputlabel : Valeur affiché dans le label.
 *
 * name: necessaire pour identifier le champs et son label.
 *
 * ## Utilisation
 *
 * ```jsx
 * <FlexyInput
 *      type={'text'},
 *      variant={'outlined'},
 *      fullWidth={true}
 *     size={'small'},
 *  />
 * ```
 */

const FlexyInput: React.FunctionComponent<FlexyInputProps<RenderFieldInputType>> = function ({
    verticalSwitch,
    inputlabel = undefined,
    required = false,
    inputRef = undefined,
    display = true,
    sx: sxProps,
    ...props
}: FlexyInputProps<RenderFieldInputType>) {
    const { type, name, onBlur }: FlexyInputProps<RenderFieldInputType> = props;

    const renderField = useCallback(() => {
        const textFieldProps: FlexyTextFieldProps = { ...props } as FlexyTextFieldProps;
        const numericFieldProps: FlexyTextFieldProps = { ...props } as FlexyTextFieldProps;
        // numericFieldProps.type = 'text';
        const switchProps: SwitchProps = { ...props } as SwitchProps;
        const radioProps: RadioProps = { ...props } as RadioProps;
        const checkboxProps: CheckboxProps = { ...props } as CheckboxProps;
        const flexyDateProps: FlexyDateProps = { ...props } as FlexyDateProps;
        const flexySelectProps: FlexySelectProps = { ...props } as FlexySelectProps;
        switch (type) {
            case 'boolean':
                return (
                    <Switch
                        inputRef={inputRef}
                        {...switchProps}
                        type="checkbox"
                        data-testid="Switch"
                    />
                );
            case 'radio':
                return (
                    <Radio
                        inputRef={inputRef}
                        {...radioProps}
                        data-testid="Radio"
                    />
                );
            case 'checkbox':
                return (
                    <Checkbox
                        inputRef={inputRef}
                        {...checkboxProps}
                        data-testid="Checkbox"
                    />
                );
            case 'date':
            case 'datetime':
            case 'time':
            case 'mobiledate':
            case 'mobiledatetime':
            case 'mobiletime':
                return (
                    <FlexyDate
                        inputRef={inputRef}
                        {...flexyDateProps}
                    />
                );
            case 'select':
                return <FlexySelect {...flexySelectProps} />;
            case 'number':
                numericFieldProps.onBlur = onBlur;
                return (
                    <FlexyTextField
                        name={name}
                        inputRef={inputRef}
                        {...numericFieldProps}
                    />
                );
            case 'hidden':
                return (
                    <FlexyTextField
                        name={name}
                        inputRef={inputRef}
                        {...textFieldProps}
                        type="text"
                        sx={{ display: 'none' }}
                    />
                );
            case 'displayData':
                return (
                    <FlexyTextField
                        name={name}
                        inputRef={inputRef}
                        {...textFieldProps}
                        inputProps={{
                            ...textFieldProps.inputProps,
                            readOnly: true,
                            sx: { color: 'text.secondary' },
                        }}
                    />
                );
            case 'text':
            case 'email':
            case 'password':
            default:
                return (
                    <FlexyTextField
                        name={name}
                        inputRef={inputRef}
                        {...textFieldProps}
                    />
                );
        }
    }, [props, type, inputRef, onBlur, name]);

    if (!display) return null;

    return type === 'boolean' && (verticalSwitch === undefined || verticalSwitch === false) ? (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            {inputlabel && inputlabel !== '' ? (
                <FlexyFormLabel data-testid="test-id-FlexyInput-label">
                    <Typography
                        component="span"
                        sx={sxProps}
                    >
                        {inputlabel}
                        {required && (
                            <Tooltip title="Le champ est requis">
                                <Typography
                                    component="span"
                                    color="danger.main"
                                >
                                    &nbsp;&nbsp;*
                                </Typography>
                            </Tooltip>
                        )}
                    </Typography>
                </FlexyFormLabel>
            ) : null}
            {renderField()}
        </Box>
    ) : (
        <>
            {type !== 'hidden' && inputlabel && inputlabel !== '' ? (
                <FlexyFormLabel
                    sx={sxProps}
                    data-testid="test-id-FlexyInput-label"
                    tooltip={props.tooltip}
                >
                    {inputlabel}
                    {required && (
                        <Tooltip title="Le champ est requis">
                            <Typography
                                component="span"
                                color="danger.main"
                            >
                                &nbsp;&nbsp;*
                            </Typography>
                        </Tooltip>
                    )}
                </FlexyFormLabel>
            ) : null}
            {renderField()}
        </>
    );
};

export default FlexyInput;
