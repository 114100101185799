import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Box, IconButton, SxProps, Tooltip, Typography } from '@mui/material';
import React from 'react';

export const testId = 'Toolbar-Action-test-id';
export type ToolbarActionButtonProps = {
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    icon: IconProp;
    label?: string;
    displayLabel?: boolean;
    tooltip?: boolean | string;
    color?: string;
    sx?: SxProps;
};

const ToolbarActionButton: React.FunctionComponent<ToolbarActionButtonProps> = function ({
    onClick,
    icon,
    label = '',
    displayLabel = true,
    tooltip = false,
    color = '',
    sx,
}) {
    const hasLabel = displayLabel && label;

    const buttonStyle = {
        borderRadius: '5px',
        display: 'flex',
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': { backgroundColor: '#11111111' },
        ...sx,
    };
    const innerIconStyle = {
        color,
        fontSize: '25px',
        ...(hasLabel ? { marginLeft: '6px' } : {}),
    };
    const labelStyle = { marginX: '10px', color };

    const tooltipContent = typeof tooltip === 'string' ? tooltip : label;
    const button = (
        <IconButton
            data-testid={testId}
            onClick={onClick}
            sx={buttonStyle}
        >
            <Box>
                <FaOptionIcon
                    icon={icon}
                    sx={innerIconStyle}
                    size="xs"
                />
            </Box>
            {hasLabel && <Typography sx={labelStyle}>{label}</Typography>}
        </IconButton>
    );
    return tooltip ? <Tooltip title={tooltipContent}>{button}</Tooltip> : button;
};

export default ToolbarActionButton;
