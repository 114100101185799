import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Box,
    ClickAwayListener,
    IconButton,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
    type TypographyProps,
} from '@mui/material';
import { FaOptionIcon, informationIcon } from '@europrocurement/l2d-icons';

export type FlexyFormTooltipProps = {
    name: undefined | string;
    text: React.ReactNode;
};

export type FlexyFormLabelProps = TypographyProps & {
    children: React.ReactNode;
    tooltip?: FlexyFormTooltipProps;
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) =>
    props.onClose ? (
        <ClickAwayListener onClickAway={props.onClose}>
            <Tooltip
                {...props}
                classes={{ popper: className }}
                PopperProps={{ sx: { '.MuiTooltip-tooltip': { bgcolor: 'green' } } }}
            />
        </ClickAwayListener>
    ) : (
        <Tooltip
            {...props}
            classes={{ popper: className }}
            PopperProps={{ sx: { '.MuiTooltip-tooltip': { bgcolor: 'green' } } }}
        />
    ),
)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '1px 1px 15px 0px rgba(0, 0, 0, 0.2)',
        textAlign: 'justify',
        padding: '15px',
        marginBottom: '5px',
    },
}));

/**
 * Composant permettant d'afficher un label personnalisé
 *
 *  ## Utilisation
 *
 * ```jsx
 * <FlexyFormLabel>
 *      mon label
 * </FlexyFormLabel>
 * ```
 */
const FlexyFormLabel: React.FunctionComponent<FlexyFormLabelProps> = styled(
    (props: FlexyFormLabelProps) => {
        const [tooltipOpen, setTooltipOpen] = useState(false);

        const handleTooltipClose = () => {
            setTooltipOpen(false);
        };

        const handleTooltipToggle = () => {
            setTooltipOpen(!tooltipOpen);
        };

        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography
                    variant="l2dlabel"
                    {...props}
                />
                {props.tooltip ? (
                    <LightTooltip
                        key={props.tooltip.name}
                        title={
                            <Typography
                                variant="l2dbodyxs"
                                color="text.primary"
                                align="justify"
                            >
                                {props.tooltip.text}
                            </Typography>
                        }
                        open={tooltipOpen}
                        onClose={handleTooltipClose}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        placement="top-end"
                    >
                        <IconButton
                            color="secondary"
                            size="small"
                            sx={{
                                height: 'fit-content',
                                marginTop: '10px',
                            }}
                            onClick={handleTooltipToggle}
                        >
                            <FaOptionIcon {...informationIcon.props} />
                        </IconButton>
                    </LightTooltip>
                ) : null}
            </Box>
        );
    },
)(() => ({
    marginBottom: '5px',
    marginTop: '15px',
    display: 'block',
}));

export default FlexyFormLabel;
