import { TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';

const FlexyDatatableBodyFailed: React.FunctionComponent = function () {
    return (
        <TableBody data-testid="FlexyDatatableBody">
            <TableRow>
                <TableCell
                    colSpan={12}
                    sx={{ height: '45vh' }}
                    data-testid="StatusFailed"
                >
                    une erreur lors du chargement est survenue
                </TableCell>
            </TableRow>
        </TableBody>
    );
};

export default FlexyDatatableBodyFailed;
