import React from 'react';
import { Box, useTheme } from '@mui/material';

export type CountBadgeProps = {
    count: number;
};

const CountBadge: React.FunctionComponent<CountBadgeProps> = function ({ count }) {
    const { palette } = useTheme();
    return (
        <Box
            data-testid="CountBadge-test-id"
            sx={{
                fontSize: '12px',
                fontWeight: '700',
                lineHeight: '14.4px',
                textAlign: 'center',
                padding: '2px 5px 2px 5px',
                borderRadius: '16px',
                backgroundColor: palette.primary.light,
            }}
        >
            {count}
        </Box>
    );
};

export default CountBadge;
