import React, { useEffect, useCallback, useRef } from 'react';
import { Box, Card, SxProps, Tab, Tabs, Typography } from '@mui/material';
import { EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import CenterCircularProgress from '../../organisms/CenterCircularProgress';
import { TopbarHeight, breadcrumbHeight, footerHeight } from '../../../assets';
import TabPanel from '../TabPanel';
import Scrollbar from '../Scrollbar';
import useUrlParameters from '../../../hooks/useUrlParameters';
import CountBadge from '../../atoms/CountBadge';

export type TabStructure = {
    tabTitle: string;
    tabPanel: React.ReactNode;
    disabled?: boolean;
    tabName: string;
    count?: number;
};

export type FlexyTabsProps = {
    status?: EuroprocApiResponseStatus;
    tabs: Array<TabStructure>;
    orientation?: 'vertical' | 'horizontal';
    sx?: SxProps;
    inCard?: boolean;
    routerParameterName?: string;
    scrollbar?: boolean;
    indicatorColor?: 'primary' | 'secondary';
};

const FlexyTabs: React.FunctionComponent<FlexyTabsProps> = function ({
    status,
    tabs,
    orientation = 'horizontal',
    sx,
    inCard,
    routerParameterName = 'tab',
    scrollbar = true,
    indicatorColor = null,
}) {
    /**
     * style
     */

    // style du wrapper des panels
    const tabWrapperStyle: SxProps = {
        width: '100%',
    };

    // Référence du paneau
    const tabRef = useRef<HTMLDivElement>(null);
    // Hauteur du paneau
    const heightRef = tabRef?.current?.offsetHeight || 0;
    const gap = 15;
    // Couleur de l'indicateur de tab actif
    let activeTabIndicatorColor: 'primary' | 'secondary' =
        orientation === 'horizontal' ? 'secondary' : 'primary';

    if (indicatorColor) {
        activeTabIndicatorColor = indicatorColor;
    }

    const tabStyle: SxProps = {
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        minHeight: '400px',
    };

    /**
     * logique
     */

    // Gestion du router
    const { urlParameters, addUrlParameters } = useUrlParameters();

    const getUrlTabParameter = useCallback(() => {
        const allowedTabNames = tabs.map((tab) => tab.tabName);
        return allowedTabNames.includes(urlParameters[routerParameterName])
            ? urlParameters[routerParameterName]
            : undefined;
    }, [routerParameterName, tabs, urlParameters]);

    const getTabNameFromIndex = useCallback((index: number) => tabs[index].tabName, [tabs]);

    const getTabIndexFromUrl = useCallback(() => {
        const urlTabParameter = getUrlTabParameter();

        // on cherche dans l'url
        if (urlTabParameter) {
            const res = tabs.findIndex(
                (tab) => tab.tabName === urlTabParameter && tab.disabled !== true,
            );
            if (res > -1) return res;
        }

        // si on trouve pas on prenbds le premier pas disable
        const res = tabs.findIndex((tab) => tab.disabled !== true);
        if (res > -1) return res;
        // si ils sont tous disabled wtf
        throw new Error('All tabs are disabled');
    }, [getUrlTabParameter, tabs]);

    // Gestion tab actif

    const [activeTabIndex, setActiveTabIndex] = React.useState(0);

    // Si 'handleActiveTab' et 'activeTab' existes et 'activeTab' différent
    // de la nouvelle valeur, alors 'handleActiveTab' est utilisé.
    // Dans tous les cas, 'value' change.
    const handleChange = useCallback(
        (event: React.SyntheticEvent | null, newValue: number) => {
            const toAdd: Record<string, string | string[]> = {};
            toAdd[routerParameterName] = getTabNameFromIndex(newValue);
            addUrlParameters(toAdd);
            setActiveTabIndex(newValue);
        },
        [addUrlParameters, getTabNameFromIndex, routerParameterName],
    );

    /**
     * Init composant
     */

    // Met à jour le tab quand le parametre d'url existe.
    useEffect(() => {
        const tabIndex = getTabIndexFromUrl();
        handleChange(null, tabIndex);
    }, [getTabIndexFromUrl, handleChange]);

    /**
     * render éléments
     */

    // rend les panel
    const renderPanel = () => {
        switch (status) {
            case undefined:
            case 'succeeded':
                return tabs.map((tab, index) => (
                    <TabPanel
                        key={tab.tabTitle}
                        value={activeTabIndex}
                        index={index}
                    >
                        {/* {tab.tabPanel} */}
                        <Box sx={tabWrapperStyle}>{tab.tabPanel}</Box>
                    </TabPanel>
                ));
            case 'idle':
            case 'loading':
                return <CenterCircularProgress />;
            default:
                return <Typography>Une erreur lors du chargement est survenue</Typography>;
        }
    };

    // rend les items de la navigation
    const renderNavigationItem = () =>
        tabs.map(({ tabTitle, disabled, count }) => (
            <Tab
                disabled={disabled ?? false}
                aria-disabled={disabled ?? false}
                data-testid={tabTitle}
                label={
                    <Box display="flex">
                        <Typography
                            variant="h5"
                            fontWeight="bold"
                            sx={{ opacity: disabled ? '0.4' : '1' }}
                        >
                            {tabTitle}
                        </Typography>
                        {count && count > 0 && (
                            <Box sx={{ marginX: '10px' }}>
                                <CountBadge count={count} />
                            </Box>
                        )}
                    </Box>
                }
                key={tabTitle}
            />
        ));

    // rend la navigation du tab
    const renderNavigationRoot = () => (
        <Tabs
            ref={tabRef}
            value={activeTabIndex}
            textColor={orientation === 'horizontal' ? 'secondary' : 'primary'}
            indicatorColor={activeTabIndicatorColor}
            onChange={handleChange}
            orientation={orientation}
            sx={
                orientation === 'vertical'
                    ? {
                          borderRight: 1,
                          borderColor: 'divider',
                      }
                    : {
                          borderBottom: 1,
                          borderColor: 'divider',
                      }
            }
        >
            {renderNavigationItem()}
        </Tabs>
    );

    // rend la box du tabpanel
    const renderTab = () => (
        <>
            {renderNavigationRoot()}
            <Box
                sx={
                    orientation === 'vertical'
                        ? {
                              padding: '0px 40px',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: `${gap}px`,
                              width: '100%',
                          }
                        : {
                              height: scrollbar
                                  ? `calc(100vh - ${TopbarHeight}px - ${gap}px - ${heightRef}px - ${footerHeight}px - ${breadcrumbHeight}px ) `
                                  : '100%',
                          }
                }
            >
                <Scrollbar>{renderPanel()}</Scrollbar>
            </Box>
        </>
    );

    // Box + incard qui ajoute le tabpanel dans une card
    return (
        <Box
            sx={
                orientation === 'vertical'
                    ? {
                          flexGrow: 1,
                          display: 'flex',
                          ...sx,
                      }
                    : { ...sx }
            }
        >
            {inCard ? <Card sx={tabStyle}>{renderTab()}</Card> : renderTab()}
        </Box>
    );
};

export default FlexyTabs;
