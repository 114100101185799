import { TableBody } from '@mui/material';

import React, { useEffect } from 'react';
import useSelectItem from '../FlexyDatatableTable/useSelectItem';
import { FlexyDatatableBodySuccededRow } from '../FlexyDatatableBodySuccededRow';
import { FlexyDatatableBodySuccededRowProps } from '../FlexyDatatableBodySuccededRow/FlexyDatatableBodySuccededRow';

export type FlexyDatatableBodySuccededProps<T extends Record<string, unknown>> = {
    data: Array<T>;
} & Omit<FlexyDatatableBodySuccededRowProps<T>, 'item'>;

export type FlexyDatatableData<T extends Record<string, unknown>> = T & {
    datatableIdentifier: string;
};

const FlexyDatatableBodySucceded = function <T extends Record<string, unknown>>({
    data,
    columns,
    ...rowProps
}: FlexyDatatableBodySuccededProps<T>) {
    const datatableDatas: Array<FlexyDatatableData<T>> = data.map((item, index) => ({
        ...item,
        datatableIdentifier: `datatableIdentifier${index}`,
    }));

    const { setCurrentData } = useSelectItem();

    useEffect(() => {
        setCurrentData(data);
    }, [data, setCurrentData]);

    const innerColumns = columns.filter((column) => column.isDisplayed !== false);

    return (
        <TableBody
            data-testid="FlexyDatatableBody"
            sx={{
                backgroundColor: 'transparent',
                overflow: 'hidden',
            }}
        >
            {datatableDatas.map((item: FlexyDatatableData<T>) => (
                <FlexyDatatableBodySuccededRow
                    columns={innerColumns}
                    key={item.datatableIdentifier}
                    item={item}
                    {...rowProps}
                />
            ))}
        </TableBody>
    );
};

export default FlexyDatatableBodySucceded;
