import { LazyBreadcrumb } from '@europrocurement/flexy-components';
import { customizerSelector } from '@europrocurement/flexy-components/redux/storeConfig/selectors';
import {
    ColumnDatatable,
    FiltersDatatableList,
    PreFilterDatatableList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import {
    DossierOpenSearch,
    DossierOpenSearchSelector,
    useFormaliteService,
} from '@europrocurement/l2d-domain';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SxProps } from '@mui/system';
import {
    useDossierCollection,
    DossierCollection,
    useDossierClient,
    DossierClient,
    DossierFormalite as DossierFormaliteFilter,
    DossierFormaliste,
    useDossierFormaliste,
    useDossierStatut,
    DossierStatut,
    useDossierNumero,
    useDossierSocieteReference,
    DossierSocieteReference,
    useDossierFormalite,
} from '../../hooks';
import useDossierObjectGroup, { DossierObjectGroup } from '../../hooks/useDossierObjectGroup';
import { dossiersBasePath } from '../../constants';
import {
    ActionMenu,
    PeoplePreview,
    PreviewColumn,
    DossierCompanyFormality,
    DossierStepCompletion,
    DossierDateCreation,
    DossierNotifications,
    DossierStatus,
    DossierReferences,
} from '../../components/liste';

const ListeDossiers: React.FunctionComponent = function () {
    const datasource: DataSource<DossierOpenSearch> = useSelector(DossierOpenSearchSelector);
    const { xIdSociete } = useSelector(customizerSelector);
    const { getOpenSearchDossiers } = useFormaliteService();
    const navigate = useNavigate();
    const { searchDossierFormalite } = useDossierFormalite();
    const { searchDossierObjectGroup } = useDossierObjectGroup();
    const { searchDossierNumero } = useDossierNumero();
    const { searchDossierCollection } = useDossierCollection();
    const { searchDossierSocieteReference } = useDossierSocieteReference();
    const { searchDossierClient } = useDossierClient();
    const { searchDossierFormaliste } = useDossierFormaliste();
    const { searchDossierStatut } = useDossierStatut();
    const [dossierStatuses, setDossierStatuses] = useState<Array<DossierStatut>>([]);

    useEffect(() => {
        searchDossierStatut().then((statuses) => {
            setDossierStatuses(statuses);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const preFilters: PreFilterDatatableList = {
        label: 'Filtrer par statuts',
        items: [
            {
                label: 'Tout',
                filters: [],
            },
        ],
    };

    dossierStatuses.forEach((status) => {
        preFilters.items.push({
            label: status.libelle as string,
            filters: [{ field: 'statutId', value: status.id as unknown as string }],
        });
    });

    const breadcrumbItems = [
        {
            link: '/accueil',
            icon: {
                icon: ['fasl', 'house'] as IconProp,
                sx: { display: 'flex', alignItems: 'center' },
            },
        },
        {
            link: '/dossiers',
            path: 'Mes dossiers',
        },
        'Mes dossiers',
    ];

    const columns: ColumnDatatable<DossierOpenSearch>[] = [
        {
            label: 'Mises à jour',
            render: ({
                contactPrenom,
                contactNom,
                societeRef,
                formalites,
                numero,
                documentsCount,
            }) => (
                <PreviewColumn
                    contactPrenom={contactPrenom}
                    contactNom={contactNom}
                    formalites={formalites?.map((formalite) => ({
                        id: formalite?.id as number,
                        libelle: formalite?.libelle as string,
                    }))}
                    societeRef={societeRef}
                    numero={numero}
                    documentsCount={documentsCount}
                />
            ),
            isDisplayed: true,
            sx: {
                display: {
                    xs: 'table-cell',
                    sm: 'table-cell',
                    md: 'none',
                    lg: 'none',
                    xl: 'none',
                },
            },
        },
        {
            label: 'Références dossiers',
            render: ({ societeRef, numero, collectionNumero }) => (
                <DossierReferences
                    itemReference={numero as string}
                    companyReference={societeRef as string}
                    collectionReference={collectionNumero as string}
                />
            ),
            isDisplayed: true,
            sortable: true,
            field: 'numero',
            sx: {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
            },
        },
        {
            label: 'Sociétés',
            render: ({ societeDenomination, formalites = [] }) => (
                <DossierCompanyFormality
                    libelle={societeDenomination as string}
                    formalites={formalites?.map((formalite) => ({
                        id: formalite?.id as number,
                        libelle: formalite?.libelle as string,
                    }))}
                />
            ),
            isDisplayed: true,
            sortable: true,
            field: 'societeDenomination',
            sx: {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
            },
        },
        {
            label: 'Col...',
            render: ({ contactNom, contactPrenom }) => (
                <PeoplePreview
                    firstName={contactPrenom as string}
                    lastName={contactNom as string}
                />
            ),
            isDisplayed: true,
            sortable: true,
            field: 'contactNom',
            sx: {
                width: '64px',

                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
            },
            headerTooltip: 'Collaborateur',
        },
        {
            label: 'Étapes',
            render: ({ stepLabel, stepCompletion }) => (
                <DossierStepCompletion
                    step={stepLabel as string}
                    currentCompletion={stepCompletion as number}
                />
            ),
            isDisplayed: true,
            sortable: false,
            sx: {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
            },
        },
        {
            label: 'Dates',
            render: ({ dateCreation }) => {
                if (!dateCreation) {
                    return null;
                }

                return <DossierDateCreation date={new Date(dateCreation)} />;
            },
            isDisplayed: true,
            sortable: true,
            field: 'dateCreation',
            sx: {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
            },
        },
        {
            label: 'Notifications',
            render: ({ documentsCount }) => (
                <DossierNotifications documentsCount={documentsCount as number} />
            ),
            isDisplayed: true,
            sortable: false,
            sx: {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
            },
        },
        {
            label: 'Statut',
            render: ({ statutLibelle }) => (
                <DossierStatus
                    label={statutLibelle as string}
                    color="primary"
                />
            ),
            isDisplayed: true,
            sortable: true,
            field: 'statutLibelle',
            sx: {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
            },
        },
        {
            label: 'For...',
            render: ({ formalisteNom, formalistePrenom }) => (
                <PeoplePreview
                    firstName={formalistePrenom as string}
                    lastName={formalisteNom as string}
                />
            ),
            isDisplayed: true,
            sortable: true,
            headerTooltip: 'Formaliste',
            field: 'formaliste',
            sx: {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
                width: '64px',
            },
        },
        {
            label: 'Actions',
            displayLabel: false,
            render: () => <ActionMenu />,
            isDisplayed: true,
            sortable: false,
            sx: {
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'table-cell',
                    lg: 'table-cell',
                    xl: 'table-cell',
                },
            },
        },
    ];

    const filters: FiltersDatatableList = [
        {
            label: 'Mes groupes',
            field: 'groupId',
            type: 'selectApi',
            icon: 'folders',
            searchFunction: searchDossierObjectGroup,
            renderItem: (item: DossierObjectGroup) => item.label as string,
            valueMap: (item: DossierObjectGroup) => item.id as unknown as string,
        },
        {
            label: 'Numéro de dossier',
            field: 'numero',
            type: 'selectApi',
            icon: 'hashtag',
            searchFunction: searchDossierNumero,
            renderItem: (item: DossierCollection) => item.numero as string,
            valueMap: (item: DossierCollection) => item.numero as string,
        },
        {
            label: 'Commandes',
            field: 'collectionId',
            type: 'selectApi',
            icon: 'file-lines',
            searchFunction: searchDossierCollection,
            renderItem: (item: DossierCollection) => item.numero as string,
            valueMap: (item: DossierCollection) => item.id,
        },
        {
            label: 'Référence client',
            field: 'societeReference',
            type: 'selectApi',
            icon: 'user',
            searchFunction: searchDossierSocieteReference,
            renderItem: (item: DossierSocieteReference) => item.reference as string,
            valueMap: (item: DossierSocieteReference) => item.reference as string,
        },
        {
            type: 'selectMultipleApi',
            label: 'Clients',
            field: 'clientId',
            icon: 'building-user',
            placeholder: 'Rechercher un client',
            title: 'Client',
            getItems: searchDossierClient,
            getItemLabel: (item: DossierClient) => item.libelle as string,
            getItemValue: (item: DossierClient) => item.id as unknown as string,
            getGroupLabel: (item: DossierClient) => item.prescripteurLibelle as string,
            getGroupValue: (item: DossierClient) => item.prescripteurId as unknown as string,
        },
        {
            label: 'Formalité',
            field: 'formaliteId',
            type: 'selectMultipleApi',
            icon: 'gavel',
            placeholder: 'Rechercher une formalite',
            title: 'Formalités',
            getItems: searchDossierFormalite,
            getItemLabel: (item: DossierFormaliteFilter) => item.libelle as string,
            getItemValue: (item: DossierFormaliteFilter) => item.id as unknown as string,
            getGroupLabel: (item: DossierFormaliteFilter) => item.groupementLibelle as string,
            getGroupValue: (item: DossierFormaliteFilter) => item.groupementId as unknown as string,
        },
        {
            label: 'Formaliste',
            field: 'formalisteId',
            icon: 'circle-user',
            type: 'selectApi',
            searchFunction: searchDossierFormaliste,
            renderItem: (item: DossierFormaliste) => item.nomComplet as string,
            valueMap: (item: DossierFormaliste) => item.id,
        },
        {
            type: 'dateRange',
            label: 'Date de création',
            field: 'dateCreation',
            icon: 'calendar',
            displayFormat: (startDate: Date, endDate: Date) =>
                `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`,
        },
    ];

    return (
        <>
            <LazyBreadcrumb
                path={breadcrumbItems}
                separator=" / "
                sx={{ marginBottom: '1.5em' }}
            />
            <StoreDatatable
                dataSource={datasource}
                columns={columns}
                computeSxRow={(item: DossierOpenSearch) =>
                    item.critical
                        ? ({
                              '&>:first-child': {
                                  borderLeft: '4px solid',
                                  borderLeftColor: 'error.main',
                              },
                          } as SxProps)
                        : {}
                }
                fetchData={getOpenSearchDossiers}
                localStorageKey="FlexyStoreDatatable"
                observers={[xIdSociete]}
                sx={{ marginBottom: '2.25em' }}
                preFilters={preFilters}
                filters={filters}
                onClickRow={(_: React.MouseEvent<HTMLElement>, { id }: DossierOpenSearch) => {
                    navigate(`/${dossiersBasePath}/${id}`);
                }}
                rowsActions={[
                    {
                        icon: 'fa-folders',
                        label: 'Déplacer dans un groupe',
                        onClick: console.log,
                    },
                    {
                        icon: 'fa-paper-plane',
                        label: 'Envoyer un récapitulatif',
                        onClick: console.log,
                    },
                ]}
            />
        </>
    );
};

export default ListeDossiers;
