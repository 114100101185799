import { ModificationDate, Toolbar, ToolbarAction } from '@europrocurement/flexy-components';
import { ordersBasePath } from '@europrocurement/l2d-modules/modules/Orders/constants';
import React from 'react';
import { Box } from '@mui/material';
import { dossiersBasePath } from '../../../constants';

export type DetailDossierToolbarProps = {
    id: number;
    reference: string;
    orderId: number;
    orderReference: string;
    createdAt: string;
};

const DetailDossierToolbar: React.FunctionComponent<DetailDossierToolbarProps> = function (props) {
    const { id, reference, orderId, orderReference, createdAt } = props;

    const breadcrumpPath = [
        {
            link: `/${ordersBasePath}/${orderId}`,
            path: `Commande #${orderReference}`,
        },
        {
            link: `/${dossiersBasePath}/${id}`,
            path: `Dossier #${reference}`,
        },
        '',
    ];

    const toolbarActions: ToolbarAction[] = [
        {
            onClick: () => {},
            icon: ['fasl', 'paper-plane'],
        },
        {
            onClick: () => {},
            icon: ['fasl', 'user-plus'],
        },
        {
            onClick: () => {},
            icon: ['fasl', 'ellipsis'],
        },
    ];

    return (
        <Toolbar
            path={breadcrumpPath}
            actions={toolbarActions}
        >
            <Box sx={{ opacity: 0.5 }}>
                <ModificationDate
                    date={new Date(createdAt)}
                    isCreationDate
                />
            </Box>
        </Toolbar>
    );
};

export default DetailDossierToolbar;
