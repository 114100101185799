import { MenuEntry } from '@europrocurement/flexy-components';
import { listeDossiersFullPath } from '@europrocurement/l2d-modules/modules/Dossiers/constants';

const menuEntries: MenuEntry[] = [
    {
        title: 'Accueil',
        icon: 'house',
        href: '/accueil',
    },
    {
        title: 'Mes dossiers',
        icon: 'folders',
        href: listeDossiersFullPath,
    },
    {
        title: 'Sociétés',
        icon: 'building-user',
        href: 'societes/',
    },
];

export default menuEntries;
