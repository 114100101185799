export const FORMALITES_REDUCER_NAME = 'formalites';
export const CATEGORY_SLICE_NAME = 'categories_formalites';
export const FORMALITE_DOCUMENT_SLICE_NAME = 'formaliteDocuments';
export const DOSSIERS_FORMALITES_SLICE_NAME = 'dosform';
export const DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME = 'autocompleteDossiersFormalites';
export const FORMALITES_SLICE_NAME = 'formalities';
export const LEGAL_STATUS_SLICE_NAME = 'legalStatuses';
export const FORMALISTE_SLICE_NAME = 'formalistes';
export const STATUS_SLICE_NAME = 'statuses';
export const DOSSIERS_OPEN_SEARCH_SLICE_NAME = 'dossiersOpenSearch';
export const DOSSIER_STEP_SLICE_NAME = 'dossierSteps';
