import React from 'react';
import { FlexyTabs, TabStructure } from '@europrocurement/flexy-components';
import InformationsTab from './InformationsTab';

const DetailDossierBody: React.FunctionComponent = function () {
    const tabs: Array<TabStructure> = [
        {
            tabName: 'details',
            tabTitle: 'Détails du dossier',
            tabPanel: <InformationsTab />,
        },
    ];

    return (
        <FlexyTabs
            routerParameterName="dossier-detail"
            sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}
            tabs={tabs}
            scrollbar={false}
            indicatorColor="primary"
        />
    );
};

export default DetailDossierBody;
