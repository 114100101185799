import React from 'react';
import {
    selectedPrescripteursSelector,
    useTiersServiceSelector,
} from '@europrocurement/l2d-domain';
import { UploaderProvider } from '@europrocurement/flexy-components';
import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';
import { ParcoursFormaliteProvider } from './provider';
import ParcoursFormaliteOutlet from './ParcoursFormaliteOutlet';

const ParcoursFormaliteRoot: React.FunctionComponent = function () {
    // const prescripteur = useSelectedPrescriber();
    const prescripteur = useTiersServiceSelector(selectedPrescripteursSelector);

    const [sideBareInit, setSideBareInit] = React.useState(false);

    const { setSidebarOpen } = useFullLayout();

    React.useEffect(() => {
        if (!sideBareInit) {
            setSidebarOpen(false);
            setSideBareInit(true);
        }
    }, [setSidebarOpen, sideBareInit]);

    return prescripteur ? (
        <ParcoursFormaliteProvider
            prescripteur={prescripteur}
            // defaultCollectionId={373}
            // defaultDossierId={1005248}
        >
            <UploaderProvider>
                <ParcoursFormaliteOutlet />
            </UploaderProvider>
        </ParcoursFormaliteProvider>
    ) : null;
};

export default ParcoursFormaliteRoot;
