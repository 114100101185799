import React, { useContext } from 'react';
import { DetailDossierToolbar, DetailDossierTitle } from '../../components/detail';
import { SelectedDossierContext } from '../../providers';

const DetailDossierHeader: React.FunctionComponent = function () {
    const { dossier } = useContext(SelectedDossierContext);

    return (
        <>
            <DetailDossierToolbar
                id={dossier.id as number}
                reference={dossier.numero as string}
                orderId={dossier.collectionId as number}
                orderReference={dossier.collectionNumero as string}
                createdAt={dossier.dateCreation as string}
            />
            <DetailDossierTitle
                color="primary"
                title={dossier.societeDenomination as string}
                statusName={dossier.statutLibelle as string}
                clientReference={dossier.societeRef as string}
                prescriberName={dossier.prescripteurLibelle as string}
                clientName={dossier.clientLibelle as string}
            />
        </>
    );
};

export default DetailDossierHeader;
