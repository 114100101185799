import { DossierOpenSearch } from '@europrocurement/l2d-domain';
import { createContext } from 'react';

const selectedDossier: DossierOpenSearch = { formalites: [] };
const setSelectedDossier: React.Dispatch<React.SetStateAction<DossierOpenSearch>> = () => {};
const siblings: Array<DossierOpenSearch> = [];
const setSiblings: React.Dispatch<React.SetStateAction<DossierOpenSearch[]>> = () => {};

const SelectedDossierContext = createContext({
    dossier: selectedDossier,
    setDossier: setSelectedDossier,
    siblings,
    setSiblings,
});

export default SelectedDossierContext;
