import React from 'react';
import { Avatar, Box, lighten, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { InformationChip } from '@europrocurement/flexy-components';

const themeColor = ['primary', 'secondary', 'warning', 'success', 'error'] as const;
type ThemeColor = (typeof themeColor)[number];
type HexColor = string;

export type DetailDossierTitleProps = {
    title: string;
    statusName: string;
    prescriberName: string;
    clientReference: string;
    clientName: string;
    icon?: IconName;
    color?: ThemeColor | HexColor;
};

const DetailDossierTitle: React.FunctionComponent<DetailDossierTitleProps> = function (props) {
    const {
        title,
        statusName,
        prescriberName,
        clientReference,
        clientName,
        icon = 'folder',
        color = 'primary',
    } = props;
    const isThemeColor = (str: string): str is ThemeColor => themeColor.includes(str as ThemeColor);
    const { palette, breakpoints } = useTheme();
    const downMdBreakpoint = useMediaQuery(breakpoints.down('md'));
    const baseColor = isThemeColor(color) ? palette[color].main : color;
    const avatarStyle = {
        display: 'flex',
        borderRadius: '8px',
        backgroundColor: lighten(baseColor, 0.92),
        minHeight: '75px',
        minWidth: '75px',
    };
    const titleWrapperStyle = {
        display: 'flex',
        flexDirection: 'row',
        gap: '0 8px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '0',
    };
    const titleStyle = {
        maxWidth: '50%',
        overflow: 'hidden',
        textWrap: 'nowrap',
        textOverflow: 'ellipsis',
    };

    if (downMdBreakpoint) {
        avatarStyle.display = 'none';
        titleWrapperStyle.flexDirection = 'column';
        titleWrapperStyle.gap = '8px 0';
        titleWrapperStyle.alignItems = 'flex-start';
        titleWrapperStyle.marginBottom = '0.5em';
        titleStyle.maxWidth = '100%';
    }

    return (
        <Box sx={{ display: 'flex', gap: '0 24px', marginBottom: '16px' }}>
            <Avatar sx={avatarStyle}>
                <FaOptionIcon
                    icon={['fasl', icon]}
                    color={color}
                />
            </Avatar>
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Box sx={titleWrapperStyle}>
                    <Tooltip title={title}>
                        <Typography
                            variant="l2dh5"
                            sx={titleStyle}
                        >
                            {title}
                        </Typography>
                    </Tooltip>
                    <Box sx={{ display: 'flex', gap: '0 8px', alignItems: 'center' }}>
                        <InformationChip
                            color="secondary"
                            label={
                                <Typography
                                    variant="l2dbodys"
                                    color="text.secondary"
                                >
                                    Ref.Client - {clientReference}
                                </Typography>
                            }
                        />
                        <InformationChip
                            color={color}
                            label={
                                <Typography
                                    variant="l2dbodys"
                                    color={baseColor}
                                >
                                    {statusName}
                                </Typography>
                            }
                        />
                    </Box>
                </Box>
                <Typography
                    variant="l2dbodys"
                    color="text.secondary"
                >
                    Pour {clientName}, par {prescriberName}
                </Typography>
            </Box>
        </Box>
    );
};

export default DetailDossierTitle;
