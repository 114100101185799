import React from 'react';
import { RouteObject } from 'react-router-dom';
import { ordersBasePath, orderDetailPath } from './constants';
import OrdersRoot from './ordersRoot';
import OrderDetail from './pages/detail/OrderDetail';

export const OrdersRouter: RouteObject = {
    path: ordersBasePath,
    element: <OrdersRoot />,
    children: [
        {
            path: orderDetailPath,
            element: <OrderDetail />,
        },
    ],
};
