import React from 'react';

import {
    BlankLayout,
    NotFound,
    Forbidden,
    Unauthorized,
    NoPrescriber,
} from '@europrocurement/flexy-components';
import { Navigate } from 'react-router';
import { KeycloakRoute } from '@europrocurement/l2d-keycloak';
import {
    Debug,
    DossiersRouter,
    OrdersRouter,
    ParcoursFormaliteRouter,
    SocietesRouter,
} from '@europrocurement/l2d-modules';
import { B2DSymboleIcon } from '@europrocurement/l2d-icons';
import { getCustomiserState } from '@europrocurement/l2d-domain';
import { Link } from 'react-router-dom';
import L2DFlayout from './L2DFLatout';

import { version } from '../../package.json';
import Accueil from '../pages/Accueil/Accueil';
import menuEntries from './menuEntries';
import { SelectSocieteRouter } from '../pages/SelectSociete/SelectSocieteRouter';

const Router = [
    {
        path: '/',
        element: (
            <L2DFlayout
                menuEntries={menuEntries}
                footerProps={{
                    children: (
                        <>
                            <B2DSymboleIcon style={{ maxHeight: '24px' }} />
                            {getCustomiserState().env === 'prod' ? null : (
                                <Link to="/debug">debug</Link>
                            )}
                        </>
                    ),
                }}
            />
        ),
        children: [
            { path: '/', element: <Navigate to="/accueil" /> },
            {
                path: 'accueil',
                exact: true,
                element: <Accueil />,
            },
            {
                path: 'debug',
                element:
                    getCustomiserState().env === 'prod' ? (
                        <KeycloakRoute roles="realm:debug">
                            <Debug panelEnvironnement={{ version }} />
                        </KeycloakRoute>
                    ) : (
                        <Debug panelEnvironnement={{ version }} />
                    ),
            },
            SelectSocieteRouter,
            ParcoursFormaliteRouter,
            DossiersRouter,
            OrdersRouter,
            SocietesRouter,
        ],
    },
    {
        path: '/errors',
        element: <BlankLayout />,
        children: [
            { path: '404', element: <NotFound /> },
            { path: '403', element: <Forbidden /> },
            { path: '401', element: <Unauthorized /> },
            {
                path: 'noPrescriber',
                element: <NoPrescriber />,
            },
        ],
    },
    {
        path: '*',
        element: <NotFound />,
    },
];

export default Router;
