import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { CenterCircularProgress } from '@europrocurement/flexy-components';
import { parcoursFormalitePathResolver } from '../parcoursFormalitePathResolver';
import { useParcoursFormalite } from '../provider';
import CurrentFormalityAndClient from '../components/currentFormalityAndClient';

const StepNextForm: React.FunctionComponent = function () {
    const navigate = useNavigate();

    const { fetchForm, refreshDossier, getCurrentDossier, setLoading } = useParcoursFormalite();

    React.useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    React.useEffect(() => {
        const hook = async () => {
            setLoading(true);
            const doss = getCurrentDossier();
            if (doss && doss.id) {
                await refreshDossier(doss.id);
            }
            await fetchForm();
            navigate(parcoursFormalitePathResolver('stepForm'));
        };
        hook();
    }, [fetchForm, getCurrentDossier, navigate, refreshDossier, setLoading]);

    return (
        <Box>
            <CurrentFormalityAndClient />
            <CenterCircularProgress sx={{ height: '500px' }} />
        </Box>
    );
};

export default StepNextForm;
