import React, { useState, useEffect } from 'react';
import { Box, Input, useTheme } from '@mui/material';
import { ObjectGroupList, ObjectGroupType } from '@europrocurement/flexy-components';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faSearch } from '@fortawesome/pro-duotone-svg-icons';
import {
    FilterOptionValue,
    FlexyFilterValue,
    FlexyFilterWidgetProps,
    StaticSelectFilter,
} from '../DatatableHeader';

type FlexySelectFilterProps = Omit<FlexyFilterWidgetProps, 'filter'> & {
    filter: StaticSelectFilter;
};

const FlexySelectFilter: React.FunctionComponent = function (props: FlexySelectFilterProps) {
    const { filter, value, onChange } = props;
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectValue, setSelectValue] = useState<FlexyFilterValue | null>(null);
    const theme = useTheme();

    useEffect(() => {
        setSelectValue(value);
    }, [value]);

    const handleChange = (objectGroup: ObjectGroupType) => {
        setSelectValue(String(objectGroup.id));
        onChange(String(objectGroup.id));
    };

    const options = filter.options
        .filter((option: FilterOptionValue) =>
            option.label.toLowerCase().match(searchTerm.toLowerCase()),
        )
        .map((option: FilterOptionValue) => ({
            id: option.value as unknown as number,
            type: filter.field,
            label: filter.renderFilterList ? filter.renderFilterList(option.value) : option.label,
        }));

    return (
        <Box sx={{ width: '100%', padding: '10px' }}>
            <Box
                sx={{
                    borderColor: theme.palette.grey[400],
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    display: 'flex',
                    borderRadius: '12px',
                    alignItems: 'center',
                    padding: '0 10px',
                }}
            >
                <FaOptionIcon
                    color={theme.palette.text.primary}
                    icon={faSearch}
                />
                <Input
                    placeholder={filter.label}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    fullWidth
                    disableUnderline
                    inputProps={{
                        sx: {
                            color: theme.palette.text.primary,
                            height: '48px',
                            boxSizing: 'border-box',
                            padding: '8px 16px;',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '32px',
                            textAlign: 'left',
                        },
                    }}
                />
            </Box>
            <Box
                sx={{
                    padding: '5px 0',
                    overflowY: 'scroll',
                    maxHeight: '220px',
                }}
            >
                <Box
                    sx={{
                        color: theme.palette.text.primary,
                        padding: '0px 14px',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '25.2px',
                        textAlign: 'left',
                        marginBottom: '5px',
                    }}
                >
                    {filter.label}
                </Box>
                <ObjectGroupList
                    selectedId={selectValue as string}
                    objectGroups={options}
                    onClick={handleChange}
                />
            </Box>
        </Box>
    );
};

export default FlexySelectFilter;
